export const LENGTHS = [60, 90, 120];
export const MONTHLY = 'monthly';
export const ANNUAL = 'annual';

export const SOOTHE_PLUS_DEFAULT = {
  subscription_tier_id: null,
  length: LENGTHS[0],
  amount: null,
  city_id: null,
  credit_card_id: null,
  short_benefits: null,
  percentage: null,
};

export const VIEWS = {
  INDEX: 'INDEX',
  SUBSCRIPTION_DETAILS: 'SUBSCRIPTION_DETAILS',
  SUBSCRIPTION_SUMMARY: 'SUBSCRIPTION_SUMMARY',
  SUBSCRIBED_CONFIRMATION: 'SUBSCRIBED_CONFIRMATION',
  CANCELLED_CONFIRMATION: 'CANCELLED_CONFIRMATION',
};
