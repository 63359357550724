/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  camelCase, compact, filter, get, identity, isEmpty,
  join, map, mapKeys, merge, orderBy, pick, pickBy, size, snakeCase, take,
} from 'lodash';
import { defaultErrorMessage } from '../../../constants';
import PlaceAutoCompleteInput from '../../Shared/PlaceAutoCompleteInput';
import TextWithIcon from '../../Shared/TextWithIcon';
import { createRebookCart, getProductsByProvider, getUserAddresses } from '../Shared/helpers';
import grayMarker from '../../../Assets/Images/places_input_marker.png';
import orangeMarker from '../../../Assets/Images/selected_place_input_marker.png';
import {
  addressAddNew, addressSelection, seBookingAddressInput, seBookingServiceSelect,
} from '../../Shared/WebAnalytics';
import ErrorModal from '../../Shared/ErrorModal';
import { createAddress, updateCart } from '../../BookingFlowRedesign/Shared/helpers';
import { setCart, setProducts } from '../../../Actions';
import { decode } from '../Shared/encode';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import FinishAddressStep from '../../BookingFlowRedesign/FinishAddressStep';
import FinishAddressStep2 from '../../BookingFlowForMarketplace/FinishAddressStep';

class AddressList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      displayAll: false,
      width: window.innerWidth,
      error: '',
      showErrorModal: false,
      redirect: false,
      openFinishAddressModal: false,
      addressError: '',
    };
    this.fetchAddressesByUserId = this.fetchAddressesByUserId.bind(this);
    this.placesInput = this.placesInput.bind(this);
    this.savedAddresses = this.savedAddresses.bind(this);
    this.viewMoreButton = this.viewMoreButton.bind(this);
    this.getProductsByZipcode = this.getProductsByZipcode.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.setSelectedAddress = this.setSelectedAddress.bind(this);
    this.setDataAndNext = this.setDataAndNext.bind(this);
    this.creatRebookCartOrUpdate = this.creatRebookCartOrUpdate.bind(this);
    this.cartCallBack = this.cartCallBack.bind(this);
    this.finishAddressUI = this.finishAddressUI.bind(this);
    this.goToNext = this.goToNext.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    this.fetchAddressesByUserId();
  }

  fetchAddressesByUserId() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const userId = get(this.props, 'client.user_id', '');
    getUserAddresses(userId, loggedIn, (response) => {
      let { addresses } = response.data;
      addresses = orderBy(addresses.map((address) => mapKeys(address, (_value, key) => camelCase(key))), ['default'], ['desc']);
      this.setState({ addresses });
    }, (e) => {
      const message = get(e, 'response.data.errors.0.message', defaultErrorMessage);
      this.setState({ showErrorModal: true, error: message });
    });
  }

  finishAddressUI() {
    const isABTester = get(this.props, 'abTest', '') === 'new_flow';
    if (isABTester) {
      return (
        <FinishAddressStep2
          closeDrawer={() => {
            this.setState({ openFinishAddressModal: false });
          }}
          assignToCart={(val) => {
            this.props.setCart({
              ...this.props.booking,
              ...val,
            });
            this.goToNext(true);
          }}
          displayError={(error) => {
            this.setState({ error, showErrorModal: true });
          }}
        />
      );
    }
    return (
      <FinishAddressStep
        closeDrawer={() => {
          this.setState({ openFinishAddressModal: false });
        }}
        assignToCart={(val) => {
          this.props.setCart({
            ...this.props.booking,
            ...val,
          });
          this.goToNext(true);
        }}
        displayError={(error) => {
          this.setState({ error, showErrorModal: true });
        }}
      />
    );
  }

  setDataAndNext(products) {
    if (!isEmpty(products)) {
      this.props.setProducts({ products });
      const address = merge({
        has_dogs: false, has_cats: false, has_table: false, has_sheets: false,
      }, pick(pickBy(mapKeys(this.state.address, (_value, key) => snakeCase(key)), identity), ['address_line_1', 'address_line_2', 'city', 'state', 'zip_code', 'parking_info', 'parking_type', 'flights_of_stairs', 'hotel_name', 'hotel_room_number', 'gate_door_code', 'has_dogs', 'has_cats', 'has_table', 'has_sheets', 'mobile_number', 'id', 'complete', 'default', 'market']), { client_id: this.props.client.user_id });
      if (!address.address_line_1) {
        address.address_line_1 = this.state.addressLine1;
        address.zip_code = this.state.zipCode;
      }

      this.setSelectedAddress(address);
    } else {
      this.setState({ error: 'We currently do not service this address. Please try another one.', showErrorModal: true });
    }
  }

  getProductsByZipcode() {
    const self = this;
    const { address } = this.state;
    const {
      country, city, zipCode, cityId, addressLine1, state,
    } = address;
    if (!(zipCode && addressLine1 && (city || cityId) && state)) {
      this.setState({ error: 'Please select a location with a street address', showErrorModal: true });
      return;
    }
    getProductsByProvider(get(this.props, 'providerId', ''), (response) => {
      let { products } = response.data;
      products = filter(products, (el) => (el.bookable));
      seBookingAddressInput(country, city, zipCode, products && products.length > 0);
      self.setDataAndNext(products);
    }, (e) => {
      const message = get(e, 'response.data.errors.0.message', defaultErrorMessage) || get(e, 'response.data.errors.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  goToNext(_skip = false) {
    // TODO revisit update flow
    // let isCompleteAddress = get(this.props, "booking.cart.address.complete", false);
    // if (isCompleteAddress) {
    const products = get(this.props, 'products', []);
    if (size(products) === 1) {
      this.props.setCart({
        ...this.props.booking,
        product: products[0],
      });
      // analytics
      // TODO control or variant here.. may not be relevant in some cases to keep it as controlled
      const market = get(this.props, 'booking.cart.address.market', '');
      const loggedIn = get(this.props, 'client.loggedIn', false);
      seBookingServiceSelect(get(this.props, 'booking.cart', null), products[0].title.toLowerCase(), get(this.props, 'client.first_time_booker', null), 'booking_23_control', loggedIn, market, "jul_2024_booking_enhancements", get(this.props, "client.ab_tests.jul_2024_booking_enhancements", ""));
    }
    this.setState({ redirect: true });
    // }
    // else if (skip) {
    //     this.setState({ openFinishAddressModal: false })
    // } else {
    //     this.setState({ openFinishAddressModal: true })
    // }
  }

  cartCallBack(response) {
    this.props.setCart({
      ...this.props.booking,
      ...{ cart: decode(response.data.cart) },
    });
    this.goToNext();
  }

  creatRebookCartOrUpdate() {
    const addressId = get(this.props, 'booking.cart.address.id', '');
    const cartId = get(this.props, 'booking.cart.id', '');
    const proId = get(this.props, 'providerId', '');
    const isRebookCart = get(this.props, 'booking.cart.rebook', false);
    if (addressId && proId) {
      if (cartId && isRebookCart) {
        updateCart(cartId, { address_id: addressId }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
          this.cartCallBack(response);
        }, (err) => this.setState({ error: get(err, 'response.data.errors.0.message', defaultErrorMessage), showErrorModal: true }));
      } else {
        createRebookCart(addressId, proId, (resp_2) => {
          this.cartCallBack(resp_2);
        }, (err2) => this.setState({ error: get(err2, 'response.data.errors.0.message', defaultErrorMessage), showErrorModal: true }));
      }
    }
  }

  setSelectedAddress(selectedAddress) {
    let oldCart = {}; let oldBooking = {};
    const isRebookCart = get(this.props, 'booking.cart.rebook', false);
    if (isRebookCart) {
      oldCart = get(this.props, 'booking.cart', {});
      oldBooking = get(this.props, 'booking', {});
    }
    if (!get(selectedAddress, 'id', '')) {
      const self = this;
      createAddress(selectedAddress, (resp) => {
        addressAddNew(get(resp, 'data.address.id', ''), 'appointment_details_page', 'booking_23_control', 'No', 'No', get(resp, 'data.address.market', '') || get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "client.ab_tests.jul_2024_booking_enhancements", ""));
        self.props.setCart({ ...oldBooking, cart: { ...oldCart, address: get(resp, 'data.address', null) } });
        this.creatRebookCartOrUpdate();
      }, (err) => this.setState({ error: get(err, 'response.data.errors.0.message', defaultErrorMessage), showErrorModal: true }));
    } else {
      const market = get(selectedAddress, 'market', '') || get(this.props, 'booking.cart.address.market', '');
      this.props.setCart({ ...oldBooking, cart: { ...oldCart, address: selectedAddress } });
      addressSelection(get(oldCart, 'id', ''), get(selectedAddress, 'id', ''), 'no', 'booking_23_control', market, "jul_2024_booking_enhancements", get(this.props, "client.ab_tests.jul_2024_booking_enhancements", ""));
      this.creatRebookCartOrUpdate();
    }
  }

  placesInput() {
    return (
      <>
        <PlaceAutoCompleteInput
          uniqueId="special-request-address-list"
          inputStylingClass={`form-control add-a-new-address-input-style ${this.state.addressError ? 'mb-0-imp' : ''}`}
          handleAddress={(newAddress) => {
            this.setState({ address: newAddress, addressError: '' }, () => this.getProductsByZipcode());
          }}
          placeholder={this.state.width < 800 ? 'Add a new address' : 'Add a new address, hotel, or business'}
          inCaseOfError={() => {
            this.setState({ addressError: 'Please select a location with a street address' });
          }}
        />
        {this.state.addressError
          ? <div className="size-14-20 color-red mt-8 medium-font mb-32">{this.state.addressError}</div>
          : null}
      </>
    );
  }

  savedAddresses() {
    let { addresses, displayAll } = this.state;
    if (isEmpty(addresses)) {
      return null;
    }
    if (!displayAll) {
      addresses = take(addresses, 3);
    }
    return map(addresses, (record, itr) => {
      const {
        id, addressLine1, city, state, zipCode, country,
      } = record;
      const isSelected = Boolean(get(this.props, 'booking.cart.address.id') === id);
      return (
        <div
          key={`address-selection-${itr}`}
          className="border-radius-8 br-gray mb-16 p-0-8 cursor-pointer"
          onClick={() => {
            this.setState({ address: record }, () => this.getProductsByZipcode());
          }}
        >
          <TextWithIcon
            icon={isSelected ? orangeMarker : grayMarker}
            title={addressLine1 || ''}
            details={join(compact([city, `${state || ''} ${zipCode || ''}`, country]), ', ')}
          />
        </div>
      );
    });
  }

  viewMoreButton() {
    const { addresses, displayAll } = this.state;
    const ln = size(addresses);
    if (ln <= 3) { return null; }
    return (
      <button
        type="button"
        className="btn bg-light-blue p-16-20 color-accent border-radius-30 medium-font"
        onClick={() => this.setState({ displayAll: !displayAll })}
      >
        {displayAll ? 'View less' : 'View more'}
      </button>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const products = get(this.props, 'products', []);
    const booking = get(this.props, 'booking', {});
    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: size(products) === 1 ? '/booking/MODALITY' : '/booking/SERVICE',
          state: {
            booking,
            products,
          },
        }}
        />
      );
    }
    return (
      <>
        {/* <LatestModalDesign isOpen={this.state.openFinishAddressModal}
                close={() => this.setState({ openFinishAddressModal: false })}
                hideFooter={true}
            >{this.finishAddressUI()}
            </LatestModalDesign> */}

        <LatestModalDesign
          isOpen={this.props.isAddressModalOpen && !this.state.openFinishAddressModal}
          title="Confirm where you would like your provider to meet you."
          close={this.props.hideModal}
          hideFooter
        >
          {' '}
          <div className="mb-150">
            {this.placesInput()}
            {this.savedAddresses()}
            {this.viewMoreButton()}
          </div>
        </LatestModalDesign>

        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  booking: state.booking,
  products: get(state, 'productsReducer.products', []),
  abTest: state.abTest,
  fieldsHolder: state.fieldsHolder,
});
export default connect(mapStateToProps, { setCart, setProducts })(AddressList);
