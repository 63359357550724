import React from 'react';
import { Star } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import { get } from 'lodash';
import { connect } from 'react-redux';
import ProProfileDetails from './ProProfileDetails';

class VerticalDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.responsiveProCard = this.responsiveProCard.bind(this);
  }

  responsiveProCard() {
    const { therapist, width } = this.props;
    if (width <= 800) {
      return (
        <div className="p-16-16-22 br-b-opaque">
          <div className="d-flex-only">
            <div className="flex-grow-1">
              <div className="mb-4">
                <div className="size-14-20 mb-4">
                  <span className="contentTertiary">
                    <Star className="size-14-20 CactusFlowerPrimary" />
                    {' '}
                    {therapist.rating || ''}
                    {' '}
                    {therapist.rated ? `(${therapist.rated})` : ''}
                  </span>
                </div>
                <div className="size-22-30 font-weight-bold contentPrimary">
                  {therapist.first_name || ''}
                  {' '}
                  {therapist.last_name[0]}
                  .
                </div>
              </div>
              <div>
                <div className="size-14-20 contentSecondary mb-16 sm-ellipsis-2">{therapist.bio || therapist.type || ''}</div>
              </div>
            </div>
            <div className="">
              <img src={get(therapist, 'avatar_url', '')} className="w-h-80 obj-fit-cover br-rd-50 br-opaque" alt="" />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn size-14-16 p-10-12 font-weight-bold  br-rd-9999 bg-cactus-flower contentInversePrimary mr-8"
              onClick={() => this.props.selectPro(therapist.id, therapist.time || this.props.time || '', get(therapist, 'match_type', ''))}
            >
              Book at
              {therapist.time || this.props.time || ''}
            </button>
            <button
              type="button"
              className="btn size-14-16 p-10-12 font-weight-bold br-rd-9999 contentPrimary bg-gray-100"
              onClick={() => {
                this.setState({ open: true });
              }}
            >
              View profile
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="mb-48">
        <div className="mb-16 height-266 br-rd-8">
          <img
            src={get(therapist, 'avatar_url', '')}
            className="w-100-perc height-266 obj-fit-cover br-rd-8"
            alt=""
          />
        </div>
        <div className="mb-12">
          <div className="size-14-20">
            <span className="contentTertiary">
              <Star className="CactusFlowerPrimary" />
              {' '}
              {therapist.rating || ''}
              {' '}
              {therapist.rated ? `(${therapist.rated})` : ''}
            </span>
          </div>
          <div className="size-24-32 font-weight-bold contentPrimary">
            {therapist.first_name || ''}
            {' '}
            {therapist.last_name[0]}
            .
          </div>
        </div>
        <div>
          <div className="size-14-20 contentSecondary mb-16 height-60 ellipsis-3 sm-ellipsis-2">{therapist.bio || therapist.type || ''}</div>
        </div>
        <div>
          <button
            type="button"
            className="btn size-14-16 p-14-16 font-weight-bold  br-rd-9999 bg-cactus-flower contentInversePrimary mr-10"
            onClick={() => this.props.selectPro(therapist.id, therapist.time || this.props.time || '', get(therapist, 'match_type', ''))}
          >
            Book at
            {therapist.time || this.props.time || ''}
          </button>
          <button
            type="button"
            className="btn size-14-16 p-14-16 font-weight-bold br-rd-9999 contentPrimary bg-gray-100"
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            View profile
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { open } = this.state;
    const { therapist } = this.props;
    return (
      <div>
        <Drawer style={{ zIndex: 999999999999 }} anchor="right" open={open} onClose={() => this.setState({ open: false })}>
          {open ? (
            <ProProfileDetails
              fieldsHolder={this.props.fieldsHolder}
              pro={therapist}
              showProDetailsToggle={() => this.setState({ open: false })}
              selectPro={this.props.selectPro}
              time={therapist.time || this.props.time || ''}
              errorHandler={this.props.errorHandler}
              clientId={get(this.props, 'client.user_id', '')}
              click_source="pick_a_pro"
            />
          ) : null}
        </Drawer>
        {this.responsiveProCard()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {})(VerticalDisplay);
