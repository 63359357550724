import React from 'react';
import { Person, People } from '@material-ui/icons';
import CenteredGrids from '../../Shared/CenteredGrids';
import '../Assets/Styles/index.css';
import './Assets/Styles/CouplePreferenceStep.css';

export default class Options extends React.Component {
  constructor(props) {
    super(props);
    this.couplesOptions = this.couplesOptions.bind(this);
  }

  couplesOptions() {
    const { selection, hideIcons } = this.props;
    return [
      {
        icon: hideIcons ? null : <Person style={{ color: selection === true ? '#EEE' : '#586B94', fontSize: '50px' }} />,
        title: 'Back to Back',
        subtitle: '1 Provider',
        value: true,
        action: (val) => {
          this.props.submitOption(val);
        },
        selected: selection === true,
        relevantId: 'backToBackSelection',
      },
      {

        icon: hideIcons ? null : <People style={{ color: selection === false ? '#EEE' : '#586B94', fontSize: '50px' }} />,
        title: 'Couples',
        subtitle: '2 Providers',
        value: false,
        action: (val) => {
          this.props.submitOption(val);
        },
        selected: selection === false,
        relevantId: 'atTheSameTimeSelection',
      },
    ];
  }

  render() {
    return (
      <CenteredGrids
        grids={this.couplesOptions()}
      />
    );
  }
}
