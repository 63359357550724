/* eslint-disable no-use-before-define */
import axios from 'axios';
import {
  has, get, isEmpty, omit,
} from 'lodash';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { isGuestUser } from '../../../constants';
import { hashAffiliateUtms } from '../../Shared/WebAnalytics';

/** ************************************************* */
/** **********Authentication status check*********** */
/** *********************************************** */
function urlStart(forceUser = false) {
  if (isGuestUser() && !forceUser) {
    return 'guest';
  }
  return 'v7';
}
function relevantHeader(forceUser = false, csrfToken = '') {
  if (isGuestUser() && !forceUser) {
    return { headers: { ...ACCEPT_LANGUAGE_HEADER.headers, 'X-CSRF-Token': csrfToken, withCredentials: true } };
  }
  return { headers: { ...ACCEPT_LANGUAGE_HEADER.headers, withCredentials: true } };
}

/** ********************************************************* */
/** **********Authenticated consumer apis updated*********** */
/** ******************************************************* */
export function getCartDetails(cartId, callBackFunc, errorHandler) {
  if (cartId) {
    axios.get(
      `${API_ROOT}/v7/carts/${cartId}`,
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    )
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function createCart(addressId, csrfToken, callBackFunc, errorHandler) {
  const attributionParams = hashAffiliateUtms();
  axios.post(
    `${API_ROOT}/v7/carts`,
    {
      cart: {},
      attribution: attributionParams,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      const cartId = get(response, 'data.cart.id', '');
      if (cartId) {
        updateCart(cartId, { address_id: addressId }, csrfToken, callBackFunc, errorHandler);
      }
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function updateCart(cartId, cart, csrfToken, callBackFunc, errorHandler) {
  const attributionParams = hashAffiliateUtms();
  let isCC = false;
  const payload = { cart };
  if (has(cart, 'address_id')) {
    payload.attribution = attributionParams;
  }
  if (has(cart, 'credit_card_id')) {
    isCC = true;
  }
  axios.patch(
    `${API_ROOT}/${urlStart(isCC)}/carts/${cartId}`,
    payload,
    relevantHeader(isCC, csrfToken),
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function addCartProduct(cartId, cart_product, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/carts/${cartId}/cart_products`,
    {
      cart_product,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function updateCartProduct(
  cartId,
  cart_product_id,
  cart_product,
  csrfToken,
  callBackFunc,
  errorHandler,
) {
  axios.patch(
    `${API_ROOT}/${urlStart()}/carts/${cartId}/cart_products/${cart_product_id}`,
    {
      cart_product,
    },
    relevantHeader(false, csrfToken),
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function deleteCartProduct(cartId, cart_product_id, csrfToken, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/${urlStart()}/carts/${cartId}/cart_products/${cart_product_id}`,
    relevantHeader(false, csrfToken),
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function createAddress(address, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/addresses`,
    {
      address,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getAddressDetails(addressId, callBackFunc, errorHandler) {
  axios.get(
    `${API_ROOT}/v7/addresses/${addressId}`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function updateAddress(addressId, address, callBackFunc, errorHandler) {
  axios.patch(
    `${API_ROOT}/v7/addresses/${addressId}`,
    {
      address,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getAvailableTherapists(
  cartId,
  cartProductId,
  dateUtc,
  csrfToken,
  callBackFunc,
  errorHandler,
) {
  axios.get(
    `${API_ROOT}/${urlStart()}/carts/${cartId}/cart_products/${cartProductId}/available_providers?session_date=${dateUtc}`,
    relevantHeader(false, csrfToken),
  ).then((response) => {
    const { success } = response.data;
    if (success && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getTherapistPrublicProfile(therapist_id, callBackFunc, errorHandler) {
  axios.get(
    isGuestUser() ? `${API_ROOT}/guest/public_profile/${therapist_id}` : `${API_ROOT}/therapists/${therapist_id}/public_profile`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getTherapistPrivateProfile(therapist_id, callBackFunc, errorHandler) {
  axios.get(
    isGuestUser() ? `${API_ROOT}/guest/pro_profile/${therapist_id}` : `${API_ROOT}/therapists/${therapist_id}/pro_profile`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function favoritePro(clientId, payload, csrfToken, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/clients/${clientId}/favorites`,
    payload,
    relevantHeader(false, csrfToken),
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function requestPro(
  cartId,
  cartProductId,
  cart_product,
  csrfToken,
  callBackFunc,
  errorHandler,
) {
  const cart = get(cart_product, 'cart', null);
  const additional_pros = get(cart_product, 'additional_pros', false);
  axios.post(
    `${API_ROOT}/${urlStart()}/carts/${cartId}/cart_products/${cartProductId}/request_pro`,
    {
      cart_product: omit(cart_product, ['cart', 'additional_pros']),
      cart,
      additional_pros,
    },
    relevantHeader(false, csrfToken),
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function removePro(cartId, cartProductId, csrfToken, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/${urlStart()}/carts/${cartId}/cart_products/${cartProductId}/remove_pro_request`,
    relevantHeader(false, csrfToken),
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function clearAllCartProducts(cartId, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/v7/carts/${cartId}/cart_products/clear_all`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function acceptTerms(cartId, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/v7/carts/${cartId}/accept_terms`, {
    cart: {
      accept: true,
    },
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function checkoutBooking(cartId, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/carts/${cartId}/checkout`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function useCreditCard(cartId, creditCardId, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/carts/${cartId}/use_credit_card`,
    {
      cart: {
        credit_card_id: creditCardId,
      },
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function applySubscription(cartId, cart, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/carts/${cartId}/apply_subscription`,
    { cart },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function removeSubscription(cartId, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/v7/carts/${cartId}/remove_subscription`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function applyPoints(cartId, cart, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/carts/${cartId}/apply_points`,
    { cart },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function removePoints(cartId, cart, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/v7/carts/${cartId}/remove_points`,
    { data: { cart } },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}

/** ************************************ */
/** ******* Guest Apis ***************** */
/** ************************************ */

export function initGuest(business_id, uuid, callBackFunc, errorHandler) {
  if (business_id && uuid) {
    axios.post(
      `${API_ROOT}/guest/init_guest`,
      {
        business_id,
        uuid,
      },
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    ).then((response) => {
      const { success } = response.data;
      if (success && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function getGuestCart(uuid, callBackFunc, errorHandler) {
  axios.get(
    `${API_ROOT}/guest/init_guest?uuid=${uuid}`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { success } = response.data;
    if (success && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function guestCreateCart(cart, address, csrfToken, callBackFunc, errorHandler) {
  if (!isEmpty(cart) && !isEmpty(address)) {
    axios.post(
      `${API_ROOT}/guest/carts`,
      { cart, address },
      relevantHeader(false, csrfToken),
    ).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function guestUpdateCart(cartId, cart, csrfToken, callBackFunc, errorHandler) {
  if (cartId && !isEmpty(cart)) {
    axios.patch(
      `${API_ROOT}/guest/carts/${cartId}`,
      { cart },
      relevantHeader(false, csrfToken),
    ).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function guestGetAvailableTherapists(
  cartId,
  cartProductId,
  dateUtc,
  csrfToken,
  callBackFunc,
  errorHandler,
) {
  axios.get(
    `${API_ROOT}/guest/carts/${cartId}/cart_products/${cartProductId}/available_providers?session_date=${dateUtc}`,
    relevantHeader(false, csrfToken),
  ).then((response) => {
    const { success } = response.data;
    if (success && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function guestRequestPro(
  cartId,
  cartProductId,
  cart_product,
  csrfToken,
  callBackFunc,
  errorHandler,
) {
  axios.post(
    `${API_ROOT}/guest/carts/${cartId}/cart_products/${cartProductId}/request_pro`,
    {
      cart_product,
    },
    relevantHeader(false, csrfToken),
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function guestRemovePro(cartId, cartProductId, csrfToken, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/guest/carts/${cartId}/cart_products/${cartProductId}/remove_pro_request`,
    relevantHeader(false, csrfToken),
  ).then((resp) => {
    const { result } = resp.data;
    if (result && callBackFunc) {
      callBackFunc(resp);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function updateRoomNumber(cartId, room_number, csrfToken, callBackFunc, errorHandler) {
  if (cartId) {
    axios.post(
      `${API_ROOT}/${urlStart()}/carts/${cartId}/update_address_room`,
      {
        room_number,
      },
      relevantHeader(false, csrfToken),
    ).then((resp) => {
      const { result } = resp.data;
      if (result && callBackFunc) {
        callBackFunc(resp);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}

// rebook
export function getTimesByProvider(userId, providerId, callBackFunc, errorHandler) {
  if (userId && providerId) {
    axios.get(`${API_ROOT}/clients/${userId}/therapist_rebook/${providerId}/times`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
// new booking flow - api declarations
export function getProducts(queryParam, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/v7/products${queryParam || ''}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getAddresses(userId, callBackFunc, errorHandler) {
  if (userId) {
    axios.get(`${API_ROOT}/users/${userId}/addresses`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function getClient(callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function updateTip(cartId, cartProductId, tip, callBackFunc, errorHandler) {
  if (cartId && cartProductId && tip) {
    axios.post(`${API_ROOT}/v7/carts/${cartId}/cart_products/${cartProductId}/tip`, {
      tip,
    }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
