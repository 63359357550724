/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import {
  forEach, get, includes, isEmpty, omit, omitBy, reduce, sortBy, union,
} from 'lodash';
import { NOT_BEFORE_H, hasUuid } from '../../../constants';

export const capHour = 22;

export const momentCap = (dateTime) => {
  dateTime = moment(dateTime);

  let cap = dateTime;
  if (moment(new Date()).isSame(dateTime, 'day')) {
    cap = moment(new Date()).add(NOT_BEFORE_H, 'hours');
  }

  if (cap.hours() < 8) {
    cap = cap.hours(8);
  } else if (cap.hours() > capHour || (cap.hours() === capHour && cap.minutes() !== 0)) {
    cap = cap.add(1, 'day').hours(8).minutes(0).seconds(0);
  }

  if (cap.minutes() % 15 !== 0) {
    cap.minutes(Math.ceil(cap.minutes() / 15) * 15);
  }

  return cap;
};

export const CART_DEFAULT = {
  id: null,
  status: '',
  time: {},
  date: {},
  address: {},
  billing: {},
  giftCode: '',
  backToBack: false,
  pickAProEnabled: false,
  cartProducts: [],
  subscriptions: {},
  terms: {},
  firstLoad: false,
};

export const STEPS = [
  { id: 'MENU', order: 1 },
  { id: 'AUTHENTICATION', order: 3 },
  { id: 'TIMING', order: 6, progressStep: hasUuid() ? 'CUSTOMIZE' : 'DATE' },
  { id: 'PICKAPRO', order: 7, progressStep: hasUuid() ? 'CUSTOMIZE' : 'DATE' },
  { id: 'PICKAPRO2', order: 7.5, progressStep: hasUuid() ? 'CUSTOMIZE' : 'DATE' },
  {
    id: 'REVIEW', order: 8, progressStep: 'REVIEW', hasOpaqueBg: true,
  },
  {
    id: 'CHECKOUT', order: 11, progressStep: 'CHECKOUT', hasOpaqueBg: true,
  },
  { id: 'CONFIRMATION', order: 12 },
];

export const PROGRESS_PERC = [
  { id: 'TIMING', perc: 25 },
  { id: 'PICKAPRO', perc: 50 },
  { id: 'PICKAPRO2', perc: 60 },
  { id: 'REVIEW', perc: 75 },
  { id: 'CHECKOUT', perc: 100 },
];

export const PROGRESS_STEPS = [
  { id: 'FIND_A_SERVICE', order: 1, title: 'Find a service' },
  { id: 'CUSTOMIZE', order: 2, title: 'Customize' },
  { id: 'DATE', order: 3, title: 'Date' },
  { id: 'REVIEW', order: 4, title: 'Review' },
  { id: 'CHECKOUT', order: 5, title: 'Checkout' },
];

export const RECIPIENT_OPTIONS = [
  {
    id: 'CURRENT_USER', title: 'Just me', personsCount: 1, userIncluded: true,
  },
  {
    id: 'CURRENT_PLUS_ONE', title: 'Me + 1', personsCount: 2, userIncluded: true,
  },
  {
    id: 'SOMEONE_ELSE', title: 'Someone else', personsCount: 1, userIncluded: false,
  },
];

export const ADDITIONAL_INFO = [
  {
    imgSrc: require('../Assets/Images/Gradient_star.png'),
    title: 'Pay with points today',
    description: "Join today and use your points at checkout to get today's service for FREE",
  },
  {
    imgSrc: require('../Assets/Images/Gradient_dollar.png'),
    title: 'Members only pricing',
    description: 'Receive exclusive pricing on all services with savings up to 30%',
  },
  {
    imgSrc: require('../Assets/Images/Gradient_arrow.png'),
    title: 'Points roll over',
    description: 'Save unused points to redeem for bigger items next month',
  },
  {
    imgSrc: require('../Assets/Images/Gradient_x.png'),
    title: 'Cancel anytime',
    description: 'No contracts or commitment',
  },
];
export const ADDRESS_REQUIRED_FIELDS = {
  display_headers: {
    address_line_1: {
      required: true,
    },
    address_line_2: {
    },
    apt_number: {
      additional_attributes: {
        title: 'Apt / Suite / Hotel name \u0026 room',
      },
    },
    city: {
      required: true,
    },
    state: {
      required: true,
    },
    zip_code: {
      required: true,
    },
    country: {
    },
  },
  display_array: [
    {
      key: 'parking_type',
      value_type: 'String',
      required: true,
      display_order: 0,
      title: 'Parking Details',
      subtitle: 'Choose one • Required',
      display_type: 'radiobutton_list',
      display_choices: [
        {
          title: 'Free street parking',
          display_order: 0,
          value: 'free_street_parking',
        },
        {
          title: 'Metered street parking',
          display_order: 1,
          value: 'metered_parking',
        },
        {
          title: 'Lot or garage',
          display_order: 2,
          value: 'parking_lot_or_garage',
        },
        {
          title: 'Valet service',
          display_order: 3,
          value: 'valet_service',
        },
      ],
      notes: 'Please note: Additional charges may apply if your Provider is required to pay for parking.',
    },
    {
      key: 'parking_info',
      value_type: 'String',
      required: true,
      display_order: 1,
      title: 'Arrival Instructions',
      subtitle: 'Required',
      why: {
        title: 'Why do we need this?',
        description: 'Arrival instructions help your Provider know exactly what to expect when they show up to your appointment. This creates a more seamless experience for both of you.',
      },
      display_type: 'edittext_instructions',
      placeholder: 'Let your Provider know about hotel name, gate codes, parking passes, complex walking directions, or anything else to help them find you when they arrive.',
    },
    {
      key: 'address_type',
      value_type: 'String',
      display_order: 2,
      title: 'Address Type',
      subtitle: 'Choose one • Optional',
      display_type: 'radiobutton_list',
      display_choices: [
        {
          title: 'Private residence',
          display_order: 0,
          default: true,
          value: 'home',
        },
        {
          title: 'Business/Office',
          display_order: 1,
          value: 'office',
        },
        {
          title: 'Hotel',
          display_order: 2,
          value: 'hotel',
        },
      ],
    },
    {
      key: 'flights_of_stairs',
      value_type: 'Integer',
      display_order: 3,
      title: 'Stairs',
      subtitle: 'Choose applicable • Optional',
      display_type: 'radiobutton_list',
      display_choices: [
        {
          title: 'No stairs',
          display_order: 0,
          value: '0',
        },
        {
          title: '1 flight',
          display_order: 1,
          value: '1',
        },
        {
          title: 'More  than 1 flight',
          display_order: 2,
          value: '-1',
        },
      ],
    },
    {
      key: 'pet_info',
      display_order: 4,
      title: 'Pets',
      subtitle: 'Choose applicable • Optional',
      display_type: 'checkbox_list',
      display_choices: [
        {
          title: 'Dog(s)',
          display_order: 0,
          key: 'has_dogs',
          is_selected: false,
          default: false,
        },
        {
          title: 'Cat(s)',
          display_order: 1,
          key: 'has_cats',
          is_selected: false,
          default: false,
        },
      ],
    },
    {
      key: 'equipment_info',
      display_order: 5,
      title: 'Equipment',
      subtitle: 'Choose applicable • Optional',
      display_type: 'checkbox_list',
      display_choices: [
        {
          title: "I'll provide my own sheets",
          display_order: 0,
          key: 'has_sheets',
          is_selected: false,
          default: false,
        },
        {
          title: "I'll provide my own table",
          display_order: 1,
          key: 'has_table',
          is_selected: false,
          default: false,
        },
      ],
    },
  ],
  settable_fields: {
    address_line_1: {
      required: true,
    },
    address_line_2: {
    },
    apt_number: {
    },
    city_id: {
      required: true,
    },
    city: {
      required: true,
    },
    state: {
      required: true,
    },
    zip_code: {
      required: true,
    },
    country: {
    },
    parking_type: {
      required: true,
      allowed_values: [
        'free_street_parking',
        'metered_parking',
        'parking_lot_or_garage',
        'valet_service',
      ],
    },
    parking_info: {
      required: true,
    },
    address_type: {
      default: 'home',
      allowed_values: [
        'home',
        'hotel',
        'office',
      ],
    },
    flights_of_stairs: {
      default: 0,
    },
    has_dogs: {
      default: 0,
    },
    has_cats: {
      default: 0,
    },
    has_sheets: {
      default: 0,
    },
    has_table: {
      default: 0,
    },
  },
};
export function formatProsWithSlotsList(therpList, isTodaySelected) {
  let arrTemp = {};
  if (!isEmpty(therpList)) {
    arrTemp = reduce(therpList, (result, value, _ky) => {
      const icSlots = get(value, 'instant_confirm_slots', []);
      const gaSlots = get(value, 'general_availability_slots', []);
      const sortedUnionArr = sortBy(union(icSlots, gaSlots));
      forEach(sortedUnionArr, (elm) => {
        const tempVal = omit(value, ['instant_confirm_slots', 'general_availability_slots']);
        if (includes(icSlots, elm)) {
          tempVal.isIC = true;
        } else if (includes(gaSlots, elm)) {
          tempVal.isGA = true;
        }
        result[elm] = union(result[elm] || [], [tempVal]);
      });
      return result;
    }, {});
  }

  if (isTodaySelected) {
    const soonestTimeToday = moment().add(NOT_BEFORE_H, 'hours');
    arrTemp = omitBy(arrTemp, (_elt, key) => (moment(key, 'HH:mm').isBefore(soonestTimeToday, 'hour') || (moment(key, 'HH:mm').isSame(soonestTimeToday, 'hour') && moment(key, 'HH:mm').isBefore(soonestTimeToday, 'minutes'))));
  }
  return arrTemp;
}
