import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import { HotJar } from '../../../constants.js';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { groupBy, map, forEach, find, result, assign, sortBy, omit, filter, isEmpty } from 'lodash';
import countries from '../shared/countries.json'
import { giftCardLocationEvent } from '../../Shared/WebAnalytics'
import MultiLevelSelector from './Select';

HotJar();
class Step3 extends React.Component {
  constructor(props) {
    super(props);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.renderGiftCardCities = this.renderGiftCardCities.bind(this);
    this.getGiftCardCities = this.getGiftCardCities.bind(this);
    this.onCitySelectionChange = this.onCitySelectionChange.bind(this);
    this.updateGiftCard = this.updateGiftCard.bind(this);
    this.updateGiftCardCart = this.updateGiftCardCart.bind(this);

    this.state = {
      width: window.innerWidth,
      giftCardCities: [],
      opts: [],
      regionOptions: {}
    };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getRegionOptions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  renderGiftCardCities() {
    return this.state.giftCardCities.map((city) => (<option value={[city.id, city.currency, city.currency_symbol, city.name, city.country]} key={`${city.name}-city-name`}>{city.name}</option>));
  }
  getRegionOptions() {
    axios.get(`${API_ROOT}/gift_cards/cities`, ACCEPT_LANGUAGE_HEADER).then((resp) => {
      let { cities } = resp.data;
      if (cities) {
        if (this.props.giftCardCart.currency && this.props.giftCardCart.items.length) {
          cities = filter(cities, (obj) => (obj.currency === this.props.giftCardCart.currency));
        }
        let groupedByCountry = groupBy(cities, "country")
        let regionOptions = {};

        if (!isEmpty(omit(groupedByCountry, ["US"]))) {
          regionOptions["International"] = sortBy(map(omit(groupedByCountry, ["US"]), (rg, ky) => {
            let rgObj = find(countries, (el) => (el && (el.code2 === ky)))
            let label = rgObj && rgObj.name || ky;
            return {
              label, value: ky,
              regions: sortBy(map(rg, (city) => {
                return { value: city.id, label: city.name, extraObj: city }
              }), (rr) => (rr.label))
            }
          }), (ss) => (ss.label));
        }
        if (!isEmpty(groupedByCountry["US"])) {
          let usCtrObj = find(countries, (el) => (el && (el.code2 === "US")));
          let usLabel = usCtrObj && usCtrObj.name;
          let groupedByState = groupBy(groupedByCountry["US"], "state");
          regionOptions[usLabel] = sortBy(map(groupedByState, (stt, i) => {
            let sttObj = usCtrObj && find(usCtrObj.states, (el) => (el && (el.code === i || el.name === i)))
            return {
              value: i, label: sttObj && sttObj.name || i,
              regions: sortBy(map(stt, (city) => {
                return { value: city.id, label: city.name, extraObj: city }
              }), (rr) => (rr.label))
            }
          }), (ss) => (ss.label));
        }
        this.setState({ regionOptions })
      } else {
        console.log('NO cities found');
      }
    });
  }
  getGiftCardCities() {
    axios.get(`${API_ROOT}/gift_cards/cities`, ACCEPT_LANGUAGE_HEADER).then((resp) => {
      let { cities } = resp.data;
      if (cities) {
        if (this.props.giftCardCart.currency && this.props.giftCardCart.items.length) {
          cities = filter(cities, (obj) => (obj.currency === this.props.giftCardCart.currency));
        }
        this.setState({ giftCardCities: cities })
        let groupedByCountry = groupBy(cities, "country")
        let grouped = map(groupedByCountry, (grp, ind) => {
          return { [ind]: groupBy(grp, "state") }
        })
        let opts = [];
        forEach(grouped, (grp) => {
          forEach(grp, (ctr, ind) => {
            let ctrObj = find(countries, (el) => (el && (el.code2 === ind)))
            opts.push({
              value: ctrObj && ctrObj.name || ind, label: ctrObj && ctrObj.name || ind,
              options: sortBy(map(ctr, (stt, i) => {
                let sttObj = ctrObj && find(ctrObj.states, (el) => (el && (el.code === i || el.name === i)))
                return {
                  value: sttObj && sttObj.name || i, label: sttObj && sttObj.name || i,
                  options: sortBy(map(stt, (city) => {
                    return { value: city.id, label: city.name, extraObj: city }
                  }),(cc)=>(cc.label))
                }
              }),(ss)=>(ss.label))
            })
          })
        })
        this.setState({ opts })
      } else {
        console.log('NO cities found');
      }
    });
  }
  onCitySelectionChange(val) {
      let cityId= result(val,"id"),
      cityObj=val;

      giftCardLocationEvent(this.props.giftCard.deliveryMethod, cityObj.country, cityObj.name);

      this.updateGiftCard({
        cityId,
        currency: cityObj.currency,
        currencySymbol: cityObj.currency_symbol
      })
      this.updateGiftCardCart({
        cityId,
        currency: cityObj.currency,
        currencySymbol: cityObj.currency_symbol,
        cityName: cityObj.name,
        country: cityObj.country
      })
      this.props.updateFieldAndStep("cityId", cityId, 4);
  }
  updateGiftCard(values) {
    this.props.setGiftCard(assign(
      this.props.giftCard,
      values
    ));
  }
  updateGiftCardCart(value) {
    this.props.setGiftCardCart(assign(
      this.props.giftCardCart,
      value
    ))
  }
  render() {

    return (
      <Translate>
        {({ translate }) =>
          <div className="full-height-section p-large bg-secondary font-style-medium txt-align-center pt-sm-192 step3" >
            <p className="steps-indicator light-mode-secondary">Step 2 of {this.props.totalSteps}</p>
            <div className="title-1 mb-24">Recipient Location</div>
            <div className="text font-style-regular mb-40 mb-sm-0 sm-custom-text mb-sm-24">Different locations have different service pricing</div>
            <div className="location-selector-wrapper txt-align-left">
              <div>
                <label htmlFor='location-selector' className="location-selector-label mb-8">Region</label>
              </div>
              <div className="display-relative">
                <MultiLevelSelector options={this.state.regionOptions} updateSelection={this.onCitySelectionChange}/>
              </div>
            </div>
          </div>
        }
      </Translate>
    )
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setGiftCard, setGiftCardCart }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step3));
