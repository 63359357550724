/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { isEmpty, map, snakeCase } from 'lodash';
import './RadioOptions.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class RadioOptions extends React.Component {
  render() {
    const { optionsArray, name, defaultValue } = this.props;
    if (!isEmpty(optionsArray)) {
      return (
        <RadioGroup
          aria-label={name}
          name={name}
          defaultValue={defaultValue}
          onChange={(event) => { this.props.onChange(event.target.value); }}
        >
          {map(optionsArray, (option, it) => (
            <FormControlLabel
              key={`radio-${snakeCase(option.label)}-${it}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
              id={`${name}-${it + 1}`}
            />
          ))}
        </RadioGroup>
      );
    }
    return null;
  }
}
