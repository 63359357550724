/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  find, get, isEmpty, map, snakeCase,
} from 'lodash';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

export default class StyledRadioTabs extends React.Component {
  render() {
    const { optionsArray, name, action } = this.props;
    if (!isEmpty(optionsArray)) {
      return (
        <RadioGroup
          className="tabs-radio"
          aria-label={name}
          name={name}
          onChange={(event) => {
            const id = Number(event.target.value);
            if (action) {
              const obj = find(optionsArray, (o) => (o.value === id));
              action(id, get(obj, 'label', ''), obj.extras);
            }
          }}
        >
          {map(optionsArray, (option, it) => (
            <FormControlLabel
              key={`radio-${snakeCase(option.label)}-${it}`}
              className="tabs-radio-label"
              value={option.value}
              control={<Radio className="tabs-radio-input" checked={option.checked} />}
              label={option.label}
              {...(option.relevantId ? { id: option.relevantId } : {})}
            />
          ))}
        </RadioGroup>
      );
    }
    return null;
  }
}
