import * as React from 'react';
import { map } from 'lodash'
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { TextField, MenuItem, FormControl, ListSubheader, SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
.MuiInputLabel-formControl {
  transform: translate(0, 12px) scale(1);
}
.MuiFormLabel-root, & .MuiFormLabel-root, & .MuiFormLabel-root:hover, & .MuiFormLabel-root:focus {
    color: #757575;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 18px;
    border:none;
    z-index:1;
    left: 16px;
    transform: translate(0, 20px) scale(1);
    cursor: pointer;
}
 .MuiFormLabel-root.Mui-focused {
    color: #757575;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 18px;
    border-radius: 4px;
    border:none;    
}
.MuiInput-underline:before, .MuiInput-underline:after {
  border-bottom: none!important;
}
.MuiSelect-icon {
  font-size: 20px;
  right: 10px;
  top: calc(50% - 3px);
}
.MuiSelect-iconOpen {
  top: -0.5em;
  right: 20px;
}
.MuiSelect-select:focus {
  background: none;
}
`;
const NestedStyledTextField = styled(TextField)`
& .MuiFormLabel-root, & .MuiFormLabel-root:hover, & .MuiFormLabel-root:focus, & .MuiFormLabel-root.Mui-focused, & .MuiInputLabel-root {
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 16px;
  color: #000000;
  border:none;
}
.Mui-selected {
  background-color: white;
}
.MuiInput-underline:before, .MuiInput-underline:after {
  border-bottom: none!important;
}
.MuiSvgIcon-root, .MuiSelect-icon {
  transform: none!important;
  height: 100%;
  font-size: 20px;
}
.MuiSelect-select:focus {
  background: none;
}
.MuiInputLabel-formControl {
  transform: translate(0, 12px) scale(1);
}
`;
class MultiLevelSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      width: 0,
      opened: false,
      childIsOpened: false,
      selectionValue: '',
      firstLevelKey: '',
      secondLevelKey: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  handleChange(val) {
    this.props.updateSelection(val)
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  render() {
    const dropupIcon = (props) => (
      <SvgIcon {...props}>
        <path d="M12 0V3.79999L6 8.39999L0 3.79999V0L6 4.60001L12 0Z" fill="#757575" />
      </SvgIcon>
    );
    const droprightIcon = (props) => (
      <SvgIcon {...props}>
        <path d="M8.89999 6L4.29999 12H0.5L5.10001 6L0.5 0H4.29999L8.89999 6Z" fill="#AFAFAF" />
      </SvgIcon>
    );
    return (
      <>
        <FormControl variant="filled" onClick={(e) => {
          e.stopPropagation();
          this.setState({ opened: !this.state.opened, childIsOpened: false, firstLevelKey: '', secondLevelKey: '' });
        }} fullWidth>
          <StyledTextField
            select
            SelectProps={{
              IconComponent: dropupIcon,
              MenuProps: {
                anchorOrigin: {
                  vertical: 'center',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    marginTop: "75px",
                    marginBottom: "25px"
                  }
                }
              },
              open: this.state.opened,
              onOpen: () => { this.setState({ opened: true, childIsOpened: false, firstLevelKey: '', secondLevelKey: '' }) },
              onClose: () => {
                if (!this.state.childIsOpened) {
                  this.setState({ opened: false, childIsOpened: false, firstLevelKey: '', secondLevelKey: '' });
                }
              }
            }}
            label={this.state.selectionValue ? this.state.selectionValue : "Select..."}
            InputLabelProps={{
              shrink: false,
              onClick: () => { this.setState({ opened: true }); }
            }}
            value={""}
          >
            {map(this.props.options, (ctry, key) => (
              <div key={key} >
                <ListSubheader onClick={(e) => {
                  e.stopPropagation();
                }}>{key}</ListSubheader>
                {map(ctry, (stt, it) => (
                  <MenuItem key={`state-${key}-${it}`} value={this.props.giftCard.cityName}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (this.state.childIsOpened) {
                        this.setState({ childIsOpened: false, firstLevelKey: '', secondLevelKey: '' });
                      }
                      else {
                        this.setState({ childIsOpened: true, firstLevelKey: key, secondLevelKey: it });
                      }
                    }}>
                    <FormControl variant="standard" fullWidth>
                      <NestedStyledTextField
                        select
                        SelectProps={{
                          IconComponent: droprightIcon,
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'right',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null
                          },
                          open: this.state.opened && this.state.childIsOpened && this.state.firstLevelKey === key && this.state.secondLevelKey === it,
                          onChange: (event) => {
                            if (event.target.value) {
                              this.setState({ opened: false, childIsOpened: false, firstLevelKey: '', secondLevelKey: '' });
                            }
                          },
                          onOpen: () => {
                            this.setState({ childIsOpened: true, firstLevelKey: key, secondLevelKey: it });
                          },
                          onClose: () => {
                            this.setState({ childIsOpened: false, firstLevelKey: '', secondLevelKey: '' });
                          },
                        }}
                        label={stt.label}
                        InputLabelProps={{
                          shrink: false,
                          onClick: (e) => {
                            e.stopPropagation();
                            if (this.state.childIsOpened) {
                              this.setState({ childIsOpened: false, firstLevelKey: '', secondLevelKey: '' });
                            }
                            else {
                              this.setState({ childIsOpened: true, firstLevelKey: key, secondLevelKey: it });
                            }
                          }
                        }}
                        size="small"
                        value={""}
                      >
                        {map(stt.regions, (ct, ind) => (
                          <MenuItem key={`city-${key}-${it}-${ind}`} value={ct.label}
                            onClick={() => {
                              this.setState({ selectionValue: ct.label });
                              this.handleChange(ct.extraObj);
                            }}
                          >
                            <div className="flex-grow-1">{ct.label}</div>
                            <button className="btn btn-selector">Select</button>
                          </MenuItem>
                        ))}
                      </NestedStyledTextField>
                    </FormControl>
                  </MenuItem>))}
              </div>

            ))}
          </StyledTextField>
        </FormControl>
      </>
    );
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart
});

export default withLocalize(connect(mapStateToProps)(MultiLevelSelect));