
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import TextWithIcon from '../../Shared/TextWithIcon';
import RoomIcon from './Assets/Images/Room_icon.png';
import { isEmpty, get } from 'lodash';
import { defaultErrorMessage, isGuestUser } from '../../../constants';
import { updateRoomNumber } from '../Shared/helpers';
import { decode } from '../Shared/encode';

class AppointmentRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roomNumber: get(props, "booking.cart.address.hotel_room_number", "")
        };
    }

    render() {
        const cart = get(this.props, "booking.cart", null);
        let cartProducts = get(cart, "cartProducts", []);
        if (!isEmpty(cartProducts) && isGuestUser()) {
            return (
                <TextWithIcon
                    icon={RoomIcon}
                    title={"Room or Apartment number"}
                    details={<>
                        <input className='form-control gray-input ht-36' placeholder='eg.: 123' value={this.state.roomNumber || ""}
                            onChange={(event) => this.setState({ roomNumber: event.target.value || "" })}
                            onBlur={() => {
                                updateRoomNumber(get(this.props, "booking.cart.id"), this.state.roomNumber, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
                                    this.props.assignToCart({ cart: decode(response.data.cart) });
                                }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
                            }} 
                            maxLength={50} />
                    </>}
                    displayBorder={false}
                />
            )
        }
        return null;
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client,
    fieldsHolder: state.fieldsHolder
});

AppointmentRoom.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(AppointmentRoom));