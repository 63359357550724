/* eslint-disable func-names */
/* eslint-disable default-param-last */
/* eslint-disable camelcase */
import { SET_ABTest } from '../Actions';

export default function (state = '', action) {
  switch (action.type) {
    case SET_ABTest:
      if (action.payload) {
        return action.payload || '';
      }

      return state;
    default:
      return state;
  }
}
