/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  first, forEach, get, isEmpty,
} from 'lodash';
import { withLocalize } from 'react-localize-redux';
import { setCart } from '../../../Actions';
import ColumnsList from '../../Shared/ColumnsList';

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);

    this.renderBillingLineItems = this.renderBillingLineItems.bind(this);
    this.renderSecondBollingLineItems = this.renderSecondBollingLineItems.bind(this);
    this.tippingMessage = this.tippingMessage.bind(this);

    this.state = {};
  }

  renderBillingLineItems() {
    const arr = [];
    const billing = get(this.props, 'booking.cart.billing', null);
    if (!isEmpty(billing)) {
      const { top_billing_line_items } = billing;

      forEach(top_billing_line_items, (item) => {
        let price; const
          strikedThroughText = item.strike_through_text ? <span className="gray-strike-through">{item.strike_through_text}</span> : null;
        if (`${item.amount}`.startsWith('- ')) {
          price = <span className="color-green">{item.amount}</span>;
        } else {
          price = (
            <span>
              {strikedThroughText}
              {' '}
              {item.amount}
            </span>
          );
        }
        arr.push({ text: item.title, price });
      });
    }
    return arr;
  }

  renderSecondBollingLineItems() {
    const arr = [];
    const billing = get(this.props, 'booking.cart.billing', null);
    if (!isEmpty(billing)) {
      const { bottom_billing_line_items } = billing;
      if (!isEmpty(bottom_billing_line_items)) {
        forEach(bottom_billing_line_items, (itm) => {
          arr.push({ text: itm.title, price: itm.amount });
        });
      }
    }
    return arr;
  }

  tippingMessage() {
    const { cart } = this.props.booking;
    return cart && cart.cart_messages ? first(cart.cart_messages) : '';
  }

  render() {
    const price_with_tip = get(this.props, 'booking.cart.billing.price_with_tip', '');

    return (
      <div>
        <div className="second-title-style">Order Summary</div>
        <div className="br-btm-gray-thin">
          <ColumnsList
            itemsList={this.renderBillingLineItems()}
          />
        </div>
        <div className="br-btm-gray-thin">
          <ColumnsList
            itemsList={this.renderSecondBollingLineItems()}
          />
        </div>
        <ColumnsList
          headerText="Due today"
          headerPrice={price_with_tip}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  booking: state.booking,
});

OrderSummary.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};

export default withLocalize(connect(mapStateToProps, { setCart })(OrderSummary));
