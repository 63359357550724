/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import {
  capitalize, isEmpty, get, map, concat, find, size, compact,
} from 'lodash';
import { connect } from 'react-redux';
import './Assets/Styles/ProviderPickStep.css';
import Badge from '@material-ui/core/Badge';
import {
  Close, Favorite, FavoriteBorder, Star,
} from '@material-ui/icons';
import Slider from 'react-slick';
import moment from 'moment';
import { favoritePro, getTherapistPrivateProfile, getTherapistPrublicProfile } from '../Shared/helpers';
import placeholder from './Assets/Images/placeholder.png';
import avatar from './Assets/Images/profile-avatar.svg';
import { seInboxViewMessage, seProfileViewed, seProviderContact } from '../../Shared/WebAnalytics';
import { openChat } from '../../AppointmentManagement/Shared/helpers';
import { getSingleChatState } from '../../AppointmentManagement/Shared/constants';
import { ROUTES } from '../../../constants';
import { HOST_ROOT } from '../../../apiConfig';
import { fetchChat } from '../../../Actions';

function ComplimentsSection(compliments) {
  if (!isEmpty(compliments)) {
    return (
      <div className="mb-24">
        <div className="size-24-32 font-weight-bold contentPrimary mt-24 mb-8">Client compliments</div>
        <Slider {...{
          dots: false,
          infinite: false,
          slidesToShow: 5.5,
          swipeToSlide: true,
          arrows: false,
        }}
        >
          {compliments.map((compliment, complimentKey) => (
            <div className=" mr-24 centered" key={`complmnt-${complimentKey}`}>
              <Badge
                overlap="rectangular"
                className="xs-bdg-top centered"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                badgeContent={compliment.total}
              >
                <img className="w-h-60 br-rd-50" src={compliment.icon_url || placeholder} alt={`${compliment.title || ''}`} />
              </Badge>
              <div className="size-10-15 contentSecondary mt-8">
                {compliment.title || ''}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
  return null;
}

function ProProfileDetails(props) {
  const [proProfile, setProProfile] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const {
    pro, selectPro, unselectPro, showProDetailsToggle, time, showSendMessage,
    click_source, isPrivate, selected, hasMoreThanProSelected, market,
  } = props;

  useEffect(() => {
    const getProProfile = async (proId) => {
      getTherapistPrublicProfile(proId, (response) => {
        setProProfile(response.data.therapist);
        setIsFavorite(get(pro, 'is_favorite', false));
        seProfileViewed(proId, get(response, 'data.therapist.rating', 0), get(response, 'data.therapist.number_of_bookings', 0), click_source, 'new_flow', market, get(pro, 'isIC', false));
      }, props.errorHandler);
    };
    const getPrivateProProfile = async (proId) => {
      getTherapistPrivateProfile(proId, (response) => {
        setProProfile(response.data.therapist);
        setIsFavorite(get(pro, 'is_favorite', false));
        seProfileViewed(proId, get(response, 'data.therapist.rating', 0), get(response, 'data.therapist.number_of_bookings', 0), click_source, 'new_flow', market, get(pro, 'isIC', false));
      }, props.errorHandler);
    };
    if (isPrivate) {
      pro.id && getPrivateProProfile(pro.id);
    } else {
      pro.id && getProProfile(pro.id);
    }
  }, [pro.id]);

  const {
    avatar_url, first_name, last_name, rating,
    bio, service_categories, number_of_bookings,
    since, compliments, public_avatar_url, private_avatar_url,
  } = proProfile;
  const displayName = `${first_name} ${get(last_name, '0', '')}.`;
  const isIC = get(pro, 'isIC', false);

  function setUnsetFav() {
    favoritePro(get(props, 'client.user_id', ''), {
      therapist_id: pro.id,
      is_favorite: !isFavorite,
    }, get(this.props, 'fieldsHolder.csrfToken', ''), () => {
      setIsFavorite(!isFavorite);
    });
  }
  function openChatWithPro(proIds) {
    openChat(compact(proIds), (resp) => {
      const crtPrds = get(resp, 'data.cart.display_helpers.v0.cart_products', []);
      props.fetchChat(get(resp, 'data', {}));
      seInboxViewMessage({
        state: getSingleChatState(get(resp, 'data.chats', [])),
        click_source: 'appointment_details',
        cart_product_count: size(crtPrds),
        booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
        is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
        service_category: get(crtPrds, '0.product.title', ''),
        service_modality: get(crtPrds, '0.cart_product_main_option.title', ''),
      });
      window.location.href = `${HOST_ROOT}${ROUTES.inbox}?chat_id=${get(resp, 'data.chat_id', '')}`;
    }, (err) => {
      console.log({ err });
    });
  }

  if (first_name) {
    return (
      <div id="pro-profile-details">
        <div className="pro-profile-container">
          <div className="p-16-0-24-24 mb-40">
            <a
              className="back-to-search"
              href="&times;"
              onClick={(e) => { e.preventDefault(); showProDetailsToggle(); }}
            >
              <Close className="LightBlack size-16-24" />
            </a>
            {isPrivate
              ? (
                <button
                  type="button"
                  className="btn btn-link abs-top t-16 r-24 p-0"
                  onClick={setUnsetFav}
                >
                  {isFavorite ? <Favorite className="size-16-24 color-red" />
                    : <FavoriteBorder className="size-16-24 contentPrimary" />}
                </button>
              )
              : null}
            <div className="centered">
              <div>
                <Badge
                  overlap="rectangular"
                  color="primary"
                  className="centered-badge badge-w-46"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  badgeContent={(
                    <div className="size-14-16 centered d-flex-only align-items-center">
                      {rating}
                      {' '}
                      <Star className="contentInversePrimary size-16-20" />
                    </div>
)}
                >
                  <img className="w-h-119 br-rd-50" src={private_avatar_url || avatar_url || public_avatar_url || avatar} alt="avatar" />
                </Badge>
              </div>
              <div className="size-32-40 font-weight-bold contentPrimary mt-16">{displayName}</div>
              <div className="size-16-24 contentPrimary mb-8">
                {number_of_bookings || 0}
                {' '}
                bookings
                <br />
                Joined
                {' '}
                {moment(new Date()).subtract(since.months, 'months').format('MMM')}
                {' '}
                {moment(new Date()).subtract(since.years, 'years').format('YYYY')}
              </div>
              {showSendMessage ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-accent-light"
                    onClick={() => {
                      seProviderContact('appointment_details_page');
                      openChatWithPro([pro.id]);
                    }}
                  >
                    Send Message
                  </button>
                </div>
              ) : null}
            </div>
            {AboutSection(bio, expanded, setExpanded)}
            {ServiceCategories(service_categories)}
            {ComplimentsSection(compliments)}
          </div>
          {!selectPro ? null : (
            <div className="pro-profile-lower">
              <div className="pro-profile-btn-container p-24">
                <div
                  className="btn pro-profile-btn fixed-btn-shadow font-weight-bold"
                  onClick={() => {
                    if (selected) {
                      unselectPro(pro);
                      showProDetailsToggle();
                    } else {
                      selectPro(pro);
                      showProDetailsToggle();
                    }
                  }}
                >
                  {isIC ? selected ? 'Booked' : `Book ${displayName} at ${time}` : selected ? 'Selected' : hasMoreThanProSelected ? `Add ${displayName} to selected providers` : `Request ${displayName} at ${time}`}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
}

const mapStateToProps = (state) => ({
  client: state.client,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, { fetchChat })(ProProfileDetails);

function AboutSection(about_description, expanded, setExpanded) {
  return (
    <div className="mt-24 mb-32">
      <div className="size-24-32 font-weight-bold contentPrimary mb-10">About</div>
      <div className={`size-14-16 contentSecondary ${expanded ? '' : 'ellipsis-4'}`}>{about_description || ''}</div>
      <br />
      <div
        className="size-14-16 CactusFlowerPrimary cursor-pointer underline"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? 'Read less' : 'Read more'}
      </div>
    </div>
  );
}
function ServiceCategories(service_categories) {
  if (!isEmpty(service_categories)) {
    let mods = [];
    map(service_categories, (service) => {
      mods = concat(mods, service.modalities || []);
    });
    return (
      <div>
        <div className="size-24-32 font-weight-bold contentPrimary mt-24 mb-8">Specialties</div>
        <div className="mb-32">
          <Slider {...{
            dots: false,
            infinite: false,
            slidesToShow: 3.25,
            swipeToSlide: true,
            arrows: false,
          }}
          >
            {map(mods, (md) => (
              <div key={`${md.title}${md.id}`} className="mr-16">
                <div className="mb-8">
                  <img
                    src={get(
                      find(md.images, (e) => (e.kind === 'card_display_image')),
                      'url',
                      '',
                    )}
                    className="w-h-118-130 obj-fit-cover br-rd-8"
                    alt=""
                  />
                </div>
                <div className="size-14-20 contentPrimary">{capitalize(md.title)}</div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}
