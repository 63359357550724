/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Close, EventNote, FormatQuote, List,
} from '@material-ui/icons';
import {
  compact, find, get, isEmpty, replace, size,
} from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import LatestModalDesign from '../../../Shared/LatestModalDesign';
import TextWithIcon from '../../../Shared/TextWithIcon';
import { acceptReschedule, declineRequest, openChat } from '../../Shared/helpers';
import SuccesModal from '../SuccessModal';
import { decode } from '../../Shared/encode';
import { setAppointment, fetchChat } from '../../../../Actions';
import ErrorModalRedesign from '../../../Shared/ErrorModalRedesign';
import { defaultErrorMessage, ROUTES } from '../../../../constants';
import {
  seInboxViewMessage, seProviderContact,
  seRescheduleRequestAccept, seRescheduleRequestDecline, seRescheduleRequestDismiss,
} from '../../../Shared/WebAnalytics';
import { HOST_ROOT } from '../../../../apiConfig';
import { getSingleChatState } from '../../Shared/constants';

class ResponseConfirmChanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainModalOpen: get(props, 'show', false),
      areYouSureOpen: false,
      showSuccessModal: false,
      successTitle: '',
      showErrorModal: false,
      error: '',
    };
    this.modalContent = this.modalContent.bind(this);
    this.relevantCta = this.relevantCta.bind(this);
    this.acceptTime = this.acceptTime.bind(this);
    this.declineTime = this.declineTime.bind(this);
    this.areYouSureModal = this.areYouSureModal.bind(this);
  }

  acceptTime(datetime, oldDate) {
    if (datetime) {
      acceptReschedule(get(this.props, 'appointment.cartProducts.0.id'), {
        datetime,
      }, (resp) => {
        this.props.setAppointment(decode(resp.data.cart));
        seRescheduleRequestAccept({ multiple_times: false, same_day: moment(datetime).isSame(oldDate, 'day') });
        this.setState({ showSuccessModal: true, successTitle: 'Appointment successfully rescheduled', mainModalOpen: false });
      }, (err) => {
        this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage), mainModalOpen: false });
      });
    }
  }

  declineTime() {
    const booking_options = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options', []);
    const oldDate = get(this.props, 'appointment.date.utc');
    const zn = get(booking_options, '0.time_zone', '');
    declineRequest(get(this.props, 'appointment.cartProducts.0.id'), (resp) => {
      this.props.setAppointment(decode(resp.data.cart));
      seRescheduleRequestDecline({ multiple_times: size(booking_options) > 1, same_day: !isEmpty(find(booking_options, (opt) => (moment.tz(get(opt, 'start_time', ''), zn).isSame(oldDate, 'day')))) });
      this.setState({
        showSuccessModal: true, successTitle: 'Your appointment will remain as originally booked', mainModalOpen: false, areYouSureOpen: false,
      });
    }, (err) => {
      this.setState({
        showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage), mainModalOpen: false, areYouSureOpen: false,
      });
    });
  }

  areYouSureModal() {
    const { areYouSureOpen } = this.state;
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', 'the provider');
    const proId = get(this.props, 'appointment.cartProducts.0.pro.id', '');

    return (
      <LatestModalDesign
        isOpen={areYouSureOpen}
        fullWidthFooter
        title="Are you sure?"
        subtitle={(
          <>
            Your provider may likely cancel your appointment if you decline.
            <br />
            If this time doesn't work, contact your provider to book a better time.
          </>
)}
        applyBtnCopy={`Contact ${proFirstName}`}
        cancelBtnCopy="Decline"
        apply={() => {
          this.setState({ areYouSureOpen: false });
          seProviderContact('respond_to_pro_reschedule');
          openChat(compact([proId]), (resp) => {
            this.props.fetchChat(get(resp, 'data', {}));
            seInboxViewMessage({
              state: getSingleChatState(get(resp, 'data.chats', [])),
              click_source: 'appointment_details',
              cart_product_count: size(get(resp, 'data.cart.display_helpers.v0.cart_products', [])),
              booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
              is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
              service_category: get(resp, 'data.cart.display_helpers.v0.cart_products.0.product.title', ''),
              service_modality: get(resp, 'data.cart.display_helpers.v0.cart_products.0.cart_product_main_option.title', ''),
            });
            window.location.href = `${HOST_ROOT}${ROUTES.inbox}?chat_id=${get(this.props, 'currentChat.chat_id', '')}`;
          }, (err) => {
            console.log({ err });
          });
        }}
        close={this.declineTime}
        back={() => this.setState({ areYouSureOpen: false, mainModalOpen: true })}
      />
    );
  }

  relevantCta() {
    const booking_options = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options', []);
    if (size(booking_options) === 1) {
      return (
        <button
          type="button"
          className="btn cta-primary"
          onClick={() => {
            const datetime = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options.0.start_time', '');
            this.acceptTime(datetime, get(this.props, 'appointment.date.utc'));
          }}
        >
          Accept
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn cta-primary"
        onClick={this.props.switchModal}
      >
        See available times
      </button>
    );
  }

  modalContent() {
    const booking_options = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options', []);
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', 'the provider');
    const zn = get(booking_options, '0.time_zone', '');
    return (
      <div className="medium-font">
        {/* Appointment details */}
        <TextWithIcon
          muiIcon={<List className="grayed-out" />}
          title="Appointment details"
          details={<>{replace(get(this.props, 'appointment.info_fields.title', ''), ' with', '\nwith')}</>}
        />
        {/* Date */}
        <TextWithIcon
          muiIcon={<EventNote className="grayed-out" />}
          title="Date"
          details={(
            <>
              {size(booking_options) === 1 ? (
                <>
                  New date:
                  {moment.tz(get(booking_options, '0.start_time', ''), zn).format('ddd, MMM D, h:mm A')}
                </>
              ) : <>Multiple times available</>}
              <div className="color-light-gray strike-through">
                {get(this.props, 'appointment.date.display', '')}
                ,
                {get(this.props, 'appointment.time.display', '')}
              </div>
            </>
)}
        />
        <TextWithIcon
          muiIcon={<FormatQuote className="grayed-out" />}
          title={`Message from ${proFirstName}`}
          details={(
            <div className="contentSecondary size-14-20 font-style-italic">
              “
              {get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.message', '')}
              ”
            </div>
)}
        />
      </div>
    );
  }

  render() {
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', 'the provider');
    return (
      <>
        <LatestModalDesign
          isOpen={this.state.mainModalOpen}
          title={`${proFirstName} requested to reschedule`}
          subtitle="They've suggested other times. Select one to confirm your appointment:"
          hideFooter
          customIcon={<Close />}
          back={() => {
            seRescheduleRequestDismiss();
            this.props.hideModal();
          }}
        >
          {this.modalContent()}
          {this.relevantCta()}
          <button
            type="button"
            className="btn cta-secondary mt-8"
            onClick={() => {
              this.setState({ areYouSureOpen: true, mainModalOpen: false });
            }}
          >
            Decline
          </button>
        </LatestModalDesign>
        {this.areYouSureModal()}
        <SuccesModal
          isOpen={this.state.showSuccessModal}
          title={this.state.successTitle || ''}
          ctaAction={() => {
            this.setState({ mainModalOpen: false });
            this.props.hideModal();
          }}
        />

        <ErrorModalRedesign
          isOpen={this.state.showErrorModal}
          title={this.state.error}
          subtitle={this.state.error ? ' ' : ''}
          clickAction={() => {
            this.props.hideModal();
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
  chats: state.chats,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, { setAppointment, fetchChat })(ResponseConfirmChanges);
