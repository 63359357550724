/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import {
  compact, every, filter, find, get, includes, isEmpty, map, size, split,
} from 'lodash';
import { ArrowBack, Timer, Warning } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { HotJar } from '../../../constants';
import { NEW_STATUSES } from '../Shared/constants';
import DashedPizzaTracker from '../../Shared/DashedPizzaTracker';
import EditTip from './EditTip';
import ExtendAppt from './ExtendAppt';
import RescheduleAppt from './RescheduleAppt';
import CancelAppt from './CancelAppt';
import PrepareForAppt from './PrepareForAppt';
import Support from './Support';
import ProviderOfAppt from './ProviderOfAppt';
import PaymentInfo from './PaymentInfo';
import '../Assets/Styles/index.css';
import { seAppointmentDetails, seRescheduleRequestView, seViewAlternativeTimes } from '../../Shared/WebAnalytics';
import { setRebookOptions } from '../../../Actions';
import AvailableTimesModal from './AvailableTimesModal';
import AddToCalendar from './AddToCalendar';
import { getAvailableTimes } from '../Shared/helpers';
import RescheduleConfirmed from './RescheduleConfirmed';
import ViewRescheduleRequest from './ViewRescheduleRequest';
import RespondToRescheduleConfirmed from './RespondToRescheduleConfirmed';
import AppleStore from '../Assets/Images/download-app-store.png';
import PlayStore from '../Assets/Images/download-google-play.png';
import logoBlue from '../../../Assets/Images/Soothe-logo-blue-svg.svg';
import BlindAuctionModal from './BlindAuctionModal';
// import Report from '../../Shared/Report';
HotJar();
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenRescheduleModal: false,
      isOpenAvailableTimesModal: !isEmpty(get(this.props, 'rebookOptions.booking_options', [])),
      showRespondToReschedule: get(props, 'appointment.info_fields.tracker.pending_response', '') === 'client_reschedule_response_required',
      allProsDeclined: false,
      unfilled24Hrs: false,
      prosPopupOpen: false,
      rebook_flag: '',
    };
    this.displayDetails = this.displayDetails.bind(this);
    this.sectionHeader = this.sectionHeader.bind(this);
    this.appointmentTimeAndPlace = this.appointmentTimeAndPlace.bind(this);
    this.actionsListBasedOnAppointmentStatus = this.actionsListBasedOnAppointmentStatus.bind(this);
    this.appointmentExtraDetails = this.appointmentExtraDetails.bind(this);
    this.serviceDescription = this.serviceDescription.bind(this);
    this.editTipSection = this.editTipSection.bind(this);
    this.tipFilterFunction = this.tipFilterFunction.bind(this);
    this.triggerViewEvent = this.triggerViewEvent.bind(this);
    this.extraButton = this.extraButton.bind(this);
    this.availableTimesModal = this.availableTimesModal.bind(this);
    this.mobileSizeStickyButton = this.mobileSizeStickyButton.bind(this);
    this.initiateAvailableTimes = this.initiateAvailableTimes.bind(this);
    this.relevantRescheduleAction = this.relevantRescheduleAction.bind(this);
    this.viewPendingRequest = this.viewPendingRequest.bind(this);
    this.trackerStepHasIcon = this.trackerStepHasIcon.bind(this);
    this.triggerReschViewEvent = this.triggerReschViewEvent.bind(this);
    this.customFooter = this.customFooter.bind(this);
    this.additionalTimeConditions = this.additionalTimeConditions.bind(this);
  }

  componentDidMount() {
    this.triggerReschViewEvent();
    this.triggerViewEvent();
    this.initiateAvailableTimes();
  }

  componentDidUpdate(prevProps) {
    const prevCartId = get(prevProps, 'appointment.id', '');
    const nextCartId = get(this.props, 'appointment.id', '');
    if (nextCartId && prevCartId !== nextCartId) {
      this.setState({ showRespondToReschedule: get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'client_reschedule_response_required' }, this.triggerReschViewEvent);
      this.triggerViewEvent();
      this.initiateAvailableTimes();
    }
  }

  triggerReschViewEvent() {
    if (this.state.showRespondToReschedule && get(this.props, 'appointment.info_fields.tracker.state', 'requested') !== 'complete') {
      const booking_options = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options', []);
      const oldDate = get(this.props, 'appointment.date.utc');
      seRescheduleRequestView({ multiple_times: size(booking_options) > 1, same_day: !isEmpty(find(booking_options, (opt) => (moment(get(opt, 'start_time', '')).isSame(oldDate, 'day')))) });
    }
  }

  triggerViewEvent() {
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    const isComingFromBookingsPage = get(this.props, 'location.state.click_source') === 'bookings_page';
    map(cartProducts, (cp) => {
      const appointment_id = get(cp, 'id', '');
      let status = 'upcoming';
      const currentStatus = get(cp, 'status', 'pending');
      const checkedIn = get(cp, 'checked_in', false);
      const pro_id = get(cp, 'pro.id', 'n/a');
      if (currentStatus === 'complete') {
        status = 'past';
      } else if (checkedIn) {
        status = 'ongoing';
      }
      if (appointment_id) {
        seAppointmentDetails(status, appointment_id, pro_id, isComingFromBookingsPage ? 'bookings_page' : 'deep_link', "jul_2024_booking_enhancements", get(this.props, "client.ab_tests.jul_2024_booking_enhancements", ""));
      }
    });
  }

  additionalTimeConditions(checkout_time, booking_time) {
    const leadTimeInHours = booking_time.diff(checkout_time, 'hours');
    let compareWithM = 0;
    switch (true) {
      case leadTimeInHours <= 5:
        compareWithM = 40;
        break;
      case leadTimeInHours > 12:
        compareWithM = 180;
        break;
      default:
        compareWithM = 100;
    }
    return compareWithM;
  }

  initiateAvailableTimes() {
    const cartId = get(this.props, 'appointment.id', '');

    if (!cartId) {
      this.props.setRebookOptions({});
      this.setState({ isOpenAvailableTimesModal: false });
      return;
    }
    getAvailableTimes(cartId, (response) => {
      const immediateCart = get(response, 'data', null);
      const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
      const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
      const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));
      const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
      const isConfirmed = get(this.props, 'appointment.info_fields.tracker.state', 'requested') === 'confirmed';
      const hasAltTimesPro = find(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'));
      const allDeclined = every(requestedProviders, (obj) => obj.offer_status === 'rejected');
      const in24hrs = Boolean(bkngDate.diff(nowDate, 'hours') <= 24) && Boolean(nowDate.diff(checkoutDate, 'minutes') >= this.additionalTimeConditions(checkoutDate, bkngDate));

      const hasAlternateTimes = !isEmpty(get(immediateCart, 'booking_options', []));
      const needsResponse = Boolean(get(immediateCart, 'tracker.pending_response', '') === 'pending_client_response_rebook' || get(immediateCart, 'tracker.pending_response', '') === 'pending_client_response');
      if (immediateCart && hasAlternateTimes && needsResponse) {
        this.props.setRebookOptions(immediateCart);
        const hasPro = get(immediateCart, 'cart.therapist_preferences.0', null);
        this.setState({
          isOpenAvailableTimesModal: Boolean(hasAlternateTimes && needsResponse && hasPro),
        });
        if (hasAlternateTimes && needsResponse && hasPro) {
          const click_source = get(this.props, 'upcomingAppointmentId', '') ? 'appointment_details' : 'email_link';
          seViewAlternativeTimes(click_source, get(immediateCart, 'cart.rebook', false), {
            ab_test_booking_flow: get(this.props, 'abTest'),
            market: get(this.props, 'appointment.address.market', ''),
            is_special_request: get(this.props, 'appointment.rebook', false),
            lead_time_selected: bkngDate.diff(checkoutDate, 'hours'),
            is_today: bkngDate.isSame(nowDate, 'day'),
            number_of_requested_pros: size(requestedProviders),
            number_of_pros_unavailable: size(filter(requestedProviders, (elt) => (elt.offer_status === 'rejected'))),
            number_of_pros_unresponsive: size(filter(requestedProviders, (elt) => (elt.offer_status === 'pending_response'))),
            time_til_appointment: bkngDate.diff(nowDate, 'hours'),
            blind_auction_opt_in: get(this.props, 'appointment.blind_auction_switch', false),
          });
        }
      } else if (get(this.props, 'appointment.blind_auction_switch', false) && !isConfirmed && isEmpty(hasAltTimesPro) && !isEmpty(requestedProviders)) {
        this.setState({
          allProsDeclined: allDeclined,
          unfilled24Hrs: in24hrs && !allDeclined,
          isOpenAvailableTimesModal: false,
        });
      } else {
        this.setState({ isOpenAvailableTimesModal: false });
        this.props.setRebookOptions({});
      }
    }, () => {
      this.props.setRebookOptions({});
      this.setState({ isOpenAvailableTimesModal: false });
    });
  }

  serviceDescription() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const availableTimes = get(this.props, 'rebookOptions.booking_options', []);
    const tracker = get(this.props, 'rebookOptions.tracker', {});
    const hasPro = get(this.props, 'rebookOptions.cart.therapist_preferences.0', null) || get(this.props, 'appointment.therapist_preferences.0', null);
    const isPendingClientResponse = get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'client_reschedule_response_required';
    const infoFieldsTracker = get(this.props, 'appointment.info_fields.tracker', {});
    const titleW = get(this.props, 'appointment.info_fields.title', '');
    const cpFiltered = filter(get(this.props, 'appointment.cartProducts', []), (ee) => (!get(ee, 'parent_id', null)));
    const wSubttl = compact(map(cpFiltered, (elm) => (get(split(get(elm, 'subtitle', ''), ' with ', 2), '1', ''))));
    const needs_attention_flag = get(this.props, 'rebookOptions.needs_attention_flag', false);
    if (needs_attention_flag || (get(tracker, 'pending_response', '') === 'pending_client_response_rebook' || get(tracker, 'pending_response', '') === 'pending_client_response' || get(infoFieldsTracker, 'pending_response', '') === 'pending_client_response') && !isEmpty(availableTimes) && hasPro) {
      return (
        <div className="mt-24">
          <div className="size-28-36 color-white medium-font mb-10">{get(this.props, 'rebookOptions.heading', '') || get(tracker, 'title', '')}</div>
          <div className="size-18-24 color-white medium-font">{get(this.props, 'rebookOptions.description', '') || get(tracker, 'description', '')}</div>
          {this.extraButton()}
        </div>
      );
    }
    if (isPendingClientResponse && get(infoFieldsTracker, 'state', 'requested') !== 'complete') {
      return (
        <div className="mt-24">
          <div className="size-28-36 color-white medium-font mb-10">{get(infoFieldsTracker, 'title', '')}</div>
          <div className="size-18-24 color-white medium-font">{get(infoFieldsTracker, 'description', '')}</div>
          <button type="button" className="btn btn-sherbert medium-font border-radius-1000 mt-36" onClick={() => this.setState({ showRespondToReschedule: true }, this.triggerReschViewEvent)}>Respond now</button>
        </div>
      );
    }
    if (!isEmpty(requestedProviders)) {
      return (
        <div className="mt-24">
          <div className="size-28-36 color-white medium-font">{get(split(titleW, ' with ', 2), '0', '')}</div>
          {isEmpty(wSubttl) ? null : (
            <div className="size-18-24 color-white medium-font">
              with
              {' '}
              {get(wSubttl, '0', '')}
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="mt-24">
        <div className="size-28-36 color-white medium-font">{get(split(titleW, ' with ', 2), '0', '')}</div>
        {isEmpty(wSubttl) ? null : (
          <div className="size-18-24 color-white medium-font">
            with
            {' '}
            {map(wSubttl, (sub, ind) => (ind === 0 ? sub : ` & ${sub}`))}
          </div>
        )}
      </div>
    );
  }

  extraButton() {
    return (
      <button
        type="button"
        className="btn btn-sherbert medium-font border-radius-1000 mt-36"
        onClick={() => this.setState({ isOpenAvailableTimesModal: true })}
      >
        See available times
      </button>
    );
  }

  trackerStepHasIcon(currStep) {
    const isPendingProResp = get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'pro_reschedule_response_required';
    const isInConfirmed = currStep === 'confirmed';
    if (currStep === 'partially_confirmed') {
      return {
        progressStep: 'confirmed', icon: <Warning className="color-yellow w-100-h-21" />, cClass: 'bg-dark-yellow', textClass: 'color-yellow',
      };
    }
    if (isPendingProResp && isInConfirmed) {
      return {
        progressStep: currStep, icon: <Timer className="color-yellow w-100-h-21" />, cClass: 'bg-dark-yellow', textClass: 'color-yellow',
      };
    }
    return { progressStep: currStep };
  }

  sectionHeader() {
    const tracker = get(this.props, 'appointment.info_fields.tracker', {});
    const hasAvailableTimes = get(this.props, 'rebookOptions.tracker.pending_response', '') === 'pending_client_response_rebook';
    const availableTimes = get(this.props, 'rebookOptions.booking_options', []);
    const hasPro = get(this.props, 'rebookOptions.cart.therapist_preferences.0', null);
    let updatedStatus = get(tracker, 'state', 'requested');
    const availableStatus = find(NEW_STATUSES, (el) => (el.id === updatedStatus));
    const isPendingClientResponse = get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'client_reschedule_response_required';
    const needs_attention_flag = get(this.props, 'rebookOptions.needs_attention_flag', false);
    if (availableStatus) {
      updatedStatus = get(availableStatus, 'id', 'requested');
    }
    return (
      <div className={`details-header ${(needs_attention_flag && !isEmpty(availableTimes)) || ((hasAvailableTimes || get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'pending_client_response') && !isEmpty(availableTimes) && hasPro) || (isPendingClientResponse && updatedStatus !== 'complete') ? 'redish' : ''}  ${this.props.xsDisplay ? 'bg-cover' : ''}`} style={this.props.width < 1200 || this.props.xsDisplay ? { backgroundImage: `url(${get(this.props, 'appointment.info_fields.img_url', '')})` } : {}}>
        {this.props.xsDisplay ? null : (
          <div className="mb-16 clickable">
            <Link
              className="color-white"
              to={{
                pathname: '/appointments/',
                state: { click_source: get(this.props, 'location.state.click_source') === 'bookings_page' ? 'nav_bar' : 'deep_link' },
              }}
            >
              <ArrowBack />
            </Link>
          </div>
        )}
        {this.serviceDescription()}
        <div className="mt-60">
          <DashedPizzaTracker
            progressSteps={NEW_STATUSES}
            currentStep={this.trackerStepHasIcon(updatedStatus)}
          />
        </div>
      </div>
    );
  }

  appointmentTimeAndPlace() {
    const address = get(this.props, 'appointment.address', {});
    const {
      address_line_1, city, state, zip_code,
    } = address;
    return (
      <div className="p-16 br-btm-gray-1">
        <div className="size-16-20 contentPrimary font-weight-bold">Details</div>
        <div className="size-14-20 color-light-gray">
          {get(this.props, 'appointment.date.display', '')}
          {' '}
          @
          {' '}
          {get(this.props, 'appointment.time.display', '')}
        </div>
        <div className="size-14-20 color-light-gray">
          {address_line_1 || ''}
          ,
          {' '}
          {city || ''}
          ,
          {' '}
          {state || ''}
          {' '}
          {zip_code || ''}
        </div>
      </div>
    );
  }

  tipFilterFunction(cp) {
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    const parent_id = get(cp, 'parent_id', null);
    const tip_changes_allowed = get(cp, 'tip.tip_changes_allowed', false);
    const pro_tip_changes_allowed = get(cp, 'pro.tip.tip_changes_allowed', false);
    const tipAmount = get(cp, 'pro.tip.amount', null);
    const checkedIn = get(cp, 'checked_in', null);
    const canTipPro = get(cp, 'pro.tip.can_tip', false);

    const pastAndAllowed = Boolean(genericStatus === 'complete' && pro_tip_changes_allowed && tipAmount !== null);
    const upcomingAndCantip = Boolean(genericStatus !== 'complete' && checkedIn && canTipPro);
    return (!parent_id && tip_changes_allowed && (pastAndAllowed || upcomingAndCantip));
  }

  editTipSection() {
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    const filteredCartProducts = filter(cartProducts, this.tipFilterFunction);
    if (isEmpty(filteredCartProducts)) {
      return null;
    }
    return (map(filteredCartProducts, (cartProduct, it) => (
      <div key={`edit-tip-cp-${it}`}>
        <EditTip cartProduct={cartProduct} moreThanOnePro={size(filteredCartProducts) > 1} openRelevantModal={this.props.openRelevantModal && it === 0 && !get(this.props, 'location.state.isRebook', false)} />
      </div>
    )));
  }

  relevantRescheduleAction(genericStatus) {
    const pendingResp = get(this.props, 'appointment.info_fields.tracker.pending_response', '');
    if (pendingResp === 'pro_reschedule_response_required' || pendingResp === 'client_reschedule_response_required' || genericStatus === 'complete') {
      return null;
    }
    if (genericStatus === 'confirmed' || genericStatus === 'on_the_way') {
      return <RescheduleConfirmed />;
    }
    return (
      <RescheduleAppt
        isComingFromInbox={get(this.props, 'xsDisplay', false)}
        rebook_flag={this.state.rebook_flag}
        isOpenModal={this.state.isOpenRescheduleModal}
        hideRescheduleModal={() => this.setState({ isOpenRescheduleModal: false })}
      />
    );
  }

  actionsListBasedOnAppointmentStatus() {
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    const currentStatus = get(this.props, 'appointment.tracking.status', 'searching');
    const filteredCpWithPros = filter(get(this.props, 'appointment.cartProducts', []), (cp) => (!isEmpty(cp.pro)));
    const isRebook = get(this.props, 'appointment.rebook', false);
    const hasGroupChat = Boolean(size(filteredCpWithPros) > 1 && !this.props.xsDisplay && (isRebook || includes(['confirmed', 'on_the_way', 'complete'], genericStatus)));
    if (genericStatus === 'complete' || currentStatus === 'arrived') {
      return (
        <div className="br-btm-gray-4">
          {this.editTipSection()}
        </div>
      );
    }
    return (
      <div className={hasGroupChat ? '' : 'br-btm-gray-4'}>
        <AddToCalendar isComingFromInbox={get(this.props, 'xsDisplay', false)} />
        <ExtendAppt isComingFromInbox={get(this.props, 'xsDisplay', false)} />
        {this.relevantRescheduleAction(genericStatus)}
        <CancelAppt
          showRescheduleModal={() => this.setState({ isOpenRescheduleModal: true })}
          hideRescheduleModal={() => this.setState({ isOpenRescheduleModal: false })}
                // TODO reschedule here is with same therapist or different therapist..
          isComingFromInbox={get(this.props, 'xsDisplay', false)}
          displayBorder={hasGroupChat}

          showMoreProsModal={() => this.setState({ prosPopupOpen: true })}
          rebook_flag={this.state.rebook_flag}

          location={this.props.location}
          prosPopupOpen={this.state.prosPopupOpen}
          openRelevantModal={this.props.openRelevantModal}
          
        />
      </div>
    );
  }

  appointmentExtraDetails() {
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    const currentStatus = get(this.props, 'appointment.tracking.status', 'searching');
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    return (
      <div className={this.props.xsDisplay ? '' : 'mb-48'}>
        <ProviderOfAppt
          openRelevantModal={this.props.openRelevantModal && size(cartProducts) === 1 && (genericStatus === 'complete' || currentStatus === 'arrived')}
          xsDisplay={this.props.xsDisplay}
          location={this.props.location}
          prosPopupOpen={this.state.prosPopupOpen}
        />
        {genericStatus === 'complete' || currentStatus === 'arrived' ? null
          : <PrepareForAppt />}
        <PaymentInfo />
        <Support />
      </div>
    );
  }

  availableTimesModal() {
    return (
      <AvailableTimesModal
        isOpenAvailableTimesModal={this.state.isOpenAvailableTimesModal}
        hideModalFromParent={() => this.setState({ isOpenAvailableTimesModal: false })}
      />
    );
  }

  viewPendingRequest() {
    const isPendingProResp = get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'pro_reschedule_response_required';
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    if (isPendingProResp && genericStatus !== 'complete') {
      return <ViewRescheduleRequest />;
    }
    return null;
  }

  customFooter() {
    return (
      <div className="centered p-16-16-24 mb-extra">
        <div className="mb-16"><img className="soothe-logo-icon" src={logoBlue} alt="Soothe-small" /></div>
        <div className="size-24-32 contentPrimary medium-font mb-16">Manage your reservation</div>
        <div className="size-14-20 contentSecondary mb-16">Download the Soothe app to manage your appointment, message your provider, rebook with your favorite providers and more!</div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 mb-8">
            <a href="https://apps.apple.com/us/app/soothe-in-home-massage/id811054908?ls=1"><img src={AppleStore} alt="dowlaod-app-apple" /></a>
          </div>
          <div className="col-xs-12 col-sm-6">
            <a href="https://play.google.com/store/apps/details?id=com.soothe.client&hl=en_US"><img src={PlayStore} alt="dowlaod-app-playStore" /></a>
          </div>
        </div>
      </div>
    );
  }

  displayDetails() {
    const smallScreen = get(this.props, 'xsDisplay', false);
    return (
      <div className={`${smallScreen ? '' : 'display-flex'} sm-display-block apointment-details`}>
        <div className="flex-1 mr-15 sm-mr-0 set-overflow max-h-full">
          {this.sectionHeader()}
          <div className="size-24-32 contentPrimary font-weight-bold p-16 pb-8">My appointment</div>
          {this.viewPendingRequest()}
          {this.appointmentTimeAndPlace()}
          {this.actionsListBasedOnAppointmentStatus()}
          {this.appointmentExtraDetails()}
          {this.customFooter()}
        </div>
        {smallScreen ? null : <div className="flex-2 d-sm-none full-w-h bg-img" style={{ backgroundImage: `url(${get(this.props, 'appointment.info_fields.img_url', '')})` }} />}
      </div>
    );
  }

  mobileSizeStickyButton() {
    const tracker = get(this.props, 'rebookOptions.tracker', {});
    const hasAvailableTimes = get(tracker, 'pending_response', '') === 'pending_client_response_rebook';
    const availableTimes = get(this.props, 'rebookOptions.booking_options', []);
    const hasPro = get(this.props, 'rebookOptions.cart.therapist_preferences.0', null);
    if (this.props.width < 800 && hasAvailableTimes && !isEmpty(availableTimes) && hasPro) {
      return (
        <button
          type="button"
          className="btn btn-action p-fixed bottom-0 border-radius-30 full-w-100 medium-font "
          onClick={() => this.setState({ isOpenAvailableTimesModal: true })}
        >
          See available times
        </button>
      );
    }
    return null;
  }

  render() {
    const { unfilled24Hrs, allProsDeclined } = this.state;
    return (
      <>
        {this.displayDetails()}
        {this.availableTimesModal()}
        {this.mobileSizeStickyButton()}
        {this.state.showRespondToReschedule && get(this.props, 'appointment.info_fields.tracker.state', 'requested') !== 'complete' ? <RespondToRescheduleConfirmed show={this.state.showRespondToReschedule} hideModal={() => this.setState({ showRespondToReschedule: false })} /> : null}
        {unfilled24Hrs || allProsDeclined ? (
          <BlindAuctionModal
            allProsDeclined={allProsDeclined}
            unfilled24Hrs={unfilled24Hrs}
            openReschedule={() => { this.setState({ isOpenRescheduleModal: true, rebook_flag: 'same_therapist', unfilled24Hrs: false }); }}
            openProsPopup={() => { this.setState({ prosPopupOpen: true, unfilled24Hrs: false }); }}
          />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
  abTest: state.abTest,
});
export default connect(mapStateToProps, { setRebookOptions })(Index);
