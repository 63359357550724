import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize, get } from 'lodash';
import { withLocalize } from 'react-localize-redux';
import Slider from 'react-slick';
import { annual, minutes, monthly } from '../../../constants';
import calendarIcon from './Assets/Images/calendar-icon.png';
import plusIcon from './Assets/Images/plus-icon.png';
import enhancementIcon from './Assets/Images/enhancement-icon.png';
import locationIcon from './Assets/Images/location-icon.png';
import avatarSingleImg from '../../../Assets/Images/User_avatar.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
class AppointmentDetails extends React.Component {
    constructor(props) {
        super(props)

        this.addOnLineItem = this.addOnLineItem.bind(this);
        this.subscriptionLineItem = this.subscriptionLineItem.bind(this);
    }


    addOnLineItem(addOns) {
        if (addOns.length === 0) { return ''; }
        let lineBreak = addOns.length > 1 ? <br /> : '';
        let addOnString = addOns.map((addOn) => { return addOn.title }).join(', ');

        return (
            <div className='detail'>
                <div className='icon-container'><img src={enhancementIcon} className='icon-3' alt='lotion' /></div>
                <div className='copy-container'>{addOnString} {lineBreak}</div>
            </div>
        );
    }

    subscriptionLineItem() {
        const firstTimeSubscriptionTierId = get(this.props, "booking.cart.subscriptions.subscription_tier_id", "");

        if (!firstTimeSubscriptionTierId) return;

        let subscriptionType = (firstTimeSubscriptionTierId === 205) ? capitalize(monthly) : capitalize(annual);

        if (firstTimeSubscriptionTierId) {
            return (
                <div>
                    <div className='icon-container'><img src={plusIcon} className='icon-4' alt='plus' /></div>
                    <div className='copy-container'>Soothe Plus: {subscriptionType}</div>
                </div>
            )
        }
    }

    renderAddress() {
        const address = this.props.booking.cart.address;
        if (address) {
            return (<div className='left'>
                <div className='icon-container'><img src={locationIcon} className='icon-2' alt='location pin' /></div>
                <div className='copy-container'>
                    <div className='location-wrapper'>
                        <div className='address-content'>
                            <div style={{ display: 'inline-block', maxWidth: '188px', width: '188px' }}>{address.address_line_1}</div>
                            <div>{address.city}, {address.state} {address.zip_code}</div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    }

    render() {
        const cart = this.props.booking.cart;
        let cartProducts = cart.cartProducts;
        let cartDetails;
        let divider = <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>;
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        if (cartProducts) {
            cartDetails = cartProducts.map(cartProduct => (
                <div key={cartProduct.id}>
                            <div>
                                <div className='appt-detail-container' >
                                    <div className='bottom'>
                                        <div className='left'>
                                            <div className='row'>
                                                <div className='col-xs-2'>
                                                    <img style={{ "width": "100%" }} src={get(cartProduct, "pro.avatar_url", avatarSingleImg)} alt={cartProduct.title || ""} />
                                                </div>
                                                <div className='col-xs-10'>
                                                    <div className='sub-title'><div className='modality'>{cartProduct.title || ""}</div></div>
                                                    <div className='pro-card-line-3'><div className='mt-gender'>{cartProduct.subtitle || ""}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='bottom'>

                                        <div className='left'>
                                            <div className='icon-container'><img src={calendarIcon} className='icon-1' alt='calendar' /></div>

                                            <div className='copy-container'>
                                                <div>{this.props.booking.cart.date.display}{divider}{this.props.booking.cart.time.display}{divider}{cartProduct.session_length} {capitalize(minutes)}</div>
                                            </div>
                                        </div>


                                        <div className='left'>{this.addOnLineItem(cartProduct.add_ons)}</div>

                                        {this.props.showAddress ? this.renderAddress() : null}

                                    </div>
                                </div>
                            </div>
                </div>
            ));
            return (
                <div className='details-wrapper'>
                    <Slider {...settings}>{cartDetails}</Slider>
                </div>
            )
        } else {
            return '';
        }
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

AppointmentDetails.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(AppointmentDetails));