/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import greenMarker from '../../../Assets/Images/pin-v3.png';

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { lat, lng } = this.props;
    return (
      <Map
        google={this.props.google}
        zoom={15}
        style={{
          width: '100%',
          height: '150px',
          position: 'relative',
          borderRadius: '8px',
        }}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
        className="map"
        initialCenter={{ lat: Number(lat), lng: Number(lng) }}
        center={{ lat: Number(lat), lng: Number(lng) }}
      >
        <Marker position={{ lat: Number(lat), lng: Number(lng) }} icon={greenMarker} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_PLACES_API_KEY),
})(MapContainer);
