/* eslint-disable jsx-a11y/scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { times, map } from 'lodash';
import moment from 'moment';
import './horizontalCalender.css';

class HorizontalCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(props.defaultSelectedDate || props.selectedDate),
      datesList: [],
    };
    this.daysTable = this.daysTable.bind(this);
    this.dayLink = this.dayLink.bind(this);
  }

  componentDidMount() {
    const { daysCount, startDate } = this.props;
    const selection = moment(startDate);
    const arr = [];

    times(daysCount, (ind) => {
      arr.push(selection.clone().add(ind, 'days'));
    });
    this.setState({ datesList: arr });
  }

  dayLink(dateEl) {
    const { selectedDate } = this.state;
    return (
      <div className={`${dateEl.isSame(selectedDate) ? 'selected-day-horizontal' : ''} padding-12 d-inline-block`}>
        <a
          href={`#${dateEl.format('D')}-${dateEl.format('MMM')}`}
          onClick={() => {
            this.setState({ selectedDate: dateEl }, () => {
              if (this.props.additionalAction) {
                this.props.additionalAction(dateEl);
              }
            });
          }}
        >
          <div className="black-color mb-3 size-14-24">{dateEl.format('D')}</div>
          <div className="size-12-20 dark-grayed">{dateEl.format('MMM')}</div>
        </a>
      </div>
    );
  }

  daysTable() {
    const { datesList } = this.state;
    return map(datesList, (dateEl, ind) => (
      <td scope="col" key={`date-col-${ind}`} className="dark-grayed fix-col-width">
        <div className="medium-font mb-9 size-14-16 dark-grayed">{dateEl.format('dd')}</div>
        {this.dayLink(dateEl)}
      </td>
    ));
  }

  render() {
    return (
      <nav id="time-scroller">
        <div className="table-responsive">
          <table className="table table-borderless text-align-center mb-0">
            <tbody>
              <tr>
                {this.daysTable()}
              </tr>
            </tbody>
          </table>
        </div>
      </nav>
    );
  }
}

export default HorizontalCalendar;
