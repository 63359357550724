import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { setCart, setAddresses, setProducts } from '../../../Actions';
import { blankHref, defaultErrorMessage, HotJar } from '../../../constants';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import PlaceAutoCompleteInput from '../../Shared/PlaceAutoCompleteInput';
import { STEPS } from '../Shared/constants';
import { find, get, filter, isEmpty, merge, pick, snakeCase, identity, pickBy, mapKeys, first, camelCase, map, orderBy, compact, join, assign } from 'lodash';
import { addressAddNew, addressSelection, seBookingAddressInput } from '../../Shared/WebAnalytics';
import grayMarker from '../../../Assets/Images/places_input_marker.png';
import orangeMarker from '../../../Assets/Images/selected_place_input_marker.png';
import TextWithIcon from '../../Shared/TextWithIcon';
import '../Assets/Styles/index.css';
import './Assets/Styles/AddressStep.css';
import Slide from "@material-ui/core/Slide";
HotJar();

class AddressStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            address: null,
            isAddNew: false
        }
        this.goToNextStep = this.goToNextStep.bind(this);
        this.addressInput = this.addressInput.bind(this);
        this.subtitleWithLink = this.subtitleWithLink.bind(this);
        this.getProductsByZipcode = this.getProductsByZipcode.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
        this.renderBasedOnUserStatus = this.renderBasedOnUserStatus.bind(this);
        this.addressList = this.addressList.bind(this);
        this.selectDefaultOrFirstAddress = this.selectDefaultOrFirstAddress.bind(this);
        this.addressListItem = this.addressListItem.bind(this);
        this.setDataAndNext = this.setDataAndNext.bind(this);
        this.fetchAddressesByUserId = this.fetchAddressesByUserId.bind(this);
        this.switchInput = this.switchInput.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeBackground("");
        let loggedIn = get(this.props, 'client.loggedIn', false);
        if (loggedIn) {
            this.fetchAddressesByUserId(get(this.props, 'client.user_id', ''), loggedIn);
        }
    }
    componentWillReceiveProps(nextProps) {
        let oldUserId = get(this.props, "client.user_id", ""),
            newUserId = get(nextProps, "client.user_id", ""),
            loggedIn = get(nextProps, 'client.loggedIn', false);
        if (loggedIn && newUserId && newUserId !== oldUserId) {
            this.fetchAddressesByUserId(newUserId, loggedIn);
        }
    }
    goToNextStep() {
        let nextStep = find(STEPS, (step) => (step.id === "SERVICE"));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    selectDefaultOrFirstAddress(addresses) {
        if (!isEmpty(addresses)) {
            let addressId = get(this.props, "booking.cart.address.id", ""),
                defaultAddress;
            if (addressId) {
                defaultAddress = find(addresses, (a) => (a.id === addressId));
            } else {
                defaultAddress = find(addresses, (a) => (a.default));
            }
            if (!defaultAddress) {
                defaultAddress = first(addresses);
            }
            this.setState({ address: defaultAddress }, this.getProductsByZipcode);
        }
    }
    fetchAddressesByUserId(userId, loggedIn) {
        if (userId && loggedIn) {
            axios.get(`${API_ROOT}/users/${userId}/addresses`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
                let { addresses } = response.data;
                addresses = orderBy(addresses.map((address) => mapKeys(address, (value, key) => camelCase(key))), ['default'], ['desc'])
                this.props.setAddresses(addresses);
                this.selectDefaultOrFirstAddress(addresses);
            }).catch((e) => {
                let message = get(e, 'response.data.errors.0.message', defaultErrorMessage);
                this.setState({ showErrorModal: true, error: message });
            });
        }
    }
    setDataAndNext(products, andNext) {
        if (!isEmpty(products)) {
            this.props.setProducts({ products });
            let address = merge({ 'has_dogs': false, 'has_cats': false, 'has_table': false, 'has_sheets': false }, pick(pickBy(mapKeys(this.state.address, (value, key) => snakeCase(key)), identity), ['address_line_1', 'address_line_2', 'city', 'state', 'zip_code', 'parking_info', 'parking_type', 'flights_of_stairs', 'hotel_name', 'hotel_room_number', 'gate_door_code', 'has_dogs', 'has_cats', 'has_table', 'has_sheets', 'mobile_number', 'id', 'complete', 'default', 'market']), { 'client_id': this.props.client.user_id });
            if (!address['address_line_1']) {
                address['address_line_1'] = this.state.addressLine1;
                address['zip_code'] = this.state.zipCode;
            }
            let cart = get(this.props, "booking.cart", {});
            this.props.assignToCart({ cart: { ...cart, address } });
            let addressId = get(address, "id", "");
            if (addressId) {
                addressSelection(get(cart, "id", ""), addressId, "no", "booking_23_control", get(cart, "address.market", get(address, "market", "")))
            } else {
                addressAddNew("", "booking_flow", "booking_23_control", "No", "No", get(cart, "address.market", get(address, "market", "")))
            }
            if (andNext) {
                this.goToNextStep();
            }
        } else {
            this.setState({ error: "We currently do not service this address. Please try another one." });
        }
    }
    getProductsByZipcode(andNext = false) {
        let self = this;
        let { address } = this.state;
        let queryParam = '';
        let { country, city, zipCode, cityId, addressLine1, state } = address;
        if (zipCode) {
            queryParam = `?zip_code=${zipCode}`;
        } else if (cityId) {
            queryParam = `?city_id=${cityId}`;
        }
        if (!(zipCode && addressLine1 && (city || cityId) && state)) {
            this.setState({ error: "Please select a location with a street address" });
            return;
        }
        axios.get(`${API_ROOT}/v7/products${queryParam}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
            let { products } = response.data;
            products = filter(products, (el) => (el.bookable));
            seBookingAddressInput(country, city, zipCode, products && products.length > 0);
            self.setDataAndNext(products, andNext);
        }).catch((e) => {
            let message = get(e, 'response.data.errors.0.message', defaultErrorMessage) || get(e, 'response.data.errors.message', defaultErrorMessage);
            this.setState({ error: message });
        });
    }
    addressInput() {
        return (<PlaceAutoCompleteInput
            uniqueId={"new-booking-flow-first-screen"}
            inputStylingClass="form-control address-search-input-style"
            handleAddress={(newAddress) => {
                this.setState({ address: newAddress }, () => this.getProductsByZipcode(true))
            }}
            placeholder="Search for an address, hotel or office"
        />);
    }
    addressListItem(addr, currentId, isFirst = false) {
        let { id, addressLine1, city, state, zipCode, country } = addr;
        let isSelected = Boolean(currentId === id);
        return (<div key={`addr-${id}`} className={`address-item ${isSelected ? 'address-item-selected' : ''}`}
            id={isFirst ? "firstSavedAddress" : `savedAddress${id}`}
            onClick={() => {
                this.setState({ address: addr }, () => this.getProductsByZipcode(true))
            }}>
            <TextWithIcon
                icon={isSelected ? orangeMarker : grayMarker}
                title={addressLine1 || ""}
                details={join(compact([city, `${state || ""} ${zipCode || ""}`, country]), ', ')}
            />
        </div>)
    }
    addressList(addresses) {
        let currentId = get(this.state, 'address.id', '');
        addresses = orderBy(addresses, (o) => (o.id !== currentId));
        return (<div>
            <div className='address-container'>
                <div className='size-18-24 color-gray p-16'>Address book</div>
                {map(addresses, (addr, ind) => {
                    return (this.addressListItem(addr, currentId, ind === 0));
                })}
            </div>
        </div>)
    }
    renderBasedOnUserStatus() {
        let loggedIn = get(this.props, 'client.loggedIn', false),
            addresses = get(this.props, 'addresses', []),
            isAddNew = this.state.isAddNew;
        if (loggedIn && !isEmpty(addresses) && !isAddNew) {
            return this.addressList(addresses);
        }
        return this.addressInput();
    }
    errorMessage() {
        return (this.state.error ?
            <div className="size-14-20 color-red txt-center medium-font">{this.state.error}</div>
            : null)
    }
    subtitleWithLink() {
        let loggedIn = get(this.props, 'client.loggedIn', false);
        if (loggedIn) {
            return null;
        }
        return (<div className='medium-font subtitle-style mt-56 sm-mt-44'>
            or <a className='login-link' href={blankHref} onClick={() => { this.props.goToAuthentication(true) }}>LOGIN</a> to use a saved address
        </div>);
    }
    switchInput() {
        let loggedIn = get(this.props, 'client.loggedIn', false),
            isAddNew = this.state.isAddNew;
        if (!loggedIn) {
            return null;
        }
        if (!isAddNew) {
            return (<div className='medium-font subtitle-style mt-56 sm-mt-44'>
                Or <a id="addNewAddressButton" className='cta-link' href={blankHref} onClick={() => { this.setState({ isAddNew: true, error: '' }) }}>Add a new address</a> to your address book
            </div>)
        }
        return (<div className='medium-font subtitle-style mt-56 sm-mt-44'>
            Or <a className='cta-link' href={blankHref} onClick={() => { this.setState({ isAddNew: false, error: '' }) }}>Choose an address</a> from your address book
        </div>)
    }
    render() {
        return (<Slide direction="up" in={true} mountOnEnter>
            <div className='display-flex mt-10vh'>
                <div className='max-width-47'>
                    <div className='medium-font main-text sm-hidden'>Where would you like your Provider to meet you?</div>
                    <div className='medium-font txt-center color-white size-32-40 sm-shown'>Where should your Provider meet you?</div>
                    {this.renderBasedOnUserStatus()}
                    {this.errorMessage()}
                    {this.subtitleWithLink()}
                    {this.switchInput()}
                </div>
            </div>
        </Slide>
        );
    }
}

const mapStateToProps = state => ({
    addresses: state.addresses,
    booking: state.booking,
    products: Object.values(get(state, "productsReducer.products", {})),
    client: state.client,
    bookingFlow: state.bookingFlow
});

AddressStep.propTypes = {
    booking: PropTypes.object,
    client: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    setAddresses: PropTypes.func.isRequired,
    setCart: PropTypes.func.isRequired,
    setProducts: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { setCart, setAddresses, setProducts })(AddressStep);
