/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/** **************************************************
class ExampleModal extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showModal: true
    };
  }

  render() {
    return (
      <Modal title="hmmmmmm" isOpen={this.state.showModal}
      close={ () => { this.setState({ showModal: false }); } }>
        <p>I am the body</p>
      </Modal>
    );
  }
}
**************************************************** */
import React from 'react';
import PropTypes from 'prop-types';
import './Modal.css';

class Modal extends React.Component {
  render() {
    if (this.props.isOpen) {
      return (
        <div className="custom-modal">
          <div className="modal-content">
            <div className="modal-title">{this.props.title}</div>
            <div className="content">{this.props.children}</div>

            <div className={`btn-container ${this.props.showBtn ? '' : 'd-none'}`}>
              <a
                className="btn"
                href="&times;"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.close();
                }}
              >
                { this.props.btnCopy }
              </a>
            </div>
          </div>
        </div>
      );
    }
    return '';
  }
}

Modal.propTypes = {
  showBtn: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  isOpen: false,
  showBtn: false,
};

export default Modal;
