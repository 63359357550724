import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { assign, omit } from 'lodash';
import { withLocalize } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import { EMAIL, PRINT, NONE } from '../shared/constants'
import { ACTIVE_LANGUAGE_CODE } from '../../../constants'
import { giftCardTypeEvent } from '../../Shared/WebAnalytics';
const cardTypes = [
  { type: "digital", title: "Digital", description: "Email directly to a special someone on the date of your choosing" },
  { type: "print_at_home", title: "Print at home", description: "Just add a bow and you’re ready to make someone’s day" },
  // { type: "buy_in_bulk", title: "Buy in bulk", description: "Share the gift of wellness with employees or clients" }
]
class Step1 extends React.Component {
  constructor(props) {
    super(props);


    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.selectDeliveryMethod = this.selectDeliveryMethod.bind(this);

    this.state = { width: 0 };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  selectDeliveryMethod(deliveryMethod) {
    this.props.setGiftCard({
      ...omit(this.props.giftCard, ['deliveryMethod',
        'cityId',
        'locale',
        'occasion',
        'amount',
        'currency',
        'currencySymbol',
        'minutes',
        'quantity',
        'deliveryDate',
        'message',
        'recipientName',
        'recipientEmail',
        'recipientEmailIsValid',
        'name',
        'email',
        'emailIsValid']),

      deliveryMethod,
      cityId: null,
      locale: ACTIVE_LANGUAGE_CODE,
      occasion: NONE,
      amount: 0,
      currency: 'usd',
      currencySymbol: '$',
      minutes: null,
      quantity: 1,
      deliveryDate: moment(new Date()).format('MM/DD/YYYY'),
      message: '',
      recipientName: '',
      recipientEmail: '',
      recipientEmailIsValid: '',
      name: '',
      email: '',
      emailIsValid: false
    });

    this.props.setGiftCardCart(assign(this.props.giftCardCart, { cityId: null }))
  }
  render() {
    let { totalSteps, giftCardType } = this.props;
    return (
      <div className="full-height-section bg-secondary font-style-medium txt-align-center  p-medium step1" id="1">
        {giftCardType ? <p className="steps-indicator light-mode-secondary mb-sm-32">Step 1 of {totalSteps}</p> : null}
        <div className="title-1 mb-48 mb-sm-45">What type of gift card?</div>
        <div className="row equal">
          {cardTypes.map((cType, it) => (<div className="col-xs-12 col-sm-6 mb-16" key={`card-type-${it}`}>
            <div className={`light-box mb-16 ${giftCardType === cType.type ? 'selected-type' : ''}`} onClick={() => {
              giftCardTypeEvent(cType.type);
              // if (cType.type === "buy_in_bulk")
              //   window.location.href = 'https://soothe-biz.cashstar.com/login/login/';
              // else {
              if (cType.type === "digital") {
                this.selectDeliveryMethod(EMAIL);
              } else this.selectDeliveryMethod(PRINT)
              this.props.updateFieldAndStep('giftCardType', cType.type, 4)
              // }
            }}>
              <div className="heading-1 sm-16-20 font-style-medium mb-12 mb-sm-4 sm-txt-left">{cType.title}</div>
              <div className="font-style-regular small-text sm-gray-font sm-txt-left">{cType.description}</div>
            </div></div>))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setGiftCard, setGiftCardCart }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step1));
