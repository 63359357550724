/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { Elements } from 'react-stripe-elements';
import translations from '../../../Translations/soothePlus.json';
import translationsBooking from '../../../Translations/bookingFlow.json';
import {
  pleaseAddACreditCardError, defaultErrorMessage,
  pleaseSelectACreditCardError, ACTIVE_LANGUAGE_CODE, US, DE,
  blankHref, ROUTES, HotJar, termsAndConditions,
} from '../../../constants';
import { membershipComplete } from '../../Shared/WebAnalytics';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import {
  loadClient, setSubscription, setSubscriptionView, setCreditCards,
} from '../../../Actions';
import { VIEWS } from '../Constants';
import CreditCard from './CreditCard';
import CreditCardsModal from './CreditCardsModal';
import NavBar from '../../Shared/NavBar';
import ErrorModal from '../../Shared/ErrorModal';
import Modal from '../../Shared/Modal';
import EditUserModal from '../../Shared/EditUserModal';
import summaryHero from '../Assets/Images/soothe-plus-summary.png';
import generic from '../Assets/Images/cc-generic-dark.png';
import check from '../Assets/Images/soothe-plus-checkmark.png';
import edit from '../Assets/Images/soothe-plus-edit.png';
import addCard from '../Assets/Images/soothe-plus-add-card.png';
import backArrow from '../../../Assets/Images/back-arrow.png';
import '../Assets/Styles/SubscribeToSoothe.css';

HotJar();

class SubscribeToSoothe extends React.Component {
  constructor(props) {
    super(props);

    this.renderModals = this.renderModals.bind(this);
    this.prev = this.prev.bind(this);
    this.load = this.load.bind(this);
    this.reload = this.reload.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.creditCardDescriptions = this.creditCardDescriptions.bind(this);
    this.subscribeToSoothePlus = this.subscribeToSoothePlus.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.subscriptionDisclaimer = this.subscriptionDisclaimer.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.updateCreditCard = this.updateCreditCard.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.subscribedConfirmationModal = this.subscribedConfirmationModal.bind(this);

    this.state = {
      height: 300,
      submitted: false,
      showCreditCards: false,
      showNewCreditCard: false,
      isChecked: false,
      showModal: false,
      showSubscribedConfirmationModal: false,
      error: null,
    };

    this.props.addTranslation(translations);
    this.props.addTranslation(translationsBooking);
  }

  componentDidMount() {
    this.load();
    // modal margin top + bottom = 140
    // modal padding top + bottom = 40
    // title height with margin = 28
    if (typeof this.parentRef !== 'undefined') {
      const height = this.parentRef.clientHeight - 208 - 100;
      this.setState({ height });
    }

    window.scrollTo(0, 0);
  }

  subscribe(event) {
    event.preventDefault();

    if (this.state.submitted) { return false; }

    if (!this.state.isChecked) return this.setState({ showModal: true, error: 'Please agree to the terms by checking the box in order to subscribe!', submitted: false });

    let params = this.props.subscription;
    params = _.omit(params, ['minutes', 'short_benefits', 'initial_payments', 'disclosure', 'currency', 'percentage', 'creditCard', 'price', 'title', 'billing_frequency', 'totalMinutes', 'confirmation_message']);
    params = _.merge({}, params, { credit_card_id: params.credit_card_id });

    this.setState({ submitted: true });

    if (this.props.subscription.credit_card_id) {
      axios.post(`${API_ROOT}/subscriptions/`, { subscription: params }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result } = resp.data;

        if (result) {
          const membership = this.props.subscription;
          membershipComplete(membership.title, membership.subscription_tier_id, `${membership.percentage}%`, membership.minutes, membership.price, membership.currency);
          this.setState({ showSubscribedConfirmationModal: true });
        } else {
          const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showModal: true, error: message, submitted: false });
        }
      }).catch((e) => {
        if (e) {
          const message = _.result(_.first(e.response.data.errors || {}), 'message') || _.result(e.response.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showModal: true, error: message, submitted: false });
        }
      });
    } else {
      this.setState({ showModal: true, error: pleaseSelectACreditCardError, submitted: false });
    }
  }

  setSubscription(value) {
    this.props.setSubscription({
      ...this.props.subscription,
      ...value,
    });
  }

  prev(event) {
    event.preventDefault();
    window.location.href = ROUTES.soothePlus;
  }

  load() {
    const userId = this.props.client.user_id;

    if (userId) {
      axios.get(`${API_ROOT}/users/${userId}/credit_cards`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
        const creditCards = response.data.credit_cards;
        this.props.setCreditCards(creditCards);

        let creditCard; let
          creditCardId;

        if (!creditCard && creditCards.length > 0) {
          creditCard = _.find(creditCards, (cc) => cc.default);

          if (creditCard) {
            creditCardId = _.result(creditCard, 'id');
          } else {
            creditCard = _.first(creditCards);
            creditCardId = _.result(creditCard, 'id');
          }

          this.setSubscription({ credit_card_id: creditCardId, creditCard });
        }
      }).catch((error) => {
        this.setState({ showModal: true, error });
        this.props.setCreditCards([]);
        this.setSubscription({ credit_card_id: null, creditCard: null });
      });
    } else {
      this.props.setCreditCards([]);
      this.setSubscription({ credit_card_id: null, creditCard: null });
    }
  }

  reload(id) {
    this.load();
    const creditCardId = id || this.props.subscription.credit_card_id;

    this.setSubscription({ credit_card_id: creditCardId });
  }

  deleteCreditCard(index) {
    const creditCard = this.props.creditCards[index];

    if (creditCard) {
      axios.delete(`${API_ROOT}/credit_cards/${creditCard.id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result, success } = resp.data;

        if (result || success) {
          this.reload();
        } else {
          const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showModal: true, error: message });
        }
      }).catch((error) => {
        const message = _.result(_.first(error.response.data.errors || {}), 'message') || _.result(error.response.data.errors || {}, 'message') || defaultErrorMessage;
        this.setState({ showModal: true, error: message });
      });
    }
  }

  creditCardDescriptions() {
    if (this.props.subscription.creditCard) {
      return (
        <div className="detail-wrapper">
          <div className="icon-wrapper">
            <div className="icon" style={{ background: `url(${generic})` }} />
          </div>
          <div className="detail">
            { this.props.subscription.creditCard.display_string }
          </div>
          <a href={blankHref} onClick={(event) => { event.preventDefault(); this.setState({ showCreditCards: true }); }}><img src={edit} className="edit-card" alt="edit card" /></a>
        </div>
      );
    }
    return (
      <div className="detail-wrapper">
        <a href={blankHref} className="add-card-center" onClick={(event) => { event.preventDefault(); this.setState({ showCreditCards: true }); }}>
          <div className="detail">
            <img src={addCard} className="add-card" alt="add card" />
&nbsp;&nbsp;&nbsp;
            { pleaseAddACreditCardError }
          </div>
        </a>
      </div>
    );
  }

  updateCreditCard(creditCardId, creditCard) {
    this.setState({ showCreditCards: false });
    this.setSubscription({ credit_card_id: creditCardId, creditCard });
  }

  renderModals() {
    const fonts = [{ src: 'url("https://www.soothe.com/static/media/GothamHTF-Book.48680770.otf")', family: 'gotham' }];
    const countryCode = ACTIVE_LANGUAGE_CODE === DE ? DE : US;

    if (this.state.showNewCreditCard) {
      return (
        <Elements locale={countryCode} fonts={fonts}>
          <CreditCard
            creditCard={this.props.subscription.creditCard}
            prev={() => this.setState({ showNewCreditCard: false })}
            close={() => this.setState({ showNewCreditCard: false, showCreditCards: false })}
            reload={this.reload}
          />
        </Elements>
      );
    } if (this.state.showCreditCards) {
      return (
        <CreditCardsModal
          creditCardId={this.props.subscription.credit_card_id}
          addNewCreditCard={() => { this.setState({ showNewCreditCard: true }); }}
          height={this.state.height}
          close={() => { this.setState({ showCreditCards: false }); }}
          onChange={this.updateCreditCard}
          reload={this.reload}
        />
      );
    }
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  subscriptionDisclaimer() {
    const { subscription } = this.props;
    let splitString; let a; let b; let
      terms;

    splitString = subscription.disclosure.split(termsAndConditions);
    a = splitString[0];
    b = splitString[1];
    terms = <a className="subscription-terms" href={ROUTES.terms} target="_blank" rel="noopener noreferrer">{ termsAndConditions }</a>;

    if (!this.props.client.subscribed) {
      return (
        <div className="form-group annual-subscription-disclaimer">
          <div className="check-box">
            <input type="checkbox" value={this.state.isChecked} className="" onChange={this.handleChecked} />
          </div>
          <div className="check-box-copy annual-disclaimer">
            { subscription.confirmation_message }
          </div>
          <div className="annual-disclaimer annual-disclaimer-italic">
            {a}
            {' '}
            {terms}
            {' '}
            {b}
          </div>
        </div>
      );
    }
  }

  subscribeToSoothePlus() {
    const { percentage } = this.props.subscription;
    const subscribeString = <Translate id="global.subscribe" />;

    return (
      <Translate>
        { ({ translate }) => (
          <div className="soothe-plus">
            <div className="desktop-wrapper">
              <div className="soothe-plus-wrapper" style={{ background: `url(${summaryHero})` }}>
                <div className="heading">
                  <h1>{ _.capitalize(translate('global.summary')) }</h1>
                </div>
                <div className="value-props summary-props">
                  <h1>
                    {this.props.subscription.title}
                    {' '}
                    { translate('navigation.membership') }
                  </h1>
                  <div className="subcription-details detail-summary">
                    {this.props.subscription.totalMinutes}
                    {' '}
                    { _.capitalize(translate('global.minutes')) }
                  </div>
                  <p className="prop-checkbox" style={{ backgroundImage: `url(${check})` }}>
                    <span>{ translate('soothePlusSubscribe.valueProps.prop1', { percentage }) }</span>
                  </p>
                  <p className="prop-checkbox" style={{ backgroundImage: `url(${check})` }}>
                    <span>{ translate('soothePlusSubscribe.valueProps.prop2') }</span>
                  </p>
                  <p className="prop-checkbox" style={{ backgroundImage: `url(${check})` }}>
                    <span>{ translate('soothePlusSubscribe.valueProps.prop3') }</span>
                  </p>
                  <p className="prop-checkbox" style={{ backgroundImage: `url(${check})` }}>
                    <span>{ translate('soothePlusSubscribe.valueProps.prop4') }</span>
                  </p>
                </div>
                <div className="payment-title">{ _.capitalize(translate('global.payment')) }</div>
                { this.creditCardDescriptions() }
                <div className="book-container">
                  { this.subscriptionDisclaimer() }
                  <span style={{ textTransform: 'capitalize' }}>
                    <a
                      className="btn form-control subscribe-cta"
                      href={blankHref}
                      onClick={this.subscribe}
                      disabled={!this.state.isChecked}
                    >
                      { subscribeString }
                    </a>
                  </span>
                  <a className="btn form-control back-btn" href={blankHref} onClick={this.prev}>
                    <img src={backArrow} className="back-arrow-img" alt="back arrow" />
                    {' '}
                    { _.capitalize(translate('global.back')) }
                  </a>
                </div>
                { this.renderModals() }
              </div>
              <div className="line-item-wrapper">
                <div className="book-container">
                  <div className="session-info">
                    <span id="length">{ this.props.subscription.title }</span>
                    <span className="price">{this.props.subscription.price}</span>
                  </div>
                  <div className="session-info">
                    <span id="length">
                      {this.props.subscription.totalMinutes}
                      {' '}
                      { _.capitalize(translate('global.minutes')) }
                    </span>
                    <span className="price">{ this.props.subscription.billing_frequency }</span>
                  </div>
                  <div className="subtotal-price">
                    <span id="label">
                      { _.capitalize(translate('global.subtotal')) }
                      :
                    </span>
                    <span id="monthly-price">{this.props.subscription.price}</span>
                  </div>
                  <div className="total-price">
                    <span className="total" id="label">
                      { _.upperCase(translate('global.total')) }
                      :
                    </span>
                    <span className="price" id="monthly-price">{this.props.subscription.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showModal}
        close={() => { this.setState({ showModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  subscribedConfirmationModal() {
    return (
      <Translate>
        { ({ translate }) => (
          <Modal title="" isOpen={this.state.showSubscribedConfirmationModal} close={this.setDetailsView}>
            <div className="confirmation">
              <div className="col-xs-12">
                <h3>
                  { translate('soothePlusIndex.title') }
                </h3>
                <div className="col-xs-12">
                  <h1>
                    { translate('global.thankYou') }
                    !
                  </h1>
                </div>
                <div className="col-xs-12 confirmation-copy">
                  <p>{ translate('soothePlusConfirmation.joined') }</p>
                  <p>{ translate('soothePlusConfirmation.creditsReady') }</p>
                </div>
                <div className="cancellation-btn">
                  <a className="btn form-control subscribe-cta" href={blankHref} onClick={this.setDetailsView}>{ translate('global.close') }</a>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Translate>
    );
  }

  setDetailsView() {
    this.props.setSubscriptionView(VIEWS.SUBSCRIPTION_DETAILS);
    this.setState({ showSubscribedConfirmationModal: false }, window.location.reload());
  }

  render() {
    if (this.props.client.phone == null || this.props.client.phone === '') {
      return (<div><EditUserModal back={this.prev} /></div>);
    }

    return (
      <div>
        <NavBar />
        { this.errorModal() }
        { this.subscribedConfirmationModal() }
        { this.subscribeToSoothePlus() }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  creditCards: state.creditCards,
  subscription: state.subscription,
  subscriptionView: state.subscriptionView,
});

SubscribeToSoothe.propTypes = {
  client: PropTypes.object.isRequired,
  creditCards: PropTypes.array.isRequired,
  loadClient: PropTypes.func.isRequired,
  setCreditCards: PropTypes.func.isRequired,
  setSubscription: PropTypes.func.isRequired,
  setSubscriptionView: PropTypes.func.isRequired,
};

export default withLocalize(connect(mapStateToProps, {
  loadClient, setCreditCards, setSubscription, setSubscriptionView,
})(SubscribeToSoothe));
