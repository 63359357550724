import React from 'react';
import { connect } from 'react-redux';
import { find, times, get, isEmpty, size, map } from 'lodash';
import { setCart, setAddresses, setProducts, setBookingFlowPreviousStep, setRecipientOption } from '../../../Actions';
import { STEPS, RECIPIENT_OPTIONS } from '../Shared/constants';
import '../Assets/Styles/index.css';
import { addCartProduct, clearAllCartProducts } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage } from '../../../constants';
import { seBookingCartProductCount } from '../../Shared/WebAnalytics';
import Slide from "@material-ui/core/Slide";
HotJar();

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.recipientOptions = this.recipientOptions.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.setOptionAndNext = this.setOptionAndNext.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeBackground("none");
        this.props.toggleNavBarStyle(true);
        let previousStep = find(STEPS, (step) => (step.id === 'SERVICE'));
        this.props.setBookingFlowPreviousStep(previousStep);
    }
    goToNextStep() {
        seBookingCartProductCount(size(get(this.props, "booking.cart.cartProducts", [])), get(this.props, "bookingFlow.recipientOption.title", ""), false);
        this.props.setLoaderFlag(false);
        let nextStep = find(STEPS, (step) => (step.id === "MODALITY"));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    handleSelection(recipientOption) {
        this.props.setLoaderFlag(true);
        let previousSelection = get(this.props, "bookingFlow.recipientOption.id", ""),
            newSelection = get(recipientOption, "id", ""),
            cartId = get(this.props, "booking.cart.id", ""),
            cartProducts = get(this.props, "booking.cart.cartProducts", []),
            newProductId = get(this.props, "booking.product.id", ""),
            oldProductId = get(this.props, "booking.cart.cartProducts.0.product.id", "");
        if (cartId && ((previousSelection && previousSelection !== newSelection) || (oldProductId && oldProductId !== newProductId))) {
            clearAllCartProducts(cartId, (response) => {
                this.props.assignToCart({ cart: decode(response.data.cart) });
                this.setOptionAndNext(recipientOption);
            }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)))
        } else if (cartId && isEmpty(cartProducts)) {
            this.setOptionAndNext(recipientOption);
        } else {
            this.props.setRecipientOption(recipientOption);
            this.goToNextStep();
        }
    }
    setOptionAndNext(recipientOption) {
        let count = get(recipientOption, "personsCount", 0),
            userIncluded = get(recipientOption, "userIncluded", false),
            client_name = get(this.props, "client.first_name", ""),
            cartId = get(this.props, "booking.cart.id", ""),
            product_id = get(this.props, "booking.product.id", "");
        times(count, (it) => {
            addCartProduct(cartId, {
                product_id,
                client_name: it === 0 && userIncluded ? client_name : ""
            }, (response) => {
                if (it === count - 1) {
                    this.props.assignToCart({ cart: decode(response.data.cart) });
                    this.props.setRecipientOption(recipientOption);
                    this.goToNextStep();
                }
            }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)))
        });
    }
    recipientOptions() {
        let selected = get(this.props, "bookingFlow.recipientOption.id", "");
        return (<div className="txt-center">
            <ul className='centered-list color-gray sm-color-black'>
                {map(RECIPIENT_OPTIONS, (opt, it) => (
                    <li key={`recipient-${it}`} className={`centered-list-item ${selected === opt.id ? 'selected-item' : ''}`} onClick={() => this.handleSelection(opt)}
                        id={opt.slug}>
                        {opt.title}
                    </li>
                ))}
            </ul>
        </div>
        );
    }
    render() {
        return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div className='display-flex mt-20vh'>
                <div className='medium-font max-width-30'>
                    <div className='main-text color-black'>Who is it for?</div>
                    {this.recipientOptions()}
                </div>
            </div>
        </Slide>
        );
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow
});


export default connect(mapStateToProps, { setRecipientOption, setCart, setAddresses, setProducts, setBookingFlowPreviousStep })(Index);