/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../Translations/soothePlus.json';
import { defaultErrorMessage, blankHref, HotJar } from '../../constants';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../apiConfig';
import { SOOTHE_PLUS_DEFAULT } from './Constants';
import SignupModal from '../Shared/SignupModal';
import NavBar from '../Shared/NavBar';
import Modal from '../Shared/Modal';
import ErrorModal from '../Shared/ErrorModal';
import { setSubscription, setSubscriptionView, loadClient } from '../../Actions';
import summaryHero from './Assets/Images/soothe-plus-summary.png';
import checked from './Assets/Images/checked.png';
import unchecked from './Assets/Images/unchecked.png';
import './Assets/Styles/SubscriptionDetails.css';

HotJar();

class SubscriptionDetails extends React.Component {
  constructor(props) {
    super(props);

    this.renderSignupModal = this.renderSignupModal.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.cancelledConfirmationModal = this.cancelledConfirmationModal.bind(this);
    this.disclaimerModal = this.disclaimerModal.bind(this);
    this.reloadClient = this.reloadClient.bind(this);
    this.getCancellationReasons = this.getCancellationReasons.bind(this);
    this.setCancellationReason = this.setCancellationReason.bind(this);
    this.renderCancellationReasons = this.renderCancellationReasons.bind(this);

    this.state = {
      submitted: false,
      subscriptionDetails: null,
      cancellationMessage: null,
      membershipLevel: null,
      canCancel: null,
      cancellationReasons: [],
      clientSubscriptionCancellationReasonId: null,
      showAreYouSureModal: false,
      showCancelModal: false,
      showCancelledConfirmationModal: false,
      showErrorModal: false,
      error: null,
    };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.getCancellationReasons();
    this.reloadClient();
  }

  getSubscriptionDetails() {
    if (this.props.client.loggedIn && (this.props.client.subscribed || this.props.client.subscription_mintues > 0)) {
      axios.get(`${API_ROOT}/clients/${this.props.client.id}/subscription_details`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const {
          result, cancellation_message, membership_level, subscription_details, can_cancel,
        } = resp.data;

        if (result) {
          this.setState({
            subscriptionDetails: subscription_details, membershipLevel: membership_level, cancellationMessage: cancellation_message, canCancel: can_cancel,
          });
        } else {
          const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showErrorModal: true, error: message });
        }
      }).catch(() => {
        this.setState({ showErrorModal: true, error: defaultErrorMessage });
      });
    }
  }

  reloadClient() {
    axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result, client } = response.data;
      if (result) {
        this.props.loadClient({ ...client, loggedIn: true });
        this.getSubscriptionDetails();
      } else {
        this.props.loadClient({ loggedIn: false });
      }
    }).catch(() => {
      // if fails ignore error message and just set loggedIn to false
      this.props.loadClient({ loggedIn: false });
    });
  }

  getCancellationReasons() {
    axios.get(`${API_ROOT}/client_subscription_cancellation_reasons`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result, client_subscription_cancellation_reasons } = response.data;
      if (result) {
        this.setState({ cancellationReasons: client_subscription_cancellation_reasons });
      } else {
        this.setState({ cancellationReasons: [] });
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  disclaimerModal() {
    return (
      <Translate>
        { ({ translate }) => (
          <Modal title={translate('soothePlusDetails.cancellationDisclaimer')} isOpen={this.state.showAreYouSureModal} close={() => { this.setState({ showAreYouSureModal: false }); }}>
            <div>{ this.state.cancellationMessage }</div>
            <div className="cancellation-btn">
              <a className="btn form-control subscribe-cta" href={blankHref} onClick={() => { this.setState({ showCancelModal: true, showAreYouSureModal: false }); }}>{ translate('soothePlusDetails.cancelSubscription') }</a>
            </div>
            <div className="cancellation-change">
              <a className="cancel-change" href={blankHref} onClick={() => { this.setState({ showAreYouSureModal: false }); }}>{ translate('soothePlusDetails.changeMyMind') }</a>
            </div>
          </Modal>
        )}
      </Translate>
    );
  }

  renderCancellationReasons() {
    return this.state.cancellationReasons.map((checkbox, index) => (
      <div className="cancel-checkbox-container" key={index}>
        <input
          className="cancel-input"
          id={checkbox.id}
          value={checkbox.id}
          type="radio"
          checked={this.state.clientSubscriptionCancellationReasonId === parseInt(checkbox.id, 10)}
          onChange={(e) => this.setCancellationReason(e)}
        />

        <label className="cancel-label label-item" htmlFor={checkbox.id}>
          <img src={this.state.clientSubscriptionCancellationReasonId === checkbox.id ? checked : unchecked} alt="check box" />
            &nbsp;&nbsp;&nbsp;&nbsp;
          <span>{ checkbox.reason }</span>
        </label>
        <div className="check" style={{ display: 'none' }} />
      </div>
    ));
  }

  cancelModal() {
    return (
      <Translate>
        { ({ translate }) => (
          <Modal title={translate('soothePlusDetails.weWillMissYou')} isOpen={this.state.showCancelModal} close={() => { this.setState({ showCancelModal: false }); }}>
            <div className="cancel-instructions">{ translate('soothePlusDetails.helpUsUnderstand') }</div>

            <div className="cancel-reasons-container">
              { this.renderCancellationReasons() }
            </div>

            <div className="cancellation-btn">
              <a className="btn form-control subscribe-cta" href={blankHref} onClick={this.cancelSubscription}>{ translate('soothePlusDetails.cancelSubscription') }</a>
            </div>
            <div className="cancellation-change">
              <a className="cancel-change" href={blankHref} onClick={() => { this.setState({ showCancelModal: false }); }}>{ translate('soothePlusDetails.changeMyMind') }</a>
            </div>
          </Modal>
        )}
      </Translate>
    );
  }

  cancelledConfirmationModal() {
    return (
      <Translate>
        { ({ translate }) => (
          <Modal title={translate('soothePlusConfirmation.membershipHasBeenCancelled')} isOpen={this.state.showCancelledConfirmationModal} close={() => { this.setState({ showCancelledConfirmationModal: false }); }}>
            <div className="cancel-instructions">{ translate('soothePlusConfirmation.cancelled') }</div>
            <div className="cancellation-btn">
              <a className="btn form-control subscribe-cta" href={blankHref} onClick={() => { this.setState({ showCancelledConfirmationModal: false }, window.location.reload()); }}>{ translate('global.close') }</a>
            </div>
          </Modal>
        )}
      </Translate>
    );
  }

  setCancellationReason(e) {
    this.setState({ clientSubscriptionCancellationReasonId: parseInt(e.target.value, 10) });
  }

  cancelSubscription(event) {
    event.preventDefault();

    if (this.state.submitted) { return false; }

    this.setState({ submitted: true });

    if (this.props.client.subscribed) {
      axios.delete(`${API_ROOT}/subscriptions/?client_subscription_cancellation_reason_id=${this.state.clientSubscriptionCancellationReasonId}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result } = resp.data;

        if (result) {
          // membershipCancelled(membershipType, membershipId, membershipDiscount, membershipLength);
          this.props.setSubscription(SOOTHE_PLUS_DEFAULT);
          this.reloadClient();
          this.setState({
            showErrorModal: false, showCancelModal: false, showCancelledConfirmationModal: true, cancellationReasons: [],
          });
        } else {
          const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showErrorModal: true, error: message, submitted: false });
        }
      }).catch(() => {
        this.setState({ showErrorModal: true, error: defaultErrorMessage, submitted: false });
      });
    } else {
      this.setState({ showErrorModal: true, submitted: false, error: defaultErrorMessage });
    }
  }

  renderSignupModal() {
    const redirectURI = `${process.env.REACT_APP_API_URL}/apple/auth_redirect_soothe_plus`;
    if (!this.props.client.loggedIn) {
      return (<SignupModal redirectURI={redirectURI} next={this.props.next} />);
    }
  }

  errorModal() {
    return (
      <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const cancel = <a className="btn form-control subscribe-cta" href={blankHref} onClick={() => { this.setState({ showAreYouSureModal: true }); }}><Translate id="soothePlusDetails.cancelSubscription" /></a>;
    const cta = this.state.canCancel ? cancel : '';

    return (
      <Translate>
        { ({ translate }) => (
          <div>
            <NavBar />
            <div className="soothe-plus">
              <div className="soothe-plus-wrapper details-wrapper" style={{ background: `url(${summaryHero})`, display: 'block' }}>
                <div className="heading">
                  <h1>Your Membership</h1>
                </div>
                <div className="value-props">
                  <h1>Soothe Plus savings</h1>
                  <div className="subcription-details">{ this.state.subscriptionDetails }</div>
                  <div className="soothe-plus-details">
                    <div className="container">
                      <div className="text-align-left">
                        <i className="glyphicon glyphicon-stats" />
&nbsp;&nbsp;
                        { translate('soothePlusDetails.membershipLevel') }
                        :  &nbsp;&nbsp;
                        {this.state.membershipLevel || translate('global.cancelled') }
                      </div>
                      <div className="text-align-left plus-current">
                        <i className="glyphicon glyphicon-time" />
&nbsp;&nbsp;
                        { translate('soothePlusDetails.currentCredit') }
                        : &nbsp;&nbsp;
                        { this.props.client.total_minutes }
                        {' '}
                        { translate('global.minutes') }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="book-container">
                  {cta}
                </div>
                { this.errorModal() }
                { this.renderSignupModal() }
                { this.disclaimerModal() }
                { this.cancelModal() }
                { this.cancelledConfirmationModal() }
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  subscription: state.subscription,
  subscriptionView: state.subscriptionView,
});

SubscriptionDetails.propTypes = {
  client: PropTypes.object.isRequired,
  loadClient: PropTypes.func.isRequired,
  setSubscription: PropTypes.func.isRequired,
  setSubscriptionView: PropTypes.func.isRequired,
};

export default withLocalize(connect(mapStateToProps, { setSubscriptionView, setSubscription, loadClient })(SubscriptionDetails));
