/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
import React from 'react';
import axios from 'axios';
import { replace } from 'lodash';
import NavBar from '../../Shared/NavBar';
import Footer from '../../Shared/Footer';
import Modal from '../../Shared/Modal';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import PhoneInput from '../../Shared/PhoneInput';
import './Assets/phone.css';

export default class PhoneNumberOptin extends React.Component {
  constructor(props) {
    super(props);

    this.submitPhoneNumber = this.submitPhoneNumber.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.numberNotPresentModal = this.numberNotPresentModal.bind(this);
    this.submitBtn = this.submitBtn.bind(this);

    this.state = {
      width: 0,
      phoneNumber: '',
      error: '',
      notRecognizeNumber: false,
      submitited: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  submitPhoneNumber(e) {
    e.preventDefault();
    const that = this;

    if (this.state.submitited) {
      return false;
    }

    if (that.state.phoneNumber) {
      this.setState({ submitited: true });

      axios.put(
        `${API_ROOT}/clients/update_contact_permissions_by_mobile_number/`,
        {
          client: {
            mobile_number: that.state.phoneNumber,
            marketing_calls_texts: true,
            can_call: true,
            can_text: true,
          },
        },
        ACCEPT_LANGUAGE_HEADER,
      ).then((resp) => {
        const { result, errors } = resp.data;

        if (result) {
          alert(result);
        } else {
          that.setState({ notRecognizeNumber: true, error: errors[0].message });
        }
        that.setState({ submitited: false });
      }).catch(() => {
        alert('Oh no something went wrong.');
        that.setState({ submitited: false });
      });
    } else {
      alert('Please enter the phone number associated with your account');
      that.setState({ submitited: false });
    }
  }

  numberNotPresentModal() {
    return (
      <Modal title="Oops" isOpen={this.state.notRecognizeNumber} close={() => { this.setState({ notRecognizeNumber: false, error: '' }); }}>
        <p>{ this.state.error }</p>
      </Modal>
    );
  }

  handleInputChange({ isValidNumber, number, countryCode }) {
    this.setState({ phoneNumber: isValidNumber ? `+${replace(number, /\D/g, '')}` : number });
  }

  submitBtn() {
    if (this.state.submitited) {
      return (
        <button type="button" className="btn submit-input">
          <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate" />
          {' '}
          Submitting...
        </button>
      );
    }
    return (<input className="btn submit-input" type="submit" value="Submit" />);
  }

  renderDesktop() {
    return (
      <div>
        { this.numberNotPresentModal() }
        <NavBar />
        <div id="phone-num-optin">
          <div className="hero">
            <div className="col-md-6 optin">
              <div className="optin-copy">
                <h1>
                  Opt-in Notification
                </h1>
                <p className="body-copy">
                  By providing your phone number below and clicking “Submit,”
                  you agree that Soothe may use the telephone number you provided to call you
                  and send you text messages (including automatically dialed calls and texts)
                  regarding your appointments, account, and upcoming offers and promotions.
                  Your consent to promotional calls and messages is not a condition of any purchase.
                </p>
                <p className="prompt-copy">
                  Enter phone number below:
                </p>
              </div>
              <form onSubmit={this.submitPhoneNumber} autoComplete="off">
                <PhoneInput onPhoneNumberChange={this.handleInputChange} className="form-control phone-input" />

                { this.submitBtn() }
              </form>
            </div>
            <div className="col-md-6" />
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div id="phone-num-optin">
        { this.numberNotPresentModal() }
        <NavBar />
        <div className="hero-mobile" />
        <div className="optin-container">
          <h1>
            Opt-in Notification
          </h1>
          <p className="body-copy">
            By providing your phone number below and clicking “Submit,”
            you agree that Soothe may use the telephone number you provided to call
            you and send you text messages (including automatically dialed calls and texts)
            regarding your appointments, account, and upcoming offers and promotions.
            Your consent to promotional calls and messages is not a condition of any purchase.
          </p>
          <div className="form-section">
            <p className="prompt-copy">
              Enter phone number below:
            </p>
            <form onSubmit={this.submitPhoneNumber} autoComplete="off">
              <PhoneInput onPhoneNumberChange={this.handleInputChange} className="form-control phone-input" />

              { this.submitBtn() }
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  render() {
    if (this.state.width > 1000) {
      return this.renderDesktop();
    }
    return this.renderMobile();
  }
}
