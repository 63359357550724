/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import Edit from '@material-ui/icons/Edit';
import {
  get, isEmpty, map, round, isNumber,
} from 'lodash';
import { defaultErrorMessage } from '../../../constants';
import TextWithIcon from '../../Shared/TextWithIcon';
import CenteredGrids from '../../Shared/CenteredGrids';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import ErrorModal from '../../Shared/ErrorModal';
import { tipProvider } from '../Shared/helpers';
import { setAppointment } from '../../../Actions/AppointmentManagementAction';
import { decode } from '../Shared/encode';
import { seAppointmentTip } from '../../Shared/WebAnalytics';

class EditTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionOpen: get(props, 'openRelevantModal', false),
      showCustomTipInput: false,
      showErrorModal: false,
      error: null,
      showCustomTipModal: false,
      amount: null,
      maxAmount: null,
      maxAmountMessage: '',
      percentage_of_original: null,
      original_price: '',
      currency_symbol: '$',
      showMaxAmountMessage: false,
      promptIsOpen: false,
      promptMessage: '',
      disabled: false,
    };
    this.getTipOptions = this.getTipOptions.bind(this);
    this.handleTipRateClick = this.handleTipRateClick.bind(this);
    this.handleCustomTipClick = this.handleCustomTipClick.bind(this);
    this.customTipInput = this.customTipInput.bind(this);
    this.actionModal = this.actionModal.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.setInitialValues = this.setInitialValues.bind(this);
    this.maximumTipModal = this.maximumTipModal.bind(this);
    this.inputErrorMessage = this.inputErrorMessage.bind(this);
    this.onCustomTipChange = this.onCustomTipChange.bind(this);
    this.submitAmount = this.submitAmount.bind(this);
    this.triggerTipEvent = this.triggerTipEvent.bind(this);
  }

  componentDidMount() {
    this.setInitialValues();
  }

  setInitialValues() {
    const cp = this.props.cartProduct;
    let submitRecalculation = false;
    if (!isEmpty(cp)) {
      let {
        max_rate, percentage_of_original, original_price, amount, currency_symbol,
      } = cp.tip;
      let maxAmount; let
        maxAmountMessage;
      if (max_rate) {
        maxAmount = max_rate * Number(original_price);
        maxAmountMessage = `You can not tip more than ${max_rate * 100}% of your total. Please adjust.`;
      }
      if (!percentage_of_original && maxAmount && amount && amount > maxAmount) {
        amount = maxAmount;
        submitRecalculation = true;
        this.setState({ promptIsOpen: true, promptMessage: `The maximum tip amount is ${max_rate * 100}% of your total. The tip added for ${cp.client_name}'s Provider is now set to ${amount}${currency_symbol}` });
      }
      this.setState({
        amount,
        percentage_of_original,
        original_price,
        currency_symbol,
        maxAmount,
        maxAmountMessage,
      }, () => {
        if (submitRecalculation) {
          this.submitAmount(true);
        }
      });
    }
  }

  handleTipRateClick({ amount, rate }) {
    if (this.state.percentage_of_original === rate) {
      this.setState({
        amount: null,
        percentage_of_original: null,
        disabled: false,
        showCustomTipInput: false,
        showMaxAmountMessage: false,
      });
    } else {
      this.setState({
        amount,
        percentage_of_original: rate,
        disabled: false,
        showCustomTipInput: false,
        showMaxAmountMessage: false,
      });
    }
  }

  handleCustomTipClick() {
    const { amount, percentage_of_original } = this.state;
    if (isNumber(amount) && !percentage_of_original) {
      this.setState({
        amount: null, disabled: false, showCustomTipInput: false, showMaxAmountMessage: false,
      });
    } else {
      this.setState({
        showCustomTipInput: true,
        percentage_of_original: null,
        amount: 0,
        disabled: false,
        showMaxAmountMessage: false,
      });
    }
  }

  triggerTipEvent(cpId, amount) {
    const pro_id = get(this.props, 'cartProduct.pro.id', 'n/a');
    seAppointmentTip(cpId, pro_id, amount);
  }

  submitAmount(keepOpen = false) {
    const cartId = get(this.props, 'appointment.id', '');
    const cartProductId = get(this.props, 'cartProduct.id', '');
    const { amount, percentage_of_original } = this.state;

    let tip;
    if (percentage_of_original) {
      tip = {
        amount,
        percentage_of_original,
      };
    } else {
      tip = { amount: amount || 0 };
    }
    if (cartId && cartProductId) {
      tipProvider(cartId, cartProductId, tip, (response) => {
        this.props.setAppointment(decode(response.data.cart));
        this.triggerTipEvent(cartProductId, amount);
        if (keepOpen) {
          this.setState({ isActionOpen: false });
        }
      }, (err) => {
        this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    }
  }

  getTipOptions(tipObj) {
    const { can_tip } = tipObj;
    const { percentage_of_original, amount } = this.state;
    if (can_tip) {
      let arr;
      const {
        currency_symbol, rate_1, rate_2, rate_3, original_price,
      } = tipObj;
      arr = map([rate_1, rate_2, rate_3], (rate) => {
        const amount2 = round(rate * Number(original_price), 2);
        return {
          title: `${rate * 100}%`,
          subtitle: `${currency_symbol}${amount2}`,
          value: { amount: amount2, rate },
          action: this.handleTipRateClick,
          selected: percentage_of_original === rate,
        };
      });
      arr.push({
        title: 'Other',
        subtitle: isNumber(amount) && !percentage_of_original ? `${currency_symbol}${amount || 0}` : '',
        action: this.handleCustomTipClick,
        selected: isNumber(amount) && !percentage_of_original,
      });
      return arr;
    }
    return [];
  }

  onCustomTipChange(event) {
    event.preventDefault();
    const amount = Number(event.target.value); const
      maximumAmount = this.state.maxAmount;
    if (maximumAmount && amount > maximumAmount) {
      this.setState({ showMaxAmountMessage: true, disabled: true });
    } else {
      this.setState({ amount, showMaxAmountMessage: false, disabled: false });
    }
  }

  inputErrorMessage() {
    const { showMaxAmountMessage } = this.state;
    if (showMaxAmountMessage) {
      return (<div className="negative-msg">{this.state.maxAmountMessage || ''}</div>);
    }
  }

  customTipInput() {
    if (this.state.showCustomTipInput) {
      return (
        <div className="form-group">
          <label
            htmlFor="customTip-input"
            className="custom-tip-label poppins-medium"
          >
            Other
          </label>
          <div className="input-group tip-custom-input-group">
            <div className="input-group-addon tip-currency-addon">
              <span className="input-group-text">{get(this.state, 'currency_symbol', '$')}</span>
            </div>
            <input
              type="number"
              max={`${this.state.maxAmount}`}
              className="form-control tip-custom-input"
              id="customTip-input"
              value={this.state.amount || ''}
              placeholder={0}
              onChange={this.onCustomTipChange}
            />
          </div>
          {this.inputErrorMessage()}
        </div>
      );
    }
  }

  actionModal() {
    const { isActionOpen, disabled } = this.state;
    return (
      <LatestModalDesign
        isOpen={isActionOpen}
        disabled={disabled}
        title="Edit tip"
        subtitle="20% is the recommended tip in most locations. 100% of the tip goes to your Provider."
        hideCancel
        applyBtnCopy="Save"
        apply={this.submitAmount}
        close={() => this.setState({ isActionOpen: false })}
      >
        <div className="txt-center">
          <CenteredGrids
            grids={this.getTipOptions(this.props.cartProduct.tip)}
          />
        </div>
        {this.customTipInput()}
      </LatestModalDesign>
    );
  }

  maximumTipModal() {
    return (
      <LatestModalDesign
        title="Maximum tip exceeded"
        isOpen={this.state.promptIsOpen}
        close={() => { this.setState({ promptIsOpen: false, promptMessage: '' }); }}
        hideBackArrow
        hideCancel
        applyBtnCopy="Close"
      >
        <p>{this.state.promptMessage}</p>
      </LatestModalDesign>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    let additionalText = '';
    if (this.props.moreThanOnePro) {
      additionalText = ` of ${get(this.props, 'cartProduct.client_name', '')}'s Provider`;
    }
    return (
      <>
        <div
          className="clickable"
          onClick={() => this.setState({ isActionOpen: true })}
        >
          <TextWithIcon
            muiIcon={<Edit />}
            title={`Edit Tip${additionalText}`}
            displayArrow
          />
        </div>
        {this.actionModal()}
        {this.maximumTipModal()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, { setAppointment })(EditTip);
