/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import '../../Assets/Styles/index.css';
import { Edit } from '@material-ui/icons';
import { get, size } from 'lodash';
import TimesModal from './TimesModal';
import AnotherTimeModal from './AnotherTimeModal';
import ErrorModal from '../../../Shared/ErrorModal';
import TextWithIcon from '../../../Shared/TextWithIcon';
import { seRescheduleInitiate } from '../../../Shared/WebAnalytics';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorModal: false,
      error: null,
      timesModalOpen: false,
      calendarModalOpen: false,
    };
    this.errorModal = this.errorModal.bind(this);
    this.openRelevantActionModal = this.openRelevantActionModal.bind(this);
    this.timesModalUI = this.timesModalUI.bind(this);
    this.calendarModalUI = this.calendarModalUI.bind(this);
  }

  timesModalUI() {
    return this.state.timesModalOpen ? (
      <TimesModal
        show={this.state.timesModalOpen}
        hideModal={() => {
          this.setState({ timesModalOpen: false });
        }}
        switchModal={() => this.setState({ timesModalOpen: false, calendarModalOpen: true })}
      />
    ) : null;
  }

  calendarModalUI() {
    return this.state.calendarModalOpen ? (
      <AnotherTimeModal
        show={this.state.calendarModalOpen}
        hideModal={() => {
          this.setState({ calendarModalOpen: false });
        }}
      />
    ) : null;
  }

  openRelevantActionModal() {
    const backToBackAvailable = get(this.props, 'appointment.backToBack.enabled', false);
    const cpLength = size(get(this.props, 'appointment.cartProducts', []));
    const apt = get(this.props, 'appointment', {});
    if (cpLength === 1 || backToBackAvailable) {
      this.setState({ timesModalOpen: true });
    } else {
      this.setState({ calendarModalOpen: true });
    }
    seRescheduleInitiate({
      appointment_id: get(apt, 'cartProducts.0.id', ''),
      state: get(apt, 'info_fields.tracker.state', ''),
      service_category: get(apt, 'cartProducts.0.product.title', '').toLowerCase(),
      service_modality: get(apt, 'cartProducts.0.cart_product_main_option.title', '').toLowerCase(),
      client_id: get(this.props, 'client.user_id', ''),
      cart_product_count: cpLength,
    });
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <>
        <div
          className="clickable"
          onClick={this.openRelevantActionModal}
        >
          <TextWithIcon
            muiIcon={<Edit />}
            title="Reschedule appointment"
            displayBorder
            displayArrow
          />
        </div>
        {this.timesModalUI()}
        {this.calendarModalUI()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
});
export default connect(mapStateToProps, {})(Index);
