import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get, map } from 'lodash';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import appliedCheck from './Assets/Images/Applied.png';
import unappliedCheck from './Assets/Images/Unapplied.png';
import { applyPoints, removePoints } from '../Shared/helpers';
import ErrorModal from '../../Shared/ErrorModal';
import { defaultErrorMessage } from '../../../constants';
import { decode } from '../Shared/encode';
import { seBookingUnapplyPoints } from '../../Shared/WebAnalytics';

class ApplicablePoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            showErrorModal: false
        }
        this.listApplicablePoints = this.listApplicablePoints.bind(this);
        this.pointsChip = this.pointsChip.bind(this);
        this.applyActionHandler = this.applyActionHandler.bind(this);
        this.unapplyActionHandler = this.unapplyActionHandler.bind(this);
        this.relevantActionButton = this.relevantActionButton.bind(this);
        this.errorModal = this.errorModal.bind(this);
    }
    applyActionHandler(pointObj) {
        let cartId = get(this.props, "booking.cart.id", ""),
            { cart_product_id, option_id } = pointObj;
        applyPoints(cartId, {
            cart_product_id,
            option_id
        }, (response) => {
            this.props.assignToCart({ cart: decode(response.data.cart) });
        }, (err) => {
            this.setState({ showErrorModal: true, error: get(err, 'errors.0.message', defaultErrorMessage) });
        })
    }
    unapplyActionHandler(pointObj) {
        let cartId = get(this.props, "booking.cart.id", ""),
            { cart_product_id, option_id, title } = pointObj;
        removePoints(cartId, {
            cart_product_id,
            option_id
        }, (response) => {
            let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", null),
                subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", null);
            seBookingUnapplyPoints(subscription_tier_name, subscription_tier_id, title);
            this.props.assignToCart({ cart: decode(response.data.cart) });
        }, (err) => {
            this.setState({ showErrorModal: true, error: get(err, 'errors.0.message', defaultErrorMessage) });
        })
    }
    relevantActionButton(pts) {
        let { applied } = pts;
        if (applied) {
            return (<button className='btn apply-btn' onClick={() => this.unapplyActionHandler(pts)}>Applied</button>)
        }
        return (<button className='btn apply-btn' onClick={() => this.applyActionHandler(pts)}>Apply</button>)
    }
    pointsChip(pts) {
        let { title, points_cost, applied } = pts;
        return (<div className={`applicable-option ${applied ? 'applied' : ''}`}>
            <img className='ptrb-16' src={applied ? appliedCheck : unappliedCheck} />
            <div>
                <div className='medium-font color-black size-16-20 mb-4'>{title}</div>
                <div className='color-gray size-14-20'>{points_cost}</div>
            </div>
            <div>{this.relevantActionButton(pts)}
            </div>
        </div>)
    }
    listApplicablePoints() {
        const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);
        let points = get(this.props, "booking.cart.info_fields.soothe_pass.user_points", 0),
            subscription_points = alreadyAppliedToCart ? get(this.props, "booking.cart.info_fields.soothe_pass.subscription_points", 0) : 0,
            redeem_points_applicable = get(this.props, "booking.cart.info_fields.soothe_pass.redeem_points_applicable", [])
        return (<>
            <div className='mb-40'>
                <div className='second-title-style'>Use your points</div>
                <div className='size-18-28 color-gray mb-16'>You have {points + subscription_points} points</div>
                <Slider
                    slidesToShow={2}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    infinite={false}
                    responsive={[
                        {
                            breakpoint: 1300,
                            settings: {
                                slidesToShow: 2,
                                arrows: true
                            }
                        },
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 1.75,
                                arrows: false
                            }
                        },
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1.15,
                                arrows: false
                            }
                        }
                    ]
                    }
                >
                    {map(redeem_points_applicable, (pts) => {
                        return (
                            <div key={`pts-${pts.option_id}`}>
                                {this.pointsChip(pts)}
                            </div>
                        )
                    })}
                </Slider>
            </div>
            {this.errorModal()}
        </>)

    }
    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }
    render() {
        let isEmptySoothePassObj = isEmpty(get(this.props, "booking.cart.info_fields.soothe_pass", null)),
            isEmptyApplicablePoints = isEmpty(get(this.props, "booking.cart.info_fields.soothe_pass.redeem_points_applicable", []))
        if (isEmptySoothePassObj || isEmptyApplicablePoints) {
            return null;
        }
        return (this.listApplicablePoints());
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

ApplicablePoints.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(ApplicablePoints));