/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import AddressesReducer from './AddressesReducer';
import AddressReducer from './AddressReducer';
import BookingFlowReducer from './BookingFlowReducer';
import ClientReducer from './ClientReducer';
import CreditCardsReducer from './CreditCardsReducer';
import CreditCardReducer from './CreditCardReducer';
import GiftCardReducer from './GiftCardReducer';
import GiftCardCartReducer from './GiftCardCartReducer';
import PurchasedGiftCardAssortmentReducer from './PurchasedGiftCardAssortmentReducer';
import SubscriptionReducer from './SubscriptionReducer';
import SubscriptionViewReducer from './SubscriptionViewReducer';
import CartReducer from './CartReducer';
import ProductsReducer from './ProductsReducer';
import AppointmentReducer from './AppointmentReducer';
import UpcomingAppointmentReducer from './UpcomingAppointmentReducer';
import RebookOptionsReducer from './RebookOptionsReducer';
import { ChatsReducer, SingleChatReducer } from './InboxReducer';
import AnonymousCartReducer from './AnonymousCartReducer';
import ABTestReducer from './ABTestReducer';
import FieldsHolderReducer from './FieldsHolderReducer';

const rootReducer = combineReducers({
  localize: localizeReducer,
  addresses: AddressesReducer,
  address: AddressReducer,
  bookingFlow: BookingFlowReducer,
  client: ClientReducer,
  creditCards: CreditCardsReducer,
  creditCard: CreditCardReducer,
  giftCard: GiftCardReducer,
  giftCardCart: GiftCardCartReducer,
  purchasedGiftCardAssortment: PurchasedGiftCardAssortmentReducer,
  subscription: SubscriptionReducer,
  subscriptionView: SubscriptionViewReducer,
  booking: CartReducer,
  productsReducer: ProductsReducer,
  appointment: AppointmentReducer,
  upcomingAppointmentId: UpcomingAppointmentReducer,
  rebookOptions: RebookOptionsReducer,
  chats: ChatsReducer,
  currentChat: SingleChatReducer,
  anonymousCart: AnonymousCartReducer,
  abTest: ABTestReducer,
  fieldsHolder: FieldsHolderReducer,
});

export default rootReducer;
