/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({ // remove this and replace it with css classes
  root: {
    marginRight: '24px',
    borderRadius: '8px',
    // add not last ya3ni 5alliyon bl scss
  },
  media: {
    height: 180,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const {
    cardTitle, cardImage, onCardClick, relevantId,
  } = props;

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          if (onCardClick) {
            onCardClick();
          }
        }}
        {...(relevantId ? { id: relevantId } : {})}
      >
        {cardImage ? (
          <CardMedia
            className={classes.media}
            image={cardImage}
            title={cardTitle}
          />
        ) : null}
        <CardContent>
          <div>
            {props.children}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
