/* eslint-disable react/jsx-filename-extension */
/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function ConfirmationLottie() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('./Lottie_navy_checmark.json'),
    });
  }, []);

  return (
    <div className="centered-188" ref={container} />
  );
}
