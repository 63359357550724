import moment from 'moment';

export const capHour = 22;

export let momentCap = (dateTime) => {
  dateTime = moment(dateTime);

  let cap = dateTime;
  if (moment().isSame(dateTime, 'day')) {
    cap = moment().add(3, 'hours');
  }

  if (cap.hours() < 8) {
    cap = cap.hours(8);
  } else if (cap.hours() > capHour || (cap.hours() === capHour && cap.minutes() !== 0)) {
    cap = cap.add(1, 'day').hours(8).minutes(0).seconds(0);
  }

  if (cap.minute() % 15 !== 0) {
    cap = cap.minutes(Math.ceil(cap.minute() / 15) * 15);
  }

  return cap;
};

export const CART_DEFAULT = {
  id: null,
  status: '',
  time: {},
  date: {},
  address: {},
  billing: {},
  giftCode: '',
  backToBack: false,
  pickAProEnabled: false,
  cartProducts: [],
  subscriptions: {},
  terms: {},
  firstLoad: false
};

export const VIEWS = {
  SELECT_SERVICE: 'SELECT_SERVICE',
  SELECT_OPTION: 'SELECT_OPTION',
  SELECT_WHEN: 'SELECT_WHEN',
  SELECT_PRO: 'SELECT_PRO',
  SUBSCRIPTION_OPTIONS: 'SUBSCRIPTION_OPTIONS',
  PAYMENT: 'PAYMENT',
  FINISH_ADDRESS: 'FINISH_ADDRESS',
  CONFIRMATION: 'CONFIRMATION',
  SIGN_UP: 'SIGN_UP',
  CHECKOUT: 'CHECKOUT',
  REVIEW: 'REVIEW'
};