/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ErrorLottie from './ErrorLottie';
import LatestModalDesign from './LatestModalDesign';

export default class ErrorModalRedesign extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      isOpen, clickAction, title, subtitle, btnLabel,
    } = this.props;
    return (
      <LatestModalDesign
        isOpen={isOpen}
        hideBackArrow
        hideFooter
      >
        <div className="centered">
          <div className="mb-16"><ErrorLottie /></div>
          <div className="size-20-28 contentPrimary font-weight-bold mb-12">{title || 'Something went wrong'}</div>
          <div className="size-12-20 contentTertiary mb-16">{subtitle || 'Please try again. If the problem persists, contact your Provider or visit our Help Center.'}</div>
          <div>
            <button
              type="button"
              className="btn btn-accent-light"
              onClick={() => {
                if (clickAction) {
                  clickAction();
                }
              }}
            >
              {btnLabel || 'Try again'}
            </button>
          </div>
        </div>
      </LatestModalDesign>
    );
  }
}
