/* eslint-disable global-require */
export const FOOTER_LINKS = [
  {
    title: 'Clients',
    linksList: [
      {
        linkText: 'Services',
        linkAddress: 'https://www.soothe.com/services/',
      },
      {
        linkText: 'Membership',
        linkAddress: 'https://www.soothe.com/soothe-pass/',
      },
      {
        linkText: 'Personal Events',
        linkAddress: 'https://www.soothe.com/personal-events/',
      },
      {
        linkText: 'Shop',
        linkAddress: 'https://shop.soothe.com',
      },
      {
        linkText: 'Gift Cards',
        linkAddress: 'https://www.soothe.com/gift-cards/',
      },
    ],
  },
  {
    title: 'Providers',
    linksList: [
      {
        linkText: 'Become a Provider',
        linkAddress: 'https://www.soothe.com/provider/',
      },
    ],
  },
  {
    title: 'Partnerships',
    linksList: [
      {
        linkText: 'Corporate Wellness',
        linkAddress: 'https://www.soothe.com/corporate-wellness-and-partnerships/',
      },
      {
        linkText: 'Corporate Partnerships',
        linkAddress: 'https://www.soothe.com/corporate-wellness-and-partnerships/',
      },
      {
        linkText: 'Corporate Events',
        linkAddress: 'https://www.soothe.com/corporate-wellness-and-partnerships/',
      },
    ],
  },
  {
    title: 'Support',
    linksList: [
      {
        linkText: 'Trust & Safety',
        linkAddress: 'https://www.soothe.com/trust-and-safety/',
      },
      {
        linkText: 'Help Center',
        linkAddress: 'https://www.soothe.com/help-center/',
      },
    ],
  },
  {
    title: 'Soothe',
    linksList: [
      {
        linkText: 'About Soothe',
        linkAddress: 'https://www.soothe.com/about-soothe/',
      },
      {
        linkText: 'Press',
        linkAddress: 'https://www.soothe.com/press/',
      },
      {
        linkText: 'Articles',
        linkAddress: 'https://www.soothe.com/articles/',
      },
      {
        linkText: 'Careers',
        linkAddress: 'https://www.soothe.com/careers/',
      },
      {
        linkText: 'Contact',
        linkAddress: 'https://www.soothe.com/contact-us/',
      },
    ],
  },
];
export const SOCIAL_LINKS = [
  {
    imgSrc: require('../../../Assets/Images/twitter.png'),
    linkAddress: 'https://twitter.com/soothe/',
  },
  {
    imgSrc: require('../../../Assets/Images/facebook.png'),
    linkAddress: 'https://www.facebook.com/soothe/',
  },
  {
    imgSrc: require('../../../Assets/Images/linkedin.png'),
    linkAddress: 'https://www.linkedin.com/company/soothe/',
  },
  {
    imgSrc: require('../../../Assets/Images/instagram.png'),
    linkAddress: 'https://www.instagram.com/soothe/',
  },
];
