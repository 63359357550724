import React from 'react';
import { find } from 'lodash';
import placeholder from './Assets/Images/placeholder.png';
import './Assets/Styles/ProviderPickStep.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
export default function ProSpecialtiesList(props) {
    const classes = useStyles();
    let { listItems, sectionTitle, keyNaming, parentKeyNaming } = props;
    if (listItems.length > 0) {
        return (
            <div key={parentKeyNaming} className="main shadowed" >
                <div className='sub-title'><p>{sectionTitle}</p></div>
                {listItems.map((item, itemKey) => {
                    let tempImage = find(item.images, (e) => (e.kind === "card_display_image"));
                    return (<Grid container key={`${keyNaming}-${itemKey}`} spacing={3} className={classes.root}>
                        <Grid item xs={3}>
                            <img className='rounded-corners-img' src={tempImage ? tempImage.url : "" || placeholder} alt={item.title || ""} />
                        </Grid>
                        <Grid item xs={9}>

                            <div className='sub-title'>{item.title || ""}</div>

                            <div className='pro-card-line-3'> <p> {item.description || ""}</p></div>
                        </Grid>
                    </Grid>)
                })}
            </div>
        )
    }
    return null;
}
