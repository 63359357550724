/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { connect } from 'react-redux';
import Message from '@material-ui/icons/Message';
import { get, isEmpty, map } from 'lodash';
import TextWithIcon from '../../Shared/TextWithIcon';
import { HOW_TO_PREPARE_IMAGES } from '../Shared/constants';
import { ROUTES } from '../../../constants';
import LatestModalDesign from '../../Shared/LatestModalDesign';

class PrepareForAppt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isTermsModalOpen: false,
    };
    this.howToPrepareIcons = this.howToPrepareIcons.bind(this);
    this.howToPrepareList = this.howToPrepareList.bind(this);
    this.howToPrepare = this.howToPrepare.bind(this);
    this.termsModal = this.termsModal.bind(this);
  }

  howToPrepareIcons() {
    return (
      <div className="display-flex align-items-center justify-content-spaced mb-8">
        {map(HOW_TO_PREPARE_IMAGES, (imgVal, imgKey) => (
          <div className=" bg-light-blue br-radius-50 p-14 w-h-57" key={imgKey}>
            <img className="max-w-h-29" src={imgVal} alt={imgKey} />
          </div>
        ))}
      </div>
    );
  }

  howToPrepareList() {
    return (
      <ul>
        <li>Shower before your appointment</li>
        <li>Adhere to local face mask guidance</li>
        <li>Offer to use your own freshly-laundered sheets if you prefer</li>
        <li>
          Provide a kitchen sink, powder room,
          or bathroom for the provider to wash his/her hands
        </li>
        <li>
          Refrain from shaking hands until both you
          and the Provider have thoroughly washed your hands.
        </li>
        <li>
          Keep children and others 6 feet away from
          the equipment and the Provider's belongings to follow social distancing guidelines
        </li>
        <li>Keep your pets out of the room/away from the Provider's equipment.</li>
      </ul>
    );
  }

  howToPrepare() {
    const { expanded } = this.state;
    return (
      <div className="p-24-16-0">
        <div className="size-24-32 color-black medium-font mb-8">How to prepare:</div>
        {this.howToPrepareIcons()}
        <div className={`color-gray size-14-20 ${expanded ? '' : 'txt-ellipsis-3'}`}>
          When you book a session, please:
          {this.howToPrepareList()}
        </div>
        <div className="txt-end size-14-20 mb-16">
          <a
            className="read-more-link medium-font"
            onClick={() => {
              this.setState({ expanded: !expanded });
            }}
          >
            {expanded ? 'Hide' : 'Read more'}
          </a>
        </div>
      </div>
    );
  }

  termsModal() {
    const { isTermsModalOpen } = this.state;
    const client_terms = get(this.props, 'appointment.terms.client_terms', '');

    return (
      <LatestModalDesign
        isOpen={isTermsModalOpen}
        title={get(this.props, 'appointment.terms.client_title_terms', '')}
        hideFooter
        close={() => this.setState({ isTermsModalOpen: false })}
      >
        {isEmpty(client_terms)
          ? <div className="color-black size-28-36 medium-font">Nothing to show.</div>
          : client_terms.split('\n').map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
      </LatestModalDesign>
    );
  }

  render() {
    return (
      <div className="br-btm-gray-4">
        {this.howToPrepare()}
        <a
          className="underline-style-links clickable"
          onClick={() => { this.setState({ isTermsModalOpen: true }); }}
        >
          <TextWithIcon
            muiIcon={<Message />}
            title="COVID-19 Guidelines"
            displayArrow
          />
        </a>
        <a
          className="underline-style-links clickable"
          href={ROUTES.trustAndSafety}
        >
          <TextWithIcon
            muiIcon={<Message />}
            title="Trust & Safety"
            displayArrow
          />
        </a>
        {this.termsModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, {})(PrepareForAppt);
