/* eslint-disable prefer-destructuring */
import { merge } from 'lodash';

export const SOOTHE_PASS_ONE_TIME_POPUP = 'SOOTHE_PASS_ONE_TIME_POPUP';
export const AVAILABLE_THERAPISTS = 'AVAILABLE_THERAPISTS';
export const AVAILABLE_THERAPISTS_WITH_TIME_SLOTS = 'AVAILABLE_THERAPISTS_WITH_TIME_SLOTS';
export const RECIPIENT_OPTION = 'RECIPIENT_OPTION';
export const BOOKING_FLOW_REDESIGN = 'BOOKING_FLOW_REDESIGN';
export const BOOKING_FLOW = 'BOOKING_FLOW';
export const BOOKING_FLOW_COMPLETE = 'BOOKING_FLOW_COMPLETE';
export const WHEN = 'when';
export const SESSION = 'session';
export const WHEN_AND_WHERE = 'WhenAndWhere';
export const PAYMENTS = 'payments';
export const CONFIRMATION = 'confirmation';
export const WHERE = 'where';
export const SIGN_UP = 'sign up';
export const FLOW = [
  SESSION, WHEN_AND_WHERE, PAYMENTS, CONFIRMATION,
];

export function nextBookingFlow(bookingFlow) {
  const { mode } = bookingFlow;
  const nextMode = FLOW[FLOW.indexOf(mode) + 1];

  if (nextMode) {
    return {
      type: BOOKING_FLOW,
      payload: merge({}, bookingFlow, { mode: nextMode }),
    };
  }
  return {
    type: BOOKING_FLOW_COMPLETE,
    payload: merge({}, bookingFlow, { mode: nextMode }),
  };
}

export function prevBookingFlow(bookingFlow) {
  const { mode } = bookingFlow;
  const prevMode = FLOW[FLOW.indexOf(mode) - 1];

  if (prevMode) {
    return {
      type: BOOKING_FLOW,
      payload: merge({}, bookingFlow, { mode: prevMode }),
    };
  }
  // this is the first page
  return {
    type: BOOKING_FLOW,
    payload: bookingFlow,
  };
}

export function setBookingFlowIndex(bookingFlowIndex) {
  let mode = FLOW[bookingFlowIndex];

  if (!mode) {
    mode = FLOW[0];
  }

  return {
    type: BOOKING_FLOW,
    payload: { mode },
  };
}

export function setBookingFlowPage(name, productId) {
  return {
    type: BOOKING_FLOW,
    payload: { mode: name, productId },
  };
}

export function setBookingFlowStep(step) {
  return {
    type: BOOKING_FLOW_REDESIGN,
    payload: { step },
  };
}
export function setBookingFlowPreviousStep(previousStep) {
  return {
    type: BOOKING_FLOW_REDESIGN,
    payload: { previousStep },
  };
}
export function setBookingFlowComingFrom(comingFromStep) {
  return {
    type: BOOKING_FLOW_REDESIGN,
    payload: { comingFromStep },
  };
}
export function setRecipientOption(recipientOption) {
  return {
    type: RECIPIENT_OPTION,
    payload: { recipientOption },
  };
}
export function setAvailableTherapists(availableTherapists) {
  return {
    type: AVAILABLE_THERAPISTS,
    payload: { availableTherapists },
  };
}
export function setAlreadyShownSoothePassPopup(soothePassPopupAlreadyShown) {
  return {
    type: SOOTHE_PASS_ONE_TIME_POPUP,
    payload: { soothePassPopupAlreadyShown },
  };
}
export function setAvailableTherapistsWithTimeSlots(availableTherapistsWithTimeSlots) {
  return {
    type: AVAILABLE_THERAPISTS_WITH_TIME_SLOTS,
    payload: { availableTherapistsWithTimeSlots },
  };
}
