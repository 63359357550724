/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const HtmlTooltip = withStyles(() => ({
  arrow: {
    color: '#586B94',
  },
  tooltip: {
    backgroundColor: '#586B94',
    color: '#fff',
    fontSize: '12px',
    lineHeight: '20px',
    padding: '16px',
  },
}))(Tooltip);
export default function TriggersTooltips(props) {
  return (
    <HtmlTooltip arrow title={props.title}>
      {props.customIcon ? props.customIcon : <InfoOutlined className="contentTertiary" />}
    </HtmlTooltip>
  );
}
