/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '4px',
    position: 'sticky',
    top: '80px',
    '@media(max-width: 800px)': {
      top: '0px',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#EEEEEE',
    },
    '& .MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
      backgroundColor: '#FFC4B5',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F58266',
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
      backgroundImage: 'none',
    },
  },
});

export default function LinearBuffer(props) {
  const stp = props.stepNumber || 0;
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => { });
  progressRef.current = () => {
    if (progress > 100) {
      setProgress(0);
      setBuffer(10);
    } else {
      setProgress(stp);
      setBuffer(stp + 10);
    }
  };
  React.useEffect(() => {
    progressRef.current();
  }, [stp]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </div>
  );
}
