/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { head, sortBy, get } from 'lodash';
import { HOST_ROOT, API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../apiConfig';
import { loadClient, setUpcomingAppointmentId } from '../../Actions';
import { setAllUserIds } from '../Shared/WebAnalytics';
import { isGuestUser } from '../../constants';

class FetchClient extends React.Component {
  constructor(props) {
    super(props);
    this.getUpcomingAppointmentId = this.getUpcomingAppointmentId.bind(this);
  }

  getUpcomingAppointmentId() {
    axios.get(`${API_ROOT}/clients/appointments/upcoming`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result, carts, b2b_session_slots } = response.data;
      if (result) {
        const tempArray = sortBy([head(carts), head(b2b_session_slots)], 'date_time.datetime');
        const upcomingAppId = get(tempArray, '0.id', '');
        this.props.setUpcomingAppointmentId(upcomingAppId);
      } else {
        this.props.setUpcomingAppointmentId('');
      }
    }).catch((e) => {
      this.props.setUpcomingAppointmentId('');
      console.log(e);
    });
  }

  componentDidMount() {
    this.props.setUpcomingAppointmentId('');
    axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result, client } = response.data;
      if (result) {
        this.getUpcomingAppointmentId();
        if (sessionStorage.getItem('userSet')) { /* empty */ } else {
          setAllUserIds(client.user_id, client.email);
        }

        this.props.loadClient({ ...client, loggedIn: true });

        axios.get(`${API_ROOT}/clients/${client.user_id}/sift_science_params`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
          const { session_id } = response.data;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'user_session_info',
            user_id: client.user_id,
            session_id,
          });
        });
      } else {
        this.props.loadClient({ loggedIn: false });
      }
    }).catch((e) => {
      const guestUser = isGuestUser();
      const { response } = e;
      if (response && response.status === 401) {
        this.props.loadClient({ loggedIn: false });
      }

      if (!guestUser && response && response.status === 404 && response.data.errors && response.data.errors[0].code === 'client_not_found' && response.data.result === false) {
        window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
      }
    });
  }

  render() {
    return ('');
  }
}

FetchClient.propTypes = {
  loadClient: PropTypes.func.isRequired,
};

export default connect(null, { loadClient, setUpcomingAppointmentId })(FetchClient);
