/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { get, isEmpty, map } from 'lodash';
import { withLocalize } from 'react-localize-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/Remove';
import ExpandMore from '@material-ui/icons/Add';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { defaultErrorMessage } from '../../../constants';
import ErrorModal from '../ErrorModal';
import Modal from '../Modal';
import { seTermsAccepted, seViewedTermsModal } from '../WebAnalytics';
import { loadClient } from '../../../Actions';
import './ForcedTermsModal.css';

class ForcedTermsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      expanded: false,
      showErrorModal: false,
      error: null,
      successMessage: '',
      showTermsModal: true,
    };
    this.acceptTerms = this.acceptTerms.bind(this);
    this.displayContent = this.displayContent.bind(this);
    this.expandableSection = this.expandableSection.bind(this);
    this.displayFooter = this.displayFooter.bind(this);
    this.displayCheckbox = this.displayCheckbox.bind(this);
    this.successModal = this.successModal.bind(this);
  }

  componentDidMount() {
    const isEmptyTermsObject = isEmpty(get(this.props, 'client.terms_conditions', []));
    const slug = get(this.props, 'client.terms_conditions.0.slug', '');
    if (!isEmptyTermsObject && slug === 'soothe_pass') {
      const subscription_tier_name = get(this.props, 'client.soothe_pass.subscription_tier_name', '');
      const subscription_tier_id = get(this.props, 'client.soothe_pass.subscription_tier_id', '');
      seViewedTermsModal(subscription_tier_name, subscription_tier_id);
    }
  }

  acceptTerms() {
    const clientId = get(this.props, 'client.user_id', '');
    const terms_condition_id = get(this.props, 'client.terms_conditions.0.id', '');
    const slug = get(this.props, 'client.terms_conditions.0.slug', '');
    if (this.state.isChecked && clientId) {
      axios.post(
        `${API_ROOT}/clients/${clientId}/accept_terms_conditions`,
        { terms_condition_id },
        { withCredentials: true },
        ACCEPT_LANGUAGE_HEADER,
      ).then((response) => {
        const { result, client } = response.data;
        if (result) {
          if (slug === 'soothe_pass') {
            const subscription_tier_name = get(this.props, 'client.soothe_pass.subscription_tier_name', '');
            const subscription_tier_id = get(this.props, 'client.soothe_pass.subscription_tier_id', '');
            seTermsAccepted(subscription_tier_name, subscription_tier_id);
          }
          this.props.loadClient({ ...client, loggedIn: true });
          window.location.reload();
        }
      }).catch((err) => {
        this.setState({ showTermsModal: false, error: get(err, 'response.data.errors.0.message', defaultErrorMessage), showErrorModal: true });
      });
    }
  }

  expandableSection(full_description_title, full_description) {
    return (
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem
          button
          onClick={() => {
            this.setState({ expanded: !this.state.expanded });
          }}
        >
          <ListItemText primary={full_description_title} />
          {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          {full_description}
        </Collapse>
      </List>
    );
  }

  displayContent() {
    const {
      title, subtitle, short_description_list, full_description_title, full_description,
    } = get(this.props, 'client.terms_conditions.0', {});
    return (
      <div className="p-16 color-black mt-32 mb-32 sm-mb-190">
        <div className="size-20-28 medium-font mb-16">{title}</div>
        <div className="size-16-24">{subtitle}</div>
        <ul className="size-16-24 mb-32">
          {map(short_description_list, (val, it) => (
            <li key={`itm-${it}`}>{val.item}</li>
          ))}
        </ul>
        <div>{this.expandableSection(full_description_title, full_description)}</div>
      </div>
    );
  }

  displayCheckbox() {
    return (
      <div className="check-box-wrapper">
        <div className="check-box">
          <input
            type="checkbox"
            value={this.state.isChecked}
            className=""
            onChange={() => {
              this.setState({ isChecked: !this.state.isChecked });
            }}
          />
        </div>
        <div className="check-box-copy size-16-24 mb-16">
          I agree to the Terms &#38; Conditions
        </div>
      </div>
    );
  }

  displayFooter() {
    return (
      <div className="p-relative color-black medium-font">
        <div className="forced-fixed-cta-wrapper p-32">
          {this.displayCheckbox()}
          <div className="forced-cta-btn-wrapper">
            <button
              type="button"
              className="btn forced-cta-btn"
              disabled={!this.state.isChecked}
              onClick={() => {
                this.acceptTerms();
              }}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    );
  }

  successModal() {
    return (
      <Modal
        title="Thank you!"
        isOpen={Boolean(this.state.successMessage)}
        close={() => {
          this.setState({ successMessage: '' });
        }}
        showBtn
        btnCopy="Ok"
      >
        <p>{this.state.successMessage}</p>
      </Modal>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const isEmptyTermsObject = isEmpty(get(this.props, 'client.terms_conditions', []));
    if (!isEmptyTermsObject) {
      return (
        <>
          <div className="forced-modal-wrapper">
            <div className="forced-modal-content">
              {this.displayContent()}
              {this.displayFooter()}
            </div>
          </div>
          {this.errorModal()}
          {this.successModal()}
        </>
      );
    }
    return (
      <>
        {this.errorModal()}
        {this.successModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
});
export default withLocalize(connect(mapStateToProps, { loadClient })(ForcedTermsModal));
