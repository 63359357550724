/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function BlueCheckLottie() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'https://assets9.lottiefiles.com/packages/lf20_2jxby0ia.json',
    });
  }, []);

  return (
    <div className="blue-check-lottie" ref={container} />
  );
}
