/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { SET_GIFT_CARD } from '../Actions';
import { GIFT_CARD_DEFAULT } from '../Containers/GiftCards/Shared/constants';

export default function (state = GIFT_CARD_DEFAULT, action) {
  switch (action.type) {
    case SET_GIFT_CARD:
      return action.payload;
    default:
      return state;
  }
}
