import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { setCart, loadClient } from '../../../Actions';
import { ArrowBackIos, Error } from '@material-ui/icons';
import TextWithIcon from '../../Shared/TextWithIcon';
import { get, replace } from 'lodash';
import { getPhoneNumber, updateClientById } from '../../Shared/Helpers';
import ErrorModal from '../../Shared/ErrorModal';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import PhoneInput from '../../Shared/PhoneInput';
import checkMark from '../../../Assets/Images/Check_mark.png';
import CTAButton from '../../Shared/CTAButton';
import { sePhoneVerificationComplete, sePhoneVerificationInitiate } from '../../Shared/WebAnalytics';

class VerifyPhoneNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrorModal: false,
            error: "",
            showPhoneNumberInput: false,
            mobileNumber: '',
            isValidNumber: false,
            countryCode: '',
            marketingCallsTexts: false,
            canEmail: false,
            phoneAlreadyExists: false,
            unformattedMobileNumber: ""
        }
        this.errorModal = this.errorModal.bind(this);
        this.phoneNumberInput = this.phoneNumberInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkPhoneExistence = this.checkPhoneExistence.bind(this);
        this.showPersona = this.showPersona.bind(this);
        this.saveAndUpdate = this.saveAndUpdate.bind(this);
    }
    componentDidMount() {
        this.showPersona(true)
    }
    componentWillUnmount() {
        if (this.props.removePersona) {
            this.props.removePersona()
        }
    }
    saveAndUpdate() {
        let { mobileNumber, canEmail, marketingCallsTexts } = this.state;
        updateClientById(get(this.props, "client.user_id", ""), {
            mobile_number: mobileNumber,
            gdpr_marketing_permissions: canEmail,
            marketing_calls_texts: marketingCallsTexts
        }, (resp) => {
            this.props.loadClient({ ...get(resp, "data.client", {}), loggedIn: true });
            this.setState({ showPhoneNumberInput: false })
            if (get(this.props, "client.phone_verification_required", false) && get(this.props, "client.ab_tests.mobile_phone_verification", "") === "needs_verification") {
                this.showPersona()
            }
        })
    }
    showPersona(onlyToInitialize = false) {
        if (this.props.showPersonaInParent) {
            this.props.showPersonaInParent(
                () => sePhoneVerificationInitiate("booking_flow", "user_auth_variant"),
                () => sePhoneVerificationComplete("booking_flow", "user_auth_variant"),
                () => { },
                () => { },
                onlyToInitialize)
        }
    }
    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: '' }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }
    handleInputChange({ isValidNumber, number, countryCode }) {
        this.setState({ unformattedMobileNumber: number, isValidNumber, mobileNumber: isValidNumber ? `+${replace(number, /\D/g, "")}` : number, countryCode, phoneAlreadyExists: false }, this.checkPhoneExistence);
    }
    checkPhoneExistence() {
        let { mobileNumber, isValidNumber } = this.state;
        if (mobileNumber && isValidNumber) {
            getPhoneNumber(mobileNumber, (resp) => {
                if (get(resp, "data.result", false)) {
                    this.setState({ phoneAlreadyExists: `+${replace(get(this.props, "client.phone", ""), /\D/g, "")}` !== mobileNumber })
                } else {
                    this.setState({ phoneAlreadyExists: false })
                }
            }, (_err) => {
                this.setState({ phoneAlreadyExists: false })
            })
        }
    }
    phoneNumberInput() {
        let yourNumber = get(this.state, "unformattedMobileNumber", "your phone number");
        return <LatestModalDesign title="Confirm your number"
            subtitle={`Enter the code we send over SMS to ${yourNumber}.`}
            isOpen={this.state.showPhoneNumberInput} hideFooter={true}
            back={() => { this.setState({ showPhoneNumberInput: false }) }} fullWidthFooter={true}
            customIcon={<ArrowBackIos />}
        >
            <form>
                <div className={`form-group tel-input mb-24 mt-24`}>
                    <label className='contentPrimary size-16-20 mb-8'>Phone number</label>
                    <div className={`${this.state.isValidNumber ? "success" : ""}`}>
                        <PhoneInput showLable={false} onPhoneNumberChange={this.handleInputChange} className={`intl-tel-input generic-input-style size-16-24 contentPrimary ${this.state.isValidNumber ? 'valid' : 'invalid'} ${this.state.phoneAlreadyExists || (!this.state.isValidNumber && this.state.mobileNumber)? "error-state":""}`}
                            phone={this.state.mobileNumber}
                        // extraconfig={{
                        //     allowDropdown: false, autoPlaceholder: 'aggressive',
                        //     customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                        //         return `${get(selectedCountryData, "dialCode", "")} ${selectedCountryPlaceholder}`;
                        //     }
                        // }}
                        // customstyle={"hideFlags-force"}
                        />
                        <img className="input-checkmark input-checkmark-editted" src={checkMark} />
                    </div>
                    {this.state.isValidNumber || !this.state.mobileNumber ? null : <div className='small-red-message mt-8'>This number is invalid.</div>}
                    {this.state.phoneAlreadyExists ? <div className='small-red-message mt-8'> An account with this phone number already exists. Please contact support:<br/><a className='underline small-red-message' href="mailto:support@soothe.com?subject=Duplicate phone number verification">support@soothe.com</a></div> : null}
                    {this.state.countryCode === 'us' ?
                        <div className='form-group mt-24 mb-24 contentTertiary'>
                            <div className='row'>
                                <div className='col-xs-1 col-sm-2 centered'>
                                    <input type='checkbox' checked={this.state.marketingCallsTexts} className='' onChange={() => this.setState({ marketingCallsTexts: !this.state.marketingCallsTexts })} />
                                </div>
                                <div className='col-xs-11 col-sm-10 size-10-15 contentTertiary'>
                                    I authorize Soothe to deliver marketing messages using automated SMS messaging. Message and data rates may apply. Message frequency varies.
                                </div>
                            </div>
                        </div> : null}
                    {this.state.countryCode !== 'us' && this.state.countryCode !== '' ?
                        <div className='form-group mt-24 mb-24 contentTertiary'>
                            <div className='row'>
                                <div className='col-xs-1 col-sm-1'>
                                    <input type='checkbox' checked={this.state.canEmail} className='' onChange={() => this.setState({ canEmail: !this.state.canEmail })} />
                                </div>
                                <div className='col-xs-10 col-sm-9 optin-text'>
                                    I would like to receive promotional emails from Soothe
                                </div>
                            </div>
                        </div> : null}
                </div>
            </form>
            {!this.state.mobileNumber || !this.state.isValidNumber || this.state.phoneAlreadyExists ? null : <CTAButton text="Continue" additionalClass="w-100-perc" additionalWrapperClass="p-0-imp br-top-none-imp sm-relative-imp "
                action={this.saveAndUpdate} />
            }
        </LatestModalDesign>
    }

    render() {
        let phoneVerificationRequired = get(this.props, "client.phone_verification_required", false) && get(this.props, "client.ab_tests.mobile_phone_verification", "") === "needs_verification",
            phoneNumber = get(this.props, "client.phone", "");
        if (phoneVerificationRequired) {
            return <>
                <div className='size-24-32 contentPrimary font-weight-bold mb-8'>Needed to Book</div>
                <div className='clickable'
                    onClick={() => {
                        this.setState({ unformattedMobileNumber: phoneNumber, showPhoneNumberInput: true, mobileNumber: phoneNumber ? `+${replace(phoneNumber, /\D/g, "")}` : "", isValidNumber: Boolean(phoneNumber) }, this.checkPhoneExistence)
                    }}>
                    <TextWithIcon
                        muiIcon={<Error className='systemRed w-h-20' />}
                        title="Verify Phone Number"
                        displayArrow={true}
                        displayBorder={true}
                    />
                </div>
                <div id="persona-custom-parent"></div>
                {this.errorModal()}
                {this.phoneNumberInput()}
            </>
        }
        return null;
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
})

export default withLocalize(connect(mapStateToProps, { setCart, loadClient })(VerifyPhoneNumber));