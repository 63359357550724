/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import Block from '@material-ui/icons/Block';
import {
  get, isEmpty, map, size, times,
} from 'lodash';
import { Close, Star, StarBorder } from '@material-ui/icons';
import { defaultErrorMessage, ROUTES } from '../../../constants';
import TextWithIcon from '../../Shared/TextWithIcon';
import { appReview, cancelAppointment, getCancellationReasons } from '../Shared/helpers';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import RadioOptions from '../../Shared/RadioOptions';
import ErrorModal from '../../Shared/ErrorModal';
import { seAppointmentCancel, seAppointmentReview } from '../../Shared/WebAnalytics';
import RescheduleAppt from './RescheduleAppt';
import ProviderOfAppt from './ProviderOfAppt';
import { hoursCountTillShiftStart } from '../Shared/constants';

class CancelAppt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancellationReasons: [],
      isPreActionOpen: false,
      isActionOpen: false,
      disabled: true,
      client_cancellation_reason_id: null,
      cancellation_index: null,
      showErrorModal: false,
      error: null,
      showSuccessModal: false,
      formDisabled: true,
      body: '',
      ratingValue: null,
    };
    this.preActionModal = this.preActionModal.bind(this);
    this.actionModal = this.actionModal.bind(this);
    this.submitCancellation = this.submitCancellation.bind(this);
    this.updateReasonsArray = this.updateReasonsArray.bind(this);
    this.successModal = this.successModal.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.starRating = this.starRating.bind(this);
    this.feeMessage = this.feeMessage.bind(this);
    this.feedbackInput = this.feedbackInput.bind(this);
    this.triggerCancelEvent = this.triggerCancelEvent.bind(this);
    this.otherOptions = this.otherOptions.bind(this);
  }

  componentDidMount() {
    getCancellationReasons((response) => {
      const client_cancellation_reasons = get(response, 'data.client_cancellation_reasons', []);
      this.setState({ cancellationReasons: map(client_cancellation_reasons, (reas) => ({ label: reas.reason, value: `${reas.id}` })) });
    });
  }

  updateReasonsArray(selectedId) {
    let { cancellationReasons } = this.state;
    let cancellation_index;
    cancellationReasons = map(cancellationReasons, (reas, index) => {
      if (reas.value === selectedId) {
        cancellation_index = index;
        return { ...reas, checked: true };
      }
      return { ...reas, checked: false };
    });
    this.setState({
      cancellationReasons,
      cancellation_index,
      disabled: false,
      client_cancellation_reason_id: selectedId,
    });
  }

  triggerCancelEvent() {
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    map(cartProducts, (cp) => {
      const appointment_id = get(cp, 'id', '');
      const pro_id = get(cp, 'pro.id', 'n/a');
      if (appointment_id) {
        seAppointmentCancel(appointment_id, pro_id, this.props.isComingFromInbox ? 'inbox_message' : 'appointment_details', "jul_2024_booking_enhancements", get(this.props, "client.ab_tests.jul_2024_booking_enhancements", ""));
      }
    });
  }

  submitCancellation() {
    const cartId = get(this.props, 'appointment.id', '');
    const quoted_fee = get(this.props, 'appointment.fees.cancellation.amount', 0);
    const { client_cancellation_reason_id, cancellation_index } = this.state;
    if (client_cancellation_reason_id) {
      cancelAppointment(cartId, {
        client_cancellation_reason_id,
        cancellation_index,
        quoted_fee,
      }, () => {
        this.triggerCancelEvent();
        this.setState({ showSuccessModal: true, isActionOpen: false });
      }, (err) => {
        this.setState({ isActionOpen: false, showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    }
  }

  otherOptions() {
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    const currentStatus = get(this.props, 'appointment.tracking.status', 'searching');
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    // const rebook = get(this.props, 'appointment.rebook', false);
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const moreThanOne = Boolean(size(requestedProviders) > 1);

    if (!isEmpty(requestedProviders) && moreThanOne) {
      // return !rebook && this.startTimeCheck() && this.gaCheck() && !isEmpty(this.state.recommendedProviders) && !get(this.props, 'appointment.request_pros_limit_reached', false);
      // todo reviit this
      return (<>
        <br />
        Increase your chance of getting a faster response with a few options below:
        <div className="mt-24">
          <ProviderOfAppt
            openRelevantModal={this.props.openRelevantModal && size(cartProducts) === 1 && (genericStatus === 'complete' || currentStatus === 'arrived')}
            xsDisplay={get(this.props, 'isComingFromInbox', false)}
            location={this.props.location}
            prosPopupOpen={this.props.prosPopupOpen}
            fromCancel={true}
          />
          <RescheduleAppt
            isComingFromInbox={get(this.props, 'isComingFromInbox', false)}
            rebook_flag={this.props.rebook_flag}
            isOpenModal={this.props.isOpenRescheduleModal}
            hideRescheduleModal={this.props.hideRescheduleModal}
            fromCancel={true}
          />
        </div>
      </>
      );
    }
  }

  feeMessage() {
    const feeAmount = get(this.props, 'appointment.fees.cancellation.amount', 0);
    const apptTime = get(this.props, 'appointment.time.utc');
    if (feeAmount) {
      return <div><span className="font-weight-bold">You will be charged {get(this.props, 'appointment.currencySymbol', '$')}{feeAmount}</span> for cancelling or rescheduling {hoursCountTillShiftStart(apptTime)}</div>;
    }
    return <div>
      You will NOT be charged for cancelling or rescheduling.
      {this.otherOptions()}
    </div>;
  }

  preActionModal() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const moreThanOne = Boolean(size(requestedProviders) > 1);
    const { isPreActionOpen } = this.state;
    const feeAmount = get(this.props, 'appointment.fees.cancellation.amount', 0);
    return (
      <LatestModalDesign
        isOpen={isPreActionOpen}
        popupId="cancelPopup"
        title={<div className="ptb-16">Are you sure?</div>}
        subtitle={<>
          <div>Are you sure you want to cancel this request?</div>
          <br />
          <div>{this.feeMessage()}</div>
        </>}
        cancelBtnCopy={!feeAmount && (!isEmpty(requestedProviders) && moreThanOne) ? "Go Back" : "Reschedule Appointment"}
        applyBtnCopy="Cancel Appointment"
        apply={() => this.setState({ isPreActionOpen: false, isActionOpen: true })}
        relevantApplyId="confirmCancelAppointmentButton"
        close={() => this.setState({ isPreActionOpen: false }, () => {
          if (feeAmount || isEmpty(requestedProviders) || !moreThanOne) {
            this.props.showRescheduleModal();
          }
        })}
        back={() => this.setState({ isPreActionOpen: false })}
        customIcon={<Close />}
        fullWidthFooter={true}
        footerExtraClass="br-top-none-imp"
        contentExtraClass="modal-content-exception"
        noSmMarginB
      />
    );
  }

  actionModal() {
    const { isActionOpen, disabled, cancellationReasons } = this.state;
    return (
      <LatestModalDesign
        isOpen={isActionOpen}
        disabled={disabled}
        title="Cancel appointment"
        subtitle="Please select a reason"
        applyBtnCopy="Cancel appointment"
        apply={this.submitCancellation}
        relevantApplyId="reasonToCancelSubmitButton"
        close={() => this.setState({ isActionOpen: false })}
      >
        <div className="p-16-24">
          <RadioOptions
            name="cancellation-reasons"
            optionsArray={cancellationReasons}
            onChange={this.updateReasonsArray}
          />
        </div>
      </LatestModalDesign>
    );
  }

  closeSuccessModal() {
    this.setState({ showSuccessModal: false }, () => {
      window.location = ROUTES.booking;
    });
  }

  starRating() {
    const { body, ratingValue } = this.state;
    return (
      <div className="display-flex align-items-center justify-content-spaced max-width-300 mb-8" id="appReviewStarRating">
        {times(5, (it) => (
          <div
            key={`star-${it}`}
            className=""
            id={`appReviewStarRating-${it + 1}`}
            onClick={() => {
              const tempRatinValue = it + 1;
              this.setState({
                ratingValue: tempRatinValue,
                formDisabled: !(body && tempRatinValue),
              });
            }}
          >
            {it + 1 <= ratingValue
              ? <Star style={{ color: '#586B94', fontSize: '30px' }} />
              : <StarBorder style={{ color: '#757575', fontSize: '30px' }} />}
          </div>
        ))}
      </div>
    );
  }

  feedbackInput() {
    const { ratingValue } = this.state;
    return (
      <>
        <div className="size-16-20 medium-font color-black mb-8">Feedback</div>
        <textarea
          className="form-control gray-textarea mb-8"
          rows={5}
          maxLength={1200}
          id="feedbackTextField"
          onChange={(event) => {
            const textVal = event.target.value || '';
            this.setState({ body: textVal, formDisabled: !(textVal && ratingValue) });
          }}
        />
        <div className="color-light-gray size-14-20">
          {size(this.state.body || '')}
          {' '}
          / 1200 characters
        </div>
      </>
    );
  }

  successModal() {
    const { body, formDisabled, ratingValue } = this.state;
    return (
      <LatestModalDesign
        isOpen={this.state.showSuccessModal}
        disabled={formDisabled}
        title="Appointment Cancelled"
        subtitle="You have successfully cancelled your appointment. Would you like share any feedback with our team?"
        hideCancel
        back={this.closeSuccessModal}
        applyBtnCopy="Done"
        relevantApplyId="appReviewButton"
        apply={() => {
          appReview({ body, value: ratingValue }, () => {
            seAppointmentReview(get(this.props, 'appointment.id', ''), get(this.props, 'appointment.cartProducts.0.pro.id', ''), ratingValue);
            // TODO pro_id should be array to support 2 pros cancellation review
            // what if there's no pro in cartProducts.0
            this.closeSuccessModal();
          }, (err) => {
            this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
          });
        }}
      >
        {this.starRating()}
        {this.feedbackInput()}
      </LatestModalDesign>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <>
        <div
          className="clickable"
          id="cancelAppointmentButton"
          onClick={() => { this.setState({ isPreActionOpen: true }); }}
        >
          <TextWithIcon
            muiIcon={<Block />}
            title="Cancel appointment"
            details={(
              <a
                className="contentSecondary underline"
                href="https://www.soothe.com/cancellation"
                onClick={(e) => e && e.stopPropagation()}
              >
                See policy
              </a>
            )}
            displayArrow
            displayBorder={this.props.displayBorder}
          />
        </div>
        {this.preActionModal()}
        {this.actionModal()}
        {this.successModal()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, {})(CancelAppt);
