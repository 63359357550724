export const SET_CHATS = 'SET_CHATS';
export const SET_CHAT = 'SET_CHAT';

export function setChats(chats) {
  return {
    type: SET_CHATS,
    payload: chats,
  };
}

export function fetchChat(chat) {
  return {
    type: SET_CHAT,
    payload: chat,
  };
}
