/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { get } from 'lodash';
import appleLogo from '../../Assets/Images/apple_logo_white.png';
import { getEmail } from './Helpers';

export default class AppleLogin extends React.Component {
  constructor(props) {
    super(props);

    this.signIn = this.signIn.bind(this);
    this.checkForAccountExistence = this.checkForAccountExistence.bind(this);
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    document.body.appendChild(script);
  }

  checkForAccountExistence(data1) {
    const { backToSelectServiceCallback } = this.props;
    // TODO fseli li abl l signin check 3an l sigin events+++ wen l next
    //  (ma b2dr a3ml shi, all controlled by uri redirection)
    const emailApple = get(data1, 'user.email', '');
    const idTokenApple = get(data1, 'authorization.id_token', '');
    if (emailApple) {
      getEmail(emailApple, (resp) => {
        if (get(resp, 'data.admin', false) && this.props.showError) {
          this.props.showError("Can't login with this user type");
        } else if (!get(resp, 'data.result', false)) {
          if (this.props.sendPixelData) {
            this.props.sendPixelData(idTokenApple, 'AppleID', emailApple);
          }
          if (backToSelectServiceCallback) {
            backToSelectServiceCallback(idTokenApple, emailApple);
          }
        } else {
          if (this.props.setEmailExists) {
            this.props.setEmailExists();
          }
          if (backToSelectServiceCallback) {
            backToSelectServiceCallback(idTokenApple);
          }
        }
      }, () => {
      });
    }
  }

  async signIn() {
    const redirectURI = this.props.redirectURI ? this.props.redirectURI : `${process.env.REACT_APP_API_URL}/apple/auth_redirect_booking`;
    const params = {
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      redirectURI,
      scope: 'name email',
    };
    try {
      window.AppleID.auth.init(params);
      const data = await window.AppleID.auth.signIn();
      this.checkForAccountExistence(data);
    } catch (error) {
      console.log({ error });
    }
  }

  render() {
    let btn;
    const copy = this.props.signIn ? 'Sign in with Apple' : 'Sign up with Apple';
    const {
      inPopup, hideCopy, customLogo, wrapperClassName, customClassName, iconClassName, customCopy,
    } = this.props;
    if (inPopup) {
      btn = (
        <button type="button" className="transparent-button w-100 pl-0" onClick={this.signIn}>
          <div className={`form-group apple-sign-in-container ${wrapperClassName || ''}`}>
            <div className={`apple-sign-in ${customClassName || ''}`}>
              <img className={`apple-logo ${iconClassName || ''}`} src={customLogo || appleLogo} alt="apple logo" />
              {' '}
              {hideCopy ? null : customCopy || copy}
            </div>
          </div>
        </button>
      );
    } else {
      btn = (
        <div className={`form-group apple-sign-in-container ${wrapperClassName || ''}`}>
          <button type="button" className={`apple-sign-in ${customClassName || ''}`} onClick={this.signIn}>
            <img className={`apple-logo ${iconClassName || ''}`} src={customLogo || appleLogo} alt="apple logo" />
            {' '}
            {hideCopy ? null : copy}
          </button>
        </div>
      );
    }

    return btn;
  }
}
