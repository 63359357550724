
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import TextWithIcon from '../../Shared/TextWithIcon';
import MarkerIcon from './Assets/Images/Marker_icon.png';
import { isEmpty, get } from 'lodash';

class AppointmentAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const address = get(this.props, "booking.cart.address", null),
            isCompleteAddress = get(address, "complete", false);
        if (!isEmpty(address)) {
            return (<TextWithIcon
                icon={MarkerIcon}
                title={"Address"}
                details={(<div className='sm-mb-12'>{address.address_line_1}<br />{address.city}, {address.state} {address.zip_code}</div>)}
                displayBorder={true}
                extraClass="sm-display-block"
            >
                {isCompleteAddress ? null : <button className='btn infoButton' onClick={() => {
                    this.props.showFinishAddress()
                }}>More details needed</button>}
            </TextWithIcon>
            )
        }
        return null;
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

AppointmentAddress.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(AppointmentAddress));
