/* eslint-disable default-param-last */
/* eslint-disable func-names */
import { SET_UPCOMING_APPOINTMENT_ID } from '../Actions';

export default function (state = '', action) {
  switch (action.type) {
    case SET_UPCOMING_APPOINTMENT_ID:
      return action.payload;
    default:
      return state;
  }
}
