import moment from 'moment';
import { map, mapKeys, merge, omit, snakeCase} from 'lodash';

export const PHYSICAL = 'physical';
export const EGIFT = 'egift';
export const PRINT = 'print';
export const EMAIL = 'email';

export const BIRTHDAY = 'birthday';
export const ANNIVERSARY = 'anniversary';
export const CONGRATULATIONS = 'congratulations';
export const HOLIDAY = 'holiday';
export const NONE = 'none';
export const GIFT_CARD_OCCASIONS = [BIRTHDAY, ANNIVERSARY, CONGRATULATIONS, HOLIDAY, NONE];

export const GIFT_CARD_LANDING = 'GIFT_CARD_LANDING';
export const GIFT_CARD_STEP1 = 'GIFT_CARD_STEP1';
export const GIFT_CARD_STEP2 = 'GIFT_CARD_STEP2';
export const GIFT_CARD_STEP3 = 'GIFT_CARD_STEP3';

export const GIFT_CARD_FLOW = [
  GIFT_CARD_LANDING, GIFT_CARD_STEP1, GIFT_CARD_STEP2, GIFT_CARD_STEP3
];

// checkout
export const CHECKOUT = 'CHECKOUT';
export const DELIVERY = 'delivery';
export const PAYMENT = 'payment';
export const SENDER_INFO = 'senerInfo';
export const STANDARD = 'standard';
export const EXPRESS = 'express';
export const REVIEW = 'review';

export const SHIPPING_RATES = {
  standard: 0,
  express: 5
};

export const SENDER_INFO_REQUIRED_PARAMS = ['firstName', 'lastName', 'email', 'phone'];
export const DELIVERY_REQUIRED_PARAMS = ['firstName', 'lastName', 'addressLine1', 'city', 'state', 'zipcode', 'type'];

export const GIFT_CARD_DEFAULT = {
  page: GIFT_CARD_STEP1,
  amount: 0,
  currency: '',
  currencySymbol: '',
  locale: null,
  minutes: null,
  quantity: 1,
  customPrice:  false,
  deliveryMethod: undefined,
  deliveryDate: moment(new Date()).format('MM/DD/YYYY'),
  message: '',
  recipientName: '',
  recipientEmail: '',
  recipientEmailIsValid: '',
  name: '',
  email: '',
  emailIsValid: false,
  ocassion: NONE
};

export const GIFT_CARD_CART_DEFAULT = {
  backFromCheckout: false,
  show: false,
  items: [],
  sales: [],
  cityId: null,
  cityName: null,
  currency: null,
  country: null,
  showCitiesModal: false,
  checkout: {
    id: null,
    page: SENDER_INFO,
    senderInfo: {
      firstName: '',
      lastName: '',
      email: '',
      emailIsValid: false,
      phone: '',
      validPhone: false
    },
    delivery: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipcode: '',
      type: STANDARD,
      notes: ''
    },
    payment: {
      stripeCardId: '',
      last4: '',
      brand: '',
      expMonth: '',
      expYear: '',
      name: ''
    }
  }
};

const getCookieValue = (name) => {
    var b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : null;
}

export function encode(data, saleMeet, currency) {
  let items = map(data.items, (item) => {
    let { deliveryDate } = item

    if (deliveryDate) {
      deliveryDate = deliveryDate.split('/')
      deliveryDate = `${deliveryDate[2]}-${deliveryDate[0]}-${deliveryDate[1]}`
    }

    return merge(mapKeys(item, (value, key) => {
      return snakeCase(key)
    }), {
      delivery_date: deliveryDate
    });
  });
  let delivery = mapKeys(data.checkout.delivery, (value, key) => snakeCase(key));
  let senderInfo = omit(mapKeys(data.checkout.senderInfo, (value, key) => snakeCase(key)), ['email_is_valid']);

  senderInfo = merge({ name: `${senderInfo['first_name']} ${senderInfo['last_name']}` }, omit(senderInfo, ['first_name', 'last_name', 'valid_phone']));
  delivery = merge({ name: `${delivery['first_name']} ${delivery['last_name']}` }, omit(delivery, ['first_name', 'last_name']));

  let formatted_json = {
    currency,
    items,
    delivery,
    payment: {
      card_token: data.checkout.payment.stripeCardId,
      last_4: data.checkout.payment.last4
    },
    sender_info: senderInfo,
    sale_meet: saleMeet
  };

  let shareasaleCookieValue = getCookieValue('shareasaleSSCID')

  if(shareasaleCookieValue) {
    merge(formatted_json, {shareasale_click_id: shareasaleCookieValue})
  }
  
  return formatted_json
}

export function calcSubtotal(items, add=null) {
  let subtotal = 0.0;
  items.forEach((item) => {
    let total = (item.deliveryMethod === PHYSICAL ? (parseFloat(item.amount) * item.quantity) : parseFloat(item.amount));
    subtotal += total;
  });

  if (add && !isNaN(parseFloat(add))) {
    subtotal += parseFloat(add);
  }

  return subtotal;
}