/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import _, { get, find, isEmpty, size } from 'lodash';
import Linkify from 'react-linkify';
import Slide from '@material-ui/core/Slide';
import {
  setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep,
  setProducts, setUpcomingAppointmentId, setField,
} from '../../../Actions';
import {
  defaultErrorMessage, pleaseAddACreditCardError,
  ROUTES, blankHref, HotJar, termsAndConditions, isGuestUser, hasUuid,
} from '../../../constants';
import { STEPS } from '../Shared/constants';
import PaymentMethod from './PaymentMethod';
import GiftCards from './GiftCards';
import PromoCodes from './PromoCodes';
import OrderSummary from './OrderSummary';
import TipProvider from './TipProvider';
import ApplicablePoints from './ApplicablePoints';
import '../../BookingFlow/Payment/Assets/Styles/Summary.css';
import './Assets/Styles/index.css';
import {
  seBookingComplete, sendPixelData, seMembershipComplete, bookingConversionImpactRadius,
} from '../../Shared/WebAnalytics';
import ErrorModal from '../../Shared/ErrorModal';
import Modal from '../../Shared/Modal';
import { decode } from '../Shared/encode';
import { acceptTerms, checkoutBooking, getCartDetails } from '../Shared/helpers';
import CTAButton from '../../Shared/CTAButton';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import ProListDrawer from '../ProviderPickStepMarketplace/ProListDrawer';
import { getRecommendedPros } from '../../Shared/Helpers';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      billing: { top_billing_line_items: '', bottom_billing_line_items: '', subtotal: '' },
      isChecked: false,
      showClientTermsModal: false,
      showErrorModal: false,
      view: 'payment',
      width: window.innerWidth,
      height: 300,
      submitted: false,
      drawerIsOpen: false,
      recommendedProviders: [],
    };

    this.book = this.book.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.clientTermsModal = this.clientTermsModal.bind(this);
    this.acceptTermsAndConditions = this.acceptTermsAndConditions.bind(this);
    this.checkForTermsAndBook = this.checkForTermsAndBook.bind(this);
    this.updateSummaryBillingData = this.updateSummaryBillingData.bind(this);
    this.hasTippableProducts = this.hasTippableProducts.bind(this);
    this.checkForCreditCard = this.checkForCreditCard.bind(this);
    this.sendPixelDataAboutAppointment = this.sendPixelDataAboutAppointment.bind(this);
    this.registerBookingCompleteAnalytic = this.registerBookingCompleteAnalytic.bind(this);
    this.handleCheckoutResponse = this.handleCheckoutResponse.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.getCartCallbackFunc = this.getCartCallbackFunc.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.soothePassFinePrint = this.soothePassFinePrint.bind(this);
    this.registerMembershipCompleteAnalytic = this.registerMembershipCompleteAnalytic.bind(this);
    this.getRelevantCTACopy = this.getRelevantCTACopy.bind(this);
    this.resetBookingAndNext = this.resetBookingAndNext.bind(this);
    this.fetchRecommendedPros = this.fetchRecommendedPros.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '')) {
      this.props.toggleNavBarStyle(false);
      this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
    } else {
      this.props.toggleNavBarStyle(true);
      this.props.changeBackground('none');
    }
    this.updateSummaryBillingData();
    const previousStep = find(STEPS, (step) => (step.id === 'REVIEW'));
    this.props.setBookingFlowPreviousStep(previousStep);
  }

  componentDidUpdate() {
    let anchorTags;

    if (document.getElementById('client-terms') !== null) {
      anchorTags = document.querySelectorAll('#client-terms a');

      for (const a of anchorTags) {
        a.setAttribute('target', '_blank');
      }
    }
  }

  resetBookingAndNext() {
    // reset booking
    this.props.setProducts({ products: [] });
    this.props.setCart({
      cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {},
    });
    setTimeout(() => { window.location = '/appointment_management/'; }, 3500);
  }

  fetchRecommendedPros() {
    // pap marketplace not allowed for couples
    const cart = get(this.props, 'booking.cart', null);
    const cartId = get(cart, 'id', '');
    const cartProducts = get(cart, 'cartProducts', []);
    getRecommendedPros(cartId, get(cartProducts, '0.id', ''), (resp) => {
      const recommendedProviders = get(resp, 'data.therapists', []);
      if (isEmpty(recommendedProviders)) {
        this.resetBookingAndNext();
      } else {
        this.setState({ drawerIsOpen: true, recommendedProviders });
      }
    }, (err) => {
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  goToNextStep() {
    if (isGuestUser()) {
      const nextStep = find(STEPS, (step) => (step.id === 'CONFIRMATION'));
      this.props.setBookingFlowStepThroughParent(nextStep);
    } else {
      const marketplaceEnabled = get(this.props, 'booking.cart.info_fields.marketplace_enabled', false);
      const isIC = get(this.props, 'booking.instant_confirmation', false);
      const rebook = get(this.props, 'booking.cart.rebook', false);
      // set apt id
      this.props.setUpcomingAppointmentId(get(this.props, 'booking.cart.id', ''));

      bookingConversionImpactRadius(get(this.props, 'booking.cart', {}), get(this.props, 'client', {}));
      const isCouple = size(get(this.props, 'booking.cart.cartProducts')) > 1;
      if (!rebook && marketplaceEnabled && !isIC && !get(this.props, 'booking.find_for_me', false) && !isCouple) {
        this.fetchRecommendedPros();
      } else {
        this.resetBookingAndNext();
      }
    }
  }

  getRelevantCTACopy() {
    const papEnabled = get(this.props, 'booking.cart.pickAProEnabled', false);
    const proId = get(this.props, 'booking.cart.therapist_preferences.0.id', '');
    if (papEnabled && proId) {
      return 'Book Instantly';
    }
    return 'Request Appointment';
  }

  acceptTermsAndConditions() {
    const cartId = get(this.props, 'booking.cart.id', '');
    acceptTerms(cartId, () => {
      this.setState({ showClientTermsModal: false }, () => this.submitRequest(true));
    }, this.errorHandler);
  }

  sendPixelDataAboutAppointment(respObj) {
    const { backToBack, billing, id } = respObj;
    const { cart } = this.props.booking;
    const data = {
      currency: billing.currency,
      id,
      receiptInfoType: cart.cartProducts.map((cp) => cp.title),
      sessionLength: cart.cartProducts.map((cp) => cp.session_length),
      totalInt: billing.total_price,
      appointmentType: (backToBack.enabled ? 'Back to Back' : 'Single'),
      service_category: get(this.props, 'booking.product.title', '').toLowerCase(),
    };
    sendPixelData(data, this.props.client.email, this.props.client.first_time_booker);
  }

  registerBookingCompleteAnalytic() {
    const subscription_tier_id = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_tier_id', '');
    const subscription_tier_name = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_tier_name', '');
    const { cart } = this.props.booking;
    let subscriptionTierId = _.get(this.props, 'client.subscription_tier_id', null);
    if (!subscriptionTierId) {
      subscriptionTierId = _.get(cart, 'subscriptions.subscription_tier_id', null);
    }
    const subscriptionTiers = get(cart, 'subscriptions.subscription_tiers', []);
    const used_points = get(this.props, 'booking.cart.billing.points_used', 0);
    const used_cash = get(this.props, 'booking.cart.billing.total_price', '') !== '0.0';
    seBookingComplete(
      cart,
      get(this.props, 'booking.product.title', '').toLowerCase(),
      this.props.client.first_time_booker,
      subscriptionTiers,
      subscriptionTierId,
      {
        membership_type: subscription_tier_name,
        membership_id: subscription_tier_id,
        used_points,
        used_cash,
      },
      'booking_23_variant',
      "jul_2024_booking_enhancements",
      get(this.props, "jul_2024_booking_enhancements", "")
    );
  }

  registerMembershipCompleteAnalytic() {
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);
    const subscription_tier_id = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_tier_id', '');
    const subscription_tier_name = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_tier_name', '');
    const {
      rebook, billing, address, cartProducts,
    } = get(this.props, 'booking.cart', {});

    if (alreadyAppliedToCart) {
      seMembershipComplete({
        membership_type: subscription_tier_name,
        first_time_booking: get(this.props, 'client.first_time_booker', false),
        membership_id: subscription_tier_id,
        service_category: get(this.props, 'booking.product.title', '').toLowerCase(),
        booking_city: get(address, 'city', ''),
        is_rebook: rebook,
        revenue_amount: get(billing, 'subtotal', ''),
        currency_type: get(billing, 'currency', '$'),
        service_nb_people: cartProducts ? cartProducts.length : 0,
        used_points: get(billing, 'points_used', 0),
        used_cash: get(billing, 'total_price', '') !== '0.0',
      });
    }
  }

  handleCheckoutResponse(data) {
    const { result } = data;
    if (result) {
      this.props.assignToCart({ cart: decode(data.cart) });
      this.sendPixelDataAboutAppointment(get(this.props, 'booking.cart', null));

      this.registerBookingCompleteAnalytic();
      this.registerMembershipCompleteAnalytic();

      this.props.setLoaderFlag(false);
      this.goToNextStep();
    } else {
      this.props.setLoaderFlag(false);
      this.setState({ submitted: false, showErrorModal: true, error: _.get(data, 'errors.0.message', defaultErrorMessage) });
    }
  }

  book() {
    const cartBeforeCheckout = this.props.booking.cart;

    this.setState({ submitted: true });
    this.props.setLoaderFlag(true);
    checkoutBooking(cartBeforeCheckout.id, (response) => {
      this.handleCheckoutResponse(response.data);
    }, (err) => {
      const stts = get(err, 'response.status', null);
      this.props.setLoaderFlag(false);
      if (stts === 422 || stts === 500) {
        this.setState({ submitted: false, showErrorModal: true, error: _.get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      }
    });
  }

  checkForTermsAndBook(termsAccepted) {
    const cart = get(this.props, 'booking.cart', null);
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);

    if (alreadyAppliedToCart && !this.state.isChecked) {
      this.setState({ showErrorModal: true, error: 'Please accept the terms and conditions by ticking the checkbox' });
      return false;
    } if (cart.terms.client_needs_to_accepted_terms && !termsAccepted) {
      this.setState({ showClientTermsModal: true });
      return false;
    }
    return true;
  }

  checkForCreditCard() {
    const cartCreditCardId = _.get(this.props.booking, 'cart.billing.credit_card.id', null);
    if (_.isEmpty(this.props.creditCards) || !(cartCreditCardId)) {
      this.setState({ showErrorModal: true, error: pleaseAddACreditCardError });
      return false;
    }
    return true;
  }

  submitRequest(termsAccepted = false) {
    if (!this.state.submitted
      && this.checkForCreditCard()
      && this.checkForTermsAndBook(termsAccepted)) {
      this.book();
    }
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  getCartCallbackFunc(response) {
    this.props.assignToCart({ cart: decode(response.data.cart) });
  }

  errorHandler(err) {
    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
  }

  updateSummaryBillingData() {
    getCartDetails(get(this.props, 'booking.cart.id', ''), this.getCartCallbackFunc, this.errorHandler);
  }

  clientTermsModal() {
    const { cart } = this.props.booking;

    if (cart.terms && cart.terms.client_needs_to_accepted_terms) {
      return (
        <div className="payments-redesign">
          <Modal isOpen={this.state.showClientTermsModal} title={cart.terms.client_title_terms || ''} close={() => this.setState({ showClientTermsModal: false })}>
            <Linkify>
              <div id="client-terms-redesign">
                {cart.terms.client_terms.split('\n').map((item, key) => {
                  const marginValue = key > 0 && key < 20 ? '30px' : '0';
                  return (
                    <span style={{ marginLeft: marginValue }} key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>
            </Linkify>

            <div className="terms-btn-container">
              <a id="acceptCovidTermsButton" className="accept-terms-btn contentInversePrimary" href={blankHref} onClick={this.acceptTermsAndConditions}>I Accept</a>
            </div>

            <div className="back-btn-container">
              <a className="btn" href={blankHref} style={{ color: '#F58266' }} onClick={() => this.setState({ showClientTermsModal: false })}>Cancel</a>
            </div>
          </Modal>
        </div>
      );
    }
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  soothePassFinePrint() {
    const fine_print = get(this.props, 'booking.cart.info_fields.soothe_pass.fine_print', '');
    const isSoothePassAvailable = get(this.props, 'booking.cart.info_fields.soothe_pass.available', false);
    const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false);
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);

    if (isSoothePassAvailable && alreadyAppliedToCart && fine_print && !isSoothePassSubscribed) {
      let a; let termsLink; let b; const
        splitString = fine_print.split(termsAndConditions);

      a = splitString[0];
      b = splitString[1];
      termsLink = <a href={ROUTES.terms} target="_blank" rel="noopener noreferrer">{termsAndConditions}</a>;

      return (
        <div className="summary-wrapper">
          <div className="annual-subscription-disclaimer" style={{ maxWidth: 'none' }}>
            <div className="check-box">
              <input type="checkbox" value={this.state.isChecked} className="" onChange={this.handleChecked} id="termsCheckbox" />
            </div>
            <div className="check-box-copy annual-disclaimer">
              {a}
              {' '}
              {termsLink}
              {' '}
              {b}
            </div>
          </div>
        </div>
      );
    }
  }

  hasTippableProducts() {
    let toTip = [];
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    if (!_.isEmpty(cartProducts)) {
      toTip = _.filter(cartProducts, (el) => (_.get(el, 'tip.can_tip', false) && _.get(el, 'tip.tip_changes_allowed', false) && !el.parent_id));
    }
    return !_.isEmpty(toTip);
  }

  render() {
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);
    const guest = Boolean(hasUuid() && get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const instant_confirmation = get(this.props, 'booking.instant_confirmation', false);

    return (
      <Slide direction="up" in mountOnEnter unmountOnExit>
        <div>
          <div className="mb-106 sm-mb-177 mt-80 sm-mt-0-imp">
            <div className={guest || rebook ? 'max-w-50-vw' : 'max-width-55'}>
              <div className={`size-44-52 sm-size-28-36 ${guest ? 'color-white txt-shadow sm-color-black' : 'contentPrimary'} medium-font txt-center sm-txt-left mb-24 sm-mb-12`}>Checkout</div>

              <div className="background-primary border-radius-16 pt-24 sm-p-0">
                <div className={`p-0-60-24 ${guest ? 'pt-24' : ''} sm-p-0`}>
                  <div className="br-btm-gray">
                    <VerifyPhoneNumber
                      showPersonaInParent={this.props.initiateOrShowPersona}
                      removePersona={this.props.removePersona}
                    />
                    <PaymentMethod
                      updateSummaryBillingData={this.updateSummaryBillingData}
                      assignToCart={this.props.assignToCart}
                      setLoaderFlag={this.props.setLoaderFlag}
                      jul_2024_booking_enhancements={this.props.jul_2024_booking_enhancements}
                    />
                    <GiftCards
                      updateSummaryBillingData={this.updateSummaryBillingData}
                      assignToCart={this.props.assignToCart}
                    />
                    <PromoCodes
                      updateSummaryBillingData={this.updateSummaryBillingData}
                      assignToCart={this.props.assignToCart}
                    />
                  </div>
                  {this.hasTippableProducts() ? (
                    <div className="br-btm-gray">
                      <TipProvider
                        updateSummaryBillingData={this.updateSummaryBillingData}
                        assignToCart={this.props.assignToCart}
                        setLoaderFlag={this.props.setLoaderFlag}
                      />
                    </div>
                  ) : null}
                  <div className="br-btm-gray">
                    <ApplicablePoints assignToCart={this.props.assignToCart} />
                  </div>
                  <OrderSummary />
                  {this.soothePassFinePrint()}
                </div>
                <CTAButton
                  text={rebook && instant_confirmation
                    ? 'Confirm Instantly' : alreadyAppliedToCart
                      ? 'Subscribe and Request now'
                      : 'Request appointment'}
                  disabled={Boolean(get(this.props, "client.phone_verification_required", false) && get(this.props, "client.ab_tests.mobile_phone_verification", "") === "needs_verification" && !hasUuid() && this.props.initiateOrShowPersona)}
                  submitted={this.state.submitted}
                  additionalWrapperClass="justify-content-center d-block-imp"
                  action={() => {
                    this.submitRequest();
                  }}
                  additionalClass={rebook ? 'full-width-btn' : ''}
                  relevantId="requestAppointmentButton"
                  legalCopyOnTop={(<div className="mb-16 contentSecondary size-12-20">
                    By clicking the button below, I acknowledge that I am requesting services from an independent provider marketing their services on Soothe's platform.
                  </div>)}
                />
              </div>
            </div>
          </div>
          {this.state.drawerIsOpen && !rebook ? (
            <ProListDrawer
              isOpen={this.state.drawerIsOpen}
              closeAndNext={() => {
                this.resetBookingAndNext();
              }}
              assignToProps={(cartProps) => this.props.assignToCart(cartProps)}
              recommendedProviders={this.state.recommendedProviders}
            />
          ) : null}
          {this.clientTermsModal()}
          {this.errorModal()}
        </div>
      </Slide>
    );
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
  creditCards: state.creditCards,
  bookingFlow: state.bookingFlow,
  fieldsHolder: state.fieldsHolder,
});

Index.propTypes = {
  booking: PropTypes.object.isRequired,
  bookingFlow: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  creditCards: PropTypes.array.isRequired,
  setBookingFlowStep: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
  setAddresses: PropTypes.func.isRequired,
};
export default withLocalize(connect(mapStateToProps, {
  setBookingFlowStep,
  setCart,
  setAddresses,
  setBookingFlowPreviousStep,
  setProducts,
  setUpcomingAppointmentId,
  setField,
})(Index));
