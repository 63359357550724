/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import NavBar from '../../Shared/NavBar';
import Footer from '../../Shared/Footer';
import './corona.css';
import { HotJar } from '../../../constants';

HotJar();

export default class Covid19 extends React.Component {
  render() {
    return (
      <div>
        <NavBar />
        <section>
          <div className="corona-header-container">
            <div className="corona-header">
              <h2 className="corona-title">
                Soothe’s Latest Information on COVID-19 (Coronavirus)
              </h2>

              <p>
                Soothe’s top priority has always been the health and wellness of our community.
                In this difficult time, know that we are taking necessary steps
                to maintain a safe and healthy
                environment for our clients and therapists.
              </p>
            </div>
          </div>

          <div className="section-corona">
            <div className="copy-block">
              To the Soothe Community:
            </div>

            <div className="copy-block">
              The team and I at Soothe are concerned about your health and wellbeing,
              as well as the safety of our overall community. Because of this, we are complying
              with all Stay-at-Home orders. If you reside in a market where one exists, we will
              not be accepting appointments in your area until
              the order is lifted for massage therapy.
              If you are a Soothe Plus subscriber, remember
              that your credits never expire and you will
              be able to redeem them once the order is lifted.
            </div>

            <div className="copy-block">
              We apologize for any inconvenience this may cause,
              but we know that by working together,
              we’re all doing our part for the greater good.
            </div>

            <div className="copy-block">
              We remain committed to our mission of bringing
              wellness to the world, and thank you for
              your understanding and ongoing support.
            </div>

            <div className="copy-block">
              Stay healthy, stay safe, and look out for each other.
            </div>

            <div className="copy-block">
              John Ellis
              <br />
              CEO
              <br />
              Soothe
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
