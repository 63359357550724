/* eslint-disable react/jsx-filename-extension */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  concat, filter, find, get, isEmpty, map, size, startCase, uniqBy,
} from 'lodash';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import HelmetTag from '../Shared/HelmetTag';
import { setAppointment, setUpcomingAppointmentId } from '../../Actions/AppointmentManagementAction';
import { loadClient, setCart, setProducts } from '../../Actions';
import LoadingOverlay from '../Shared/LoadingOverlay';
import ErrorModal from '../Shared/ErrorModal';
import './Assets/Styles/index.css';
import {
  asyncGetPastAppointments, asyncGetUpcomingAppointments,
  getPastAppointments, getUpcomingAppointments,
} from './Shared/helpers';
import HorizontalCard from '../Shared/HorizontalCard';
import EmptyState from './EmptyState';
import FooterRedesign from '../Shared/FooterRedesign';
import { setAllUserIds, seViewAppointments } from '../Shared/WebAnalytics';
import SignupModal from '../Shared/SignupModal';
import { ACCEPT_LANGUAGE_HEADER, API_ROOT, HOST_ROOT } from '../../apiConfig';
import NavbarRedesignForMarketplace from '../Shared/NavbarRedesignForMarketplace';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      showErrorModal: false,
      showLoader: false,
      width: window.innerWidth,
      upcomingPage: 1,
      pastPage: 1,
      upcomingTotalPages: 1,
      pastTotalPages: 1,
      pastCarts: [],
      upcomingCarts: [],
      openRelevantModal: false,
      redirect: false,
    };
    this.renderView = this.renderView.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.pageTitle = this.pageTitle.bind(this);
    this.upcomingApts = this.upcomingApts.bind(this);
    this.pastApts = this.pastApts.bind(this);
    this.getUpcoming = this.getUpcoming.bind(this);
    this.getPast = this.getPast.bind(this);
    this.getRelevantButtonLabel = this.getRelevantButtonLabel.bind(this);
    this.tipFilterFunction = this.tipFilterFunction.bind(this);
    this.triggerBookingsViewEvent = this.triggerBookingsViewEvent.bind(this);
    this.getRelevantProList = this.getRelevantProList.bind(this);
    this.fetchClientOnLoad = this.fetchClientOnLoad.bind(this);
    this.redirectBasedOnUserStatus = this.redirectBasedOnUserStatus.bind(this);
    this.customizeStatus = this.customizeStatus.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));

    this.props.setProducts({ products: [] });
    this.props.setCart({
      cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {},
    });

    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (loggedIn) {
      this.setState({ showLoader: true });
      Promise.all([asyncGetUpcomingAppointments(1), asyncGetPastAppointments(1)])
        .then(([resp1, resp2]) => {
          const upcomingCarts = get(resp1, 'data.carts', []);
          const pastCarts = get(resp2, 'data.carts', []);
          this.setState({
            showLoader: false,
            upcomingCarts,
            upcomingTotalPages: get(resp1, 'data.meta.total_pages', 1),
            pastCarts,
            pastTotalPages: get(resp2, 'data.meta.total_pages', 1),
          });
          this.triggerBookingsViewEvent(upcomingCarts, pastCarts);
        }).catch(([_err1, _err2]) => {
          this.setState({ showLoader: false });
        });
    }
  }

  redirectBasedOnUserStatus() {
    if (this.props.client && this.props.client.loggedIn) {
      window.location.reload();
    }
  }

  fetchClientOnLoad() {
    axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result, client } = response.data;
      if (result) {
        if (!sessionStorage.getItem('userSet')) {
          setAllUserIds(client.user_id, client.email);
        }

        this.props.loadClient({ ...client, loggedIn: true });
        this.redirectBasedOnUserStatus();

        axios.get(`${API_ROOT}/clients/${client.user_id}/sift_science_params`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response2) => {
          const { session_id } = response2.data;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'user_session_info',
            user_id: client.user_id,
            session_id,
          });
        });
      } else {
        this.props.loadClient({ loggedIn: false });
        this.redirectBasedOnUserStatus();
      }
    }).catch((e) => {
      const { response } = e;
      if (response.status === 401) {
        this.props.loadClient({ loggedIn: false });
        this.redirectBasedOnUserStatus();
      }

      if (response.status === 404 && response.data.errors && response.data.errors[0].code === 'client_not_found' && response.data.result === false) {
        window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
      }
    });
  }

  triggerBookingsViewEvent(upcoming, past) {
    let state = 'empty';
    const isComingFromNavbar = get(this.props, 'location.state.click_source') === 'nav_bar';
    if (!isEmpty(upcoming) && !isEmpty(past)) {
      state = 'upcoming_and_past';
    } else if (isEmpty(upcoming) && !isEmpty(past)) {
      state = 'past_only';
    } else if (!isEmpty(upcoming) && isEmpty(past)) {
      state = 'upcoming_only';
    }
    if (!isEmpty(find(upcoming, (el) => (get(el, 'tracker.pending_response', '') === 'client_reschedule_response_required' && get(el, 'tracker.state', 'requested') !== 'complete')))) {
      state = 'reschedule requested';
    }
    seViewAppointments(state, isComingFromNavbar ? 'nav_bar' : 'deep_link');
  }

  getUpcoming() {
    const { upcomingPage } = this.state;
    this.setState({ showLoader: true });
    getUpcomingAppointments(upcomingPage, (resp) => {
      this.setState({ showLoader: false, upcomingCarts: get(resp, 'data.carts'), upcomingTotalPages: get(resp, 'data.meta.total_pages', 1) });
    }, (_err) => {
      this.setState({ showLoader: false, upcomingCarts: [] });
    });
  }

  getPast() {
    const { pastPage, pastCarts } = this.state;
    this.setState({ showLoader: true });
    getPastAppointments(pastPage, (resp) => {
      this.setState({ showLoader: false, pastCarts: concat(pastCarts, get(resp, 'data.carts')), pastTotalPages: get(resp, 'data.meta.total_pages', 1) });
    }, (_err) => {
      this.setState({ showLoader: false, pastCarts: [] });
    });
  }

  pageTitle() {
    return (<div className="medium-font size-40-52 mb-24 color-black sm-size-28-36">Bookings</div>);
  }

  getRelevantProList(crt) {
    let list = []; let hideRating = false;
    const isBackToBack = get(crt, 'back_to_back', false);
    const cps = get(crt, 'cart_products', []);
    if (isBackToBack) {
      list = [get(crt, 'cart_products.0.pro', null)];
    } else {
      list = map(cps, (crtp) => (crtp.pro));
    }
    if (isEmpty(list)) {
      list = get(crt, 'therapist_preferences', []);
    }
    if ((size(list) < size(cps)) && !isBackToBack) {
      hideRating = true;
      list = uniqBy(map(cps, (el) => ({ first_name: el.subtitle })), 'first_name');
    }
    return { list, hideRating };
  }

  customizeStatus(crt) {
    const pending_response = get(crt, 'tracker.pending_response', '');
    const stt = get(crt, 'tracker.state', 'requested');
    // const requestedProviders = get(crt, 'cart_products.0.pap_therapist_preferences', []);
    // const hasAltTimesPro = find(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'));
    if (stt !== 'complete') {
      if (pending_response === 'pending_client_response') {
        return 'Requires Attention';
      } if (pending_response === 'pro_reschedule_response_required') {
        return 'Reschedule Pending';
      } if (pending_response === 'client_reschedule_response_required') {
        return 'Reschedule Requested';
      } if (pending_response === 'pending_client_response_rebook') {
        return 'Requires Attention';
      }
    }
    return startCase(stt);
  }

  upcomingApts() {
    const { upcomingCarts } = this.state;
    if (isEmpty(upcomingCarts)) {
      return <EmptyState />;
    }
    return (
      <div id="upcoming-apts">
        {map(upcomingCarts, (crt, itr) => {
          const { list, hideRating } = this.getRelevantProList(crt);
          return (
            <div key={`upcmng-crt-${itr}`} className="mb-32">
              <HorizontalCard info={{
                aptId: crt.id,
                img_url: crt.img_url,
                title: crt.title,
                status: this.customizeStatus(crt),
                time: get(crt, 'date_time.time', ''),
                date: `${get(crt, 'date_time.day_of_week', '')}, ${moment(get(crt, 'date_time.datetime', '')).format('MMM')} ${get(crt, 'date_time.day', '')}`,
                buttonLabel: 'Edit',
                buttonAction: () => {
                  this.props.setUpcomingAppointmentId(crt.id);
                  this.setState({ openRelevantModal: false, redirect: true });
                },
                prosList: list,
                hideRating,
              }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  tipFilterFunction(cp, crt) {
    const genericStatus = get(crt, 'tracker.state', 'requested');
    const parent_id = get(cp, 'parent_id', null);
    const tip_changes_allowed = get(cp, 'tip.tip_changes_allowed', false);
    const pro_tip_changes_allowed = get(cp, 'pro.tip.tip_changes_allowed', false);
    const tipAmount = get(cp, 'pro.tip.amount', null);
    const checkedIn = get(cp, 'checked_in', null);
    const canTipPro = get(cp, 'pro.tip.can_tip', false);

    const pastAndAllowed = Boolean(genericStatus === 'complete' && pro_tip_changes_allowed && tipAmount !== null);
    const upcomingAndCantip = Boolean(genericStatus !== 'complete' && checkedIn && canTipPro);
    return (!parent_id && tip_changes_allowed && (pastAndAllowed || upcomingAndCantip));
  }

  getRelevantButtonLabel(crt) {
    const cartProducts = get(crt, 'cart_products', []);
    const filteredCartProducts = filter(cartProducts, (cp) => this.tipFilterFunction(cp, crt));
    const genericStatus = get(crt, 'tracker.state', 'requested');
    const currentStatus = get(cartProducts, '0.tracking.status', 'searching');
    if (size(cartProducts) === 1 && (genericStatus === 'complete' || currentStatus === 'arrived')) {
      // TODO - rebook2
      return 'Rebook';
    } if (!isEmpty(filteredCartProducts)) {
      return 'Tip';
    }
    return 'View';
  }

  pastApts() {
    let onlyFirstRebookFlag = -1;
    const {
      pastTotalPages, pastPage, pastCarts, width,
    } = this.state;
    if (isEmpty(pastCarts)) {
      return null;
    }
    return (
      <div id="past-apts">
        <div className="size-28-36 medium-font color-black mb-12 mt-8">{width < 800 ? 'Complete' : 'Past appointments'}</div>
        {map(pastCarts, (crt, itr) => {
          const buttonLabel = this.getRelevantButtonLabel(crt);
          if (onlyFirstRebookFlag === -1 && Boolean(buttonLabel === 'Rebook')) {
            onlyFirstRebookFlag = itr;
          }
          return (
            <div key={`past-crt-${itr}`} className="mb-32">
              <HorizontalCard info={{
                aptId: crt.id,
                img_url: crt.img_url,
                title: crt.title,
                status: startCase(get(crt, 'tracker.state', 'requested')),
                time: get(crt, 'date_time.time', ''),
                date: `${get(crt, 'date_time.day_of_week', '')}, ${moment(get(crt, 'date_time.datetime', '')).format('MMM')} ${get(crt, 'date_time.day', '')}`,
                buttonLabel,
                buttonAction: () => {
                  this.props.setUpcomingAppointmentId(crt.id);
                  this.setState({ openRelevantModal: buttonLabel !== 'View', redirect: true, isRebook: Boolean(buttonLabel === 'Rebook') });
                },
                prosList: get(crt, 'back_to_back', false) ? [get(crt, 'cart_products.0.pro', null)] : map(get(crt, 'cart_products', []), (crtp) => (crtp.pro)),
                buttonId: buttonLabel === 'Rebook' && onlyFirstRebookFlag === itr ? 'first-rebook-cta' : null,
              }}
              />
            </div>
          );
        })}
        {pastTotalPages > pastPage ? (
          <div className="txt-center">
            <button
              type="button"
              className="btn p-16-20 border-radius-30 color-dark-blue medium-font bg-light-blue mt-32 mb-32 w-220 sm-w-100"
              onClick={() => {
                this.setState({ pastPage: pastPage + 1 }, this.getPast);
              }}
            >
              Load more
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  renderView() {
    const { showLoader } = this.state;
    const isABTester = get(this.props, 'abTest', '') === 'new_flow';
    return (
      <>
        <NavbarRedesignForMarketplace
          hideGoBack
          darkenHeader
          additionalActionLoggedIn
          notBooking
          showMenu
          jul_2024_booking_enhancements={get(this.props, "client.ab_tests.jul_2024_booking_enhancements", "")}
        />
        <div className="min-height-100">
          {!showLoader && (
          <div className={`maxx-width-60 ${this.state.width < 800 ? '' : 'mt-120 sm-mt-80'}`}>
            {this.pageTitle()}
            <div className="size-28-36 medium-font color-black mb-12">Upcoming</div>
            {this.upcomingApts()}
            {this.pastApts()}
          </div>
          )}
        </div>
        <FooterRedesign />
      </>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => {
          this.setState({ showErrorModal: false, error: null });
        }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const {
      showLoader, redirect, openRelevantModal, isRebook,
    } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (!loggedIn) {
      return (
        <SignupModal
          isSignin
          next={this.fetchClientOnLoad}
        />
      );
    }
    if (redirect) {
      return (
        <Redirect to={{
          pathname: '/appointment_management/',
          state: {
            openRelevantModal,
            click_source: 'bookings_page',
            isRebook,
          },
        }}
        />
      );
    }
    return (
      <>
        <HelmetTag />
        {this.renderView()}
        {this.errorModal()}
        <LoadingOverlay showLoader={showLoader} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  abTest: state.abTest,
});
export default connect(mapStateToProps, {
  setAppointment, setUpcomingAppointmentId, loadClient, setCart, setProducts,
})(Index);
