/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import { Close, FormatQuote, Timer } from '@material-ui/icons';
import { compact, get, size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import arrowSvg from '../../../Assets/Images/long_blue_arrow_right.svg';
import { seInboxViewMessage, seProviderContact } from '../../Shared/WebAnalytics';
import { openChat } from '../Shared/helpers';
import { HOST_ROOT } from '../../../apiConfig';
import { ROUTES } from '../../../constants';
import { fetchChat } from '../../../Actions';
import { getSingleChatState } from '../Shared/constants';

class ViewRescheduleRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModal: false,
    };
    this.rescheduleRequestModal = this.rescheduleRequestModal.bind(this);
    this.fromToUI = this.fromToUI.bind(this);
    this.messageToPro = this.messageToPro.bind(this);
  }

  fromToUI() {
    const newTime = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options.0.start_time', '');
    const day = moment(newTime).format('MMM D'); const
      hour = moment(newTime).format('h:mm A');
    if (newTime) {
      return (
        <div className="br-gray-2 p-16 mb-16 border-radius-8">
          <div className="row">
            <div className="col-xs-4 color-black size-12-20 ">
              <div className="clr-light-gray">Original</div>
              <div className="size-20-28 medium-font">
                {' '}
                {moment(get(this.props, 'appointment.date.utc', '')).format('MMM D')}
              </div>
              <div className="medium-font squeezed-in-with-lh">{get(this.props, 'appointment.time.display', '')}</div>
            </div>
            <div className="col-xs-4 text-align-center">
              <div className="display-flex"><img src={arrowSvg} alt="arrow-right" /></div>
            </div>
            <div className="col-xs-4 content-positive size-12-20 txt-end">
              <div className="clr-light-gray">New</div>
              <div className="size-20-28 medium-font">{day}</div>
              <div className="medium-font squeezed-in-with-lh">{hour}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  messageToPro() {
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', 'your provider');
    const proId = get(this.props, 'appointment.cartProducts.0.pro.id', '');
    return (
      <div className="row">
        <div className="col-xs-1"><FormatQuote className="size-20-imp clr-gray" /></div>
        <div className="col-xs-11">
          <div className="size-16-20 medium-font color-black mb-4">
            Message to
            {proFirstName}
          </div>
          <div className="size-14-20 clr-gray font-style-italic mb-16">
            “
            {get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.message', '')}
            ”
          </div>
          <div>
            <button
              type="button"
              className="btn bg-light-blue padding-10-12 color-accent border-radius-30 medium-font size-12-16"
              onClick={() => {
                this.setState({ viewModal: false });
                seProviderContact('initiate_reschedule');
                openChat(compact([proId]), (resp) => {
                  this.props.fetchChat(get(resp, 'data', {}));
                  seInboxViewMessage({
                    state: getSingleChatState(get(resp, 'data.chats', [])),
                    click_source: 'appointment_details',
                    cart_product_count: size(get(resp, 'data.cart.display_helpers.v0.cart_products', [])),
                    booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
                    is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
                    service_category: get(resp, 'data.cart.display_helpers.v0.cart_products.0.product.title', ''),
                    service_modality: get(resp, 'data.cart.display_helpers.v0.cart_products.0.cart_product_main_option.title', ''),
                  });
                  window.location.href = `${HOST_ROOT}${ROUTES.inbox}?chat_id=${get(this.props, 'currentChat.chat_id', '')}`;
                }, (err) => {
                  // eslint-disable-next-line no-console
                  console.log({ err });
                });
              }}
            >
              Contact
              {' '}
              {proFirstName}
            </button>
          </div>
        </div>
      </div>
    );
  }

  rescheduleRequestModal() {
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', 'your provider');
    return (
      <LatestModalDesign
        isOpen={this.state.viewModal}
        customIcon={<Close />}
        close={() => {
          this.setState({ viewModal: false });
        }}
        title={`Waiting for ${proFirstName} to respond`}
        customFooter={<div className="clr-light-gray text-align-center"><span className="size-12-20">If they decline or the request expires, your appointment will remain as originally booked.</span></div>}
      >
        <div className="min-height-50">
          {this.fromToUI()}
          {this.messageToPro()}
        </div>
      </LatestModalDesign>
    );
  }

  render() {
    const pendingProReschResponse = get(this.props, 'appointment.info_fields.tracker.pending_response', '') === 'pro_reschedule_response_required';
    const newTime = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options.0.start_time', '');
    const day = moment(newTime).format('ddd, MMM D');
    const hour = moment(newTime).format('h:mm A');
    if (pendingProReschResponse) {
      return (
        <>
          <div className="p-16">
            <div className="padding-10-16 bg-yellow">
              <div className="display-flex align-items-center justify-content-spaced">
                <div className=""><Timer className="color-yellow w-100-h-21" /></div>
                <div className="size-14-20 mrl-16">
                  Reschedule requested for
                  {' '}
                  {day}
                  {' '}
                  at
                  {' '}
                  {hour}
                  .
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btn bg-dark-yellow br-radius-9999 medium-font txt-end color-black size-14-16 padding-10-12"
                    onClick={() => {
                      this.setState({ viewModal: true });
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.rescheduleRequestModal()}
        </>
      );
    }

    return null;
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  chats: state.chats,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, { fetchChat })(ViewRescheduleRequest);
