/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import handsIcon from '../../../Assets/Images/icon-static-loading.png';
import './LoadingOverlay.css';

function CircleSpinner() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./spinner-with-blur-white.json'),
    });
  }, []);

  return (
    <div className="circle-spinner" ref={container} />
  );
}
export default class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showLoader } = this.props;
    return (showLoader ? (
      <div className="grayed-overlay" id="loadingIndicator">
        <CircleSpinner />
        <img src={handsIcon} alt="loading..." className="hands-icon" />
      </div>
    )
      : null);
  }
}
