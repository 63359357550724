import moment from 'moment';
import { NOT_BEFORE_H, hasUuid } from '../../../constants';

export const capHour = 22;

export let momentCap = (dateTime) => {
  dateTime = moment(dateTime);

  let cap = dateTime;
  if (moment(new Date()).isSame(dateTime, 'day')) {
    cap = moment(new Date()).add(NOT_BEFORE_H, 'hours');
  }

  if (cap.hours() < 8) {
    cap = cap.hours(8);
  } else if (cap.hours() > capHour || (cap.hours() === capHour && cap.minutes() !== 0)) {
    cap = cap.add(1, 'day').hours(8).minutes(0).seconds(0);
  }

  if (cap.minutes() % 15 !== 0) {
    cap.minutes(Math.ceil(cap.minutes() / 15) * 15);
  }

  return cap;
};

export const CART_DEFAULT = {
  id: null,
  status: '',
  time: {},
  date: {},
  address: {},
  billing: {},
  giftCode: '',
  backToBack: false,
  pickAProEnabled: false,
  cartProducts: [],
  subscriptions: {},
  terms: {},
  firstLoad: false
};

export const STEPS = [
  { id: 'ADDRESS', order: 1, withBackground: true },
  { id: 'SERVICE', order: 2, progressStep: 'FIND_A_SERVICE', withBackground: true, onlyOnMobile: true },
  { id: 'AUTHENTICATION', order: 3 },
  { id: 'RECIPIENT', order: 4, progressStep: 'FIND_A_SERVICE' },
  { id: 'MODALITY', order: 5, progressStep: 'CUSTOMIZE', hasOpaqueBg: true },
  { id: 'TIMING', order: 6, progressStep: hasUuid() ? 'CUSTOMIZE' : 'DATE' },
  { id: 'PICKAPRO', order: 7, progressStep: hasUuid() ? 'CUSTOMIZE' : 'DATE' },
  { id: 'REVIEW', order: 8, progressStep: 'REVIEW', hasOpaqueBg: true },
  { id: 'FINISH_ADDRESS', order: 9, progressStep: 'CUSTOMIZE' },
  { id: 'COUPLE_PREFERENCE', order: 10, progressStep: hasUuid() ? 'CUSTOMIZE' : 'DATE' },
  { id: 'CHECKOUT', order: 11, progressStep: 'CHECKOUT', hasOpaqueBg: true },
  { id: 'CONFIRMATION', order: 12 }
];

export const PROGRESS_STEPS = [
  { id: 'FIND_A_SERVICE', order: 1, title: 'Find a service' },
  { id: 'CUSTOMIZE', order: 2, title: 'Customize' },
  { id: 'DATE', order: 3, title: 'Date' },
  { id: 'REVIEW', order: 4, title: 'Review' },
  { id: 'CHECKOUT', order: 5, title: 'Checkout' }
];

export const RECIPIENT_OPTIONS = [
  { id: 'CURRENT_USER', title: 'Just me', personsCount: 1, userIncluded: true, slug: "justMe" },
  { id: 'CURRENT_PLUS_ONE', title: 'Me + 1', personsCount: 2, userIncluded: true, slug: "mePlusOne" },
  { id: 'SOMEONE_ELSE', title: 'Someone else', personsCount: 1, userIncluded: false, slug: "someoneElse" }
]

export const ADDITIONAL_INFO = [
  {
    imgSrc: require('../Assets/Images/Gradient_star.png'),
    title: 'Pay with points today',
    description: "Join today and use your points at checkout to get today's service for FREE"
  },
  {
    imgSrc: require('../Assets/Images/Gradient_dollar.png'),
    title: 'Members only pricing',
    description: 'Receive exclusive pricing on all services with savings up to 30%'
  },
  {
    imgSrc: require('../Assets/Images/Gradient_arrow.png'),
    title: 'Points roll over',
    description: 'Save unused points to redeem for bigger items next month'
  },
  {
    imgSrc: require('../Assets/Images/Gradient_x.png'),
    title: 'Cancel anytime',
    description: 'No contracts or commitment'
  }
]