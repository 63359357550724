/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { LOAD_CLIENT } from '../Actions';

export default function (state = {}, action) {
  switch (action.type) {
    case LOAD_CLIENT:
      return action.payload;
    default:
      return state;
  }
}
