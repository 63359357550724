/* eslint-disable react/sort-comp */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable class-methods-use-this */
/* eslint-disable global-require */
/** **************************************************
class ExampleModal extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      date: '12/25/2018'
    };

    this.onDayChange = this.onDayChange.bind(this);
  }

  onDayChange (day, dayString) {
    this.setState({ date: dayString })
  }

  render() {
    return (
       <DatetimeWrapper dateFormat='MM/DD/YY'
                        value={ this.state.date }
                        timeFormat={ false }
                        onChange={ this.onDayChange }
                        isValidDate={ (currentDate) => currentDate.isAfter(yesterday); }
       />
    );
  }
}
**************************************************** */
import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import { ACTIVE_LANGUAGE_CODE, DE } from '../../constants';

require('react-datetime/css/react-datetime.css');

if (ACTIVE_LANGUAGE_CODE === DE) {
  require('moment/locale/de');
}

class DatetimeWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.calcState = this.calcState.bind(this);
    this.onChange = this.onChange.bind(this);

    const { format, value } = this.calcState(this.props);

    this.state = {
      value,
      format,
    };
  }

  calcState(props) {
    let format; let
      value;

    if (props.dateFormat) {
      format = props.dateFormat;
      value = moment(props.value, format).toDate();
    } else if (props.timeFormat) {
      format = props.timeFormat;
      value = moment(props.value, format).toDate();
    } else {
      value = moment(props.value).toDate();
    }

    return { format, value };
  }

  componentWillReceiveProps(nextProps) {
    const { format, value } = this.calcState(nextProps);

    this.setState({
      format,
      value,
    });
  }

  onChange(day) {
    const dayString = moment(day).format(this.state.format);
    this.props.onChange(day, dayString);
  }

  render() {
    return (
      <Datetime
        {...this.props}
        dateFormat={this.props.dateFormat ? 'MMM Do, YYYY' : this.props.dateFormat}
        timeFormat={this.props.timeFormat}
        value={this.state.value}
        timeConstraints={this.props.timeConstraints}
        onChange={this.onChange}
        isValidDate={this.props.isValidDate}
        closeOnSelect
      />
    );
  }
}

DatetimeWrapper.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  timeFormat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default DatetimeWrapper;
