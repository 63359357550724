import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get } from 'lodash';
import EnhnacementsPerPerson from './EnhnacementsPerPerson';
import ServicePerPerson from './ServicePerPerson';
import SoothePassInCart from './SoothePassInCart';

class Appointments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.relevantTitle = this.relevantTitle.bind(this);
    }
    relevantTitle() {
        let alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false);
        return (<div className='second-title-style medium-font'>{alreadyAppliedToCart ? 'Your Cart' : 'Appointments'}</div>);
    }
    render() {
        const cart = get(this.props, "booking.cart", null);
        let cartProducts = get(cart, "cartProducts", []);
        if (!isEmpty(cartProducts)) {
            const ln = cartProducts.length;
            return (
                <div>
                    {this.relevantTitle()}
                    <SoothePassInCart assignToCart={this.props.assignToCart} />
                    {cartProducts.map((cartProduct, it) => (
                        <div key={`selection-${it}`}>
                            <ServicePerPerson cartProduct={cartProduct} deleteRelevantService={this.props.deleteRelevantService} openPlanPopup={this.props.openPassPlanPopup} />
                            <EnhnacementsPerPerson cartProduct={cartProduct} productIteration={it}
                                productEnhancements={get(this.props.enhancementsByProductId, cartProduct.id, [])}
                                enhancementClick={this.props.enhancementClick}
                            />
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

Appointments.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(Appointments));
