import React from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { setBookingFlowPreviousStep, loadClient } from '../../../Actions';
import { HOST_ROOT, API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { STEPS } from '../Shared/constants';
import { find, get, isEmpty } from 'lodash';
import '../Assets/Styles/index.css';
import SignupModal from '../../Shared/SignupModal';
import { createAddress, createCart } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage } from '../../../constants';
import Slide from "@material-ui/core/Slide";
HotJar();

class AuthenticationStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.goToNextStep = this.goToNextStep.bind(this);
        this.createAddressIfNotExist = this.createAddressIfNotExist.bind(this);
        this.manageNext = this.manageNext.bind(this);
        this.createCartCallback = this.createCartCallback.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeBackground("none");
    }
    goToNextStep(nextId) {
        let nextStep = find(STEPS, (step) => (step.id === nextId));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    errorHandler(err) {
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
        setTimeout(() => {
            this.goToNextStep("ADDRESS");
        }, 2500);
    }
    createCartCallback(response) {
        let self = this;
        let addressId = get(response, "data.address.id", null);
        createCart(addressId, get(this.props,"fieldsHolder.csrfToken",""), (response2) => {
            self.props.assignToCart({ cart: decode(response2.data.cart), product: this.props.booking.product, addressId })
            self.goToNextStep("RECIPIENT");
        }, this.errorHandler)
    }
    createAddressIfNotExist() {
        let address = get(this.props, "booking.cart.address", null);
        if (!isEmpty(address) && !address.id) {
            createAddress(address, this.createCartCallback, this.errorHandler)
        } else {
            this.goToNextStep("ADDRESS");
        }
    }
    manageNext() {
        axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
            const { result, client } = response.data;
            if (result) {
                this.props.loadClient({ ...client, loggedIn: true });
                this.createAddressIfNotExist();
            } else {
                this.props.loadClient({ loggedIn: false });
            }
        }).catch((e) => {
            // Regardless of response code set loggedIn to false
            this.props.loadClient({ loggedIn: false });
            let response = e.response;
            if (response.status === 401) {
                this.props.loadClient({ loggedIn: false });
            }
            if (response.status === 404 && response.data.errors && response.data.errors[0].code === "client_not_found" && response.data.result === false) {
                window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
            }

        });
    }

    render() {
        const parsedParams = queryString.parse(window.location.search);
        let redirectURI = `${process.env.REACT_APP_API_URL}/apple/auth_redirect_booking`;
        return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <SignupModal isBooking={true} showPersona={this.props.initiateOrShowPersona} removePersona={this.props.removePersona}  isSignin={this.props.isSignin} next={this.manageNext} redirectURI={redirectURI} error={get(parsedParams, 'auth_error', null)} 
            back={this.props.back}/>
        </Slide>
        )
    }
}

const mapStateToProps = state => ({
    addresses: state.addresses,
    booking: state.booking,
    products: Object.values(get(state,"productsReducer.products",{})),
    client: state.client,
    bookingFlow: state.bookingFlow,
    fieldsHolder: state.fieldsHolder
});

AuthenticationStep.propTypes = {
    booking: PropTypes.object,
    client: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    setBookingFlowPreviousStep: PropTypes.func.isRequired,
    loadClient: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { setBookingFlowPreviousStep, loadClient })(AuthenticationStep);
