/* eslint-disable no-param-reassign */
import {
  compact, concat, flatten, forEach, get,
  groupBy, isEmpty, last, map,
  sortBy, times, uniqBy, values,
} from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { SELECTABLE_HOURS } from '../../../constants';
import BlueButtonsList from '../../Shared/BlueButtonsList';
import HorizontalCalendar from '../../Shared/HorizontalCalendar';
import { getTimesByProvider } from '../Shared/helpers';

class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timesList: [],
      selectedDate: null,
      lastAddressTimezone: '',
    };
    this.structureTimesList = this.structureTimesList.bind(this);
    this.listOfButtons = this.listOfButtons.bind(this);
    this.extendedArr = this.extendedArr.bind(this);
  }

  componentDidMount() {
    const loader = this.props.setLoaderFlag;
    if (loader) {
      loader(true);
    }
    const userId = get(this.props, 'client.user_id', '');
    const providerId = get(this.props, 'booking.cart.therapist_preferences.0.id', '');
    getTimesByProvider(userId, providerId, (resp) => {
      const gnrl = get(resp, 'data.general_availability', []);
      const instnt = get(resp, 'data.instant_availability', []);
      this.setState({ lastAddressTimezone: get(resp, 'data.therapist.last_address.timezone', '') });
      this.structureTimesList(flatten(values(gnrl)), flatten(values(instnt)));
      if (loader) {
        loader(false);
      }
    }, (err) => {
      if (loader) {
        loader(false);
      }
      console.log({ err });
    });
  }

  extendedArr(mainArr) {
    let res = [];
    const lastElt = last(mainArr);
    const zn = get(this.props, 'booking.cart.time.timezone', '');
    times(53, (ii) => {
      const bf = get(lastElt, 'datetime', '');
      const nw = moment.tz(bf, zn).add(ii + 1, 'day');
      const formattedDate = nw.format('YYYY-MM-DD');
      res = concat(res, map(SELECTABLE_HOURS, (hr) => {
        const formattedTime = moment(`${formattedDate} ${hr}`).format('HH:mm');
        return {
          day: Number(nw.format('D')),
          day_of_week: nw.format('dddd'),
          month: Number(nw.format('M')),
          mon: nw.format('MMM'),
          time: hr,
          datetime: moment.tz(`${formattedDate} ${formattedTime}`, zn).format(),
          category: 'Request',
        };
      }));
    });
    return concat(mainArr, res);
  }

  structureTimesList(general_availability, instant_availability) {
    if (!isEmpty(general_availability)) {
      forEach(general_availability, (el, itr) => {
        general_availability[itr].category = 'Request';
        general_availability[itr].mon = moment(general_availability[itr].datetime).format('MMM');
      });
    }
    if (!isEmpty(instant_availability)) {
      forEach(instant_availability, (el, itr) => {
        instant_availability[itr].category = 'Confirm instantly';
        instant_availability[itr].mon = moment(instant_availability[itr].datetime).format('MMM');
      });
    }
    const mainArr = sortBy(uniqBy(compact(concat(instant_availability, general_availability)), 'datetime'), 'datetime');
    this.setState({ timesList: this.extendedArr(mainArr) });
  }

  listOfButtons(grpList) {
    let firstElt = true;
    return map(grpList, (list, ind) => {
      const grpgrpList = groupBy(list, 'day');
      return (
        <div key={`time-list-${ind}`}>
          {map(grpgrpList, (subList, itr) => {
            const tempVal = firstElt;
            if (firstElt) {
              firstElt = false;
            }
            return (
              <div key={`time-sublist-${itr}`} className="mb-50">
                <BlueButtonsList
                  small
                  firstTimeSlot={tempVal}
                  title={moment(subList[0].datetime).format('ddd, MMM D')}
                  customAnchor="extraHeightAnchorCopy"
                  buttonsList={subList}
                  customId={`${itr}-${get(subList, '0.mon', '')}`}
                  globalAction={(val) => {
                    this.props.assignToCart({ instant_confirmation: Boolean(val.category === 'Confirm instantly'), find_for_me: false });
                    this.props.action(moment.tz(val.datetime, this.state.lastAddressTimezone).format('YYYY-MM-DD'), val.time);
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    });
  }

  render() {
    const { timesList } = this.state;
    const grpList = groupBy(timesList, 'mon');
    if (!isEmpty(timesList)) {
      return (
        <div className="mb-24">
          <div className="top-sticky-80 bg-primary z-index-1">
            <div className="p-24">
              <HorizontalCalendar
                daysCount={60}
                startDate={timesList[0].datetime}
              />
            </div>
            <div className="br-btm-black-1 txt-center size-16-20 medium-font p-16 contentPrimary">
              {get(this.props, 'booking.cart.info_fields.title', '')}
            </div>
          </div>
          <div className="p-24" data-spy="scroll" data-target="#time-scroller" data-offset="0">
            {this.listOfButtons(grpList)}
          </div>

        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
});
export default connect(mapStateToProps, {})(TimePicker);
