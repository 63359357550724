/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import produce from 'immer';
import { assign } from 'lodash';
import { SET_CART, SET_CART_PRODUCT, DELETE_CART_PRODUCT } from '../Actions';
import { CART_DEFAULT } from '../Containers/BookingFlow/Shared/constants';

export default function (state = {
  cart: assign(CART_DEFAULT, { firstLoad: true }), cartProducts: [], product: {}, addressId: '', b2bDiscountCode: '', currentCartProduct: {}, promoCodes: [], giftCardCodes: [], instant_confirmation: false, find_for_me: false, currentOption: {},
}, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_CART:
        const {
          cart, cartProducts, product, addressId, b2bDiscountCode, currentCartProduct, promoCodes, giftCardCodes, instant_confirmation, find_for_me, currentOption,
        } = action.payload;
        draft.cart = cart;
        draft.cartProducts = cartProducts;
        draft.product = product;
        draft.addressId = addressId;
        draft.b2bDiscountCode = b2bDiscountCode;
        draft.currentCartProduct = currentCartProduct;
        draft.promoCodes = promoCodes;
        draft.giftCardCodes = giftCardCodes;
        draft.instant_confirmation = instant_confirmation;
        draft.find_for_me = find_for_me;
        draft.currentOption = currentOption;
        break;
      case SET_CART_PRODUCT:
        const { cartProduct } = action.payload;
        draft.cartProducts[cartProduct.id] = cartProduct;
        break;
      case DELETE_CART_PRODUCT:
        const { id } = action.payload;

        if (draft.cartProducts[id]) {
          delete draft.cartProducts[id];
        }

        break;
      default:
        return draft;
    }
  });
}
