/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/** **************************************************
class Example extends React.Component {
  constructor(props){
    super(props);

    this.emailChange = this.emailChange.bind(this);

    this.state = {
      isValid: false,
      email: '',
    };
  }

  emailChange({ isValid, email }){
    this.setState({ isValid, email });
  }

  render() {
    return (
      <EmailInput email={this.state.email} onEmailChange={ this.emailChange }
      className={`form-control ${this.state.isValid ? 'valid' : 'invalid'}`} />
    );
  }
}
**************************************************** */
import React from 'react';
import PropTypes from 'prop-types';

class EmailInput extends React.Component {
  constructor(props) {
    super(props);
    this.emailChange = this.emailChange.bind(this);
  }

  emailChange(e) {
    const email = e.target.value;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(email).toLowerCase());

    this.props.onEmailChange({ email, isValid });
  }

  render() {
    return (
      <input
        type="text"
        placeholder={this.props.placeholder}
        value={this.props.email}
        onChange={this.emailChange}
        className={this.props.className}
      />
    );
  }
}

EmailInput.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
};

export default EmailInput;
