
import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get, map, find, filter } from 'lodash';
import CTAButton from '../../Shared/CTAButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/Remove';
import ExpandMore from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import { applySubscription } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import ErrorModal from '../../Shared/ErrorModal';
import { defaultErrorMessage } from '../../../constants';
import Drawer from '@material-ui/core/Drawer';
import Close from '@material-ui/icons/Close'
import { setAlreadyShownSoothePassPopup } from '../../../Actions'
import { ADDITIONAL_INFO } from '../Shared/constants';
import { seMembershipContinue, seMembershipSkip, seMembershipUpsellView, seMembershipView, seViewSoothePassFAQ } from '../../Shared/WebAnalytics';

class SubscriptionPlanPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedIndex: null,
            error: '',
            showErrorModal: false,
            width: window.innerWidth,
            showSmallSizeModal: get(props, "withoutToaster", false)
        }
        this.popupHeader = this.popupHeader.bind(this);
        this.planDetails = this.planDetails.bind(this);
        this.additionalInfo = this.additionalInfo.bind(this);
        this.popupFooter = this.popupFooter.bind(this);
        this.redeemPointsAd = this.redeemPointsAd.bind(this);
        this.faqSection = this.faqSection.bind(this);
        this.subscribeToPlan = this.subscribeToPlan.bind(this);
        this.displayViewBasedOnWindowWidth = this.displayViewBasedOnWindowWidth.bind(this);
        this.learnMoreDrawer = this.learnMoreDrawer.bind(this);
        this.fullSizePopup = this.fullSizePopup.bind(this);
        this.smallSizePopup = this.smallSizePopup.bind(this);
        this.laterAndLearnMore = this.laterAndLearnMore.bind(this);
        this.additionalInfoList = this.additionalInfoList.bind(this);
        this.additionalInfoGrids = this.additionalInfoGrids.bind(this);
        this.redeemPointsAdList = this.redeemPointsAdList.bind(this);
        this.redeemPointsAdGrids = this.redeemPointsAdGrids.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });

        let soothePassObj = get(this.props, "booking.cart.info_fields.soothe_pass", null),
            alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
            subscription_membership_ends_date = get(this.props, "client.soothe_pass.subscription_membership_ends_date", "");
        if (soothePassObj && soothePassObj.available && !alreadyAppliedToCart && !soothePassObj.subscribed && !subscription_membership_ends_date) {
            let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", null),
                subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", null);
            if (this.state.width > 1100) {
                seMembershipUpsellView(subscription_tier_name, subscription_tier_id, "booking_23_control", get(this.props, "booking.cart.address.market", ""));
                seMembershipView(subscription_tier_name, subscription_tier_id);
            } else if (this.state.showSmallSizeModal) {
                seMembershipView(subscription_tier_name, subscription_tier_id);
            } else {
                seMembershipUpsellView(subscription_tier_name, subscription_tier_id, "booking_23_control", get(this.props, "booking.cart.address.market", ""));
            }
        }
    }
    subscribeToPlan() {
        let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", null),
            subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", null),
            cartId = get(this.props, "booking.cart.id", "");
        if (subscription_tier_id && cartId) {
            applySubscription(cartId, { soothe_subscription_tier_id: subscription_tier_id }, (response) => {
                seMembershipContinue(subscription_tier_name, subscription_tier_id)
                this.props.assignToCart({ cart: decode(response.data.cart) });
                this.props.showHideModal(false);
                this.props.goToNextStep();
            }, (err) => {
                this.setState({ error: get(err, 'response.data.errors.0.message', defaultErrorMessage), showErrorModal: true })
                this.props.showHideModal(false);
            })
        }
    }
    closeModal() {
        let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", null),
            subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", null);
        seMembershipSkip(subscription_tier_name, subscription_tier_id)
        this.props.setAlreadyShownSoothePassPopup(true);
        this.props.showHideModal(false);
    }
    popupHeader(addBg = false) {
        let soothePassObj = get(this.props, "booking.cart.info_fields.soothe_pass", null);
        return (<div className={`br-radius-top ${addBg ? 'radial-bg' : ''}`}>
            <div className='plt-16'>
                <a className='size-18-24 cursor-pointer' onClick={() => {
                    this.closeModal();
                }}>
                    <Close style={{ color: "#000000", fontSize: "24px" }} />
                </a>
            </div>
            <div className={`max-width-60 pb-24`}>
                <div className='size-36-44 color-black medium-font sm-size-32-40'>
                    {addBg ? soothePassObj.header :
                        soothePassObj.header.split('\n').map((item, key) => {
                            return <div key={key}>{item}</div>
                        })}
                </div>
                <div className='size-20-28 color-black sm-size-18-28'>{soothePassObj.tagline}</div>
            </div>
        </div>)
    }
    planDetails(soothePassObj) {
        let { subscription_tier_name, subscription_tier_price, subscription_points } = soothePassObj
        return (<div className='max-width-60'>
            <div className='size-28-36 color-black medium-font mb-16'>{subscription_tier_name}</div>
            <div className='size-16-24 color-black mb-16'>{subscription_tier_price} for {subscription_points} points</div></div>)
    }
    additionalInfoList() {
        return <div className='max-width-60 p-50-0'>
            {map(ADDITIONAL_INFO, (info, it) => (<div key={`list-info-${it}`} className={'row pb-24'}>
                {it % 2 === 0 ? null : <div className='col-xs-3 txt-center'><img className='' src={info.imgSrc} alt={info.title} /></div>}
                <div className='col-xs-9'>
                    <div className='size-16-20 color-black medium-font mb-4'>{info.title}</div>
                    <div className='size-12-20 color-black'>{info.description}</div>
                </div>
                {it % 2 === 0 ? <div className='col-xs-3 txt-center'><img className='' src={info.imgSrc} alt={info.title} /></div> : null}

            </div>))}
        </div>
    }
    additionalInfoGrids() {
        let isFullSize = Boolean(this.state.width > 1100)
        return <div className={`row ${isFullSize ? 'p-50-0' : 'max-width-60'}`}>
            {map(ADDITIONAL_INFO, (info, it) => (<div key={`info-${it}`} className={`col-xs-12 ${isFullSize ? 'col-sm-3' : 'col-sm-6'} col-sm-lg-3 txt-center`}>
                <div className='mb-20'><img src={info.imgSrc} alt={info.title} /></div>
                <div className='size-16-20 color-black medium-font mb-4'>{info.title}</div>
                <div className={`size-12-20 color-black ${isFullSize ? '' : 'p-16'}`}>{info.description}</div>
            </div>))}
        </div>
    }
    additionalInfo() {
        if (this.state.width > 800) {
            return this.additionalInfoGrids();
        }
        return this.additionalInfoList();
    }
    popupFooter(soothePassObj) {
        let { subscription_tier_name, subscription_tier_price, subscription_points } = soothePassObj
        return (<CTAButton text="Add to cart" addon={{ content: (`${subscription_tier_name}, ${subscription_points} points`), direction: "left", subContent: (subscription_tier_price) }}
            additionalWrapperClass={this.state.width > 800 ? "fixed-white-bg" : ""}
            action={() => {
                this.subscribeToPlan();
            }}
            relevantId="addToCartSubscriptionButton"
        />)
    }
    redeemPointsAdList(redeem_points_advertisement) {
        return (map(redeem_points_advertisement, (ad, it) => (
            <div key={`ad-${it}`} className="row">
                <div className='size-24-32 color-black medium-font col-xs-4'>{ad.title || ""}</div>
                <div className='size-14-20 color-black col-xs-8'>{ad.description || ""}</div>
            </div>
        )))
    }
    redeemPointsAdGrids(redeem_points_advertisement) {
        return (<div className='max-width-60 row'>
            {map(redeem_points_advertisement, (ad, it) => (
                <div key={`ad-${it}`} className="col">
                    <div className='size-24-32 color-black medium-font'>{ad.title || ""}</div>
                    <div className='size-14-20 color-black'>{ad.description || ""}</div>
                </div>
            ))}
        </div>)
    }
    redeemPointsAd(soothePassObj) {
        let adsList = get(soothePassObj, "redeem_points_advertisement", []),
            redeem_points_advertisement = filter(adsList, (ad) => (ad.title)),
            disclaimer = find(adsList, (ad) => (!ad.title));
        if (!isEmpty(redeem_points_advertisement)) {
            let content;
            if (this.state.width > 1100) {
                content = this.redeemPointsAdList(redeem_points_advertisement);
            } else {
                content = this.redeemPointsAdGrids(redeem_points_advertisement);
            }
            return (<div className={this.state.width > 1100 ? '' : 'light-gray-bg'}>
                <div className='max-width-60 p-50-0'>
                    <div className='size-24-32 color-black medium-font mb-24 sm-mb-16'>Redeem points</div>
                    {content}
                    <div className='size-14-20 color-black'>{get(disclaimer, "description", "")}</div>
                </div>
            </div>)
        }
    }
    faqSection(soothePassObj) {
        let { faq } = soothePassObj,
            { openedIndex } = this.state;
        if (!isEmpty(faq)) {
            return (<div className='max-width-60 p-50-0'>
                <div className='size-24-32 color-black medium-font mb-24 sm-mb-16'>Frequently asked questions</div>
                <List component='nav' aria-labelledby='nested-list-subheader'>
                    {faq.map((doc, ind) => {
                        return (
                            <div key={ind}>
                                <ListItem button key={`question-${ind}`} onClick={() => {
                                    if (openedIndex === ind) {
                                        this.setState({ openedIndex: -1 })
                                    } else {
                                        if (openedIndex === null) {
                                            let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", null),
                                                subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", null);
                                            seViewSoothePassFAQ(subscription_tier_name, subscription_tier_id);
                                        }
                                        this.setState({ openedIndex: ind })
                                    }
                                }}>
                                    <ListItemText primary={doc.question} />
                                    {openedIndex === ind ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse
                                    key={`collapse-${ind}`}
                                    in={openedIndex === ind}
                                    timeout='auto'
                                    unmountOnExit
                                >
                                    {doc.answer}
                                </Collapse>
                                <Divider />
                            </div>
                        );
                    })}
                </List>
            </div>)
        }
    }
    smallSizePopup() {
        let soothePassObj = get(this.props, "booking.cart.info_fields.soothe_pass", null);
        if (this.state.showSmallSizeModal) {
            return (<div className='mimic-modal-wrapper'>
                <div className='mimic-modal-content' id="subscriptionPopup">
                    <div className='plt-16'>
                        <a className='size-18-24 cursor-pointer' onClick={() => {
                            this.setState({ showSmallSizeModal: false })
                            this.closeModal();
                        }} id="closeSubscriptionPopup">
                            <Close style={{ color: "#000000", fontSize: "24px" }} />
                        </a>
                    </div>
                    <div className='white-bg'>
                        <div className='pb-100'>
                            {this.planDetails(soothePassObj)}
                            {this.additionalInfo()}
                            {this.redeemPointsAd(soothePassObj)}
                            {this.faqSection(soothePassObj)}
                        </div>
                        {this.popupFooter(soothePassObj)}
                    </div>
                </div>
            </div>);
        }
    }
    laterAndLearnMore() {
        return (<div className='mb-36 txt-center'>
            <button className='btn light-btn' onClick={() => {
                this.closeModal();
                this.props.goToNextStep();
            }}>Maybe later</button>
            <button className='btn dark-btn' onClick={() => {
                let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", null),
                    subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", null);
                seMembershipView(subscription_tier_name, subscription_tier_id);
                this.setState({ showSmallSizeModal: true })
            }}>Learn more</button>
        </div>)
    }
    learnMoreDrawer() {
        return (<div><Drawer style={{ zIndex: 999999999999 }} anchor={'bottom'} open={!this.state.showSmallSizeModal} onClose={() => {
            this.closeModal();
        }}>
            <div className='radial-bg p-0-16'>
                {this.popupHeader()}
                {this.laterAndLearnMore()}
            </div>
        </Drawer>
            {this.smallSizePopup()}
        </div>)

    }
    fullSizePopup() {
        let soothePassObj = get(this.props, "booking.cart.info_fields.soothe_pass", null);
        return (<div className='mimic-modal-wrapper'>
            <div className='mimic-modal-content'>
                {this.popupHeader(true)}
                <div className='max-width-60 pb-100'>
                    {this.additionalInfo()}
                    <div className='row'>
                        <div className='col-sm-6'>
                            {this.faqSection(soothePassObj)}
                        </div>
                        <div className='col-sm-6'>
                            {this.redeemPointsAd(soothePassObj)}
                        </div>
                    </div>
                </div>
                {this.popupFooter(soothePassObj)}
            </div>
        </div>)
    }
    displayViewBasedOnWindowWidth() {
        if (this.state.width < 1100) {
            return this.learnMoreDrawer();
        } else {
            return this.fullSizePopup();
        }
    }
    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: '' }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }

    render() {
        let soothePassObj = get(this.props, "booking.cart.info_fields.soothe_pass", null),
            alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
            subscription_membership_ends_date = get(this.props, "client.soothe_pass.subscription_membership_ends_date", "");
        if (!soothePassObj || !soothePassObj.available || alreadyAppliedToCart || soothePassObj.subscribed || subscription_membership_ends_date) {
            return null;
        }
        return (<>
            {this.displayViewBasedOnWindowWidth()}
            {this.errorModal()}
        </>)
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    bookingFlow: state.bookingFlow,
    client: state.client
});

export default withLocalize(connect(mapStateToProps, { setAlreadyShownSoothePassPopup })(SubscriptionPlanPopup));