
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import axios from 'axios';
import { Elements } from 'react-stripe-elements';
import { setCreditCards, setCart } from '../../../Actions';
import { defaultErrorMessage, ACTIVE_LANGUAGE_CODE, US, DE, hasUuid } from '../../../constants';
import { decode } from '../Shared/encode';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import TextWithIcon from '../../Shared/TextWithIcon';
import CardIcon from './Assets/Images/Card_icon.png';
import ErrorModal from '../../Shared/ErrorModal';
import CreditCardsModal from '../../BookingFlow/Payment/CreditCards/CreditCardsModal';
import AddCreditCardModal from '../../BookingFlow/Payment/CreditCards/AddCreditCardModal';
import _, { get } from 'lodash';
import moment from 'moment';
import '../../BookingFlow/Payment/Assets/Styles/Payments.css';
import { updateCart } from '../Shared/helpers';
import { seBookingSummary } from '../../Shared/WebAnalytics';

class PaymentMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creditCard: null,
            height: 300,
            showCreditCards: false,
            showNewCreditCard: false,
            showErrorModal: false,
            error: null
        }
        this.setCart = this.setCart.bind(this);
        this.setCartCreditCard = this.setCartCreditCard.bind(this);
        this.load = this.load.bind(this);
        this.getCardDetails = this.getCardDetails.bind(this);
        this.reload = this.reload.bind(this);
        this.deleteCreditCard = this.deleteCreditCard.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.errorModal = this.errorModal.bind(this);
    }
    componentDidMount() {
        this.load("firstLoad");
        // modal margin top + bottom = 140
        // modal padding top + bottom = 40
        // title height with margin = 28
        if (typeof this.parentRef !== 'undefined') {
            const height = this.parentRef.clientHeight - 208 - 100;
            this.setState({ height });
        }
    }

    setCart(value) {
        this.props.setCart({
            ...this.props.booking,
            ...value
        })
    }

    setCartCreditCard(creditCardId) {
        let cartId = get(this.props, "booking.cart.id", "");
        if (this.props.setLoaderFlag) {
            this.props.setLoaderFlag(true)
        }
        updateCart(cartId, {
            "credit_card_id": creditCardId,
            "b2b_refresh": hasUuid()
        }, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
            let creditCard;
            this.props.assignToCart({ cart: decode(response.data.cart) });

            if (this.props.creditCards) { creditCard = _.find(this.props.creditCards, (cc) => (cc.id === this.props.booking.cart.billing.credit_card.id)) }


            if (creditCard) {
                this.setState({ creditCard });
            }
            this.setState({ showCreditCards: false })

            if (this.props.setLoaderFlag) {
                this.props.setLoaderFlag(false)
            }
        }, (error) => {

            if (this.props.setLoaderFlag) {
                this.props.setLoaderFlag(false)
            }
            if (error.response.status !== 401) {
                this.setState({ showErrorModal: true, error: get(error, 'response.data.errors.0.message', defaultErrorMessage) });
            }
        })
    }

    load(firstTime="") {
        const userId = this.props.client.user_id;
        if (this.props.setLoaderFlag) {
            this.props.setLoaderFlag(true)
        }
        axios.get(`${API_ROOT}/users/${userId}/credit_cards`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
            let creditCards = response.data.credit_cards;
            let { errors } = response.data;
            this.props.setCreditCards(creditCards);
            let creditCard, creditCardId;

            if (creditCard === undefined && creditCards.length > 0) {
                creditCard = _.find(creditCards, (cc) => { return cc.default; });

                if (creditCard) {
                    creditCardId = _.result(creditCard, 'id');
                } else {
                    creditCard = _.first(creditCards);
                    creditCardId = _.result(creditCard, 'id');
                }

                this.setState({ creditCard }, () => {
                    this.setCartCreditCard(creditCardId);
                });
                return true;
            } else if (creditCards.length === 0) {
                this.setState({ creditCard: null });
            } else if (errors) {
                console.log(errors)
            }
            if (firstTime === "firstLoad") {
                let cart = get(this.props, "booking.cart", null);
                if (cart) {
                    let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", ""),
                        subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", "");
                    seBookingSummary(cart, get(this.props, "booking.product.title", "").toLowerCase(), get(this.props, "client.first_time_booker", false), subscription_tier_name, subscription_tier_id, "booking_23_control", creditCards && creditCards.length ? "Saved credit card" : "None");
                }
            }

            this.setState({ showNewCreditCard: false });
            if (this.props.setLoaderFlag) {
                this.props.setLoaderFlag(false)
            }
        }).catch((error) => {
            if (this.props.setLoaderFlag) {
                this.props.setLoaderFlag(false)
            }
            console.log(error)
        });
    }


    reload(id) {
        this.load();
        const creditCardId = id || this.props.booking.cart.billing.credit_card.id || this.state.creditCard.id;

        this.setCartCreditCard(creditCardId);
        this.setState({ showNewCreditCard: false }, this.load);
    }

    deleteCreditCard(index) {
        const creditCard = this.props.creditCards[index];

        if (creditCard) {
            axios.delete(`${API_ROOT}/credit_cards/${creditCard.id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
                const { result, success } = resp.data;

                if (result || success) {
                    this.reload();
                } else {
                    const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
                    this.setState({ showErrorModal: true, error: message });
                }
            }).catch((error) => {
                const message = _.result(_.first(error.response.data.errors || {}), 'message') || _.result(error.response.data.errors || {}, 'message') || defaultErrorMessage;
                this.setState({ showErrorModal: true, error: message });
            });
        }
    }

    renderModal() {
        const fonts = [{ src: 'url("https://www.soothe.com/static/media/GothamHTF-Book.48680770.otf")', family: 'gotham' }];
        let countryCode = ACTIVE_LANGUAGE_CODE === DE ? DE : US;

        if (this.state.showNewCreditCard) {
            return (
                <Elements locale={countryCode} fonts={fonts}>
                    <AddCreditCardModal
                        setLoaderFlag={this.props.setLoaderFlag}
                        prev={() => { this.setState({ showNewCreditCard: false }) }}
                        close={() => { this.setState({ showNewCreditCard: false, showCreditCards: false }) }}
                        reload={this.reload}
                        showAddCreditCard={this.state.showNewCreditCard}
                    />
                </Elements>
            );
        } else if (this.state.showCreditCards) {
            return (
                <Elements locale={countryCode} fonts={fonts}>
                    <CreditCardsModal
                        creditCardId={get(this.props, "booking.cart.billing.credit_card.id", null)}
                        addNewCreditCard={() => { this.setState({ showNewCreditCard: true }); }}
                        height={this.state.height}
                        close={() => { this.setState({ showCreditCards: false }); }}
                        onChange={(creditCardId, creditCard) => { this.setState({ creditCard }, () => { this.setCartCreditCard(creditCardId) }) }}
                        reload={this.reload}
                    />
                </Elements>
            );
        }
    }

    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
                {this.state.error}
            </ErrorModal>
        )
    }

    getCardDetails() {
        if (this.state.creditCard) {
            return (<div>{this.state.creditCard.type} ending in {this.state.creditCard.last_4}<br />Exp. {moment(`${this.state.creditCard.exp_month}/${this.state.creditCard.exp_date}`).format("M[/]YY")}</div>)
        }

        return "Add Payment Method";
    }

    render() {
        const card = this.state.creditCard;
        return (<>
            <div className='clickable' onClick={() => {
                if (!_.isEmpty(card)) {
                    this.setState({ showCreditCards: true });
                }
                else {
                    this.setState({ showNewCreditCard: true });
                }
            }} id="paymentMethod">
                <TextWithIcon
                    icon={CardIcon}
                    title={"Payment method"}
                    details={this.getCardDetails()}
                    displayBorder={true}
                    displayArrow={true}
                /></div>
            <div ref={element => { this.parentRef = element; }} className='payments-redesign'>
                {this.renderModal()}
            </div>
            {this.errorModal()}
        </>
        )
    }
}
const mapStateToProps = state => ({
    creditCards: state.creditCards,
    booking: state.booking,
    addresses: state.addresses,
    client: state.client,
    bookingFlow: state.bookingFlow,
    creditCard: state.creditCard,
    fieldsHolder: state.fieldsHolder
});

PaymentMethod.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    creditCards: PropTypes.array.isRequired,
    setCreditCards: PropTypes.func.isRequired,
    setCart: PropTypes.func.isRequired
};

export default withLocalize(connect(mapStateToProps, { setCreditCards, setCart })(PaymentMethod));
