import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, get, isEmpty, map, findLastKey, difference, forEach, intersection, filter, includes, union, size } from 'lodash';
import { setCart, setBookingFlowPreviousStep, setAvailableTherapists } from '../../../Actions';
import { STEPS } from '../Shared/constants';
import OptionsCarousel from './OptionsCarousel';
import CTAButton from '../../Shared/CTAButton';
import SessionLength from './SessionLength';
import ProviderGender from './ProviderGender';
import '../Assets/Styles/index.css';
import './Assets/Styles/ModalityStep.css';
import RecipientName from './RecipientName';
import { updateCartProduct, removeSubscription, addCartProduct } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage, hasUuid } from '../../../constants';
import { seBookingDetails } from '../../Shared/WebAnalytics';
import Slide from "@material-ui/core/Slide";

HotJar();

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ctaDisabled: true,
            cartProductsObject: {},
            submitted: false
        };
        this.goToNextStep = this.goToNextStep.bind(this);
        this.listAppointmentsByClient = this.listAppointmentsByClient.bind(this);
        this.submitOptionsAndNext = this.submitOptionsAndNext.bind(this);
        this.updateCallBack = this.updateCallBack.bind(this);
        this.updateOptionsByCartProduct = this.updateOptionsByCartProduct.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.updateClientName = this.updateClientName.bind(this);
        this.updateCTAStatus = this.updateCTAStatus.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.checkRequiredOptionIds = this.checkRequiredOptionIds.bind(this);
        this.setDefaultValues = this.setDefaultValues.bind(this);
        this.showRelevantPrices = this.showRelevantPrices.bind(this);
        this.afterTherapistsCheckAction = this.afterTherapistsCheckAction.bind(this);
        this.addRebookCartProduct = this.addRebookCartProduct.bind(this);
        this.showOneLinePrice = this.showOneLinePrice.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        let rebook = get(this.props, "booking.cart.rebook", false);
        if (!rebook) {
            let previousStep = find(STEPS, (step) => (step.id === 'RECIPIENT'));
            this.props.setBookingFlowPreviousStep(previousStep);
            this.initializeState();
        } else {
            let cartProducts = get(this.props, "booking.cart.cartProducts", []);
            this.props.changeBackground("");
            this.props.toggleNavBarStyle(false);
            let previousStep = find(STEPS, (step) => (step.id === 'SERVICE'));
            this.props.setBookingFlowPreviousStep(previousStep);
            if (isEmpty(cartProducts)) {
                this.addRebookCartProduct()
            } else {
                this.initializeState();
            }
        }
    }
    addRebookCartProduct() {
        let loader = this.props.setLoaderFlag;
        if (loader) {
            loader(true);
        }
        let product_id = get(this.props, "booking.product.id", ""),
            cartId = get(this.props, "booking.cart.id", "");
        addCartProduct(cartId, { product_id, client_name: get(this.props,"client.first_name","") }, (resp) => {
            this.props.assignToCart({ cart: decode(resp.data.cart) });
            this.initializeState();
            if (loader) {
                loader(false);
            }
        }, (err) => {
            console.log({ err })
            if (loader) {
                loader(false);
            }
        })
    }
    errorHandler(err) {
        this.props.setLoaderFlag(false);
        this.setState({ submitted: false });
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
    }
    goToNextStep() {
        let nextStep = find(STEPS, (step) => (step.id === "TIMING"));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    setDefaultValues(cartProductsObject) {
        let cp_info_fields = get(this.props, "booking.cart.info_fields.cart_products", []),
            cpObjectTemp = cartProductsObject;
        forEach(cartProductsObject, (cpObj, cpId) => {
            let prdOpIds = map(get(this.props, "product.product_options", []), (pOpObj) => (pOpObj.id)),
                option_ids = get(cpObj, "option_ids", []),
                prdOs = get(find(cp_info_fields, (ci) => (`${ci.id}` === cpId)), "product.product_options", []);
            if (!isEmpty(prdOs)) {
                prdOs = filter(prdOs, (p) => (includes(prdOpIds, p.id) && !isEmpty(p.options.default)));
                forEach(prdOs, (pOs) => {
                    if (isEmpty(intersection(option_ids, pOs.options.allowed_values))) {
                        cpObjectTemp[cpId]['option_ids'] = union(get(cpObjectTemp, `${cpId}.option_ids`, []), pOs.options.default);
                    }
                })
            }
        })
        this.setState({ cartProductsObject: cpObjectTemp }, () => {
            this.updateCTAStatus();
        })
    }
    initializeState() {
        let cartProducts = get(this.props, "booking.cart.cartProducts", []),
            cartProductsObject = this.state.cartProductsObject;
        if (!isEmpty(cartProducts)) {
            map(cartProducts, (cp) => {
                if (cp) {
                    cartProductsObject[cp.id] = {
                        ["client_name"]: cp.client_name,
                        option_ids: cp.option_ids
                    }
                }
            })
            this.setDefaultValues(cartProductsObject);
        }
    }
    checkRequiredOptionIds(cpObj, cpId) {
        let prdOpIds = map(get(this.props, "product.product_options", []), (pOpObj) => (pOpObj.id));
        let option_ids = get(cpObj, "option_ids", []),
            cp_info_fields = get(this.props, "booking.cart.info_fields.cart_products", []);
        let prdOs = get(find(cp_info_fields, (ci) => (`${ci.id}` === cpId)), "product.product_options", []);
        if (!isEmpty(prdOs)) {
            prdOs = filter(prdOs, (p) => (includes(prdOpIds, p.id) && p.options.required));
            forEach(prdOs, (pOs) => {
                if (isEmpty(intersection(option_ids, pOs.options.allowed_values))) {
                    this.setState({ ctaDisabled: true });
                    return false;
                }
            })
        }
    }
    updateCTAStatus(skipp = false) {
        let cartProductsObject = get(this.state, "cartProductsObject", {}),
            prdId = get(this.props, "product.id", "");
        this.setState({ ctaDisabled: false }, () => {
            if (!isEmpty(cartProductsObject) && prdId) {
                forEach(cartProductsObject, (cpObj, cpId) => {
                    if (isEmpty(cpObj.client_name) || isEmpty(cpObj.option_ids)) {
                        this.setState({ ctaDisabled: true });
                        return false;
                    } else {
                        return this.checkRequiredOptionIds(cpObj, cpId);
                    }
                })
            }
            if (!skipp) {
                this.submitOptionsAndNext(true);
            }
        });
    }
    updateOptionsByCartProduct(cartProductId, optionId, allowedValues, required) {
        let cartProductsObject = get(this.state, "cartProductsObject", {});
        let option_ids = get(cartProductsObject, `${cartProductId}.option_ids`, []);
        let newArray = difference(option_ids, allowedValues);
        newArray.push(optionId);
        cartProductsObject[cartProductId]["option_ids"] = newArray;
        this.setState({ cartProductsObject }, () => {
            if (required) {
                this.updateCTAStatus();
            }
        });
    }
    updateClientName(cartProductId, clientName) {
        let cartProductsObject = get(this.state, "cartProductsObject", {});
        cartProductsObject[cartProductId]["client_name"] = clientName;
        this.setState({ cartProductsObject }, () => this.updateCTAStatus(true));
    }
    afterTherapistsCheckAction(providers) {
        this.props.setAvailableTherapists(providers);
        this.props.setLoaderFlag(false);
        this.setState({ submitted: false });
        this.goToNextStep();
    }
    updateCallBack(response, skipNext = false) {
        this.props.assignToCart({ cart: decode(response.data.cart) });
        if (!skipNext) {
            let alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
                cartId = get(this.props, "booking.cart.id", "");
            if (alreadyAppliedToCart && cartId) {
                removeSubscription(cartId, (response_2) => {
                    this.props.assignToCart({ cart: decode(response_2.data.cart) });
                });
            }
            let cart = get(this.props, "booking.cart", null),
                cartProducts = get(cart, "cartProducts", []);
            if (!isEmpty(cartProducts)) {
                forEach(cartProducts, (cp) => {
                    seBookingDetails(cart, cp, this.props.booking.product.title.toLowerCase(), this.props.client.first_time_booker, "booking_23_control", size(cartProducts), get(cp,"subtitle",""), get(this.props,"client.loggedIn", false), get(this.props,"booking.cart.address.market", "") )
                });
            }
            this.afterTherapistsCheckAction([]);
        }
    }
    submitOptionsAndNext(skipNext = false) {
        let cartId = get(this.props, "booking.cart.id", "");
        let { cartProductsObject } = this.state;
        if (!isEmpty(cartProductsObject)) {
            let lastKey = findLastKey(cartProductsObject);
            if (!skipNext) {
                this.props.setLoaderFlag(true);
                this.setState({ submitted: true });
            }
            map(cartProductsObject, (cpObj, cpId) => {
                updateCartProduct(cartId, cpId, {
                    "option_ids": cpObj.option_ids,
                    "client_name": cpObj.client_name || ""
                }, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
                    if (lastKey === cpId) {
                        this.updateCallBack(response, skipNext);
                    }
                }, this.errorHandler)
            });
        }
    }
    listAppointmentsByClient() {
        let cart_products = get(this.props, "booking.cart.cartProducts", []),
            cartProductsObject = this.state.cartProductsObject,
            rebook = get(this.props, "booking.cart.rebook", false);
        if (!isEmpty(cartProductsObject)) {
            return map(cart_products, (cp, it) => {
                let option_ids = get(cartProductsObject[cp.id], "option_ids", []);
                return (<div key={`cp-${it}`} className="mb-40">
                    <div className='medium-font size-44-52 txt-center sm-txt-left color-black mb-40'>Customize appointment{size(cart_products)===1 ? null : ` #${it + 1}`}</div>
                    <OptionsCarousel optionIds={option_ids} updateOption={(optionId, allowedValues, required) => this.updateOptionsByCartProduct(cp.id, optionId, allowedValues, required)} prdIndex={it}/>
                    <SessionLength optionIds={option_ids} updateOption={(optionId, allowedValues, required) => this.updateOptionsByCartProduct(cp.id, optionId, allowedValues, required)} prdIndex={it}/>
                    {rebook ? null : <>
                    <ProviderGender optionIds={option_ids} updateOption={(optionId, allowedValues, required) => this.updateOptionsByCartProduct(cp.id, optionId, allowedValues, required)} prdIndex={it}/>
                    <RecipientName defaultValue={get(cartProductsObject[cp.id], "client_name", "")} updateClientName={(clientName) => this.updateClientName(cp.id, clientName)} prdIndex={it}/> </>}
                </div>)
            })
        }
    }
    showRelevantPrices() {
        let mmbrTotal = 0, nonMmbrTotal = 0,
            cartProducts = filter(get(this.props, "booking.cart.cartProducts", []), (el) => (get(el, "cart_product_main_option.member_price", ""))),
            isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
            isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false) && !hasUuid(),
            currSymbol = get(this.props, "booking.cart.currency_symbol", "$");
        if (!isEmpty(cartProducts)) {
            map(cartProducts, (cp) => {
                mmbrTotal += get(cp, "cart_product_main_option.raw_member_price", 0);
                nonMmbrTotal += get(cp, "cart_product_main_option.raw_price", 0);
            })
            if (!isSoothePassAvailable && (!mmbrTotal || !isSoothePassSubscribed) && nonMmbrTotal) {
                return { content: (`${currSymbol}${nonMmbrTotal}`), direction: "left" }
            }
            if (isSoothePassSubscribed) {
                return { content: (`${currSymbol}${mmbrTotal} Member`), direction: "left", subContent: (`${currSymbol}${nonMmbrTotal} NonMember`) }
            }
            return { content: (`${currSymbol}${nonMmbrTotal} NonMember`), direction: "left", subContent: (`${currSymbol}${mmbrTotal} Member`) }
        }
    }
    showOneLinePrice() {
        let mmbrTotal = get(this.props, "booking.cart.cartProducts.0.cart_product_main_option.raw_member_price", 0),
            nonMmbrTotal = get(this.props, "booking.cart.cartProducts.0.cart_product_main_option.raw_price", 0),
            rebook = get(this.props, "booking.cart.rebook", false),
            isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
            isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false) && !hasUuid(),
            currSymbol = get(this.props, "booking.cart.currency_symbol", "$");
        if (rebook) {
            if (!isSoothePassAvailable && (!mmbrTotal || !isSoothePassSubscribed) && nonMmbrTotal) {
                return { content: (`${currSymbol}${nonMmbrTotal}`), direction: "left" }
            }
            if (isSoothePassSubscribed) {
                return { content: (`${currSymbol}${mmbrTotal}`), direction: "left" }
            }
            return { content: (`${currSymbol}${nonMmbrTotal}`), direction: "left" }
        }
    }
    render() {
        let { ctaDisabled } = this.state,
            rebook = get(this.props, "booking.cart.rebook", false);
        return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div className='display-flex'>
                <div className={rebook ? "max-w-50-vw" : 'max-width-55'}>
                    <div className={rebook ? 'background-primary border-radius-16 pt-24' : ""}>
                        <div className='p-0-60-24'>{this.listAppointmentsByClient()}</div>
                        <CTAButton text="Continue" disabled={ctaDisabled} addon={rebook ? this.showOneLinePrice() : this.showRelevantPrices()}
                            submitted={this.state.submitted}
                            action={this.submitOptionsAndNext}
                            additionalClass={rebook ? "full-width-btn" : ""}
                            relevantId="continueButton"
                        />
                    </div>
                </div>
            </div>
        </Slide>)
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    product: state.booking.product,
    fieldsHolder: state.fieldsHolder
});

Index.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    setCart: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { setCart, setBookingFlowPreviousStep, setAvailableTherapists })(Index);