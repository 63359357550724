/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Translate } from 'react-localize-redux';
import { capitalize, upperCase } from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function RenderPricingCards(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let slides;
  const { subscriptionTiers } = props;

  if (subscriptionTiers) {
    slides = subscriptionTiers.map((st, index) => (
      <Translate key={index}>
        { ({ translate }) => (
          <div className={`card ${subscriptionTiers && subscriptionTiers.length > 2 ? 'four-cards' : ''}`} key={index}>
            <h3 className={`st-badge ${st.subscription_tier.best_value ? 'best-value' : ''}`}>
              <span style={{ display: `${st.subscription_tier.best_value ? 'inline' : 'none'}` }}>&#9733;&nbsp;&nbsp;</span>
              { upperCase(st.subscription_tier.pricing[props.pricingIndex].badge) }
&nbsp;
              <span style={{ display: `${st.subscription_tier.best_value ? 'inline' : 'none'}` }}>&nbsp;&nbsp;&#9733;</span>
            </h3>
            <h3 className="minute-value">{ st.subscription_tier.title }</h3>
            <div className="pricing-container">
              <h1 className="subscription_price">{ st.subscription_tier.pricing[props.pricingIndex].price_per_month }</h1>
            </div>

            <div className="gray-divider" />

            <div className="value-prop-head">
              <span>
                { capitalize(translate('global.save')) }
                {' '}
                { st.subscription_tier.percentage }
                %
              </span>
              {' '}
              { translate('soothePlusIndex.valueProps20.prop1') }
            </div>
            <div className="value-prop-head">{ st.subscription_tier.billing_frequency }</div>
            <div className="value-prop-head">{ translate('soothePlusIndex.valueProps20.prop3') }</div>
            <div className="terms-and-conditions">
              <a href={translate('routes.terms')}>{ translate('navigation.termsAndConditions') }</a>
            </div>

            <input id="subscription-length-input" name="session_length" type="hidden" value="60" />
            <input
              type="button"
              className="button"
              value={capitalize(translate('global.subscribe'))}
              onClick={(e) => props.selectSubscription(
                e,
                subscriptionTiers[index],
                props.pricingIndex,
              )}
            />
          </div>
        )}
      </Translate>
    ));
  } else {
    slides = (
      <Translate>
        { ({ translate }) => (
          <div className="card-placeholder-div">
            <div className="card">
              <h3 className="minute-value minute-padding">Soothe Plus</h3>
              <div className="pricing-container">
                <h1 className="subscription_price">- - -</h1>
              </div>

              <div className="gray-divider" />

              <div className="value-prop-head">
                <span>
                  { capitalize(translate('global.save')) }
                  {' '}
                  %
                </span>
                {' '}
                { translate('soothePlusIndex.valueProps20.prop1') }
              </div>
              <div className="value-prop-head">{ translate('soothePlusIndex.valueProps20.prop2') }</div>
              <div className="value-prop-head">{ translate('soothePlusIndex.valueProps20.prop3') }</div>
              <div className="terms-and-conditions">
                <a href={translate('routes.terms')}>{ translate('navigation.termsAndConditions') }</a>
              </div>

              <input id="subscription-length-input" name="session_length" type="hidden" value="60" />
              <input
                type="submit"
                className="button"
                value={capitalize(translate('global.subscribe'))}
                onClick={() => props.handleEnterZipModal()}
              />
            </div>

            <div className="card card-mobile-hide">
              <h3 className="minute-value minute-padding">Soothe Plus</h3>
              <div className="pricing-container">
                <h1 className="subscription_price">- - -</h1>
              </div>

              <div className="gray-divider" />

              <div className="value-prop-head">
                <span>
                  { capitalize(translate('global.save')) }
                  {' '}
                  %
                </span>
                {' '}
                { translate('soothePlusIndex.valueProps20.prop1') }
              </div>
              <div className="value-prop-head">{ translate('soothePlusIndex.valueProps25.prop2') }</div>
              <div className="value-prop-head">{ translate('soothePlusIndex.valueProps20.prop3') }</div>
              <div className="terms-and-conditions">
                <a href={translate('routes.terms')}>{ translate('navigation.termsAndConditions') }</a>
              </div>

              <input id="subscription-length-input" name="session_length" type="hidden" value="60" />
              <input
                type="submit"
                className="button"
                value={capitalize(translate('global.subscribe'))}
                onClick={() => props.handleEnterZipModal()}
              />
            </div>
          </div>
        )}
      </Translate>
    );
  }

  if (props.width > 1279) {
    return (
      <div className="cards">
        { slides }
      </div>
    );
  }
  return (
    <div className="cards cards-mobile">
      <Slider {...settings}>
        { slides }
      </Slider>
    </div>
  );
}

export default function SubTierPricingSection(props) {
  return (
    <Translate>
      { ({ translate }) => (
        <div className="section details">
          <div className="section-inner">
            <h2 className="pricing-h2" id="pricing-section">{ translate('global.servicePricing') }</h2>

            <div className="in-minutes">
              <div onClick={(e) => { e.preventDefault(); props.handlePricingZip(); }}>
                { translate('soothePlusIndex.inMinutes') }
                {' '}
                <br />
                { props.zipCode
                  && `${translate('soothePlusIndex.ratesFor')} ${props.zipCode}   `}
                { props.zipCode
                  && (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePricingZip(() => window.location.href = '#pricing');
                    }}
                    href="#zipCode"
                    id="update-zip-code"
                  >
                    { capitalize(translate('global.edit')) }
                  </a>
                  )}
                { !props.zipCode
                  && (
                  <a
                    href="#zipCode"
                    id="update-zip-code"
                    onClick={() => window.location.href = '#pricing'}
                  >
                    Enter Zip/Postal code for exact pricing
                  </a>
                  )}
              </div>
            </div>

            { props.renderPricingOptions() }

            <RenderPricingCards
              subscriptionTiers={props.subscriptionTiers}
              handlePricingZip={props.handlePricingZip}
              pricingIndex={props.pricingIndex}
              selectSubscription={props.selectSubscription}
              width={props.width}
              handleEnterZipModal={props.handleEnterZipModal}
            />

            <div className="p-disclaimer">
              { translate('soothePlusIndex.pricingDisclaimer') }
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
}
