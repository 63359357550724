/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { assign } from 'lodash';
import { SET_FIELD } from '../Actions';

export default function (state = {}, action) {
  switch (action.type) {
    case SET_FIELD:
      if (action.payload) {
        return assign(state, action.payload || {});
      }
      return state;

    default:
      return state;
  }
}
