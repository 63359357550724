/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ArrowUpward } from '@material-ui/icons';
import { first, get, size } from 'lodash';
import './Inbox.scss';
import { connect } from 'react-redux';
import { sendMessage } from './Shared/helpers';
import { seInboxSendMessage } from '../Shared/WebAnalytics';
import { decode } from '../AppointmentManagement/Shared/encode';
import { setAppointment, fetchChat } from '../../Actions';
import { defaultErrorMessage } from '../../constants';

class MessageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      stillSending: false,
    };
    this.sendMessageAndRefresh = this.sendMessageAndRefresh.bind(this);
  }

  sendMessageAndRefresh() {
    const currentMsgs = get(this.props, 'currentChat.chats', []);
    const newestMessageId = get(first(currentMsgs), 'message_id', '');
    const { stillSending } = this.state;
    if (stillSending) return;
    this.setState({ stillSending: true });
    sendMessage(get(this.props, 'currentChat.chat_id', ''), this.state.message, (resp) => {
      this.setState({ message: '', stillSending: false });
      seInboxSendMessage(size(get(resp, 'data.cart.display_helpers.v0.cart_products', [])));
      this.props.setAppointment(decode(get(resp, 'data.cart', null)));
      this.props.fetchChat(get(resp, 'data', null));
      if (Number(get(this.props, 'currentChat.chat_id', '')) !== Number(newestMessageId)) {
        this.props.reloadMessages();
      }
    }, (err) => {
      this.setState({ message: '', stillSending: false });
      this.props.showError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      this.props.reloadMessages();
    });
  }

  render() {
    return (
      <div className={`relative-input-wrapper ${this.state.message ? 'show' : ''}`}>
        <input
          className="chat-text-input"
          placeholder="Write a message"
          value={this.state.message}
          onChange={(evt) => {
            this.setState({ message: get(evt, 'target.value', '') });
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              this.sendMessageAndRefresh();
            }
          }}
        />
        <button
          type="button"
          className="btn chat-input-send-btn"
          onClick={this.sendMessageAndRefresh}
        >
          <ArrowUpward className="chat-input-send-icon contentInversePrimary font-weight-bold" />
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  chats: state.chats,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, { setAppointment, fetchChat })(MessageInput);
