/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Add, Remove } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { setCart } from '../../../Actions';

class QuantityInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { quantity } = this.props;
    return (
      <div className="ptb-20 br-b-opaque d-flex-only justify-content-spaced align-items-center">
        <div className="size-16-20 color-black medium-font">Quantity</div>
        <div>
          <button
            type="button"
            className="btn p-10-12 br-rd-36 size-14-16 bg-gray-100 contentPrimary"
            disabled={quantity === 1}
            onClick={() => {
              if (quantity === 2) {
                this.props.updateQty(quantity - 1);
              }
            }}
            id="quantityMinus"
          >
            <Remove />
          </button>
          <span className="size-18-24 font-weight-bold contentPrimary p-0-16 centered">{quantity}</span>
          <button
            type="button"
            className="btn p-10-12 br-rd-36 size-14-16 bg-gray-100 contentPrimary"
            disabled={quantity === 2}
            onClick={() => {
              if (quantity === 1) {
                this.props.updateQty(quantity + 1);
              }
            }}
            id="quantityPlus"
          >
            <Add />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  product: state.booking.product,
});

export default connect(mapStateToProps, { setCart })(QuantityInput);
