/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { SET_CREDIT_CARDS } from '../Actions';

export default function (state = [], action) {
  switch (action.type) {
    case SET_CREDIT_CARDS:
      return action.payload;
    default:
      return state;
  }
}
