/* eslint-disable no-return-assign */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
// * Do Not Remove!! Used for FAQ Collapse/Toggle
import bootstrap from 'bootstrap';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../../Translations/soothePlus.json';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { setSubscription, setSubscriptionView } from '../../../Actions';
import { SOOTHE_PLUS_DEFAULT, VIEWS } from '../Constants';
import { HotJar, ROUTES } from '../../../constants';
import { membershipSelection } from '../../Shared/WebAnalytics';
import HeroSection from './HeroSection';
import SubTierPricingSection from './SubTierPricingSection';
import FaqSection from './FaqSection';
import NotReadySection from './NotReadySection';
import NavBar from '../../Shared/NavBar';
import ErrorModal from '../../Shared/ErrorModal';
import TextAppLink from '../../Shared/TextAppLink';
import '../Assets/Styles/Index.css';

HotJar();

class SubscriptionLanding extends React.Component {
  constructor(props) {
    super(props);

    this.getSubscriptionsByZip = this.getSubscriptionsByZip.bind(this);
    this.getZipFromGeoip = this.getZipFromGeoip.bind(this);
    this.updateZipCode = this.updateZipCode.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
    this.renderAreaNotServicedModal = this.renderAreaNotServicedModal.bind(this);
    this.renderEnterZipcodeModal = this.renderEnterZipcodeModal.bind(this);
    this.renderPricingOptions = this.renderPricingOptions.bind(this);
    this.selectSubscription = this.selectSubscription.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handlePricingZip = this.handlePricingZip.bind(this);
    this.handleEnterZipModal = this.handleEnterZipModal.bind(this);

    this.state = {
      width: window.innerWidth,
      zipCode: '',
      errors: '',
      pricingIndex: 0,
      subscriptionTiers: null,
      showErrorModal: false,
      enterZipcodeModal: false,
    };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.props.setSubscription({ ...SOOTHE_PLUS_DEFAULT });
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.scrollTo(0, 0);
    this.getZipFromGeoip();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  setSubscription(value) {
    this.props.setSubscription({
      ...this.props.subscription,
      ...value,
    });
  }

  renderAreaNotServicedModal() {
    if (this.state.errors !== '' && this.state.zipCode !== '') {
      return (
        <ErrorModal
          isOpen={this.state.showErrorModal}
          close={() => { this.setState({ showErrorModal: false }); }}
        >
          <div>{ this.state.errors }</div>
        </ErrorModal>
      );
    }
  }

  renderEnterZipcodeModal() {
    return (
      <Translate>
        { ({ translate }) => (
          <ErrorModal
            isOpen={this.state.enterZipcodeModal}
            close={() => {
              this.setState(
                { enterZipcodeModal: false },
                () => window.location.href = '#pricing',
              );
            }}
          >
            <div>{ translate('soothePlusIndex.enterZipcode') }</div>
          </ErrorModal>
        )}
      </Translate>
    );
  }

  getZipFromGeoip() {
    axios.get(`${API_ROOT}/geoip_zip_code`, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { geoip_postal_code } = response.data;

      if (geoip_postal_code) {
        this.setState({ zipCode: geoip_postal_code }, this.getSubscriptionsByZip);
      } else {
        this.setState({ zipCode: '' });
      }
    });
  }

  getSubscriptionsByZip() {
    axios.get(`${API_ROOT}/no_auth_subscriptions/?zip_code=${this.state.zipCode}`, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { subscription_tiers, errors } = response.data;

      if (subscription_tiers) {
        this.setState({ subscriptionTiers: subscription_tiers, errors: '' });
      } else {
        this.setState({ errors: errors.message, showErrorModal: true });
      }

      this.setSubscriptionsData();
    });
  }

  updateZipCode(e) {
    this.setState({ zipCode: e.target.value });
  }

  setSubscriptionsData() {
    if (this.state.zipCode) window.location.href = '#pricing-section';
  }

  selectSubscription(e, subscriptionTier, index) {
    e.preventDefault();

    const subscriptionTierPricing = subscriptionTier.subscription_tier.pricing[index];
    let id; let percentage; let
      title;

    ({ id, percentage, title } = subscriptionTier.subscription_tier);

    const { minutes } = subscriptionTierPricing;
    const subscriptionTierId = id;

    membershipSelection(subscriptionTierId, title, id, `${percentage}%`, minutes);

    const srch = queryString.stringify({
      zipCode: this.state.zipCode,
      subscription_tier_id: id,
      minutes,
    });
    this.props.history.push(`${ROUTES.soothePlusSubscription}?${srch}`);
  }

  renderPricingOptions() {
    const sixtyActive = this.state.pricingIndex === 0 ? 'active' : '';
    const ninetyActive = this.state.pricingIndex === 1 ? 'active' : '';
    const oneTwentyActive = this.state.pricingIndex === 2 ? 'active' : '';

    return (
      <div className="">
        <div className="lengths">
          <p
            onClick={(e) => {
              e.preventDefault();
              this.setState({ pricingIndex: 0 }, this.setSubscriptionsData);
            }}
            className={`first ${sixtyActive}`}
          >
            60
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              this.setState({ pricingIndex: 1 }, this.setSubscriptionsData);
            }}
            className={`middle ${ninetyActive}`}
          >
            90
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              this.setState({ pricingIndex: 2 }, this.setSubscriptionsData);
            }}
            className={`last ${oneTwentyActive}`}
          >
            120
          </p>
        </div>
      </div>
    );
  }

  handlePricingZip(cb) {
    if (cb) {
      this.setState({ zipCode: '', subscriptionTiers: null }, cb);
    } else {
      this.setState({ zipCode: '', subscriptionTiers: null });
    }
  }

  handleEnterZipModal() {
    this.setState({ enterZipcodeModal: true });
  }

  render() {
    return (
      <div>
        <NavBar />
        <div id="soothe-plus">

          <HeroSection
            zipCode={this.state.zipCode}
            updateZipCode={this.updateZipCode}
            getSubscriptionsByZip={this.getSubscriptionsByZip}
          />

          <div className="memberships">
            <SubTierPricingSection
              zipCode={this.state.zipCode}
              handlePricingZip={this.handlePricingZip}
              subscriptionTiers={this.state.subscriptionTiers}
              renderPricingOptions={this.renderPricingOptions}
              pricingIndex={this.state.pricingIndex}
              selectSubscription={this.selectSubscription}
              width={this.state.width}
              handleEnterZipModal={this.handleEnterZipModal}
            />

            <NotReadySection />

            <FaqSection />

            <TextAppLink user="client" />

            { this.renderAreaNotServicedModal() }
            { this.renderEnterZipcodeModal() }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  subscription: state.subscription,
  subscriptionView: state.subscriptionView,
});

SubscriptionLanding.propTypes = {
  client: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  setSubscription: PropTypes.func.isRequired,
  setSubscriptionView: PropTypes.func.isRequired,
};

export default withLocalize(connect(
  mapStateToProps,
  { setSubscription, setSubscriptionView },
)(SubscriptionLanding));
