
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { defaultErrorMessage } from '../../../constants';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { setCart } from '../../../Actions';
import { decode } from '../Shared/encode';
import CenteredGrids from '../../Shared/CenteredGrids';
import ModalRedesign from '../../Shared/ModalRedesign';
import ErrorModal from '../../Shared/ErrorModal';
import Modal from '../../Shared/Modal';
import { get, isEmpty, map, round, result, first, isNumber } from 'lodash';

class TipPerPerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustomTipModal: false,
            amount: null,
            maxAmount: null,
            maxAmountMessage: '',
            percentage_of_original: null,
            original_price: "",
            currency_symbol: "$",
            error: '',
            showErrorModal: false,
            showMaxAmountMessage: false,
            promptIsOpen: false,
            promptMessage: ""

        }
        this.setInitialValues = this.setInitialValues.bind(this);
        this.setCart = this.setCart.bind(this);
        this.getTipOptions = this.getTipOptions.bind(this);
        this.handleTipRateClick = this.handleTipRateClick.bind(this);
        this.handleCustomTipClick = this.handleCustomTipClick.bind(this);
        this.submitAmount = this.submitAmount.bind(this);
        this.errorModal = this.errorModal.bind(this);
        this.promptModal = this.promptModal.bind(this);
    }
    componentDidMount() {
        this.setInitialValues();
    }

    componentDidUpdate(prevProps) {
        if (get(prevProps, "cartProduct.tip.original_price", "") !== get(this.props, "cartProduct.tip.original_price", "")) {
            this.setInitialValues()
        }
    }
    setInitialValues() {
        let cp = this.props.cartProduct;
        let submitRecalculation = false;
        if (!isEmpty(cp)) {
            let { max_rate, percentage_of_original, original_price, amount, currency_symbol } = cp.tip;
            let maxAmount, maxAmountMessage;
            if (max_rate) {
                maxAmount = max_rate * Number(original_price);
                maxAmountMessage = `You can not tip more than ${max_rate * 100}% of your total. Please adjust.`;
            }
            if (!percentage_of_original && maxAmount && amount && amount > maxAmount) {
                amount = maxAmount;
                submitRecalculation = true;
                this.setState({ promptIsOpen: true, promptMessage: `The maximum tip amount is ${max_rate * 100}% of your total. The tip added for ${cp.client_name}'s Provider is now set to ${amount}${currency_symbol}` });
            }
            this.setState({ amount, percentage_of_original, original_price, currency_symbol, maxAmount, maxAmountMessage }, () => {
                if (submitRecalculation) {
                    this.submitAmount();
                }
            });
        }
    }

    promptModal() {
        return (<Modal title="Maximum tip exceeded" isOpen={this.state.promptIsOpen} close={() => { this.setState({ promptIsOpen: false, promptMessage: "" }); }}
            showBtn={true} btnCopy="Close">
            <p>{this.state.promptMessage}</p>
        </Modal>);
    }

    setCart(value) {
        this.props.assignToCart(value)
    }

    getTipOptions(tipObj) {
        let { can_tip } = tipObj;
        let { percentage_of_original, amount } = this.state;
        if (can_tip) {
            let arr;
            let { currency_symbol, rate_1, rate_2, rate_3, original_price } = tipObj;
            arr = map([rate_1, rate_2, rate_3], (rate) => {
                let amount2 = round(rate * Number(original_price), 2).toFixed(2);
                return {
                    title: `${rate * 100}%`,
                    subtitle: `${currency_symbol}${amount2}`,
                    value: { amount: amount2, rate },
                    action: this.handleTipRateClick,
                    selected: percentage_of_original === rate,
                    relevantId: `${this.props.elIndex === 1 ? "" : "second-"}tip-${rate * 100}`
                }
            })
            arr.push({
                title: `Other`,
                subtitle: isNumber(amount) && !percentage_of_original ? `${currency_symbol}${amount || 0}` : "",
                action: this.handleCustomTipClick,
                selected: isNumber(amount) && !percentage_of_original,
                relevantId: this.props.elIndex === 1 ? "other" : "secondOther"
            })
            return arr;
        }
        return [];

    }
    handleTipRateClick({ amount, rate }) {
        let cb = this.submitAmount;
        if (this.state.percentage_of_original === rate) {
            this.setState({ amount: null, percentage_of_original: null }, cb);
        } else {
            this.setState({ amount, percentage_of_original: rate }, cb);
        }
    }
    handleCustomTipClick() {
        let { amount, percentage_of_original } = this.state;
        if (isNumber(amount) && !percentage_of_original) {
            this.setState({ amount: null }, this.submitAmount);
        } else {
            this.setState({ showCustomTipModal: true, percentage_of_original: null, amount: 0 }, ()=>this.submitAmount(true));
        }
    }
    customTipModal() {
        let cpId = this.props.cartProduct.id;
        return (
            <ModalRedesign title={"Other tip"} isOpen={this.state.showCustomTipModal} close={() => { this.setState({ showCustomTipModal: false, showMaxAmountMessage: false }); }} apply={() => {
                this.setState({ showCustomTipModal: false, percentage_of_original: null, showMaxAmountMessage: false });
                this.submitAmount();
            }} relevantId={this.props.elIndex === 1 ? "customTipPopup" : "secondCustomTipPopup"} relevantBtnId={this.props.elIndex === 1 ? "applyCustomTip" : "secondApplyCustomTip"}>
                <div className="form-group">
                    <label htmlFor={this.props.elIndex === 1 ? "customTipInput" : "secondCustomTipInput"} className="custom-tip-label poppins-medium">Amount</label>
                    <div className="input-group tip-custom-input-group">
                        <div className="input-group-addon tip-currency-addon">
                            <span className="input-group-text">{get(this.state, "currency_symbol", "$")}</span>
                        </div>
                        <input type="number" max={`${this.state.maxAmount}`} className="form-control tip-custom-input" id={this.props.elIndex === 1 ? "customTipInput" : "secondCustomTipInput"}
                            value={this.state.amount || ""}
                            placeholder={0}
                            onChange={(event) => {
                                event.preventDefault();
                                let amount = Number(event.target.value), maximumAmount = this.state.maxAmount;
                                if (maximumAmount && amount > maximumAmount) {
                                    this.setState({ showMaxAmountMessage: true, amount: maximumAmount })
                                }
                                else {
                                    this.setState({ amount, showMaxAmountMessage: false });
                                }
                            }} />
                    </div>
                    {this.state.showMaxAmountMessage ? <div className='negative-msg'>{this.state.maxAmountMessage || ""}</div> : null}
                </div>
            </ModalRedesign>
        )
    }
    submitAmount(skipLoad=false) {
        let cartId = get(this.props, "booking.cart.id", "");
        let { amount, percentage_of_original } = this.state;

        let tip;
        if (!skipLoad) {
            this.props.setLoaderFlag(true);
        }
        if (percentage_of_original) {
            tip = {
                amount,
                percentage_of_original
            };
        }
        else {
            tip = { amount: amount || 0 };
        }

        axios.post(`${API_ROOT}/v7/carts/${cartId}/cart_products/${this.props.cartProduct.id}/tip`, {
            tip
        }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
            let res = get(response.data, 'result');

            if (res) {
                this.props.assignToCart({ cart: decode(response.data.cart) });
            } else {
                let message = result(first(response.data.errors || {}), 'message') || result(response.data.errors || {}, 'message') || response.data.errors[0].message || defaultErrorMessage;
                this.setState({ showErrorModal: true, error: message });
            }

            this.props.setLoaderFlag(false);

        }).catch((e) => {
            this.props.setLoaderFlag(false);
            if (e.response.data.errors) {
                let message = result(first(e.response.data.errors || {}), 'message') || result(e.response.data.errors || {}, 'message') || e.response.data.errors[0].message || defaultErrorMessage;
                this.setState({ showErrorModal: true, error: message });
            }
        });

    }
    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: '' }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }

    render() {
        let cp = this.props.cartProduct;
        if (!isEmpty(cp)) {
            return (
                <div>
                    <CenteredGrids
                        title={`For ${cp.client_name}'s Provider`}
                        grids={this.getTipOptions(cp.tip)}
                    />
                    {this.customTipModal()}
                    {this.errorModal()}
                    {this.promptModal()}
                </div>
            )
        }
        return null;
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

TipPerPerson.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps, { setCart })(TipPerPerson));
