/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import {
  map, isEmpty, get, find, size, filter, partition, union,
} from 'lodash';
import moment from 'moment-timezone';
import Slide from '@material-ui/core/Slide';
import { removeProFromList } from '../../Shared/Helpers';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage } from '../../../constants';
import {
  seBookingPAPView, seBookingPAPConfirm,
  sePickAProSelect, sePickAProUnselect,
} from '../../Shared/WebAnalytics';
import CTAButton from '../../Shared/CTAButton';
import { setBookingFlowStep, setCart, setBookingFlowPreviousStep } from '../../../Actions';
import '../Assets/Styles/index.css';
import './Assets/Styles/ProviderPickStep.css';
import ProCard from './ProCard';
import { STEPS } from '../Shared/constants';
import { removePro, requestPro } from '../Shared/helpers';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      currentStepId: 'TIMING',
      width: window.innerWidth,
      selectedProviders: [],
      instantConfirm: null,
    };
    this.goToNextStep = this.goToNextStep.bind(this);
    this.listAllPros = this.listAllPros.bind(this);
    this.removeProAndNext = this.removeProAndNext.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.skipButton = this.skipButton.bind(this);
    this.hasPAP2 = this.hasPAP2.bind(this);
    this.showHideCTA = this.showHideCTA.bind(this);
    this.addPro = this.addPro.bind(this);
    this.isProSelected = this.isProSelected.bind(this);
    this.defaultRemoveIC = this.defaultRemoveIC.bind(this);
    this.removeProFromSelection = this.removeProFromSelection.bind(this);
    this.submitList = this.submitList.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    const relevantPrev = currStepId === 'PICKAPRO2' ? 'PICKAPRO' : 'TIMING';
    const providers = get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', []);
    const icSize = size(filter(providers, (ell) => (ell.isIC)));
    const notCouples = size(get(this.props, 'booking.cart.cartProducts', [])) === 1;
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const previousStep = find(STEPS, (step) => (step.id === relevantPrev));
    this.props.setBookingFlowPreviousStep(previousStep);
    this.props.changeBackground(null);
    seBookingPAPView(size(providers), get(cart, 'id', ''), 'booking_23_variant', notCouples ? 'False' : this.hasPAP2() ? 'Same_Time' : 'Back_to_Back', {
      market: get(cart, 'address.market', ''),
      number_of_ga_pros: size(providers) - icSize,
      number_of_ic_pros: icSize,
      lead_time_selected: bkngDate.diff(nowDate, 'hours'),
      is_today: bkngDate.isSame(nowDate, 'day'),
      click_source: get(this.props, 'bookingFlow.comingFromStep', 'TIMING') === 'TIMING' ? 'Date_Screen' : 'Review_Screen',
      test_name: "jul_2024_booking_enhancements",
      test_value: get(this.props, "jul_2024_booking_enhancements", "")
    });
    this.defaultRemoveIC();
    this.setState({ currentStepId: get(this.props, 'bookingFlow.step.id', ''), providers, selectedProviders: get(this.props, 'booking.cart.cartProducts.0.pap_therapist_preferences', []) });
  }

  defaultRemoveIC() {
    const curr = get(this.props, 'booking.cart.cartProducts.0.pap_therapist_preferences', []);
    const ln = size(curr);
    const isAlreadyConfirmed = get(this.props, 'booking.instant_confirmation', false);
    if (ln === 1 && isAlreadyConfirmed) {
      const cartId = get(this.props, 'booking.cart.id', '');
      const cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
      removePro(cartId, cartProductId, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
        this.props.assignToCart({
          cart: decode(response.data.cart),
          find_for_me: false,
          instant_confirmation: false,
        });
      });
    }
  }

  removeProFromSelection(therapist, subIt) {
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
    const loader = this.props.setLoaderFlag;
    const isIC = get(therapist, 'isIC', false);
    if (isIC) {
      if (loader) {
        loader(true);
      }
      removeProFromList(cartId, cartProductId, therapist.id, (response) => {
        this.props.assignToCart({ cart: decode(response.data.cart), find_for_me: false });
        sePickAProUnselect({
          ab_test_booking_flow: 'new_flow', market: get(this.props, 'booking.cart.address.market', ''), list_position: subIt + 1, rating_value: get(therapist, 'rating', 0), bookings_count: get(therapist, 'number_of_bookings', 0),
          test_name: "jul_2024_booking_enhancements",
          test_value: get(this.props, "jul_2024_booking_enhancements", "")
        });
        if (loader) {
          loader(false);
        }
      }, () => {
        if (loader) {
          loader(false);
        }
      });
    } else {
      this.setState({ selectedProviders: filter(this.state.selectedProviders, (el) => (el.id !== get(therapist, 'id', ''))) });
      sePickAProUnselect({
        ab_test_booking_flow: 'new_flow', market: get(this.props, 'booking.cart.address.market', ''), list_position: subIt + 1, rating_value: get(therapist, 'rating', 0), bookings_count: get(therapist, 'number_of_bookings', 0),
        test_name: "jul_2024_booking_enhancements",
        test_value: get(this.props, "jul_2024_booking_enhancements", "")
      });
    }
  }

  hasPAP2() {
    const backToBackAvailable = get(this.props, 'booking.cart.backToBack.available', false);
    const backToBackEnabled = get(this.props, 'booking.cart.backToBack.enabled', false);
    return backToBackAvailable && !backToBackEnabled;
    // we're not allowing pap2 for back to back.. users can always use rebook if any
  }

  errorHandler(err) {
    const loader = this.props.setLoaderFlag;
    if (loader) {
      loader(false);
    }
    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
  }

  goToNextStep(nextStepId = 'REVIEW') {
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    nextStepId = currStepId === 'PICKAPRO' && this.hasPAP2() ? 'PICKAPRO2' : nextStepId;
    const nextStep = find(STEPS, (step) => (step.id === nextStepId));
    this.props.setBookingFlowStepThroughParent(nextStep);
  }

  removeProAndNext() {
    const cartId = get(this.props, 'booking.cart.id', '');
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    const cartProductId = currStepId === 'PICKAPRO2' ? get(this.props, 'booking.cart.cartProducts.1.id', '') : get(this.props, 'booking.cart.cartProducts.0.id', '');
    removePro(cartId, cartProductId, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      this.props.assignToCart({ cart: decode(response.data.cart), find_for_me: true });
      this.goToNextStep();
    }, this.errorHandler);
  }

  skipButton() {
    return (<div>
      <div className="contentPrimary size-24-32 medium-font mr-4 mb-16">Book with first available</div>
      <div className={`br-rd-16 sm-mb-16 ${this.state.width < 800 ? "" : "p-16 pro-card br-opaque"}`}>
        <div className="size-12-20 mb-10">Send a request to all qualified Providers in our network who match your preferences.</div>
        <button
          type="button"
          className="btn md-cta-quaternary width-auto"
          onClick={this.removeProAndNext}
        >
          Find a Provider for me
        </button>
      </div>
    </div>
    );
  }

  listAllPros() {
    const { providers } = this.state;
    if (!isEmpty(providers)) {
      const [trueSet, falseSet] = partition(providers, (item) => item.isIC);
      const gaSize = size(falseSet);
      const gaAllowed = get(this.props, "booking.cart.info_fields.ga_pap_enabled", false);
      let counter = -1;
      return (
        <>
          <div className="medium-font size-44-52 txt-center sm-size-28-36 color-black mb-4 mt-24 sm-txt-left">Select your provider{gaAllowed ? "s" : ""}</div>
          {this.state.width < 800 ? (
            <div className="contentPrimary size-20-28 medium-font mb-40">
              {get(this.props, 'booking.cart.date.display', '')}
              ,
              {' '}
              {get(this.props, 'booking.cart.time.display', '')}
            </div>
          )
            : !gaAllowed ? <div className='mb-20'></div>
              : <div className="contentTertiary size-18-28 centered mb-28">The more providers you request, the faster your request will get accepted! You'll be confirmed with the first one who accepts.</div>}
          <div className="row">
            {isEmpty(trueSet) ? null :
              <div className="col-xs-12 mb-24">
                <div className='mb-4 d-flex-only align-items-center'>
                  <div className="contentPrimary size-24-32 medium-font mr-4">Instant Book</div>
                  <div className='label-xs-lavender-ash'>Recommended</div>
                </div>
                <div className="contentSecondary size-12-20">Your booking will be instantly accepted by the Provider you select.</div>
              </div>}
            {map(trueSet, (pro, subIt) => (
              <div key={`therapist-${subIt}-ic`} className="col-xs-12 mb-32 sm-mb-16 col-sm-4">
                <ProCard
                  therapist={pro}
                  errorHandler={this.errorHandler}
                  width={this.state.width}
                  updateParentState={(ther) => this.addPro(ther, subIt)}
                  selected={this.isProSelected(pro.id, true)}
                  removeProFromList={(ther) => this.removeProFromSelection(ther, subIt)}
                />
              </div>
            ))}
          </div>
          <div className='row'>
            <div className={`col-xs-12 mb-32 sm-mb-16 ${!gaAllowed && isEmpty(trueSet) ? "col-sm-8" : "col-sm-4"}`}>
              {this.skipButton()}
            </div>
          </div>
          {gaAllowed ? <div className="row">
            {isEmpty(falseSet) ? null :
              <div className="col-xs-12 mb-24">
                <div className='mb-4 contentPrimary size-24-32 medium-font'>Request to Book</div>
                <div className="contentSecondary size-12-20">Book by sending a request to Providers. Your booking will be confirmed if a Provider accepts.</div>
              </div>}
            {map(falseSet, (pro, subIt) => {
              if (gaSize > 3) {
                counter += 1;
              }
              return (
                <div key={`therapist-${subIt}-ga`} className="display-contents">
                  <div className="col-xs-12 mb-32 sm-mb-16 col-sm-4">
                    <ProCard
                      therapist={pro}
                      errorHandler={this.errorHandler}
                      width={this.state.width}
                      updateParentState={(ther) => this.addPro(ther, subIt + size(trueSet))}
                      selected={this.isProSelected(pro.id, false)}
                      removeProFromList={(ther) => this.removeProFromSelection(
                        ther,
                        subIt + size(trueSet),
                      )}
                    />
                  </div>
                </div>
              );
            })}
          </div> : null}
        </>
      );
    }
    return null;
  }

  isProSelected(proId, isIC) {
    if (isIC) {
      return !isEmpty(find(get(this.props, 'booking.cart.cartProducts.0.pap_therapist_preferences', []), (el) => (el.id === proId)));
    }
    return !isEmpty(find(this.state.selectedProviders, (el) => (el.id === proId)));
  }

  addPro(therapist, subIt) {
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
    const loader = this.props.setLoaderFlag;
    const isIC = get(therapist, 'isIC', false);
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const sessionDate = moment(get(this.props, 'booking.cart.date.utc', '')).format('YYYY-MM-DD');
    if (isIC) {
      if (loader) {
        loader(true);
      }
      requestPro(cartId, cartProductId, {
        pro_ids: [get(therapist, 'id', '')],
        remove_enhancements: true,
        session_time: moment(`${sessionDate} ${get(cart, 'time.display', '')}`, 'YYYY-MM-DD hh:mm A').format('HH:mm'),
        remove_therapist_preferences: Boolean(isIC),
        session_date: sessionDate,
      }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
        if (loader) {
          loader(false);
        }
        this.props.assignToCart({
          cart: decode(response.data.cart),
          instant_confirmation: Boolean(isIC),
          find_for_me: false,
        });
        sePickAProSelect({
          ab_test_booking_flow: 'new_flow', market: get(this.props, 'booking.cart.address.market', ''), list_position: subIt + 1, rating_value: get(therapist, 'rating', 0), bookings_count: get(therapist, 'number_of_bookings', 0), is_ic: Boolean(isIC),
          test_name: "jul_2024_booking_enhancements",
          test_value: get(this.props, "jul_2024_booking_enhancements", "")
        });
        if (isIC) {
          seBookingPAPConfirm(therapist.id || '', subIt || 0, true, cartId, {
            ab_test_booking_flow: 'new_flow',
            market: get(this.props, 'booking.cart.address.market', ''),
            is_ic: true,
            number_of_ga_pros: size(filter(get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', []), (ell) => (!ell.isIC))),
            number_of_ga_pros_selected: 0,
            lead_time_selected: bkngDate.diff(nowDate, 'hours'),
            is_today: bkngDate.isSame(nowDate, 'day'),
            click_source: get(this.props, 'bookingFlow.comingFromStep', 'TIMING') === 'TIMING' ? 'Date_Screen' : 'Review_Screen',
            test_name: "jul_2024_booking_enhancements",
            test_value: get(this.props, "jul_2024_booking_enhancements", "")
          });
          this.goToNextStep();
        }
      }, this.errorHandler);
    } else {
      const selectedProviders = this.state.selectedProviders;
      this.setState({ selectedProviders: union(selectedProviders, [therapist]) });
      sePickAProSelect({
        ab_test_booking_flow: 'new_flow', market: get(this.props, 'booking.cart.address.market', ''), list_position: subIt + 1, rating_value: get(therapist, 'rating', 0), bookings_count: get(therapist, 'number_of_bookings', 0), is_ic: Boolean(isIC),
        test_name: "jul_2024_booking_enhancements",
        test_value: get(this.props, "jul_2024_booking_enhancements", "")
      });
    }
  }

  submitList() {
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
    const loader = this.props.setLoaderFlag;
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const sessionDate = moment(get(this.props, 'booking.cart.date.utc', '')).format('YYYY-MM-DD');
    const availablePros = get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', []);
    const reqPros = this.state.selectedProviders;
    if (loader) {
      loader(true);
    }
    requestPro(cartId, cartProductId, {
      pro_ids: map(reqPros, (ell) => (ell.id)),
      remove_enhancements: true,
      session_time: moment(`${sessionDate} ${get(cart, 'time.display', '')}`, 'YYYY-MM-DD hh:mm A').format('HH:mm'),
      remove_therapist_preferences: false,
      session_date: sessionDate,
    }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      if (loader) {
        loader(false);
      }
      this.props.assignToCart({
        cart: decode(response.data.cart),
        instant_confirmation: false,
        find_for_me: false,
      });

      seBookingPAPConfirm(size(reqPros) === 1 ? get(reqPros, '0.id', '') : '', 'n/a', true, get(cart, 'id', ''), {
        ab_test_booking_flow: 'new_flow',
        market: get(cart, 'address.market', ''),
        is_ic: false,
        number_of_ga_pros: size(filter(availablePros, (pp) => (!pp.isIC))),
        number_of_ga_pros_selected: size(reqPros),
        lead_time_selected: bkngDate.diff(nowDate, 'hours'),
        is_today: bkngDate.isSame(nowDate, 'day'),
        click_source: get(this.props, 'bookingFlow.comingFromStep', 'TIMING') === 'TIMING' ? 'Date_Screen' : 'Review_Screen',
        test_name: "jul_2024_booking_enhancements",
        test_value: get(this.props, "jul_2024_booking_enhancements", "")
      });
      this.goToNextStep();
    }, this.errorHandler);
  }

  showHideCTA() {
    const ln = size(this.state.selectedProviders);
    const availablePros = get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', []);
    const isOnlyOneIC = get(find(availablePros, (elt) => elt.id === get(this.state, 'selectedProviders.0.id', '')), 'isIC', false);
    if (ln && !(ln === 1 && isOnlyOneIC)) {
      if (this.state.width < 800) {
        return (
          <div className="d-flex-only align-items-center justify-content-end bg-primary br-t-opaque shadow-top fixed-btm">
            <div className="p-12-16">
              <div><span className="tip-label mr-4 mb-12">Pro Tip</span></div>
              <div className="mb-24 contentPrimary size-16-24">Appointment requests with at least 5 Providers are more likely to be accepted.</div>
              <button
                type="button"
                className="btn md-cta-primary"
                onClick={() => {
                  this.submitList();
                }}
              >
                Request {ln} Provider{ln > 1 ? "s" : ""}
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="d-flex-only align-items-center justify-content-end bg-primary br-t-opaque shadow-top fixed-btm">
          <div className="contentPrimary size-16-20">
            <span className="tip-label mr-4">Pro Tip</span>
            Appointment requests with at least 5 Providers are more likely to be accepted.
          </div>
          <CTAButton
            text={`Request ${ln} Provider${ln > 1 ? "s" : ""}`}
            additionalWrapperClass="br-top-none-imp"
            action={() => {
              this.submitList();
            }}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <Slide direction="up" in mountOnEnter unmountOnExit>
        <div className="display-flex">
          <div className="maxW-75-vw m-auto sm-w-100 sm-p-24-16">
            <div className="sm-mb-150">
              {this.listAllPros()}
            </div>

            {this.showHideCTA()}
          </div>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  product: get(state, 'booking.product', null),
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {
  setBookingFlowStep,
  setCart,
  setBookingFlowPreviousStep,
})(Index);
