import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import '../index.css'

function Success() {

  const container = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('./Lottie_success.json')
    })
  }, [])
  
  return (
    <div className="App">
      <div className="lottie-small-container" ref={container}></div>
    </div>
  );
}

export default Success;