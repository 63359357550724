/* eslint-disable camelcase */
/* eslint-disable max-len */
export const SET_CART = 'SET_CART';
export const SET_CART_PRODUCT = 'SET_CART_PRODUCT';
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT';

export function setCart({
  cart, cartProducts, product, addressId, b2bDiscountCode, currentCartProduct, address, instant_confirmation, find_for_me, currentOption,
}) {
  return {
    type: SET_CART,
    payload: {
      cart, cartProducts, product, addressId, b2bDiscountCode, currentCartProduct, address, instant_confirmation, find_for_me, currentOption,
    },
  };
}

export function setCartProduct({ cartProduct }) {
  return {
    type: SET_CART_PRODUCT,
    payload: { cartProduct },
  };
}

export function deletCartProduct({ id }) {
  return {
    type: DELETE_CART_PRODUCT,
    payload: { id },
  };
}
