import React from 'react';
import { Star } from '@material-ui/icons';
import './Assets/Styles/ProviderPickStep.css';
import Drawer from '@material-ui/core/Drawer';
import ProProfileDetails from './ProProfileDetails';
import { get } from 'lodash';
import { hasUuid } from '../../../constants';
import { connect } from 'react-redux';
class ProCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    render() {
        let { open } = this.state;
        let { therapist } = this.props,
            guest = Boolean(hasUuid() && get(this.props, "fieldsHolder.listingPageStorage.bannerimage", ""));
        return (<div className={`pro-card ${guest ? "border-light-gray border-radius-16" : ""}`}>
            <Drawer style={{ zIndex: 999999999999 }} anchor={'right'} open={open} onClose={() => this.setState({ open: false })}>
                {open ? <ProProfileDetails
                    fieldsHolder={this.props.fieldsHolder}
                    pro={therapist}
                    showProDetailsToggle={() => this.setState({ open: false })}
                    selectPro={this.props.selectPro}
                    time={therapist.time || this.props.time || ''}
                    errorHandler={this.props.errorHandler}
                    click_source="pick_a_pro"
                    market={get(this.props, "booking.cart.address.market", "")}
                /> : null}
            </Drawer>
            <div className='row '>
                <div className='col-xs-3 pr-4'>
                    <img className='therapist-img' src={therapist.avatar_url || ""} alt="" />
                </div>
                <div className='col-xs-9 pl-4'>
                    <div className='size-14-20 mb-4'><span className='color-light-gray'><Star style={{ color: "#F58266" }} /> {therapist.rating || ''} {therapist.rated ? `(${therapist.rated})` : ''}</span></div>
                    <div className='size-18-24 color-black'>{therapist.first_name || ""} {therapist.last_name || ""}</div>
                </div>
                <div className='col-xs-12'>
                    <div className='size-12-20 color-gray mtb-12 min-ht-80 txt-ellipsis-4'>{therapist.bio || therapist.type || ""}</div>
                </div>
                <div className='col-xs-6 col-sm-12 col-lg-6'><button className='btn actionButton medium-font mb-8' onClick={() => this.props.selectPro(therapist.id, therapist.time || this.props.time || '', get(therapist, "match_type", ""))}>Book at {therapist.time || this.props.time || ''}</button></div>
                <div className='col-xs-6 col-sm-12 col-lg-6'><button className='btn infoButton medium-font mb-8' onClick={() => {
                    this.setState({ open: true })
                }}>View profile</button></div>
            </div>
        </div>);
    }
}
const mapStateToProps = state => ({
    fieldsHolder: state.fieldsHolder,
    booking: state.booking
});

export default connect(mapStateToProps, {})(ProCard);