import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0 -5px 10px 0 rgba(0, 0, 0, 0.05)",
  },
  tabsStyling: {
    backgroundColor: theme.palette.background.paper
  },
  singleTabStyling: {
    textTransform: "capitalize",
    color: "#6088db",
    fontSize: "16px",
    borderBottom: "2px solid rgba(96, 136, 219, 0.5)"
  },
}));
export default function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <div className={classes.root}  >
      <AppBar position="static" elevation={0}  >
        <Tabs className={classes.tabsStyling} TabIndicatorProps={{ style: { background: '#6088db' } }}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="About" {...a11yProps(0)} className={classes.singleTabStyling} />
          <Tab label="Specialties" {...a11yProps(1)} className={classes.singleTabStyling} />
          <Tab label="Bio" {...a11yProps(2)} className={classes.singleTabStyling} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {props.getTabContent(props.proProfile, 0, props.pro)}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {props.getTabContent(props.proProfile, 1, props.pro)}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {props.getTabContent(props.proProfile, 2, props.pro)}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
