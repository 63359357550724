import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './Lottie_loading.json'
import '../index.css'

function LottieAnimation() {
  const container = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData
    })
  }, [])

  return (
    <div className="App">
      <div className="lottie-container" ref={container}>
        <div className="animation-title font-style-medium mtb-105 custom-lottie-text">Processing your order</div>
      </div>
    </div>
  );
}

export default LottieAnimation;