/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import Slider from 'react-slick';
import translations from '../../../Translations/soothePlus.json';
import { EN, ACTIVE_LANGUAGE_CODE } from '../../../constants';
import appStoreIcon from './Assets/Images/download_app_apple.png';
import germanAppStoreIcon from './Assets/Images/germanAppStoreIcon.png';
import googlePlayIcon from './Assets/Images/download_app_google.png';
import clientLogin from './Assets/Images/c_login.png';
import clientHome from './Assets/Images/c_home.png';
import clientBook from './Assets/Images/c_book.png';
import clientAppointments from './Assets/Images/c_appointments.png';
import clientArticles from './Assets/Images/c_articles.png';
import proHome from './Assets/Images/p_home.png';
import proOffers from './Assets/Images/p_offers.png';
import proAvailability from './Assets/Images/p_availability.png';
import proEarnings from './Assets/Images/p_earnings.png';
import proLocations from './Assets/Images/p_locations.png';
import './Assets/Styles/text-app-link.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const copy = {
  title: ['Download the App', 'How Soothe Pro Works'],
  slideData: [
    [
      { title: 'Soothe app overview', copy: 'With the new and improved Soothe app, you can book and keep track of appointments with ease, learn about new service offerings, update your profile to fit your specific preferences, and stay up to date with current wellness trends.', img: clientLogin },
      { title: 'New & Improved Homepage', copy: "With the updated Soothe App homepage, you can view your current appointments, rebook a Pro, explore our new service offerings and see what's new in wellness with featured articles.", img: clientHome },
      { title: 'Book a service for you and your friends', copy: 'Booking made easy and convenient. Once you’ve logged in and entered your address, you can view  the services available in your area, choose enhancements, select a date, and hit schedule! ', img: clientBook },
      { title: 'Appointment tracking', copy: 'Review current and past sessions with the appointments feature. Organized by date, all appointment cards detail time, date and Pro. Easily review, tip, and rebook Pros you’ve had in the past.', img: clientAppointments },
      { title: 'Stay in the know with articles', copy: 'Read up on the latest in wellness trends and find tips and tricks you can practice at home or on the go. The articles feature keeps you up to date on Soothe news, personal wellness, and workplace wellness.', img: clientArticles },
    ],
    [
      { title: 'Soothe Pro overview ', copy: 'Once your application and onboarding process is complete, download Soothe For Pros and start accepting offers. The app gives Pros the ability to set their own availability, keep track of their earnings and appointments, choose their neighborhoods, and keep in touch with Soothe’s 24/7 support team.', img: proHome },
      { title: 'Receive and accept offers', copy: 'As appointments are requested by clients in your area, offers will appear. These offers detail which service is being requested (and any additional add-ons), the start time of the appointment, the location (and how far away the location is from the Pro), and how much money a Pro will earn by completing the offer.', img: proOffers },
      { title: 'Remain flexible with editable availability', copy: 'Pros are able to work on their own terms with the availability feature. Pros can decide if and when they’d like to receive offers. In addition, with the Instant Confirm feature Pros can remain “on call” at any time and instantly book any qualifying offers.', img: proAvailability },
      { title: 'Keep track of earnings and reviews', copy: 'Pros can stay up to date financially, and monitor client reviews with the activity feature. Earnings are detailed by amount, description, date, and payment status. Reviews left by clients show Pros appreciation and where there is room for improvement.', img: proEarnings },
      { title: 'Work when and where you want', copy: 'With the updated Soothe For Pro app the emphasis is on where! With the new Locations feature, Pros can decide where they would like to work within their market. Introducing customizable location bundles! With location bundles Pros can hand select their favorite locations to work.', img: proLocations },
    ],
  ],
  appleDownloadLink: ['https://apps.apple.com/us/app/soothe-in-home-massage/id811054908?ls=1',
    'https://apps.apple.com/us/app/soothe-for-therapists/id989668503'],
  googleDownloadLink: ['https://play.google.com/store/apps/details?id=com.soothe.client&hl=en_US',
    'https://play.google.com/store/apps/details?id=com.soothe.soothe_android_therapist&hl=en_US'],
};

class TextAppLink extends React.Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(translations);
  }

  render() {
    const copyIndex = this.props.user === 'client' ? 0 : 1;
    const title = copy.title[copyIndex];
    const appleStoreIcon = ACTIVE_LANGUAGE_CODE === EN ? appStoreIcon : germanAppStoreIcon;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const cards = copy.slideData[copyIndex].map((data, index) => (
      <div key={index}>
        <div className={`title-mobile title-${this.props.user}`}>
          { title }
        </div>

        <div className="dowload-image-column">
          <img src={data.img} alt={`Phone Img ${index}`} />
        </div>

        <div className={`dowload-form-column copy-color-${this.props.user}`}>
          <div className={`download-the-app title-${this.props.user}`}>
            { title }
          </div>

          <div className="download-copy copy-title">
            { data.title }
          </div>

          <div className="download-copy">
            { data.copy }
          </div>

          <div className="download-icon-container">
            <a href={copy.appleDownloadLink[copyIndex]}>
              <img src={appleStoreIcon} className="apple" alt="apple store icon" />
            </a>
            <a href={copy.googleDownloadLink[copyIndex]}>
              <img src={googlePlayIcon} className="google" alt="google play icon" />
            </a>
          </div>
        </div>
      </div>
    ));

    return (
      <div className={`section-container background-color-${this.props.user}`}>
        <div id="section-download-global">
          <Slider {...settings}>
            { cards }
          </Slider>
        </div>
      </div>
    );
  }
}

export default withLocalize(TextAppLink);
