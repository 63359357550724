/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import GrayArrow from '../../../Assets/Images/Gray_arrow.png';
import './TextWithIcon.css';

class TextWithIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      iconClass, txtClass, cstmImg, icon, muiIcon,
      title, displayBorder, extraClass, details, children, displayArrow,
      noPaddingForIcon,
    } = this.props;
    return (
      <div className="text-with-icon">
        <div className="row align-items-center">
          {icon || muiIcon || cstmImg ? (
            <div className={iconClass || 'col-xs-2 col-sm-1'}>
              <div className={`txt-center ${noPaddingForIcon ? "" : "p-16"}`}>
                {muiIcon
                  ? <div className="icon-style">{muiIcon}</div>
                  : icon ? (
                    <img
                      src={icon}
                      alt={title || ''}
                      className="icon-style"
                    />
                  )
                    : cstmImg || null}
              </div>
            </div>
          ) : null}
          <div className={txtClass || (!muiIcon && !icon && !cstmImg ? 'col-xs-12' : 'col-xs-10 col-sm-11')}>
            <div className={`ptlb-16 ${displayBorder ? 'br-btm' : ''}`}>
              <div className={`parent-flex ${extraClass || ''}`}>
                <div className="flex-1">
                  <div className="title-style">{title || ''}</div>
                  <div className="details-style">{details || ''}</div>
                </div>
                {children}
                {displayArrow ? (
                  <img
                    src={GrayArrow}
                    alt={title || ''}
                    className="arrow-icon-style"
                  />
                )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize((TextWithIcon));
