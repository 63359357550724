/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import {
  map, isEmpty, get, find, size,
} from 'lodash';
import moment from 'moment-timezone';
import Slide from '@material-ui/core/Slide';
import {
  setBookingFlowStep, setCart, setBookingFlowPreviousStep,
  setAvailableTherapists, setBookingFlowComingFrom,
} from '../../../Actions';
import '../Assets/Styles/index.css';
import './Assets/Styles/ProviderPickStep.css';
import { STEPS } from '../Shared/constants';
import { removePro, requestPro } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import {
  HotJar, defaultErrorMessage, hasUuid, isGuestUser,
} from '../../../constants';
import { seBookingPAPView, seBookingPAPConfirm, sePickAProSelect } from '../../Shared/WebAnalytics';
import VerticalDisplay from './VerticalDisplay';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      currentStepId: 'TIMING',
      width: window.innerWidth,
    };
    this.goToNextStep = this.goToNextStep.bind(this);
    this.fillStateWithPros = this.fillStateWithPros.bind(this);
    this.listAllPros = this.listAllPros.bind(this);
    this.selectProAndNext = this.selectProAndNext.bind(this);
    this.removeProAndNext = this.removeProAndNext.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.skipButton = this.skipButton.bind(this);
    this.hasPAP2 = this.hasPAP2.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    const relevantPrev = currStepId === 'PICKAPRO2' ? 'PICKAPRO' : 'TIMING';
    if (!isGuestUser()) {
      this.setState({ currentStepId: get(this.props, 'bookingFlow.step.id', '') });
      const previousStep = find(STEPS, (step) => (step.id === relevantPrev));
      this.props.setBookingFlowPreviousStep(previousStep);
    } else {
      this.setState({ currentStepId: get(this.props, 'bookingFlow.step.id', '') });
      const previousStep = find(STEPS, (step) => (step.id === relevantPrev));
      this.props.setBookingFlowPreviousStep(previousStep);
      this.props.toggleNavBarStyle(!get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
      this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
    }

    this.fillStateWithPros();
  }

  hasPAP2() {
    const backToBackAvailable = get(this.props, 'booking.cart.backToBack.available', false);
    const backToBackEnabled = get(this.props, 'booking.cart.backToBack.enabled', false);
    return backToBackAvailable && !backToBackEnabled;
  }

  errorHandler(err) {
    const loader = this.props.setLoaderFlag;
    if (loader) {
      loader(false);
    }
    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
  }

  goToNextStep(nextStepId = 'REVIEW') {
    // let currStepId = get(this.props, 'bookingFlow.step.id', '');
    // nextStepId = currStepId === "PICKAPRO" && this.hasPAP2() ? "PICKAPRO2" : nextStepId;
    const nextStep = find(STEPS, (step) => (step.id === nextStepId));
    this.props.setBookingFlowComingFrom(this.state.currentStepId);
    this.props.setBookingFlowStepThroughParent(nextStep);
  }

  fillStateWithPros() {
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
    const dateUtc = get(this.props, 'booking.cart.date.utc', '');
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    if (cartId && cartProductId && dateUtc) {
      const providers = get(this.props, 'bookingFlow.availableTherapists', []);
      if (isEmpty(providers)) {
        const { currentStepId } = this.state; let
          nextStepId = 'REVIEW';
        if (currentStepId === 'REVIEW') {
          nextStepId = 'TIMING';
        }
        this.goToNextStep(nextStepId);
      } else {
        this.setState({ providers });
        const notCouples = size(get(this.props, 'booking.cart.cartProducts', [])) === 1;
        seBookingPAPView(size(providers), cartId, 'booking_23_variant', notCouples ? 'False' : this.hasPAP2() ? 'Same_Time' : 'Back_to_Back', {
          market: get(cart, 'address.market', ''),
          number_of_ga_pros: 0,
          number_of_ic_pros: size(providers),
          lead_time_selected: bkngDate.diff(nowDate, 'hours'),
          is_today: bkngDate.isSame(nowDate, 'day'),
          click_source: get(this.props, 'bookingFlow.comingFromStep', 'TIMING') === 'TIMING' ? 'Date_Screen' : 'Review_Screen',
          test_name: "jul_2024_booking_enhancements",
          test_value: get(this.props, "jul_2024_booking_enhancements", ""),
        });
      }
    }
  }

  selectProAndNext(proId, time, match_type, subIt, therapist) {
    const loader = this.props.setLoaderFlag;

    const cartId = get(this.props, 'booking.cart.id', '');
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    const cartProductId = currStepId === 'PICKAPRO2' ? get(this.props, 'booking.cart.cartProducts.1.id', '') : get(this.props, 'booking.cart.cartProducts.0.id', '');
    const formattedDate = moment(get(this.props, 'booking.cart.date.utc', '')).format('YYYY-MM-DD');
    const cart_product = {
      pro_ids: [proId],
      session_date: formattedDate,
      session_time: moment(`${formattedDate} ${time}`, 'YYYY-MM-DD hh:mm A').format('HH:mm'),
      remove_enhancements: true,
      remove_therapist_preferences: true,
    };
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    if (proId) {
      if (loader) {
        loader(true);
      }
      requestPro(cartId, cartProductId, cart_product, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
        if (loader) {
          loader(false);
        }
        this.props.assignToCart({ cart: decode(response.data.cart) });
        sePickAProSelect({
          ab_test_booking_flow: 'new_flow', market: get(this.props, 'booking.cart.address.market', ''), list_position: subIt + 1, rating_value: get(therapist, 'rating', 0), bookings_count: get(therapist, 'number_of_bookings', 0), is_ic: true,
          test_name: "jul_2024_booking_enhancements",
          test_value: get(this.props, "jul_2024_booking_enhancements", ""),
        });
        seBookingPAPConfirm(proId || '', subIt || 0, Boolean(match_type === 'exact'), cartId, {
          ab_test_booking_flow: 'new_flow',
          market: get(this.props, 'booking.cart.address.market', ''),
          is_ic: true,
          number_of_ga_pros: 0,
          number_of_ga_pros_selected: 0,
          lead_time_selected: bkngDate.diff(nowDate, 'hours'),
          is_today: bkngDate.isSame(nowDate, 'day'),
          click_source: get(this.props, 'bookingFlow.comingFromStep', 'TIMING') === 'TIMING' ? 'Date_Screen' : 'Review_Screen',
          test_name: "jul_2024_booking_enhancements",
          test_value: get(this.props, "jul_2024_booking_enhancements", ""),
        });
        this.goToNextStep();
      }, this.errorHandler);
    }
  }

  removeProAndNext() {
    const cartId = get(this.props, 'booking.cart.id', '');
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    const cartProductId = currStepId === 'PICKAPRO2' ? get(this.props, 'booking.cart.cartProducts.1.id', '') : get(this.props, 'booking.cart.cartProducts.0.id', '');
    removePro(cartId, cartProductId, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      this.props.assignToCart({ cart: decode(response.data.cart) });
      this.goToNextStep();
    }, this.errorHandler);
  }

  skipButton() {
    return (
      <div className="br-rd-8 p-16 br-opaque sm-m-16-16-22 ">
        <div className="size-18-24 color-black medium-font">Struggling to decide?</div>
        <div className="size-14-20 color-gray mt-12">Let us find a Provider for you by sending a request out to our larger network. This may take longer to get confirmed and is not always guaranteed.</div>
        <button
          type="button"
          className="btn bg-accent-light p-10-12 BluePrimary br-rd-30 mt-16 font-weight-bold"
          onClick={this.removeProAndNext}
        >
          Find me a Provider
        </button>
      </div>
    );
  }

  listAllPros() {
    const providers = this.props.bookingFlow.availableTherapists || []; const guest = Boolean(hasUuid() && get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
    const currStepId = get(this.props, 'bookingFlow.step.id', '');
    const guestName = get(this.props, 'booking.cart.cartProducts.1.client_name', '');
    if (!isEmpty(providers)) {
      return (
        <>
          <div className={`mb-100 medium-font size-44-52 txt-center sm-size-28-36 ${guest ? 'color-white txt-shadow sm-color-black' : 'color-black'} mb-40 sm-m-0 sm-align-left ptb-4 plr-16`}>{currStepId === 'PICKAPRO2' ? `Pick ${guestName}'s Provider` : 'Pick a Provider'}</div>
          <div className={`row ${guest ? 'background-primary p-24 border-radius-8' : ''} `}>
            {map(providers, (pro, subIt) => (
              <div key={`therapist-${subIt}`} className={`col-xs-12 mb-24 sm-mb-0 ${!guest ? 'col-sm-4' : 'col-sm-6 '}`}>
                <VerticalDisplay
                  therapist={pro}
                  time={pro.time || ''}
                  selectPro={(proId, time, match_type) => this.selectProAndNext(
                    proId,
                    time,
                    match_type,
                    subIt,
                    pro,
                  )}
                  errorHandler={this.errorHandler}
                  width={this.state.width}
                />
              </div>
            ))}
            <div className={`col-xs-12 mb-24 ${!guest ? 'col-sm-4' : 'col-sm-12'}`}>
              {this.skipButton()}
            </div>
          </div>
        </>
      );
    }
    return null;
  }

  render() {
    return (
      <Slide direction="up" in mountOnEnter unmountOnExit>
        <div className="display-flex">
          <div className="max-w-60 m-auto sm-w-100">
            <div className="">
              {this.listAllPros()}
            </div>
          </div>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  product: get(state, 'booking.product', null),
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {
  setBookingFlowStep,
  setCart,
  setBookingFlowPreviousStep,
  setAvailableTherapists,
  setBookingFlowComingFrom,
})(Index);
