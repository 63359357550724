/* eslint-disable default-param-last */
import { SET_CHATS, SET_CHAT } from '../Actions';

export function ChatsReducer(state = [], action) {
  switch (action.type) {
    case SET_CHATS:
      if (action.payload) {
        return action.payload || [];
      }
      return state;
    default:
      return state;
  }
}
export function SingleChatReducer(state = [], action) {
  switch (action.type) {
    case SET_CHAT:
      if (action.payload) {
        return action.payload || {};
      }
      return state;
    default:
      return state;
  }
}
