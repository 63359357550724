/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Badge, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import {
  find, get, includes, isEmpty, split,
} from 'lodash';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  AddCircle, ArrowBack,
  EventAvailable, EventAvailableOutlined, Menu as MenuIcon, Message, MessageOutlined,
} from '@material-ui/icons';
import { Link, Redirect } from 'react-router-dom';
import { blankHref, hasUuid, ROUTES } from '../../../constants';
import FetchClientContainer from '../../Client/FetchClient';
import { logoutEvent, navigationViewHomepage } from '../WebAnalytics';
import leftArrowWhite from '../../../Assets/Images/left-long-arrow-white.png';
import leftArrowBlack from '../../../Assets/Images/left-long-arrow-black.png';
import logoWhite from '../../../Assets/Images/Soothe-logo-white-svg.svg';
import logoBlue from '../../../Assets/Images/Soothe-logo-blue-svg.svg';
import smallLogoWhite from '../../../Assets/Images/White-small-logo-svg.svg';
import smallLogoBlue from '../../../Assets/Images/Blue-small-logo-svg.svg';
import userAvatarPlaceholder from '../../../Assets/Images/User_avatar.png';
import './Assets/Styles/Navbar.css';
import { ACCEPT_LANGUAGE_HEADER, HOST_ROOT } from '../../../apiConfig';
import { loadClient, setChats } from '../../../Actions';
import ForcedTermsModal from '../ForcedTermsModal';
import { getChatsOfCurrentUser } from '../Helpers';
import logoutSvg from '../../../Assets/Images/logout.svg';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      scrolledDown: false,
      anchorEl: null,
      redirect: false,
      pathname: '/appointments/',
      navigationValue: get(split(window.location.pathname, '/', 2), '1', ''),
    };
    this.renderBookingNav = this.renderBookingNav.bind(this);
    this.showPopover = this.showPopover.bind(this);
    this.logout = this.logout.bind(this);
    this.relevantHeader = this.relevantHeader.bind(this);
    this.b2bHeader = this.b2bHeader.bind(this);
    this.bottomNavigation = this.bottomNavigation.bind(this);
    this.fetchInboxChats = this.fetchInboxChats.bind(this);
    this.hasNewMessagesFlag = this.hasNewMessagesFlag.bind(this);
  }

  componentDidMount() {
    const self = this;
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
    window.addEventListener('scroll', () => {
      if (!self.state.scrolledDown && window.scrollY > 0) {
        self.setState({ scrolledDown: true });
      } else if (window.scrollY === 0 && self.state.scrolledDown) {
        self.setState({ scrolledDown: false });
      }
    });
    if (!includes(window.location.pathname, '/booking/')) {
      this.fetchInboxChats();
    }
  }

  fetchInboxChats() {
    getChatsOfCurrentUser((resp) => {
      this.props.setChats(get(resp, 'data.chat_list', []));
    });
  }

  hasNewMessagesFlag() {
    const hasNewMessages = find(get(this.props, 'chats', []), (el) => !get(el, 'chats.0.seen', false));
    return !isEmpty(hasNewMessages);
  }

  logout() {
    logoutEvent();
    axios.delete(
      `${HOST_ROOT}/users/sign_out`,
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    ).then(() => {
      this.props.loadClient({ loggedIn: false });
      window.location.href = `${HOST_ROOT}${ROUTES.index}`;
    }).catch(() => {
      this.props.loadClient({ loggedIn: false });
      window.location.href = `${HOST_ROOT}${ROUTES.index}`;
    });
    this.setState({ anchorEl: null });
  }

  showPopover() {
    const { anchorEl, width } = this.state;
    return (
      <Menu
        style={{ zIndex: '999999999999' }}
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => this.setState({ anchorEl: null })}
      >
        {width <= 800 || includes(window.location.pathname, '/booking/')
          ? (
            <>
              <MenuItem
                onClick={() => {
                  if (!includes(window.location.pathname, '/appointments')) {
                    this.setState({ redirect: true, pathname: '/appointments/' });
                  }
                }}
                id="appointments"
              >
                Bookings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (!includes(window.location.pathname, '/inbox')) {
                    this.setState({ redirect: true, pathname: '/inbox/' });
                  }
                }}
              >
                <Badge overlap="rectangular" color="secondary" variant="dot" invisible={!this.hasNewMessagesFlag()}>
                  Inbox
                </Badge>
              </MenuItem>
              <MenuItem onClick={() => { window.location.href = `${HOST_ROOT}/help-center/`; }}>Help</MenuItem>
            </>
          )
          : null}
        <MenuItem onClick={this.logout}>Logout</MenuItem>
      </Menu>
    );
  }

  b2bHeader() {
    const { scrolledDown, width } = this.state;
    const lightThemed = this.props.darkenHeader || scrolledDown;
    const { hideGoBack, additionalClass } = this.props;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    return (
      <header className={`${!lightThemed ? 'drop-shadow' : ''} p-fixed top-0 nav-header-style with-border-bottom ${additionalClass || ''} ${lightThemed ? 'with-white-bg' : ''} ${hideGoBack ? 'over-2-grids' : ''}`}>
        <a className={`back-arrow-wrapper ${lightThemed ? 'contentPrimary-imp' : 'contentInversePrimary'}`} href={blankHref} onClick={this.props.relevantGoBack}>
          <img className="back-arrow" src={lightThemed ? leftArrowBlack : leftArrowWhite} alt="back-arrow" />
          {width <= 800 ? '' : 'Back'}
        </a>
        <a className={`logo-wrapper ${hideGoBack ? 'm-0' : ''}`} href="/" onClick={() => navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""))} id="sootheLogo">
          <img className="soothe-logo-icon sm-hidden" src={lightThemed ? logoBlue : logoWhite} alt="Soothe" />
          <img className="soothe-logo-icon sm-shown" src={lightThemed ? smallLogoBlue : smallLogoWhite} alt="Soothe-small" />
        </a>
        <div className={`header-actions ${loggedIn ? '' : 'visibility-hidden'}`}>
          {loggedIn ? (
            <>
              <a
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => {
                  const { anchorEl } = this.state;
                  if (anchorEl) {
                    this.setState({ anchorEl: null });
                  } else this.setState({ anchorEl: event.currentTarget });
                }}
                id="avatar"
              >
                <img className="user-avatar-style" src={get(this.props, 'client.avatar_url', null) || userAvatarPlaceholder} alt="User avatar" />
              </a>
              {this.showPopover()}
            </>
          ) : null}
        </div>
        <FetchClientContainer />
      </header>
    );
  }

  bottomNavigation() {
    const { scrolledDown } = this.state;
    const lightThemed = this.props.darkenHeader || scrolledDown;
    let {
      hideGoBack, additionalClass, exceptionUiShowBtm, hideLogout,
    } = this.props;
    const { navigationValue } = this.state;
    if (!includes(['inbox', 'appointments'], navigationValue) && !hideGoBack && !exceptionUiShowBtm) {
      return (
        <header className={`p-fixed top-0  nav-header-style p-24 d-flex-only align-items-center justify-content-spaced ${additionalClass || ''} ${lightThemed ? 'with-white-bg with-border-bottom' : ''}`}>
          <div>
            <a className="back-arrow-wrapper" href={blankHref} onClick={this.props.relevantGoBack}>
              <ArrowBack className={this.props.darkenHeader ? 'contentPrimary' : 'contentInversePrimary'} />
            </a>
          </div>
          <div>
            <a className={`logo-wrapper ${hideGoBack ? 'm-0' : ''}`} href="/" onClick={() => navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""))} id="sootheLogo">
              <img className="soothe-logo-icon sm-hidden" src={lightThemed ? logoBlue : logoWhite} alt="Soothe" />
              <img className="soothe-logo-icon sm-shown" src={lightThemed ? smallLogoBlue : smallLogoWhite} alt="Soothe-small" />
            </a>
          </div>
          <div>
            {hideLogout ? <span className="header-actions" /> : (
              <a className="floatR" href={blankHref} onClick={this.logout}>
                <img className="width-20" alt="logout" src={logoutSvg} />
              </a>
            )}
          </div>
        </header>
      );
    }
    return (
      <>
        <div className={`p-fixed top-0 right-0 z-index-2 p-16 bg-primary ${scrolledDown ? 'left-0 shadow-btm' : ''}`}>
          <a className="floatR" href={blankHref} onClick={this.logout}>
            <img className="width-20" alt="logout" src={logoutSvg} />
          </a>
        </div>
        <BottomNavigation
          showLabels
          className="mobile-toolbar br-t-opaque-2 size-12-16 contentTertiary font-weight-bold z-index-2"
          value={navigationValue}
        >
          <BottomNavigationAction
            label="Bookings"
            value="appointments"
            icon={navigationValue === 'appointments' ? <EventAvailable /> : <EventAvailableOutlined />}
            component={Link}
            to="/appointments/"
            id="appointments"
          />
          <BottomNavigationAction
            label="Book"
            value="booking"
            icon={<AddCircle className="CactusFlowerPrimary" />}
            component={Link}
            to="/booking/"
          />
          <BottomNavigationAction
            label="Inbox"
            value="inbox"
            icon={(
              <Badge overlap="rectangular" color="secondary" variant="dot" invisible={!this.hasNewMessagesFlag()}>
                {navigationValue === 'inbox' ? <Message /> : <MessageOutlined />}
              </Badge>
)}
            component={Link}
            to="/inbox/"
          />
        </BottomNavigation>
      </>
    );
  }

  relevantHeader() {
    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: this.state.pathname,
          state: {
            click_source: 'nav_bar',
          },
        }}
        />
      );
    }
    if (hasUuid()) {
      return this.b2bHeader();
    }
    if (this.state.width < 800) {
      return this.bottomNavigation();
    }
    return this.renderBookingNav();
  }

  renderBookingNav() {
    const { scrolledDown, width } = this.state;
    const lightThemed = this.props.darkenHeader || scrolledDown;
    let {
      hideGoBack, hideHeaderActions, additionalActionLoggedIn,
      goBackText, additionalClass, notBooking,
    } = this.props;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    goBackText = goBackText || 'Back';
    return (
      <header className={`p-fixed top-0 nav-header-style ${additionalClass || ''} ${lightThemed ? 'with-white-bg' : ''} ${hideGoBack ? 'over-2-grids' : ''}`}>
        {hideGoBack ? null : (
          <a className={`back-arrow-wrapper ${lightThemed ? 'contentPrimary-imp' : 'contentInversePrimary'}`} href={blankHref} onClick={this.props.relevantGoBack}>
            <img className="back-arrow" src={lightThemed ? leftArrowBlack : leftArrowWhite} alt="back-arrow" />
            {width <= 800 ? '' : goBackText}
          </a>
        )}
        <a className={`logo-wrapper ${hideGoBack ? 'm-0' : ''}`} href="/" onClick={() => navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""))} id="sootheLogo">
          <img className="soothe-logo-icon sm-hidden" src={lightThemed ? logoBlue : logoWhite} alt="Soothe" />
          <img className="soothe-logo-icon sm-shown" src={lightThemed ? smallLogoBlue : smallLogoWhite} alt="Soothe-small" />
        </a>
        {notBooking && width > 800 ? (
          <>
            <a
              className={`header-link-style ${window.location.pathname === '/appointments/' ? 'active' : ''}`}
              onClick={() => {
                if (!includes(window.location.pathname, '/appointments')) {
                  this.setState({ redirect: true, pathname: '/appointments/' });
                }
              }}
              id="appointments"
            >
              Bookings
            </a>
            <Link className={`header-link-style ${window.location.pathname === '/inbox/' ? 'active' : ''}`} to="/inbox/">
              <Badge overlap="rectangular" color="secondary" variant="dot" invisible={!this.hasNewMessagesFlag()}>
                Inbox
              </Badge>
            </Link>
            <a className="header-link-style" href="/help-center/">Help</a>
          </>
        )
          : null}
        <div className={`header-actions ${hideHeaderActions ? 'visibility-hidden' : ''}`}>
          {loggedIn
            ? (
              <>
                {' '}
                {additionalActionLoggedIn
                  ? (
                    <button
                      type="button"
                      className="btn action-btn mr-15"
                      onClick={() => { window.location = ROUTES.booking; }}
                      id="bookNow"
                    >
                      Book now
                    </button>
                  )
                  : null}
                <a
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(event) => {
                    const { anchorEl } = this.state;
                    if (anchorEl) {
                      this.setState({ anchorEl: null });
                    } else this.setState({ anchorEl: event.currentTarget });
                  }}
                  id="avatar"
                >
                  {width > 800 || !notBooking
                    ? <img className="user-avatar-style" src={get(this.props, 'client.avatar_url', null) || userAvatarPlaceholder} alt="User avatar" />
                    : <MenuIcon className="header-link-style ml-16" />}
                </a>
                {this.showPopover()}
              </>
            )
            : (
              <>
                <a className={`signup-link ${width <= 800 ? 'btn' : ''}`} href={blankHref} onClick={() => this.props.goToAuthentication(false)}>
                  Signup
                </a>
                <button
                  type="button"
                  className="btn btn-login sm-hidden"
                  onClick={() => this.props.goToAuthentication(true)}
                >
                  Login
                </button>
              </>
            )}
        </div>
        <FetchClientContainer />
      </header>
    );
  }

  render() {
    return (
      <>
        {this.relevantHeader()}
        <ForcedTermsModal />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  chats: state.chats,
});

Navbar.propTypes = {
  client: PropTypes.object.isRequired,
};

Navbar.defaultProps = {
  bookingNav: null,
};

export default withLocalize(connect(mapStateToProps, { loadClient, setChats })(Navbar));
