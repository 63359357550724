/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-undef */
import queryString from 'query-string';
import { hotjar } from 'react-hotjar';
import { capitalize, get } from 'lodash';
import globalTrans from './Translations/global.json';
import bookingTrans from './Translations/bookingFlow.json';
import giftCardTrans from './Translations/giftCards.json';
import soothePlusTrans from './Translations/soothePlus.json';
import twitterSVG from './Assets/Images/twitter.svg';
import facebookSVG from './Assets/Images/facebook.svg';
import linkedinSVG from './Assets/Images/linkedin.svg';
import instagramSVG from './Assets/Images/instagram.svg';

// Localization Constants
const routePath = window.location.pathname;
export const EN = 'en';
export const DE = 'de';
export const US = 'us';
export const ACTIVE_LANGUAGE_CODE = routePath.slice(0, 4) === '/de/' ? DE : EN;
export const LOCALE = ACTIVE_LANGUAGE_CODE === EN ? 'en_US' : 'de_DE';
const index = ACTIVE_LANGUAGE_CODE === EN ? 0 : 1;

// ERRORS
export const addCreditCardError = globalTrans.errors.addCreditCard[index];

export const defaultErrorMessage = globalTrans.errors.defaultError[index];

export const invalidDeliveryDetailsError = globalTrans.errors.invalidDeliveryDetails[index];

export const invalidEmailError = globalTrans.errors.invalidEmail[index];

export const invalidPhoneNumberError = globalTrans.errors.invalidPhone[index];

export const invalidRecipientEmailError = globalTrans.errors.invalidRecipientEmail[index];

export const invalidSenderEmailError = globalTrans.errors.invalidSenderEmail[index];

export const invalidSenderInfoError = globalTrans.errors.invalidSenderInfo[index];

export const nothingInCartError = globalTrans.errors.nothingInCart[index];

export const notInYourAreaError = globalTrans.errors.notInYourArea[index];

export const nothingToCheckoutError = globalTrans.errors.nothingToCheckout[index];

export const pleaseAddACreditCardError = globalTrans.errors.pleaseAddACreditCard[index];

export const pleaseEnterError = globalTrans.errors.pleaseEnter[index];

export const pleaseEnterAGiftCodeError = globalTrans.errors.pleaseEnterAGiftCode[index];

export const pleaseEnterAMobileNumberError = globalTrans.errors.pleaseEnterAMobileNumber[index];

export const pleaseEnterAnAddressError = globalTrans.errors.pleaseEnterAnAddress[index];

export const pleaseEnterRequiredFormData = globalTrans.errors.pleaseEnterRequiredFormData[index];

export const pleaseSelectACreditCardError = globalTrans.errors.pleaseSelectACreditCard[index];

export const tbd = giftCardTrans.giftCardsStep2.toBeDeterminedAbbreviation[index];

// GENDERS
export const genders = {
  male: globalTrans.global.male[index],
  female: globalTrans.global.female[index],
  either: globalTrans.global.either[index],
};

// MODALITIES
export const modalities = {
  chair: globalTrans.modalities.chairMassage[index],
  deep: globalTrans.modalities.deepTissueMassage[index],
  percussive: globalTrans.modalities.percussiveMassage[index],
  prenatal: globalTrans.modalities.prenatalMassage[index],
  sports: globalTrans.modalities.sportsMassage[index],
  swedish: globalTrans.modalities.swedishMassage[index],
};

// STRINGS
export const and = globalTrans.global.and[index];

export const annual = bookingTrans.bookingFlow.annual[index];

export const couplesMassageString = `${capitalize(globalTrans.global.couples[index])} ${capitalize(globalTrans.global.massage[index])}`;

export const enhancements = bookingTrans.bookingFlow.enhancements[index];

export const error = globalTrans.global.error[index];

export const express = giftCardTrans.giftCardCheckout.shippingType.express[index];

export const massage = globalTrans.global.massage[index];

export const minute = globalTrans.global.minute[index];

export const minutes = globalTrans.global.minutes[index];

export const monthly = bookingTrans.bookingFlow.monthly[index];

export const ok = globalTrans.global.ok[index];

export const oopsString = globalTrans.global.oops[index];

export const pricingOptions = bookingTrans.bookingFlow.pricingOptions[index];

export const sootheMassageCard = giftCardTrans.giftCardCheckout.sootheMassageCard[index];

export const sootheEMassageCardFor = giftCardTrans.giftCardCheckout.sootheEMassageCardFor[index];

export const standard = giftCardTrans.giftCardCheckout.shippingType.standard[index];

export const summary = capitalize(globalTrans.global.summary[index]);

export const termsAndConditions = soothePlusTrans.soothePlusIndex.termsAndConditions[index];

export const therapist = globalTrans.global.therapist[index];

export const tryAgain = globalTrans.global.tryAgain[index];

export const bookingVideoDescriptions = {
  deep: bookingTrans.videoDescriptions.deep[index],
  swedish: bookingTrans.videoDescriptions.swedish[index],
  sports: bookingTrans.videoDescriptions.sports[index],
  prenatal: bookingTrans.videoDescriptions.prenatal[index],
};

export const parkingTypes = {
  freeStreetParking: bookingTrans.bookingFlow.freeStreetParking[index],
  meteredStreetParking: bookingTrans.bookingFlow.meteredStreetParking[index],
  parkingLotOrGarage: bookingTrans.bookingFlow.parkingLotOrGarage[index],
  valetService: bookingTrans.bookingFlow.valetService[index],
};

export const flightsOfStairs = {
  oneFlight: bookingTrans.bookingFlow.oneFlightOfStairs[index],
  twoFlights: bookingTrans.bookingFlow.twoFlightsOfStairs[index],
  threeOrMoreFlights: bookingTrans.bookingFlow.threeOrMoreFlightsOfStairs[index],
};

// ROUTES

export const ROUTES = {
  booking: globalTrans.routes.booking[index],
  index: globalTrans.routes.index[index],
  massageCards: globalTrans.routes.massageCards[index],
  massageCardsCheckout: globalTrans.routes.massageCardsCheckout[index],
  terms: globalTrans.routes.terms[index],
  userSignin: globalTrans.routes.userSignin[index],
  oldUserSignin: globalTrans.routes.oldUserSignin[index],
  userSignout: globalTrans.routes.userSignout[index],
  soothePlus: globalTrans.routes.soothePlus[index],
  soothePlusSubscription: globalTrans.routes.soothePlusSubscription[index],
  trustAndSafety: globalTrans.routes.trustAndSafety[index],
  helpCenter: globalTrans.footerRoutes.helpCenter[index],
  inbox: globalTrans.routes.inbox[index],
  appointmentDetails: globalTrans.routes.appointmentDetails[index],
};

export const blankHref = '# ';

// GLOBAL METHODS

export const currencyToSymbol = (currency = 'usd') => {
  let currencySymbol;

  switch (currency.toLowerCase()) {
    case 'cad':
      currencySymbol = 'CAD';
      break;
    case 'gbp':
      currencySymbol = '£';
      break;
    case 'eur':
      currencySymbol = '€';
      break;
    default:
      currencySymbol = '$';
  }

  return currencySymbol;
};

export const HotJar = () => {
  if (process.env.NODE_ENV !== 'production') return;

  hotjar.initialize(process.env.REACT_APP_HOTJAR_TRACKING_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION_ID);
};

export const getCookie = (name) => {
  const dc = document.cookie;
  const prefix = `${name}=`;
  let begin = dc.indexOf(`; ${prefix}`);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(';', begin);

    if (end == -1) {
      end = dc.length;
    }
  }

  return decodeURI(dc.substring(begin + prefix.length, end));
};

export const setCookie = (value) => {
  let expires = '';

  const date = new Date();
  date.setTime(date.getTime() + (2 * 24 * 60 * 60 * 1000));
  expires = `; expires=${date.toUTCString()}`;

  document.cookie = `aads=${(value || '')}${expires}; path=/`;
};

export const isGuestUser = () =>
  // let listingPageStorage = JSON.parse(window.localStorage.getItem("listingPageStorage"));
  // return get(listingPageStorage, "user_kind", "") === "guest";
  // eslint-disable-next-line implicit-arrow-linebreak
  hasUuid();
export const setCartJson = (data) => {
  window.localStorage.setItem('listingPageStorage', JSON.stringify(data));
};

export const getB2bInfo = (fieldname) => {
  const listingPageStorage = JSON.parse(window.localStorage.getItem('listingPageStorage'));
  return get(listingPageStorage, fieldname, '');
};

export const clearB2bInfo = () => {
  window.localStorage.removeItem('listingPageStorage');
};

export const getLocalInfo = (fieldname) => JSON.parse(window.localStorage.getItem(fieldname)) || '';

export const setLocalInfo = (fieldname, data) => {
  window.localStorage.setItem(fieldname, JSON.stringify(data));
};

export const removeLocalInfo = (fieldname) => {
  window.localStorage.removeItem(fieldname);
};

export let hasUuid = () => {
  const parsedParams = queryString.parse(window.location.search);
  return Boolean(Object.keys(parsedParams).includes('guest') && Object.keys(parsedParams).includes('business'));
};

export const guestWithDarkBackground = () => Boolean(hasUuid() && getB2bInfo('bannerimage'));

export const FOOTER_LINKS = [
  {
    title: 'Clients',
    linksList: [
      {
        linkText: 'Services',
        linkAddress: 'https://www.soothe.com/services/',
      },
      {
        linkText: 'Membership',
        linkAddress: 'https://www.soothe.com/soothe-pass/',
      },
      {
        linkText: 'Personal Events',
        linkAddress: 'https://www.soothe.com/personal-events/',
      },
      {
        linkText: 'Shop',
        linkAddress: 'https://shop.soothe.com',
      },
      {
        linkText: 'Gift Cards',
        linkAddress: 'https://www.soothe.com/gift-cards/',
      },
    ],
  },
  {
    title: 'Providers',
    linksList: [
      {
        linkText: 'Become a Provider',
        linkAddress: 'https://www.soothe.com/provider/',
      },
    ],
  },
  {
    title: 'Partnerships',
    linksList: [
      {
        linkText: 'Corporate Wellness',
        linkAddress: 'https://www.soothe.com/corporate-wellness-and-partnerships/',
      },
      {
        linkText: 'Corporate Partnerships',
        linkAddress: 'https://www.soothe.com/corporate-wellness-and-partnerships/',
      },
      {
        linkText: 'Corporate Events',
        linkAddress: 'https://www.soothe.com/corporate-wellness-and-partnerships/',
      },
    ],
  },
  {
    title: 'Support',
    linksList: [
      {
        linkText: 'Trust & Safety',
        linkAddress: 'https://www.soothe.com/trust-and-safety/',
      },
      {
        linkText: 'Help Center',
        linkAddress: 'https://www.soothe.com/help-center/',
      },
    ],
  },
  {
    title: 'Soothe',
    linksList: [
      {
        linkText: 'About Soothe',
        linkAddress: 'https://www.soothe.com/about-soothe/',
      },
      {
        linkText: 'Press',
        linkAddress: 'https://www.soothe.com/press/',
      },
      {
        linkText: 'Articles',
        linkAddress: 'https://www.soothe.com/articles/',
      },
      {
        linkText: 'Careers',
        linkAddress: 'https://www.soothe.com/careers/',
      },
      {
        linkText: 'Contact',
        linkAddress: 'https://www.soothe.com/contact-us/',
      },
    ],
  },
];
export const SOCIAL_LINKS = [
  {
    imgSrc: twitterSVG,
    linkAddress: 'https://twitter.com/soothe/',
  },
  {
    imgSrc: facebookSVG,
    linkAddress: 'https://www.facebook.com/soothe/',
  },
  {
    imgSrc: linkedinSVG,
    linkAddress: 'https://www.linkedin.com/company/soothe/',
  },
  {
    imgSrc: instagramSVG,
    linkAddress: 'https://www.instagram.com/soothe/',
  },
];
export const SELECTABLE_HOURS = [
  '8:00 AM', '8:15 AM', '8:30 AM', '8:45 AM',
  '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM',
  '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
  '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
  '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
  '1:00 PM', '1:15 PM', '1:30 PM', '1:45 PM',
  '2:00 PM', '2:15 PM', '2:30 PM', '2:45 PM',
  '3:00 PM', '3:15 PM', '3:30 PM', '3:45 PM',
  '4:00 PM', '4:15 PM', '4:30 PM', '4:45 PM',
  '5:00 PM', '5:15 PM', '5:30 PM', '5:45 PM',
  '6:00 PM', '6:15 PM', '6:30 PM', '6:45 PM',
  '7:00 PM', '7:15 PM', '7:30 PM', '7:45 PM',
  '8:00 PM', '8:15 PM', '8:30 PM', '8:45 PM',
  '9:00 PM', '9:15 PM', '9:30 PM', '9:45 PM',
  '10:00 PM',
];
// Soonest booking: after x hours
export const NOT_BEFORE_H = 3;

export const PASSWORD_RULES = {
  minCharacterLength: 12,
  characterLengthText: '12+ characters',
  includesUpperCase: 'One uppercase letter',
  includesLowerCase: 'One lowercase letter',
  includesNumbers: 'One number',
  includesSpecialCharacters: 'One special character',
};
export const generalCheckPassRules = (password = '') => {
  const newListPassMistakes = [];
  const {
    minCharacterLength, characterLengthText, includesUpperCase, includesLowerCase, includesNumbers, includesSpecialCharacters,
  } = PASSWORD_RULES;
  // Check if the password has at least 12 characters
  if (password && password.length < minCharacterLength) {
    newListPassMistakes.push(characterLengthText);
  }
  // Check if the password contains at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    newListPassMistakes.push(includesUpperCase);
  }
  // Check if the password contains at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    newListPassMistakes.push(includesLowerCase);
  }
  // Check if the password contains at least one number
  if (!/\d/.test(password)) {
    newListPassMistakes.push(includesNumbers);
  }

  // Check if the password contains at least one special character
  if (!/[~`!@#\$%\^&\*\(\)\-_+=\{\}\[\]\|\\;:'"<>,\./\?]/.test(password)) {
    newListPassMistakes.push(includesSpecialCharacters);
  }
  return newListPassMistakes;
};
export const dynamicCopyForDefaultBlindAuction = (leadTimeInHours) => {
  if (leadTimeInHours) {
    let xUnits;
    switch (true) {
      case leadTimeInHours <= 5:
        xUnits = '40 minutes';
        break;
      case leadTimeInHours > 12:
        xUnits = '3 hours';
        break;
      default:
        xUnits = '1 hour and 40 minutes';
    }
    return `I would like to automatically request new Providers if my request is not confirmed within ${xUnits}.`;
  }
  return '';
};
export const dynamicCopyForForcedBlindAuction = (leadTimeInHours) => {
  if (leadTimeInHours) {
    let xUnits;
    switch (true) {
      case leadTimeInHours <= 5:
        xUnits = '40 minutes';
        break;
      case leadTimeInHours > 12:
        xUnits = '3 hours';
        break;
      default:
        xUnits = '1 hour and 40 minutes';
    }
    return `We'll automatically request new Providers if your request is not confirmed within ${xUnits}.`;
  }
  return '';
};