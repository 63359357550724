/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import DatetimeWrapper from '../../Shared/DatetimeWrapper';

class DayPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="minus-mrl-20">
        <DatetimeWrapper
          open
          input={false}
          {...this.props}
        />
      </div>
    );
  }
}
export default DayPicker;
