import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { StripeProvider } from 'react-stripe-elements';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizeProvider } from 'react-localize-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { reactRoutes } from './Routes';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './configureStore';
import HttpsWWWRedirect from './Components/HttpsWWWRedirect';

const { store, persistor } = configureStore();

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GCP_CLIENT_ID}>
    <LocalizeProvider>
      <HttpsWWWRedirect>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_TOKEN}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
                {reactRoutes}
              </Router>
            </PersistGate>
          </Provider>
        </StripeProvider>
      </HttpsWWWRedirect>
    </LocalizeProvider>
  </GoogleOAuthProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);
registerServiceWorker();
