/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, map } from 'lodash';
import './CenteredGrids.css';

class CenteredGrids extends React.Component {
  render() {
    const cells = this.props.grids;
    const additionalClassName = this.props.additionalClassName || '';
    if (!isEmpty(cells)) {
      return (
        <div className={`mb-22 ${additionalClassName}`}>
          {this.props.title ? <div className="section-title">{this.props.title}</div> : null}
          <div className="grids-section">
            {map(cells, (cell, it) => (
              <div
                key={`${this.props.extraIdentifier}-cell-${it}`}
                className={`centered-cell ${cell.selected ? 'selected' : ''}`}
                onClick={() => {
                  if (cell.action) {
                    cell.action(cell.value);
                  }
                }}
                {...(cell.relevantId ? { id: cell.relevantId } : {})}
              >
                <div className="p-10">
                  {cell.icon ? <div className="cell-icon">{cell.icon || ''}</div> : null}
                  <div className="cell-title">{cell.title || ''}</div>
                  <div className="cell-subtitle">{cell.subtitle || ''}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withLocalize((CenteredGrids));
