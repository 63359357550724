/* eslint-disable no-useless-constructor */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable */
import React from 'react';
import './CTAButton.css';

class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      text, addon, disabled, action, additionalClass, submitted,
      additionalWrapperClass, bottomSection, type, forceLeft, relevantId, topSection,
      legalCopyOnTop, showBottomSectionOnly
    } = this.props;
    return (
      <div className="position-relative">
        <div className={`ctaButton-wrapper ${additionalWrapperClass || ''}`}>
          {topSection || null}
          {legalCopyOnTop || null}
          {addon && addon.direction === 'left' && additionalClass !== 'full-width-btn' ? (
            <span className={`mr-32 sm-mr-0 ${forceLeft ? 'txt-left' : 'txt-right'}`}>
              <div className="size-16-20 medium-font color-black mb-4">{addon.content || ''}</div>
              <div className="size-14-20 color-gray">{addon.subContent || ''}</div>
            </span>
          ) : null}
          {legalCopyOnTop ? <div>
            <button
              type={type || 'button'}
              className={`btn ${disabled ? 'ctaButton-disabled' : 'ctaButton-enabled'} ${additionalClass || ''}`}
              onClick={() => {
                if (!disabled && !submitted && type !== 'submit' && action) {
                  action();
                }
              }}
              {...(relevantId ? { id: relevantId } : {})}
            >
              {text || ''}
              {addon && addon.direction === 'left' && additionalClass === 'full-width-btn'
                ? <span className="float-right">{addon.content || ''}</span> : null}
            </button>
          </div>
            : <button
              type={type || 'button'}
              className={`btn ${disabled ? 'ctaButton-disabled' : 'ctaButton-enabled'} ${additionalClass || ''}`}
              onClick={() => {
                if (!disabled && !submitted && type !== 'submit' && action) {
                  action();
                }
              }}
              {...(relevantId ? { id: relevantId } : {})}
            >
              {text || ''}
              {addon && addon.direction === 'left' && additionalClass === 'full-width-btn'
                ? <span className="float-right">{addon.content || ''}</span> : null}
            </button>}
          {bottomSection && !showBottomSectionOnly ? <div className="sm-display">{bottomSection}</div> : null}
        </div>
        {bottomSection ? <div className={showBottomSectionOnly ? "" : "sm-none"}>{bottomSection}</div> : null}
      </div>
    );
  }
}

export default Index;
