import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize, assign } from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import { HotJar } from '../../../constants.js';
import whiteArrow from '../images/Arrow_right_white.png'
import checkMark from '../images/Check_mark.png'
import xMark from '../images/X_mark.png'
import grayArrow from '../images/Arrow_right_gray.png'
import { EMAILREGEX } from '../shared/constants';
import { giftCardRecipientInfoEvent } from '../../Shared/WebAnalytics';

HotJar();
class Step6 extends React.Component {
    constructor(props) {
        super(props);


        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateGiftCard = this.updateGiftCard.bind(this);
        this.getAdditionalClass = this.getAdditionalClass.bind(this);

        this.state = { width: 0 };

        this.props.addTranslation(translations);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    updateGiftCard(values) {
        giftCardRecipientInfoEvent(this.props.giftCard.deliveryMethod, this.props.giftCardCart.country, this.props.giftCardCart.cityName, this.props.giftCard.minutes, this.props.giftCard.amount, this.props.giftCard.deliveryDate)

        this.props.setGiftCard(
            assign(
                this.props.giftCard,
                values
            ));
    }
    getAdditionalClass(isValid, exists = false) {
        if (isValid) {
            return "success";
        } else if (exists) {
            return "erroneous";
        }
        return "";
    }
    render() {
        let { recipientName, recipientEmail, senderName, senderEmail, giftCardMessage } = this.props.details;
        let giftCardType = this.props.giftCardType;
        let reg = EMAILREGEX;
        let btnDisabled = !(recipientName && (giftCardType === "print_at_home" || (recipientEmail && reg.test(recipientEmail))))
        return (
            <div className={`full-height-section p-large bg-primary font-style-medium  txt-align-center  step6`} id="6">
                <p className="steps-indicator light-mode-secondary ">Step {this.props.totalSteps} of {this.props.totalSteps}</p>
                <p className="title-1 mb-48">Who's the lucky recipient?</p>
                <form onSubmit={(e) => { e.preventDefault() }}>
                    <div className="row equal">
                        <div className={`col-xs-12 ${giftCardType !== "print_at_home" ? "col-sm-6" : "col-sm-12"}`}>
                            <div className={`form-group txt-align-left ${recipientName ? "success" : ""}`}>
                                <label htmlFor="recipientName" className=" label-style mb-8">Recipient name*</label>
                                <input type="text" id="recipientName" className="form-control input-style" value={recipientName} onChange={(event) => this.props.updateField('recipientName', event.target.value)} />
                                <img className="input-checkmark" src={checkMark} />
                            </div>
                        </div>
                        {giftCardType !== "print_at_home" ? <div className="col-xs-12 col-sm-6">
                            <div className={`form-group txt-align-left ${this.getAdditionalClass(recipientEmail && reg.test(recipientEmail), recipientEmail)}`}>
                                <label htmlFor="recipientEmail" className=" label-style mb-8">Recipient email*</label>
                                <input type="email" id="recipientEmail" className="form-control input-style" value={recipientEmail} onChange={(event) => {
                                    this.props.updateField('recipientEmail', event.target.value)
                                }} />
                                <img className="input-checkmark" src={checkMark} />
                                <img className="input-xmark" src={xMark} />
                            </div>
                        </div> : null}
                        {/* <div className="col-xs-12 col-sm-6"> */}
                        {/* <div className={`form-group txt-align-left ${senderName ? "success" : ""}`}>
                                <label htmlFor="senderName" className=" label-style mb-8">Your name*</label>
                                <input type="text" id="senderName" className="form-control input-style" value={senderName} onChange={(event) =>
                                    this.props.updateField('senderName', event.target.value)} />
                                <img className="input-checkmark" src={checkMark} />
                            </div> */}
                        {/* {giftCardType !== "print_at_home" && <div className={`form-group txt-align-left ${this.getAdditionalClass(senderEmail && reg.test(senderEmail), senderEmail)}`}>
                                <label htmlFor="senderEmail" className=" label-style mb-8">Your email*</label>
                                <input type="email" id="senderEmail" className="form-control input-style" value={senderEmail} onChange={(event) => {
                                    this.props.updateField('senderEmail', event.target.value)
                                }} />
                                <img className="input-checkmark" src={checkMark} />
                                <img className="input-xmark" src={xMark} />
                            </div>} */}
                        {/* </div> */}

                    </div>

                    <div className="form-group txt-align-left mb-32 mb-sm-24">
                        <label htmlFor="exampleFormControlTextarea1" className='label-style mb-8'>Message</label>
                        <textarea className="form-control input-style text-area-style" id="exampleFormControlTextarea1" rows="5" maxLength='1200' onChange={(event) => this.props.updateField('giftCardMessage', event.target.value)}></textarea>
                        <span className="text-area-counter font-style-regular">{giftCardMessage ? giftCardMessage.length : 0} / 1200 characters</span>
                    </div>
                    <div className="mb-32 mb-sm-24">
                        <button className={`btn cta-btn cactus-flower-primary ${btnDisabled ? "disabled" : ""}`} onClick={() => {
                            if (!btnDisabled) {
                                this.updateGiftCard({
                                    recipientName, message: giftCardMessage,
                                    recipientEmail,
                                    recipientEmailIsValid: recipientEmail && reg.test(recipientEmail)
                                })
                                this.props.scrollToStep(7)
                            }

                        }}
                        >Add to cart<img className="img-icon" src={btnDisabled ? grayArrow : whiteArrow} />
                        </button>
                    </div>
                </form>
                <p className="small-gray-text">Gift cards do not expire. Gift cards cannot be replaced or refunded if lost or stolen. Gift cards cannot be returned and cannot be exchanged for cash except where required by law. Coupons, promo codes and other discounts may not be used to purchase gift cards. In the event that a purchase made with a gift card is returned, the original card will be credited. </p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    giftCard: state.giftCard,
    giftCardCart: state.giftCardCart
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setGiftCard, setGiftCardCart }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step6));
