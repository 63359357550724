export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SET_ADDRESS = 'SET_ADDRESS';

export function setAddresses(addresses) {
  return {
    type: SET_ADDRESSES,
    payload: addresses,
  };
}

export function fetchAddress(address) {
  return {
    type: SET_ADDRESS,
    payload: address,
  };
}
