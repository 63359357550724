export const SET_APPOINTMENT = 'SET_APPOINTMENT';
export const SET_UPCOMING_APPOINTMENT_ID = 'SET_UPCOMING_APPOINTMENT_ID';
export const SET_REBOOK_OPTIONS = 'SET_REBOOK_OPTIONS';

export function setAppointment(appointment) {
  return {
    type: SET_APPOINTMENT,
    payload: appointment,
  };
}
export function setUpcomingAppointmentId(upcomingAppointmentId) {
  return {
    type: SET_UPCOMING_APPOINTMENT_ID,
    payload: upcomingAppointmentId,
  };
}
export function setRebookOptions(rebookOptions) {
  return {
    type: SET_REBOOK_OPTIONS,
    payload: rebookOptions,
  };
}
