/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import $ from 'jquery';
import { blankHref } from '../../../constants';
import FetchClientContainer from '../../Client/FetchClient';
import { navigationViewHomepage } from '../WebAnalytics';
import './Assets/Styles/NavBar.css';
import backArrow from './Assets/Images/back.png';
import ForcedTermsModal from '../ForcedTermsModal';
import { get } from 'lodash';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };

    this.nonBasicAccountLink = this.nonBasicAccountLink.bind(this);
    this.nonBasicAccountLinkMobile = this.nonBasicAccountLinkMobile.bind(this);
    this.renderDefaultNav = this.renderDefaultNav.bind(this);
    this.renderBookingNav = this.renderBookingNav.bind(this);
  }

  componentDidMount() {
    $('.hamburger-icon').on('click', () => {
      $('.header-items').css('display', 'flex');
      $('.close-wrapper').css('display', 'inline-block');
      $('body').addClass('locked-body');
      $('.header-items').on('click', () => {
        $('body').removeClass('locked-body');
        $('.header-items').hide();
      });
    });

    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClick, false);
  }

  nonBasicAccountLink() {
    if (this.props.client.loggedIn) {
      return (
        <Translate>
          {({ translate }) => (
            <a
              className="header-item-account-icon"
              href={`${translate('routes.clientLoggedIn')}${this.props.client.user_id}`}
            >
              &#8205;
            </a>
          )}
        </Translate>
      );
    }
    return (
      <Translate>
        {({ translate }) => <a className="header-item-account-icon" href={translate('routes.userSignin')}>&#8205;</a>}
      </Translate>
    );
  }

  nonBasicAccountLinkMobile() {
    if (this.props.client.loggedIn) {
      return (
        <Translate>
          {({ translate }) => <a className="header-item-account-icon-mobile" href={`${translate('routes.clientLoggedIn')}${this.props.client.user_id}`}>&#8205;</a>}
        </Translate>
      );
    }
    return (
      <Translate>
        {({ translate }) => <a className="header-item-account-icon-mobile" href={translate('routes.userSignin')}>&#8205;</a>}
      </Translate>
    );
  }

  renderBookingNav() {
    return (
      <Translate>
        {({ translate }) => (
          <>
            <header id="soothe-navbar" className="soothe-navbar-generic p-fixed top-0">
              <a className="booking-back-arrow" href={blankHref} onClick={this.props.bookingNav.prev}>
                <img className="back-arrow" src={backArrow} alt="back-arrow" />
                {this.state.width > 600 ? this.props.bookingNav.backArrowText : ''}
              </a>

              <a className="logo-wrapper-booking" href={translate('routes.index')} onClick={() => navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""))}>
                <div className="soothe-logo-icon" alt="soothe hands" />
              </a>
              <FetchClientContainer />
            </header>
            <ForcedTermsModal />
          </>
        )}
      </Translate>
    );
  }

  renderDefaultNav() {
    return (
      <Translate>
        {({ translate }) => (
          <>
            <header id="soothe-navbar" className={`soothe-navbar-generic ${this.props.giftCardNav ? 'p-fixed max-w-100' : ''}`}>
              <a className={`logo-wrapper-booking ${this.props.giftCardNav ? 'fl-left m-0' : ''}`} href={translate('routes.index')} onClick={() => navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""))}>
                <div className="soothe-logo-icon" alt="soothe hands" />
              </a>
              {this.props.giftCardNav ? (
                <a className="cart-label" href={translate('routes.massageCardsCheckout')} onClick={() => navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""))}>
                  <span className="cart-text"> Cart</span>
                  <span className="cart-badge">
                    {this.props.cardsTotal}
                  </span>
                </a>
              ) : null}
              {this.props.giftCardNav ? null : <FetchClientContainer />}
            </header>
            <ForcedTermsModal />
          </>
        )}
      </Translate>
    );
  }

  render() {
    if (this.props.bookingNav) {
      return this.renderBookingNav();
    }
    return this.renderDefaultNav();
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
});

NavBar.propTypes = {
  client: PropTypes.object.isRequired,
};

NavBar.defaultProps = {
  bookingNav: null,
};

export default withLocalize(connect(mapStateToProps, {})(NavBar));
