/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  compact, filter, find, get, groupBy, isEmpty, map, size,
} from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';
import { defaultErrorMessage, ROUTES } from '../../../constants';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import { decode } from '../Shared/encode';
import { openChat, rescheduleAppointment } from '../Shared/helpers';
import { setAppointment, setRebookOptions, fetchChat } from '../../../Actions';
import BlueButtonsList from '../../Shared/BlueButtonsList';
import ErrorModal from '../../Shared/ErrorModal';
import {
  seConfirmAlternativeTimes, seDismissAlternativeTimes, seFindNewProvider, seInboxViewMessage,
} from '../../Shared/WebAnalytics';
import { getSingleChatState } from '../Shared/constants';
import { HOST_ROOT } from '../../../apiConfig';

class AvailableTimesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',
      selectedTime: '',
      selectedTimeZone: '',
      display: '',
      isOpenAvailableTimesModal: get(this.props, 'isOpenAvailableTimesModal', true),
      isOpenConfirmationModal: false,
      isOpenSkipModal: false,
      timesList: [],
      showErrorModal: false,
      error: '',
      at: '',
    };

    this.availableTimesList = this.availableTimesList.bind(this);
    this.timeSelectionConfirmation = this.timeSelectionConfirmation.bind(this);
    this.noTimeSelectionConfirmation = this.noTimeSelectionConfirmation.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.buildTimesList = this.buildTimesList.bind(this);
    this.modifySpecialRequest = this.modifySpecialRequest.bind(this);
    this.listOfButtons = this.listOfButtons.bind(this);
    this.findNewProAction = this.findNewProAction.bind(this);
  }

  componentDidMount() {
    this.buildTimesList();
  }

  componentDidUpdate(prevProps) {
    const prevModalState = Boolean(get(prevProps, 'isOpenAvailableTimesModal', false));
    const nextModalState = Boolean(get(this.props, 'isOpenAvailableTimesModal', false));
    if (prevModalState !== nextModalState) {
      if (nextModalState) {
        this.buildTimesList();
      }
      this.setState({ isOpenAvailableTimesModal: nextModalState });
    }
  }

  closeAllModals() {
    this.setState(
      { isOpenSkipModal: false, isOpenConfirmationModal: false, isOpenAvailableTimesModal: false },
      () => this.props.hideModalFromParent(),
    );
  }

  modifySpecialRequest() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const cartId = get(this.props, 'appointment.id', '');
    const { selectedDate, selectedTime } = this.state;
    const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
    const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));
    const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
    const marketplaceEnabled = get(this.props, 'appointment.info_fields.marketplace_enabled', false);
    let selectedPro = get(this.props, 'selectedTherapist', null);
    if (!selectedPro) {
      selectedPro = find(get(this.props, 'rebookOptions.cart.cart_products.0.pap_therapist_preferences', []), (el) => (el.offer_status === 'provided_alternate_times'));
    }
    rescheduleAppointment(cartId, {
      quoted_fee: get(this.props, 'appointment.fees.reschedule.amount', 0),
      rebook: 'same_therapist',
      session_date: selectedDate,
      session_time: moment(`${selectedDate} ${selectedTime}`, 'YYYY-MM-DD h:mm a').format('HH:mm'),
      therapist_id: get(selectedPro, 'id', ''),
    }, (response) => {
      const timeTilApp = moment(`${selectedDate} ${selectedTime}`, 'YYYY-MM-DD h:mm a').diff(moment(new Date()), 'hours');
      this.props.setAppointment(decode(response.data.cart));
      this.props.setRebookOptions({});
      seConfirmAlternativeTimes(timeTilApp, get(this.props, 'appointment.rebook', false), {
        // ab_test_booking_flow: get(this.props, "abTest"),
        market: get(this.props, 'appointment.address.market', ''),
        click_source: get(this.props, 'upcomingAppointmentId', '') ? 'appointment_details' : 'email_link',
        is_special_request: get(this.props, 'appointment.rebook', false),
        lead_time_selected: bkngDate.diff(checkoutDate, 'hours'),
        is_today: bkngDate.isSame(nowDate, 'day'),
        number_of_requested_pros: size(requestedProviders),
        number_of_pros_unavailable: size(filter(requestedProviders, (elt) => (elt.offer_status === 'rejected'))),
        number_of_pros_unresponsive: size(filter(requestedProviders, (elt) => (elt.offer_status === 'pending_response'))),
        blind_auction_opt_in: get(this.props, 'appointment.blind_auction_switch', false),
      });
      this.closeAllModals();
    }, (err) => {
      this.closeAllModals();
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
    // }
  }

  findNewProAction() {
    const timeZoneA = get(this.props, 'appointment.time.timezone', '');
    const nowTime = moment().tz(timeZoneA);
    const timeTilApp = moment(get(this.props, 'appointment.time.utc', '')).tz(timeZoneA).diff(nowTime, 'hours');
    const cartId = get(this.props, 'appointment.id', '');
    const sessionDate = moment(get(this.props, 'appointment.time.utc', '')).tz(timeZoneA);
    const sessionTime = get(this.props, 'appointment.time.display', '');
    rescheduleAppointment(cartId, {
      quoted_fee: get(this.props, 'appointment.fees.reschedule.amount', 0),
      rebook: 'any_therapist',
      session_date: sessionDate.format('YYYY-MM-DD'),
      session_time: moment(`${sessionDate.format('YYYY-MM-DD')} ${sessionTime}`, 'YYYY-MM-DD h:mm A').tz(timeZoneA).format('HH:mm'),
    }, (response) => {
      this.props.setAppointment(decode(response.data.cart));
      this.props.setRebookOptions({});
      seFindNewProvider(timeTilApp, get(this.props, 'appointment.rebook', false));
      this.closeAllModals();
    }, (err) => {
      this.closeAllModals();
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  buildTimesList() {
    const booking_options = get(this.props, 'rebookOptions.booking_options', []);
    if (!isEmpty(booking_options)) {
      const temp = map(booking_options, (opt) => {
        const time = moment.tz(opt.start_time, opt.time_zone);
        return {
          ...opt,
          display: time.clone().format('ddd, MMM D'),
          day: time.clone().format('D'),
          month: time.clone().format('M'),
          time: time.clone().format('h:mm A'),
        };
      });
      this.setState({ timesList: groupBy(temp, 'month') });
    }
  }

  listOfButtons() {
    const grpList = this.state.timesList;
    return map(grpList, (list, ind) => {
      const grpgrpList = groupBy(list, 'day');
      return (
        <div key={`modify-time-list-${ind}`}>
          {map(grpgrpList, (subList, itr) => (
            <div key={`modify-time-sublist-${itr}`}>
              <BlueButtonsList
                title={moment.tz(subList[0].start_time, subList[0].time_zone).format('ddd, MMM D')}
                buttonsList={subList}
                customId={`modify-${itr}`}
                globalAction={(val) => {
                  const { start_time, time, time_zone } = val;
                  this.setState({
                    selectedDate: moment.tz(start_time, time_zone).clone().format('YYYY-MM-DD'), selectedTime: time, at: moment.tz(start_time, time_zone).clone().format('MMM D [at] h:mm a'), isOpenConfirmationModal: true, isOpenAvailableTimesModal: false, selectedTimeZone: time_zone,
                  });
                }}
              />
            </div>
          ))}
        </div>
      );
    });
  }

  availableTimesList() {
    let selectedPro = get(this.props, 'selectedTherapist', null);
    if (!selectedPro) {
      selectedPro = find(get(this.props, 'rebookOptions.cart.cart_products.0.pap_therapist_preferences', []), (el) => (el.offer_status === 'provided_alternate_times'));
    }
    const { heading, description, title } = get(this.props, 'rebookOptions.tracker', {});
    const ther_name = get(selectedPro, 'first_name', '') || get(this.props, 'rebookOptions.cart.therapist_preferences.0.first_name', '');
    const proId = get(selectedPro, 'id', '') || get(this.props, 'rebookOptions.cart.therapist_preferences.0.id', '');
    const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
    const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));
    const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    return (
      <LatestModalDesign
        hideFooter
        isOpen={this.state.isOpenAvailableTimesModal}
        customIcon={<Close />}
        title={heading || title || ''}
        subtitle={description || ''}
        back={() => {
          seDismissAlternativeTimes(get(this.props, 'appointment.rebook', false), {
            // ab_test_booking_flow: get(this.props, "abTest"),
            click_source: get(this.props, 'upcomingAppointmentId', '') ? 'appointment_details' : 'email_link',
            market: get(this.props, 'appointment.address.market', ''),
            is_special_request: get(this.props, 'appointment.rebook', false),
            lead_time_selected: bkngDate.diff(checkoutDate, 'hours'),
            is_today: bkngDate.isSame(nowDate, 'day'),
            number_of_requested_pros: size(requestedProviders),
            number_of_pros_unavailable: size(filter(requestedProviders, (elt) => (elt.offer_status === 'rejected'))),
            number_of_pros_unresponsive: size(filter(requestedProviders, (elt) => (elt.offer_status === 'pending_response'))),
            time_til_appointment: bkngDate.diff(nowDate, 'hours'),
          });
          this.closeAllModals();
        }}
      >
        {this.listOfButtons()}
        <div className="mt-36 bg-secondary p-24-16 border-radius-8">
          <div className="size-28-36 color-black medium-font mb-4">Not finding a time?</div>
          <div className="size-12-20 color-gray mb-20">
            If none of these times work, you can remove
            {' '}
            {ther_name}
            {' '}
            from your request and let Soothe find a new provider for you at your original time.
          </div>
          <button
            type="button"
            className="btn bg-blue color-white medium-font padding-10-12 border-radius-1000 mtb-12"
            onClick={() => {
              this.setState({ isOpenSkipModal: false, isOpenAvailableTimesModal: false });
              openChat(compact([proId]), (resp) => {
                this.props.fetchChat(get(resp, 'data', {}));
                seInboxViewMessage({
                  state: getSingleChatState(get(resp, 'data.chats', [])),
                  click_source: 'sr_response',
                  cart_product_count: size(get(resp, 'data.cart.display_helpers.v0.cart_products', [])),
                  booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
                  is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
                  service_category: get(resp, 'data.cart.display_helpers.v0.cart_products.0.product.title', ''),
                  service_modality: get(resp, 'data.cart.display_helpers.v0.cart_products.0.cart_product_main_option.title', ''),
                });
                window.location.href = `${HOST_ROOT}${ROUTES.inbox}?chat_id=${get(this.props, 'currentChat.chat_id', '')}`;
              }, (err) => {
                console.log({ err });
              });
            }}
          >
            Contact
            {' '}
            {ther_name}
          </button>
          <a
            className="DarkBluePrimary medium-font p-24-16 cursor-pointer mtb-12"
            onClick={() => {
              this.setState({ isOpenSkipModal: true, isOpenAvailableTimesModal: false });
            }}
          >
            Find new provider
          </a>

        </div>
      </LatestModalDesign>
    );
  }

  timeSelectionConfirmation() {
    const { display, at } = this.state;
    const serviceTitle = get(this.props, 'appointment.cartProducts.0.subtitle', '');
    return (
      <LatestModalDesign
        isOpen={this.state.isOpenConfirmationModal}
        customIcon={<Close />}
        title={`Confirm ${display} ${at}`}
        subtitle={`Your ${serviceTitle} will be instantly confirmed.`}
        applyBtnCopy="Confirm"
        apply={this.modifySpecialRequest}
        close={() => this.setState({
          isOpenConfirmationModal: false,
          isOpenAvailableTimesModal: true,
        })}
        cancelBtnCopy="Back to times"
        back={this.closeAllModals}
      />
    );
  }

  noTimeSelectionConfirmation() {
    let selectedPro = get(this.props, 'selectedTherapist', null);
    if (!selectedPro) {
      selectedPro = find(get(this.props, 'rebookOptions.cart.cart_products.0.pap_therapist_preferences', []), (el) => (el.offer_status === 'provided_alternate_times'));
    }
    const proName = get(selectedPro, 'first_name', '') || get(this.props, 'appointment.therapist_preferences.0.first_name', 'the provider');
    return (
      <LatestModalDesign
        isOpen={this.state.isOpenSkipModal}
        customIcon={<Close />}
        title="Are you sure?"
        subtitle={`Choosing from one of ${proName}'s suggested times will instantly confirm your appointment.\n \n Finding a new provider will remove ${proName} from your request and send a request to our network of verified providers and may take longer to confirm.`}
        applyBtnCopy="No, Go Back"
        apply={() => {
          this.setState({ isOpenSkipModal: false, isOpenAvailableTimesModal: true });
        }}
        close={() => {
          this.findNewProAction();
        }}
        cancelBtnCopy="Yes, Find a new Provider"
        back={this.closeAllModals}
      />
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => {
          this.setState({ showErrorModal: false, error: null });
        }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <>
        {this.availableTimesList()}
        {this.timeSelectionConfirmation()}
        {this.noTimeSelectionConfirmation()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  rebookOptions: state.rebookOptions,
  chats: state.chats,
  currentChat: state.currentChat,
  upcomingAppointmentId: state.upcomingAppointmentId,
  abTest: state.abTest,
  fieldsHolder: state.fieldsHolder,
});
export default connect(
  mapStateToProps,
  { setAppointment, setRebookOptions, fetchChat },
)(AvailableTimesModal);
