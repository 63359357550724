/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/** **************************************************
class ExampleModal extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showModal: true
    };
  }

  render() {
    return (
      <ErrorModal isOpen={this.state.showModal}
      close={ () => { this.setState({ showModal: false }); } }>
        <p>I am the body</p>
      </ErrorModal>
    );
  }
}
**************************************************** */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { withLocalize } from 'react-localize-redux';
import { error, ok } from '../../../constants';
import './ErrorModal.css';

class ErrorModal extends Component {
  render() {
    if (this.props.isOpen) {
      return (
        <div id="custom-error-modal">
          <div className="modal-content">
            <a className="close" href="&times;" onClick={(e) => { e.preventDefault(); this.props.close(); }}>&times;</a>
            <h1>{ this.props.title }</h1>
            <div className="content">{this.props.children}</div>
            <div className="btn-container">
              <button type="button" className="btn" onClick={(e) => { e.preventDefault(); this.props.close(); }}>{ this.props.btnCopy }</button>
            </div>
          </div>
        </div>
      );
    }
    return '';
  }
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ErrorModal.defaultProps = {
  title: `${capitalize(error)}`,
  btnCopy: `${capitalize(ok)}`,
  isOpen: false,
};

export default withLocalize(ErrorModal);
