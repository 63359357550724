/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function SimpleCheck(props) {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'https://assets8.lottiefiles.com/packages/lf20_lk80fpsm.json',
    });
  }, []);

  return (
    <div className={props.className} ref={container} />
  );
}
