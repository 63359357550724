/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleIcon from '../../Assets/Images/google.svg';

export default function GoogleSignin(props) {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const { code } = codeResponse;
      axios.post('https://oauth2.googleapis.com/token', {
        code,
        client_id: process.env.REACT_APP_GCP_CLIENT_ID,
        client_secret: process.env.REACT_APP_GCP_CLIENT_SECRET,
        redirect_uri: process.env.REACT_APP_API_URL,
        grant_type: 'authorization_code',
      }).then((respp) => {
        const { id_token, access_token, token_type } = respp.data;
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
          headers: {
            Authorization: `${token_type} ${access_token}`,
            Accept: 'application/json',
          },
        })
          .then((res) => {
            props.callback({ ...res.data, id_token });
          })
          .catch((err) => {
            console.log(err);
            props.showError('Something went wrong trying to login');
          });
      }).catch((err_1) => {
        console.log({ err_1 });
        props.showError("Login couldn't be complete");
      });
    },
    onError: (error) => {
      console.log('Login Failed:', error);
      props.showError('Login Failed');
    },
    flow: 'auth_code',
  });
  return (
    <div className="br-rd-24 br-opaque-2 ptb-14 centered mb-16" onClick={login}>
      <div className="size-16-20 DarkBluePrimary font-weight-bold">
        <img src={GoogleIcon} className="w-h-20 mr-8" alt="" />
        Continue with Google
      </div>
    </div>
  );
}
