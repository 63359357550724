/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import {
  EventNote, List, MonetizationOnOutlined, ReportProblemOutlined,
} from '@material-ui/icons';
import moment from 'moment';
import {
  find, first, get, isEmpty, replace, result, round, size,
} from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import LatestModalDesign from '../../../Shared/LatestModalDesign';
import CTAButton from '../../../Shared/CTAButton';
import TextWithIcon from '../../../Shared/TextWithIcon';
import { ACCEPT_LANGUAGE_HEADER, API_ROOT } from '../../../../apiConfig';
import AddCreditCardModal from '../../../BookingFlow/Payment/CreditCards/AddCreditCardModal';
import CreditCardsModal from '../../../BookingFlow/Payment/CreditCards/CreditCardsModal';
import { setAppointment } from '../../../../Actions/AppointmentManagementAction';
import { setCreditCards } from '../../../../Actions';
import {
  ACTIVE_LANGUAGE_CODE, DE, defaultErrorMessage, US,
} from '../../../../constants';
import ErrorModal from '../../../Shared/ErrorModal';
import { updateCart } from '../../../BookingFlowRedesign/Shared/helpers';
import ClickableTooltip from '../../../Shared/ClickableTooltip';
import { decode } from '../../Shared/encode';
import { seRescheduleReview } from '../../../Shared/WebAnalytics';

class ConfirmChanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      creditCard: null,
      height: 300,
      showCreditCards: false,
      showNewCreditCard: false,
      showErrorModal: false,
      error: null,
      changedCC: false,
    };
    this.setCartCreditCard = this.setCartCreditCard.bind(this);
    this.load = this.load.bind(this);
    this.getCardDetails = this.getCardDetails.bind(this);
    this.reload = this.reload.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.commonPart = this.commonPart.bind(this);
    this.extraFees = this.extraFees.bind(this);
    this.mainModal = this.mainModal.bind(this);
    this.uncommonPart = this.uncommonPart.bind(this);
    this.descriptionText = this.descriptionText.bind(this);
    this.relevantTooltip = this.relevantTooltip.bind(this);
    this.totalFees = this.totalFees.bind(this);
    this.triggerReviewEvent = this.triggerReviewEvent.bind(this);
    // Maybe I need to spend some time separating the payment modal and make it a shared component
    // instead of rewriting it each time
  }

  componentDidMount() {
    this.triggerReviewEvent();
    this.load();
    // modal margin top + bottom = 140
    // modal padding top + bottom = 40
    // title height with margin = 28
    if (typeof this.parentRef !== 'undefined') {
      const height = this.parentRef.clientHeight - 208 - 100;
      this.setState({ height });
    }
  }

  triggerReviewEvent() {
    const apt = get(this.props, 'appointment', {});
    seRescheduleReview({
      appointment_id: get(apt, 'id', ''),
      state: get(apt, 'info_fields.tracker.state', ''),
      service_category: get(apt, 'cartProducts.0.product.title', '').toLowerCase(),
      service_modality: get(apt, 'cartProducts.0.cart_product_main_option.title', '').toLowerCase(),
      client_id: get(this.props, 'client.user_id', ''),
      cart_product_count: size(get(apt, 'cartProducts', [])),
      same_day: moment(get(this.props, 'selection.datetime', '')).isSame(get(apt, 'time.utc', ''), 'day'),
      ic_utilized: get(this.props, 'selection.category', '') === 'Instant Book',
      late_reschedule: Boolean(get(apt, 'fees.reschedule.amount', 0)),
      different_pro: get(this.props, 'comingFromCalendar', false),
      price_adjustment: Boolean(get(this.props, 'selection.high_demand_fee', 0)),
    });
  }

  load() {
    const userId = this.props.client.user_id;
    if (this.props.setLoaderFlag) {
      this.props.setLoaderFlag(true);
    }
    axios.get(`${API_ROOT}/users/${userId}/credit_cards`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const creditCards = response.data.credit_cards;
      const { errors } = response.data;
      this.props.setCreditCards(creditCards);
      let creditCard; let
        creditCardId;

      if (creditCard === undefined && creditCards.length > 0) {
        creditCard = find(creditCards, (cc) => cc.default);

        if (creditCard) {
          creditCardId = result(creditCard, 'id');
        } else {
          creditCard = first(creditCards);
          creditCardId = result(creditCard, 'id');
        }

        this.setState({ creditCard }, () => {
          this.setCartCreditCard(creditCardId);
        });
        return true;
      } if (creditCards.length === 0) {
        this.setState({ creditCard: null });
      } else if (errors) {
        console.log(errors);
      }

      this.setState({ showNewCreditCard: false });
      if (this.props.setLoaderFlag) {
        this.props.setLoaderFlag(false);
      }
    }).catch((error) => {
      if (this.props.setLoaderFlag) {
        this.props.setLoaderFlag(false);
      }
      console.log(error);
    });
  }

  reload(id) {
    this.load();
    const creditCardId = id
    || this.props.appointment.billing.credit_card.id || this.state.creditCard.id;

    this.setCartCreditCard(creditCardId, true);
    this.setState({ showNewCreditCard: false }, this.load);
  }

  deleteCreditCard(index) {
    const creditCard = this.props.creditCards[index];

    if (creditCard) {
      axios.delete(`${API_ROOT}/credit_cards/${creditCard.id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result, success } = resp.data;

        if (result || success) {
          this.reload();
        } else {
          const message = result(first(resp.data.errors || {}), 'message') || result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showErrorModal: true, error: message });
        }
      }).catch((error) => {
        const message = result(first(error.response.data.errors || {}), 'message') || result(error.response.data.errors || {}, 'message') || defaultErrorMessage;
        this.setState({ showErrorModal: true, error: message });
      });
    }
  }

  setCartCreditCard(creditCardId, setChange = false) {
    const cartId = get(this.props, 'appointment.id', '');
    if (this.props.setLoaderFlag) {
      this.props.setLoaderFlag(true);
    }
    updateCart(cartId, {
      credit_card_id: creditCardId,
    }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      let creditCard;
      this.props.setAppointment(decode(response.data.cart));
      if (setChange) {
        this.setState({ changedCC: true });
      }
      if (this.props.creditCards) {
        creditCard = find(
          this.props.creditCards,
          (cc) => (cc.id === this.props.appointment.billing.credit_card.id),
        );
      }

      if (creditCard) {
        this.setState({ creditCard });
      }
      this.setState({ showCreditCards: false });

      if (this.props.setLoaderFlag) {
        this.props.setLoaderFlag(false);
      }
    }, (error) => {
      if (this.props.setLoaderFlag) {
        this.props.setLoaderFlag(false);
      }
      if (get(error, 'response.status', '') && get(error, 'response.status', '') !== 401) {
        this.setState({ showErrorModal: true, error: get(error, 'response.data.errors.0.message', defaultErrorMessage) });
      }
    });
  }

  renderModal() {
    const fonts = [{ src: 'url("https://www.soothe.com/static/media/GothamHTF-Book.48680770.otf")', family: 'gotham' }];
    const countryCode = ACTIVE_LANGUAGE_CODE === DE ? DE : US;

    if (this.state.showNewCreditCard) {
      return (
        <Elements locale={countryCode} fonts={fonts}>
          <AddCreditCardModal
            setLoaderFlag={this.props.setLoaderFlag}
            prev={() => { this.setState({ showNewCreditCard: false }); }}
            close={() => { this.setState({ showNewCreditCard: false, showCreditCards: false }); }}
            reload={this.reload}
            showAddCreditCard={this.state.showNewCreditCard}
          />
        </Elements>
      );
    } if (this.state.showCreditCards) {
      return (
        <Elements locale={countryCode} fonts={fonts}>
          <CreditCardsModal
            creditCardId={get(this.props, 'appointment.billing.credit_card.id', null)}
            addNewCreditCard={() => { this.setState({ showNewCreditCard: true }); }}
            height={this.state.height}
            close={() => { this.setState({ showCreditCards: false }); }}
            onChange={(creditCardId, creditCard) => {
              this.setState({ creditCard }, () => {
                this.setCartCreditCard(creditCardId, true);
              });
            }}
            reload={this.reload}
          />
        </Elements>
      );
    }
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => {
          this.setState({ showErrorModal: false, error: null });
        }}
      >
        {this.state.error}
      </ErrorModal>
    );
  }

  getCardDetails() {
    if (this.state.creditCard) {
      return (
        <div className="size-12-20 CactusFlowerPrimary p-10-12">
          {this.state.creditCard.type}
          {' '}
          {this.state.creditCard.last_4}
        </div>
      );
    }

    return <div className="size-12-20 CactusFlowerPrimary p-10-12">Add Payment Method</div>;
  }

  commonPart() {
    const { selection } = this.props;
    return (
      <>
        {/* Date */}
        <TextWithIcon
          muiIcon={<EventNote className="grayed-out" />}
          title="Date"
          details={(
            <>
              New date:
              {selection.day_of_week
                ? (
                  <>
                    {' '}
                    {selection.day_of_week && selection.day_of_week.substring(0, 3)}
                    ,
                    {' '}
                    {moment().month(selection.month - 1).format('MMM')}
                    {' '}
                    {selection.day}
                    ,
                    {' '}
                    {selection.time}
                  </>
                )
                : (
                  <>
                    {' '}
                    {moment(selection.datetime).format('ddd, MMM D')}
                    ,
                    {' '}
                    {selection.time}
                  </>
                )}
              <div className="color-light-gray strike-through">
                {get(this.props, 'appointment.date.display', '')}
                ,
                {get(this.props, 'appointment.time.display', '')}
              </div>
            </>
)}
        />
        {/* Appointment details */}
        <TextWithIcon
          muiIcon={<List className="grayed-out" />}
          title="Appointment details"
          details={<>{replace(get(this.props, 'appointment.info_fields.title', ''), ' with', '\nwith')}</>}
        />
      </>
    );
  }

  descriptionText(high_demand_fee, reschedule_fee) {
    let desc1 = null; let desc2 = null;
    const curr = get(this.props, 'appointment.currencySymbol', '$');
    if (reschedule_fee) {
      desc1 = (
        <div>
          Late reschedule fee:
          {curr}
          {round(reschedule_fee, 2)}
        </div>
      );
    }
    if (high_demand_fee) {
      desc2 = (
        <div>
          Price adjustment:
          {curr}
          {round(high_demand_fee, 2)}
        </div>
      );
    }
    return (
      <>
        {desc1}
        {desc2}
      </>
    );
  }

  relevantTooltip(high_demand_fee, reschedule_fee) {
    let desc1 = null; let desc2 = null;
    const reschedule_perc = get(this.props, 'appointment.fees.reschedule.amount_pct', 0);
    const hrs = Number(get(this.props, 'appointment.fees.reschedule.mins_until_session', 0)) / 60;
    if (reschedule_fee) {
      desc1 = (
        <div className="size-12-20 contentInversePrimary">
          Late reschedule fee:
          <br />
          {reschedule_perc}
          % of your total when rescheduling within
          {' '}
          {hrs}
          {' '}
          hours of your appointment.
          <br />
          <a className="contentInversePrimary" href="https://www.soothe.com/cancellation">See policy</a>
        </div>
      );
    }
    if (high_demand_fee) {
      desc2 = (
        <div className="size-12-20 contentInversePrimary">
          Price adjustment:
          <br />
          The cost of your new appointment differs
          from what you originally booked due to high demand surges.
        </div>
      );
    }
    return (
      <ClickableTooltip title={(
        <>
          {desc1}
          {desc2}
        </>
)}
      />
    );
  }

  extraFees() {
    const card = this.state.creditCard;
    const high_demand_fee = get(this.props, 'selection.high_demand_fee', 0);
    const reschedule_fee = get(this.props, 'appointment.fees.reschedule.amount', 0);
    if (high_demand_fee || reschedule_fee) {
      return (
        <div
          className="clickable"
          onClick={() => {
            if (!isEmpty(card)) {
              this.setState({ showCreditCards: true });
            } else {
              this.setState({ showNewCreditCard: true });
            }
          }}
        >
          <TextWithIcon
            muiIcon={<MonetizationOnOutlined className="grayed-out" />}
            title={(
              <>
                Total
                {this.relevantTooltip(high_demand_fee, reschedule_fee)}
              </>
)}
            details={this.descriptionText(high_demand_fee, reschedule_fee)}
            displayArrow
          >
            {this.getCardDetails()}
          </TextWithIcon>
        </div>
      );
    }
  }

  uncommonPart() {
    const { comingFromCalendar, selection } = this.props;
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', 'the provider');
    const high_demand_fee = get(this.props, 'selection.high_demand_fee', 0);
    const reschedule_fee = get(this.props, 'appointment.fees.reschedule.amount', 0);
    if (comingFromCalendar) {
      return (
        <div className="mb-36">
          <TextWithIcon
            muiIcon={<ReportProblemOutlined className="grayed-out" />}
            title="Need to know"
            details={size(get(this.props, 'appointment.cartProducts', [])) === 1
              ? "Once you reschedule, your appointment will return to 'Pending' and will not be confirmed until we assign providers. You may be assigned new providers."
              : "Your appointment will return to 'Pending' and will not be confirmed until a new proider is assigned. "}
          />
        </div>
      );
    }
    return (
      <>
        <div className="mt-24 size-16-20 medium-font mb-8">
          Let
          {proFirstName}
          {' '}
          know why you need to reschedule
          <br />
          (required)
        </div>
        <textarea
          className="form-control gray-textarea mb-36"
          rows={3}
          onChange={(event) => this.setState({ message: event.target.value || '' })}
        />
        <div className="size-12-20 color-light-gray txt-center mb-20">
          {selection.category === 'Instant Book' ? 'Once you confirm, your appointment will be immediately updated'
            : `If ${proFirstName} declines or does not respond, your appointment will remain as originally booked.${high_demand_fee || reschedule_fee ? ' You will only be charged fees when your request is accepted.' : ''}`}
        </div>

      </>
    );
  }

  totalFees() {
    const high_demand_fee = get(this.props, 'selection.high_demand_fee', 0);
    const reschedule_fee = get(this.props, 'appointment.fees.reschedule.amount', 0);
    const curr = get(this.props, 'appointment.currencySymbol', '$');
    const feeTotal = Number(high_demand_fee) + Number(reschedule_fee);
    if (feeTotal) {
      return { content: (`${curr}${round(feeTotal, 2)}`), direction: 'right' };
    }
    return {};
  }

  mainModal() {
    const {
      selection, comingFromCalendar, show, hideModal,
    } = this.props;
    return (
      <LatestModalDesign
        isOpen={show}
        title="Confirm changes"
        disabled={!this.state.message && !comingFromCalendar}
        hideFooter
        back={hideModal}
      >
        {this.commonPart()}
        {this.extraFees()}
        {this.uncommonPart()}
        <CTAButton
          text={selection.category === 'Instant Book' ? 'Reschedule Instantly' : 'Send request'}
          additionalClass="full-width-btn"
          disabled={!this.state.message && !comingFromCalendar}
          addon={this.totalFees()}
          action={() => this.props.ctaAction(this.state.message, this.state.changedCC)}
        />
      </LatestModalDesign>
    );
  }

  render() {
    const { selection } = this.props;
    if (!selection) {
      return null;
    }
    return (
      <>
        {this.mainModal()}
        <div ref={(element) => { this.parentRef = element; }} className="payments-redesign">
          {this.renderModal()}
        </div>
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
  creditCards: state.creditCards,
  creditCard: state.creditCard,
  fieldsHolder: state.fieldsHolder,
});
export default connect(mapStateToProps, { setCreditCards, setAppointment })(ConfirmChanges);
