/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { isEmpty, map, snakeCase } from 'lodash';
import './CheckboxOptions.css';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default class CheckboxOptions extends React.Component {
  render() {
    const { optionsArray } = this.props;
    if (!isEmpty(optionsArray)) {
      return (
        <FormGroup>
          {map(optionsArray, (option, it) => (
            <FormControlLabel
              key={`checkbox-${snakeCase(option.label)}-${it}`}
              control={(
                <Checkbox
                  defaultChecked={option.checked}
                  onChange={(event) => this.props.onChange(option.key, event.target.checked)}
                  name={option.label}
                />
)}
              label={option.label}
            />
          ))}
        </FormGroup>
      );
    }
    return null;
  }
}
