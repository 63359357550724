export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export const SET_CREDIT_CARD = 'SET_CREDIT_CARD';

export function setCreditCards(creditCards) {
  return {
    type: SET_CREDIT_CARDS,
    payload: creditCards,
  };
}

export function setCreditCard(creditCard) {
  return {
    type: SET_CREDIT_CARD,
    payload: creditCard,
  };
}
