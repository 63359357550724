/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { SET_ADDRESSES } from '../Actions';

export default function (state = [], action) {
  switch (action.type) {
    case SET_ADDRESSES:
      if (action.payload) {
        return action.payload || [];
      }

      return state;
    default:
      return state;
  }
}
