import React from 'react';
import { connect } from 'react-redux';
import { setBookingFlowStep, setCart, setBookingFlowPreviousStep } from '../../../Actions';
import { withLocalize } from 'react-localize-redux';
import ErrorModal from '../../Shared/ErrorModal';
import MapContainer from '../../Shared/MapContainer';
import RadioOptions from '../../Shared/RadioOptions';
import '../Assets/Styles/index.css';
import './Assets/Styles/FinishAddress.css';
import CheckboxOptions from '../../Shared/CheckboxOptions';
import { getAddressDetails, updateAddress, updateCart } from '../Shared/helpers';
import { compact, get, isEmpty, set, sortBy, map, join, keys, filter } from 'lodash';
import CTAButton from '../../Shared/CTAButton';
import closeIcon from '../../../Assets/Images/Close_icon.png';
import { HotJar, defaultErrorMessage } from '../../../constants';
import { decode } from '../Shared/encode';
import { seBookingAddressDetails } from '../../Shared/WebAnalytics';
import $ from 'jquery';
import Slide from "@material-ui/core/Slide";
HotJar();

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.setCart = this.setCart.bind(this);
        this.showMap = this.showMap.bind(this);
        this.showUpperSection = this.showUpperSection.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.showDetailsSection = this.showDetailsSection.bind(this);
        this.showByRelevantType = this.showByRelevantType.bind(this);
        this.displayInRadio = this.displayInRadio.bind(this);
        this.displayInCheckbox = this.displayInCheckbox.bind(this);
        this.displayInTextArea = this.displayInTextArea.bind(this);
        this.listViews = this.listViews.bind(this);
        this.submitDetailsAndNext = this.submitDetailsAndNext.bind(this);
        this.updateRequiredStatus = this.updateRequiredStatus.bind(this);
        this.addressUpdateCallBack = this.addressUpdateCallBack.bind(this);

        this.state = {
            width: 0,
            showErrorModal: false,
            message: '',
            newAddress: {},
            ctaDisabled: true
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let addressId = get(this.props, "booking.cart.address.id", '');
        if (addressId) {
            getAddressDetails(addressId, (response) => {
                let { display_helpers, settable_fields, info_fields } = get(response, "data.address", {});
                let display_headers = get(display_helpers, "v0.display_headers", {}),
                    display_array = get(display_helpers, "v0.display_array", []);
                this.setState({ info_fields, settable_fields, display_headers, display_array }, () => {

                    this.updateRequiredStatus();
                    $(document).ready(function () {
                        $('[data-toggle="tooltip"]').tooltip();
                    });
                });
            }, (err) => {
                this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
            })
        }
    }
    setCart(value) {
        this.props.setCart({
            ...this.props.booking,
            ...value
        })
    }
    updateRequiredStatus() {
        let { settable_fields, newAddress } = this.state,
            missingRequiredFields = [];
        if (!isEmpty(settable_fields)) {
            let settable_fields_keys = keys(settable_fields);
            missingRequiredFields = filter(settable_fields_keys, (val) => (settable_fields[val]["required"] && isEmpty(settable_fields[val]["value"]) && isEmpty(get(newAddress, val, null)) && val !== "city" && val!=="city_id"))
        }
        this.setState({ ctaDisabled: !isEmpty(missingRequiredFields) })
    }
    setFieldValue(path, value, isRequired) {
        let stt = this.state;
        set(stt, path, value)
        this.setState(stt, () => {
            if (this.state.ctaDisabled && isRequired) {
                this.updateRequiredStatus();
            }
        });
    }
    addressUpdateCallBack(address_id) {
        let cartId = get(this.props, "booking.cart.id", "");
        if (cartId && address_id) {
            updateCart(cartId, { address_id }, get(this.props,"fieldsHolder.csrfToken",""), (resp) => {
                this.props.assignToCart({ cart: decode(resp.data.cart) });
                if (get(this.props, "booking.cart.address.complete", false)) {
                    let address_type = get(this.props, "booking.cart.address.address_type", ""),
                        parking_details = get(this.props, "booking.cart.address.parking_info", ""),
                        market = get(this.props, "booking.cart.address.market", "");
                    seBookingAddressDetails(address_type, parking_details, false, get(this.props, "client.first_time_booker", false), "booking_23_control", "booking_flow", market);
                }
                this.props.closeDrawer();
            }, (err) => {
                this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
            })
        } else {
            this.props.closeDrawer();
        }
    }
    submitDetailsAndNext() {
        let { ctaDisabled, newAddress } = this.state;
        if (!ctaDisabled) {
            if (!isEmpty(newAddress)) {
                updateAddress(get(this.props, "booking.cart.address.id", ""), newAddress, (response) => {
                    this.addressUpdateCallBack(get(response, "data.address.id"));
                }, (err) => {
                    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
                })
            }
            else {
                this.props.closeDrawer();
            }
        }
    }
    listViews() {
        let { display_array } = this.state;
        if (!isEmpty(display_array)) {
            return (map(sortBy(display_array, [function (o) { return o.display_type !== 'edittext_instructions'; }, function (o) { return o.key !== 'parking_info'; }]), (displayObj) => (
                this.showByRelevantType(displayObj)
            )))
        }
        return null;
    }
    displayInRadio(viewObject) {
        let { key, title, subtitle, required, value, notes } = viewObject;
        let choices = sortBy(get(viewObject, "display_choices", []), ['display_order'])
        let formattedOptions = map(choices, (choice) => ({
            label: choice.title,
            value: choice.value,
            checked: choice.value === value,
            defaultValue: value
        }))
        return (<div className='col-xs-12 col-sm-6 p-16' key={`radio-${key}`}>
            <div className='size-24-32 medium-font color-black'>{title || ""}</div>
            {subtitle ? <div className='size-18-28 color-gray'>{subtitle || ""}</div> : null}
            {/* Choose one • Required */}
            <div className='size-16-20 medium-font color-black mt-8'>
                <RadioOptions name={key || ""} optionsArray={formattedOptions}
                    onChange={(value1) => this.setFieldValue(`newAddress.${key}`, value1, required)}
                    defaultValue={value}
                    required={required} />
            </div>
            {notes ? <div className='size-12-20 color-light-gray ml-16'>{notes}</div> : null}
        </div>
        );
    }
    displayInCheckbox(viewObject) {
        let choices = sortBy(get(viewObject, "display_choices", []), ['display_order']),
            { title, subtitle, key, notes, required } = viewObject;
        let formattedOptions = map(choices, (choice) => ({
            key: choice.key,
            label: choice.title,
            value: choice.value,
            checked: choice.is_selected
        }))
        return (<div key={`checkbox-${key}`} className={`col-xs-12 ${key === "parking_info" ? "" : 'col-sm-6'} p-16`}>
            <div className='size-24-32 medium-font color-black'>{title || ""}</div>
            {subtitle ? <div className='size-18-28 color-gray'>{subtitle || ""}</div> : null}
            <div className='size-16-20 medium-font color-black mt-8'>
                <CheckboxOptions optionsArray={formattedOptions}
                    onChange={(key1, checked) => this.setFieldValue(`newAddress.${key1}`, Boolean(checked), required)} />
            </div>
            {notes ? <div className='size-12-20 color-light-gray ml-16 max-width-50'>{notes}</div> : null}
        </div>);
    }
    displayInTextArea(viewObject) {
        let { key, value, required, title, subtitle, why, placeholder, notes } = viewObject;
        let rebook = get(this.props,"booking.cart.rebook", false);
        return (<div key={`textarea-${key}`} className="col-xs-12"><div className="row p-16-0">
            <div className='col-xs-12 col-sm-8'>
                <div className='size-24-32 medium-font color-black'>{title || ""}</div>
                {subtitle ? <div className='size-18-28 color-gray mb-24'>{subtitle || ""}</div> : null}
            </div>
            <div className='col-xs-12 col-sm-4 txt-right mb-24'>
                {rebook ? null : <button className='btn infoButton' data-toggle="tooltip" data-placement="bottom" title={why ? why.description : ""}>{why ? why.title : ""}</button>}
            </div>
            <div className='col-xs-12'>
                <textarea className='form-control gray-textarea mb-8' required={required} rows={5}
                    placeholder={placeholder || ""} defaultValue={value || ""}
                    onChange={(event) => this.setFieldValue(`newAddress.${key}`, event.target.value || "", required)}
                >
                </textarea>
                {notes ? <div className='size-12-20 color-light-gray '>{notes}</div> : null}
            </div>
        </div>
            <div className='col-xs-12'></div>
        </div>)
    }
    showByRelevantType(viewObject) {
        let type = get(viewObject, "display_type", "");
        if (type) {
            switch (type) {
                case "radiobutton_list":
                    return this.displayInRadio(viewObject);
                case "checkbox_list":
                    return this.displayInCheckbox(viewObject);
                case "edittext_instructions":
                    return this.displayInTextArea(viewObject);
            }
        }
        return null;
    }
    showMap() {
        let latitude = get(this.state, "info_fields.latitude", ''),
            longitude = get(this.state, "info_fields.longitude", '');
        if (latitude && longitude) {
            return (<div className='mb-20'>
                <MapContainer lat={latitude} lng={longitude} />
            </div>)
        }
        return null;
    }
    showUpperSection() {
        let { display_headers } = this.state;
        if (!isEmpty(display_headers)) {
            let { address_line_1, apt_number, city, state, zip_code, country, address_line_2 } = display_headers;
            let tempArr = [
                get(city, "value", ""),
                get(state, "value", ""),
                get(zip_code, "value", ""),
                get(country, "value", "")];

            let formattedAddressLine = join(compact(tempArr), ", "),
                aptNumberTitle = get(apt_number, "additional_attributes.title", ""),
                aptNumber = get(apt_number, "value", "");
            return (<><div className='row'>
                <div className='col-xs-12 col-sm-6 mb-24'>
                    <div className='size-16-20 color-black medium-font mb-4'>{get(address_line_1, "value", "")}</div>
                    <div className='size-14-20 color-gray'>{formattedAddressLine || ""}</div>
                    {!isEmpty(get(address_line_2, "value", "")) ? <div className='size-14-20 color-gray'>{get(address_line_2, "value", "")}</div> : null}
                </div>
                <div className='col-xs-12 col-sm-6 mb-24'>
                    <div className='form-label mb-8'>{aptNumberTitle}</div>
                    <input className='form-control gray-input ht-36' placeholder='123' defaultValue={aptNumber || ""}
                        onChange={(event) => this.setFieldValue("newAddress.apt_number", event.target.value || "", get(apt_number, "required", false))} />
                </div>
            </div>
                <hr className='light-gray-hr' />
            </>)
        }
        return null;
    }
    showDetailsSection() {
        return (<div className='row sm-border-btm'>{this.listViews()}</div>)
    }

    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }

    render() {
        let { ctaDisabled } = this.state,
        rebook = get(this.props,"booking.cart.rebook", false);
        return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div>
                <div className='display-flex mb-106'>
                    {rebook ? null : <a className='close-icon-link size-18-24' onClick={this.props.closeDrawer}>
                        <img className='close-icon' src={closeIcon} alt='close-arrow' /> Close
                    </a>}
                    <div className='max-width-55'>
                        {rebook?
                        <div className='size-24-32 color-black medium-font mb-24'>Finish address details</div>
                        :<div className='size-44-52 sm-size-18-24 color-black medium-font txt-center mb-24 mt-80'>Finish address details</div>}
                        {this.showMap()}
                        <div className='size-24-32 color-black medium-font mb-8 sm-shown'>Confirm address</div>
                        <div className='mb-32'>
                            {this.showUpperSection()}
                            {this.showDetailsSection()}
                        </div>
                        <CTAButton text="Save address details" disabled={ctaDisabled} additionalWrapperClass="justify-content-center"
                            action={this.submitDetailsAndNext}
                            additionalClass={rebook ? "full-width-btn" : ""}
                        />
                    </div>
                </div>
                {this.errorModal()}
            </div>
        </Slide>
        );
    }
}

const mapStateToProps = state => ({
    addresses: state.addresses,
    booking: state.booking,
    bookingFlow: state.bookingFlow,
    client: state.client,
    fieldsHolder: state.fieldsHolder
});

export default withLocalize(connect(mapStateToProps, { setBookingFlowStep, setCart, setBookingFlowPreviousStep })(Index));