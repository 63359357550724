/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';

function HttpsWWWRedirect({ children }) {
  if (typeof window === 'undefined' || !window.location || window.location.hostname === 'localhost') {
    return children;
  }

  let url = window.location.href;

  if (window.location.protocol === 'http:') {
    url = window.location.href.replace(/^http(?!s)/, 'https');
  }

  if (!window.location.hostname.includes('www.') && !window.location.hostname.includes('herokuapp') && !window.location.hostname.includes('ww1')) {
    url = url.replace('://', '://www.');
  }

  if (url !== window.location.href) {
    window.location.href = url;
    return null;
  }

  return children;
}

HttpsWWWRedirect.propTypes = {
  children: PropTypes.node,
};

export default HttpsWWWRedirect;
