/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import Vase from '../../Assets/Images/empty_state_svg.svg';

export default class EmptyState extends React.Component {
  render() {
    return (
      <div className="txt-center br-gray border-radius-8 p-40 mb-32">
        <div><img src={Vase} className="mb-16 width-44" alt="No bookings" /></div>
        <div className="mb-8 medium-font color-black size-14-16">Nothing on the books...yet</div>
        <div className="mb-14 color-secondary-gray size-12-20">Wellness and beauty treatments are just a few taps away</div>
        <div>
          <button
            type="button"
            className="btn border-radius-36 padding-10-12 color-deep-orange bg-peach medium-font size-14-16"
            onClick={() => {
              window.location.href = '/booking/';
            }}
          >
            Book now
          </button>
        </div>
      </div>
    );
  }
}
