/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get } from 'lodash';
import Delete from '@material-ui/icons/Delete';
import ColumnsList from '../../Shared/ColumnsList';
import { removeSubscription } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import ErrorModal from '../../Shared/ErrorModal';
import { defaultErrorMessage } from '../../../constants';

class SoothePassInCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      showErrorModal: false,
    };
    this.deleteSub = this.deleteSub.bind(this);
    this.errorModal = this.errorModal.bind(this);
  }

  deleteSub() {
    const cartId = get(this.props, 'booking.cart.id', '');
    if (cartId) {
      removeSubscription(cartId, (response) => {
        this.props.assignToCart({ cart: decode(response.data.cart) });
      }, (err) => {
        this.setState({ showErrorModal: true, error: get(err, 'errors.0.message', defaultErrorMessage) });
      });
    }
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const soothePassObj = get(this.props, 'booking.cart.info_fields.soothe_pass', null);
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);

    if (alreadyAppliedToCart && !isEmpty(soothePassObj)) {
      return (
        <>
          <div className="row">
            <div className="col-xs-10 col-sm-11">
              <ColumnsList
                headerText={<div className="medium-font">SoothePass™</div>}
                itemsList={[{
                  text: (<div>
                    {get(soothePassObj, 'subscription_tier_name', '')}
                    ,
                    {' '}
                    {get(soothePassObj, 'subscription_points', 0)}
                    {' '}
                    points
                  </div>),
                }, {
                  text: (<div>{get(soothePassObj, 'subscription_tier_price', '')}</div>),
                }]}
              />
            </div>
            <div className="col-xs-2 col-sm-1">
              <button
                type="button"
                className="btn edit-icon"
                onClick={() => this.deleteSub()}
              >
                <Delete />
              </button>
            </div>
          </div>
          {this.errorModal()}
        </>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

SoothePassInCart.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(SoothePassInCart));
