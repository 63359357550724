import React from 'react';
import { get, snakeCase, trim } from 'lodash';
import { pleaseEnterError, ACTIVE_LANGUAGE_CODE, EN } from './../../constants';

export default class GiftCardsBase extends React.Component {
  constructor(props){
    super(props);

    this.validateParams = this.validateParams.bind(this);

    this.state = {
      error: ''
    }
  }

  validateParams ({ requiredParams, params }) {
    let error = '';

    this.setState({ error: '' });

    requiredParams.forEach((requiredParam) => {
      let value = get(params, requiredParam);

      if (!value || trim(String(value)) === '') {
        let humanReadableRequiredParam = snakeCase(requiredParam).split('_').join(' ');
        let ein = ACTIVE_LANGUAGE_CODE === EN ? '' : ' ein';

        error += `${pleaseEnterError} ${humanReadableRequiredParam}${ein}. `
      }
    });

    if (error === '') {
      return true;
    } else {
      this.setState({ error });
      return false;
    }
  }
}
