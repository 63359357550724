/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import SelectTimeWrapper from '../../Shared/SelectTimeWrapper';

class HourPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="max-width-315 mb-24">
        <div className="medium-font size-18-24 color-black mb-8 size-16-20">Preferred Time</div>
        <div className="gray-select-style">
          <SelectTimeWrapper
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
export default HourPicker;
