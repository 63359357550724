import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import '../index.css'
import { GIFT_CARD_STEP1, GIFT_CARD_DEFAULT } from '../shared/constants';
import { giftCardStartEvent } from '../../Shared/WebAnalytics'

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      learnMore: false,
      selectAMarketError: false,
      showGcCitiesModal: false,
      giftCardCities: []
    };

    this.props.addTranslation(translations);
    // Check whether the user is coming back from the checkout flow
    // If no, then reset the city id
    // If yes, preserve last city id and take to step one
    if (!this.props.giftCardCart.backFromCheckout) {
      // Reset city_id every time you hit the landing page
      this.props.setGiftCardCart({ ...this.props.giftCardCart, cityId: null });
    } else {
      this.props.setGiftCardCart({ ...this.props.giftCardCart, backFromCheckout: false });
      this.props.setGiftCard({ ...GIFT_CARD_DEFAULT, ...this.props.giftCard, page: GIFT_CARD_STEP1 });
    }
  }

  render() {
    return (
      <div className="full-height-section blue-subtle font-style-medium txt-align-center p-large pb-0 step0" id="0">
        <img className="double-card-img mb-30" src={'https://s3.amazonaws.com/soothe-public-assets/web/gift-cards/live/gift-cards-start.png'} />
        <div className="title-1 mb-24 mb-sm-8">Give the gift of wellness</div>
        <div className="text font-style-regular mb-30">We make our gift cards super easy to give and use by matching them to the cost of some of our favorite services. Send instantly or schedule ahead for special occasions.</div>
        <div className=""><button className="btn cta-btn cactus-flower-primary m-0" onClick={() => {
          if (this.props.step === 0) {
            giftCardStartEvent();
          }
          this.props.scrollToStep(1)
        }}>Shop now</button></div>
        {/* for later */}
        {/* <div className=""><a className="label-style dark-blue-primary">Gift card FAQs</a></div> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart
});

export default withLocalize(connect(mapStateToProps, { setGiftCard, setGiftCardCart })(Landing));
