/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { map } from 'lodash';
import { navigationViewLegalPages } from '../WebAnalytics';
import appStoreIcon from '../../../Assets/Images/download_app_store.png';
import googlePlayIcon from '../../../Assets/Images/download_google_play.png';
import { FOOTER_LINKS, SOCIAL_LINKS } from './constants';
import './Footer.css';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };

    this.relevantLinksView = this.relevantLinksView.bind(this);
    this.linksInGrids = this.linksInGrids.bind(this);
    this.linksInExpandable = this.linksInExpandable.bind(this);
    this.appsAndSocialLinks = this.appsAndSocialLinks.bind(this);
    this.socialLinksAndCopy = this.socialLinksAndCopy.bind(this);
    this.getAppsSection = this.getAppsSection.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  }

  relevantLinksView() {
    if (this.state.width > 1000) {
      return this.linksInGrids();
    }
    return this.linksInExpandable();
  }

  linksInGrids() {
    return (
      <div className="d-flex-only justify-content-space-between medium-font pb-32 border-bottom-transparent">
        {map(FOOTER_LINKS, (colObj, ind) => (
          <div key={`footer-col-${ind}`} className="">
            <div className="size-18-24 mb-16">{colObj.title}</div>
            {map(colObj.linksList, (lnk, it) => (
              <div key={`footer-link-${ind}-${it}`} className="mb-16">
                <a href={lnk.linkAddress}>{lnk.linkText}</a>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  linksInExpandable() {
    return (<div />);
  }

  appsAndSocialLinks() {
    return (
      <div className="row pt-32">
        <div className="col-xs-12 col-sm-2">
          {this.getAppsSection()}
        </div>
        <div className="col-xs-12 col-sm-10">
          {this.socialLinksAndCopy()}
        </div>
      </div>
    );
  }

  socialLinksAndCopy() {
    return (
      <>
        <div className="mb-20">
          {map(SOCIAL_LINKS, (lnk, itr) => (
            <a key={`social-link-${itr}`} href={lnk.linkAddress} className="mr-16">
              <img src={lnk.imgSrc} alt={`Social-link-${itr}`} />
            </a>
          ))}
        </div>
        <div className="max-width-50 size-12-20">
          Copyright 2021, Soothe Inc. Please read our
          {' '}
          <a href="https://www.soothe.com/terms-and-conditions/" onClick={() => navigationViewLegalPages()}>
            Terms and Conditions
          </a>
          {' '}
          and
          {' '}
          <a
            href="https://www.soothe.com/privacy-policy/"
            onClick={() => navigationViewLegalPages()}
          >
            {' '}
            Privacy Policy
          </a>
          {' '}
          for more information.
        </div>
      </>
    );
  }

  getAppsSection() {
    return (
      <>
        <div className="mb-16">
          <a href="https://apps.apple.com/us/app/soothe-in-home-massage/id811054908?ls=1">
            <img src={appStoreIcon} alt="Apple store" />
          </a>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.soothe.client&hl=en_US">
            <img src={googlePlayIcon} alt="Google play store" />
          </a>
        </div>
      </>
    );
  }

  render() {
    return (
      <footer className="background-darkest-blue content-primary-white">
        <div className="max-width-75 ptb-40">
          {this.relevantLinksView()}
          {this.appsAndSocialLinks()}
        </div>
      </footer>
    );
  }
}
