/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';

class RecipientName extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ptb-20">
        <div className="size-16-20 color-black medium-font mb-8">Client Name*</div>
        <div>
          <input
            type="text"
            className="gray-input form-control size-16-20 p-16-12"
            defaultValue={this.props.defaultValue || ''}
            placeholder="Enter name"
            onChange={(event) => this.props.updateClientName('clientName', event.target.value || '')}
            maxLength={255}
            id={this.props.isFirstCP ? 'clientNameTextField' : 'secondClientNameTextField'}
          />
        </div>
        {this.props.quantity === 2 ? (
          <div className="mt-16">
            <input
              type="text"
              className="gray-input form-control size-16-20 p-16-12"
              id="secondClientNameTextField"
              placeholder="Enter name"
              defaultValue={`${this.props.defaultValue || 'Client'}'s Guest `}
              onChange={(event) => this.props.updateClientName('client2', event.target.value || '')}
              maxLength={255}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
export default RecipientName;
