/* eslint-disable default-param-last */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import { get, omit } from 'lodash';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';

export function getCartAndUserId(key, callBackFunc, errorHandler) {
  if (key) {
    axios.get(`${API_ROOT}/v7/carts/lookup?lookup_code=${key}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
          // {
          //     "result": true,
          //     "cart": {
          //         "cart_id": 32092,
          //         "client_id": 1576344,
          //         "client_email": "asdf@soothe.com"
          //     }
          // }
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function getCartDetails(cartId, callBackFunc, errorHandler) {
  if (cartId) {
    axios.get(`${API_ROOT}/v7/carts/${cartId}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function extendAppointment(cartId, cartProductId, cart_product, callBackFunc, errorHandler) {
  if (cartId && cartProductId) {
    axios.post(`${API_ROOT}/v7/carts/${cartId}/cart_products/${cartProductId}/add_extension`, {
      cart_product,
    }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function rescheduleAppointment(cartId, cart, callBackFunc, errorHandler) {
  if (cartId) {
    axios.post(`${API_ROOT}/v7/carts/${cartId}/reschedule`, {
      therapist_id: get(cart, 'therapist_id', ''),
      cart: omit(cart, 'therapist_id'),
    }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function getCancellationReasons(callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/client_cancellation_reasons`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
}
export function cancelAppointment(cartId, cart, callBackFunc, errorHandler) {
  if (cartId) {
    // delete methods require wrapping the payload inside "data" object
    axios.delete(`${API_ROOT}/v7/carts/${cartId}`, { data: { cart } }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function appReview(review, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/app_review`, {
    review,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
}
export function tipProvider(cartId, cartProductId, tip, callBackFunc, errorHandler) {
  // {"tip" : {
  //         "amount": 20.0,
  //         "percentage_of_original": 0.5
  //     }
  // }
  if (cartId && cartProductId) {
    axios.post(`${API_ROOT}/v7/carts/${cartId}/cart_products/${cartProductId}/tip`, {
      tip,
    }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function getProPrivateProfile(proId, callBackFunc, errorHandler) {
  if (proId) {
    axios.get(`${API_ROOT}/therapists/${proId}/pro_profile/`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}

export function createRebookCart(address_id, therapist_id, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/v7/carts`,
    {
      cart: {
        therapist_id,
        address_id,
      },
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getUserAddresses(userId, loggedIn, callBackFunc, errorHandler) {
  if (userId && loggedIn) {
    axios.get(`${API_ROOT}/users/${userId}/addresses`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function getProductsByParam(queryParam, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/v7/products${queryParam}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getProductsByProvider(providerId, callBackFunc, errorHandler) {
  if (providerId) {
    axios.get(`${API_ROOT}/therapists/${providerId}/products`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      if (callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function getAvailableTimes(cartId, callBackFunc, errorHandler) {
  if (cartId) {
    axios.get(`${API_ROOT}/carts/${cartId}/immediate`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function getAvailableTimesOfPro(
  { cartId, cart_product_id, therapist_id },
  callBackFunc,
  errorHandler,
) {
  if (cartId && cart_product_id && therapist_id) {
    axios.get(`${API_ROOT}/carts/${cartId}/immediate?cart_product_id=${cart_product_id}&therapist_id=${therapist_id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
// get times to reschedule confirmed appointment
export function getTimesByProvider(userId, providerId, cartId, callBackFunc, errorHandler) {
  if (userId && providerId) {
    axios.get(`${API_ROOT}/clients/${userId}/therapist_rebook/${providerId}/times?cart_id=${cartId}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
  }
}
export function requestRescheduleConfirmed(cart_product_id, cart_product, message, late_reschedule_fee, instant = false, credit_card_id = '', callBackFunc, errorHandler) {
  // {
  //     "cart_product": {
  //       "datetime": ["2022-08-03T10:00:00.000-07:00"]
  //     },
  //     “message”: “My babysitter cancelled and I cannot …”  ,
  //     "instant": true    ← if client selects IC time.
  //     "reason": reschedule_reason_id,     ← optional
  //     "credit_card_id": 33     ← optional
  //   }
  if (cart_product_id) {
    let payload = {
      cart_product,
      message,
      late_reschedule_fee,
    };
    if (instant) {
      payload = { ...payload, instant };
    }
    if (credit_card_id) {
      payload = { ...payload, credit_card_id };
    }
    axios.post(`${API_ROOT}/cart_products/${cart_product_id}/reschedule`, payload, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function acceptReschedule(cart_product_id, cart_product, callBackFunc, errorHandler) {
  // payload form
  // { "cart_product": { "session_date": "2022-10-05", "session_time": "13:22" } }
  if (cart_product_id) {
    axios.post(`${API_ROOT}/cart_products/${cart_product_id}/reschedule/accept`, {
      cart_product,
    }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function declineRequest(cart_product_id, callBackFunc, errorHandler) {
  if (cart_product_id) {
    axios.post(
      `${API_ROOT}/cart_products/${cart_product_id}/reschedule/reject`,
      {},
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    )
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function openChat(participant_ids, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/chats/`,
    {
      participant_ids,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
