/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Helmet } from 'react-helmet';

export default function HelmetTag(props) {
  return (
    <Helmet>
      <title>{ props.title ? props.title : 'Soothe - Mobile Massage, Personal Care & Wellness Delivered To You' }</title>
      <meta name="description" content={props.description ? props.description : 'Soothe is the world’s leading wellness platform, enabling you to connect with the best mobile wellness professionals. Massage, skincare, haircuts, and beauty treatments – let the spa come to you in the safety and comfort of your own home or office.'} />
      <meta charset="utf-8" />
      <meta name="google-site-verification" content="JZ0SfwKHaeSJiV207Yu-WyKr1N0ju5ohq84kSaXF2t8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta property="fb:app_id" content="391496747628628" />
      <meta property="og:site_name" content="Soothe - Mobile Massage, Personal Care & Wellness Delivered To You" />
      <meta property="og:title" content="Soothe - Mobile Massage, Personal Care & Wellness Delivered To You | In-home massage in an hour" />
      <meta property="og:description" name="og:description" content="Soothe - Mobile Massage, Personal Care & Wellness on Demand delivers world-class massages to your doorstep in as little as 1 hour." />
      <meta property="og:image" content="https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/06/24175311/soothe_meta_share.jpg" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.soothe.com" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@soothe" />
      <meta name="twitter:description" content="Soothe is the world’s leading wellness platform, enabling you to connect with the best mobile wellness professionals. Massage, skincare, haircuts, and beauty treatments – let the spa come to you in the safety and comfort of your own home or office." />
      <meta name="twitter:creator" content="@soothe" />
      <meta name="twitter:domain" content="soothe.com" />
      <meta name="twitter:image" content="https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/06/24175311/soothe_meta_share.jpg" />

      {/* GLADLY - CHAT SDK */}
      <script>
        {`
          !function(c,n,r,t){if(!c[r]){var i,d,p=[];d="PROD"!==t&&t?"STAGING"===t?"https://cdn.gladly.qa/gladly/chat-sdk/widget.js":t:"https://cdn.gladly.com/chat-sdk/widget.js",c[r]={init:function(){i=arguments;var e={then:function(t){return p.push({type:"t",next:t}),e},catch:function(t){return p.push({type:"c",next:t}),e}};return e}},c.__onHelpAppHostReady__=function(t){if(delete c.__onHelpAppHostReady__,(c[r]=t).loaderCdn=d,i)for(var e=t.init.apply(t,i),n=0;n<p.length;n++){var a=p[n];e="t"===a.type?e.then(a.next):e.catch(a.next)}},function(){try{var t=n.getElementsByTagName("script")[0],e=n.createElement("script");e.async=!0,e.src=d+"?q="+(new Date).getTime(),t.parentNode.insertBefore(e,t)}catch(t){}}()}}
          (window,document,'Gladly','PROD')
        `}
      </script>

      {/* IMPACT - Universal Tracking Tag */}

      <script type="text/javascript">
        {`
          (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://d.impactradius-event.com/A2613579-3c82-4983-9117-5e05e5cada681.js','script','ire',document,window);
        `}
      </script>
    </Helmet>
  );
}
