/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  isEmpty, get, forEach, find, size,
} from 'lodash';
import Slide from '@material-ui/core/Slide';
import moment from 'moment-timezone';
import { Checkbox } from '@material-ui/core';
import {
  setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep, setBookingFlowComingFrom,
} from '../../../Actions';
import { STEPS } from '../Shared/constants';
import { decode } from '../Shared/encode';
import AppointmentDate from './AppointmentDate';
import AppointmentAddress from './AppointmentAddress';
import Appointments from './Appointments';
import ErrorModal from '../../Shared/ErrorModal';
import './Assets/Styles/index.css';
import FinishAddressStep from '../FinishAddressStep';
import { deleteCartProduct, updateCart, updateCartProduct } from '../Shared/helpers';
import CTAButton from '../../Shared/CTAButton';
import {
  HotJar, defaultErrorMessage, dynamicCopyForDefaultBlindAuction,
  hasUuid,
} from '../../../constants';
import { seBookingReview } from '../../Shared/WebAnalytics';
import SubscriptionPlanPopup from './SubscriptionPlanPopup';
import AppointmentRoom from './AppointmentRoom';
import UnchangedAuthModal from '../../Shared/UnchangedAuthModal';
import { getCartWithSalesTax } from '../../Shared/Helpers';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enhancementsByProductId: {},
      error: '',
      showErrorModal: false,
      openFinishAddressDrawer: false,
      showPassPlanModal: false,
      showPassPlanModalWithoutToaster: false,
      showLoginPopup: false,
      width: window.innerWidth,
    };
    this.setCart = this.setCart.bind(this);
    this.enhancementClick = this.enhancementClick.bind(this);
    this.setEnhancementToProduct = this.setEnhancementToProduct.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.getCartCallbackFunc = this.getCartCallbackFunc.bind(this);
    this.displayFinishAddressDrawer = this.displayFinishAddressDrawer.bind(this);
    this.getRelevantCTAButton = this.getRelevantCTAButton.bind(this);
    this.deleteRelevantService = this.deleteRelevantService.bind(this);
    this.triggerReviewEvent = this.triggerReviewEvent.bind(this);
    this.soothePassPlanModal = this.soothePassPlanModal.bind(this);
    this.displayFinishAddressDrawer = this.displayFinishAddressDrawer.bind(this);
    this.goToCheckoutAction = this.goToCheckoutAction.bind(this);
    this.blindAuctionOption = this.blindAuctionOption.bind(this);
    this.updateBlindAuctionOption = this.updateBlindAuctionOption.bind(this);
  }

  componentDidMount() {
    const cart = get(this.props, 'booking.cart', null);
    const marketplaceEnabled = get(cart, 'info_fields.marketplace_enabled', false);
    const rebook = get(cart, 'rebook', false);
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    this.triggerReviewEvent();
    this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');

    // Setting previous step of booking flow
    const pickAProEnabled = get(this.props, 'booking.cart.pickAProEnabled', false);
    let previousStepKey = 'TIMING';
    if (pickAProEnabled && size(get(this.props, 'booking.cart.cartProducts', [])) === 1 && (!isEmpty(get(this.props, 'bookingFlow.availableTherapists', [])) || !isEmpty(get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', [])))) {
      previousStepKey = 'PICKAPRO';
    }
    const previousStep = find(STEPS, (step) => (step.id === previousStepKey));
    this.props.setBookingFlowPreviousStep(previousStep);
    this.props.setBookingFlowComingFrom('REVIEW');
    if (!rebook && !get(this.props, 'booking.cart.blind_auction_switch', false) && marketplaceEnabled && !get(this.props, 'booking.instant_confirmation', false) && !get(this.props, 'booking.find_for_me', false)) {
      this.updateBlindAuctionOption(true);
    } else {
      this.updateBlindAuctionOption(false);
    }
  }

  triggerReviewEvent() {
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const service_nb_people = size(get(cart, 'cartProducts', []));
    seBookingReview(get(cart, 'id', ''), get(cart, 'rebook', false), get(this.props, 'booking.product.title', '').toLowerCase(), service_nb_people, this.props.client.first_time_booker, 'booking_23_variant', {
      market: get(cart, 'address.market', ''),
      is_ic: get(this.props, 'booking.instant_confirmation', false),
      lead_time_selected: bkngDate.diff(nowDate, 'hours'),
      is_today: bkngDate.isSame(nowDate, 'day'),
      blind_auction_opt_in: get(this.props, 'booking.cart.blind_auction_switch', false),
      test_name: 'jul_2024_booking_enhancements',
      test_value: get(this.props, 'jul_2024_booking_enhancements', ''),
    });
  }

  getCartCallbackFunc(response) {
    this.props.assignToCart({ cart: decode(response.data.cart) });
  }

  setCart(value) {
    this.props.setCart({
      ...this.props.booking,
      ...value,
    });
  }

  goToCheckoutAction() {
    const isSoothePassAvailable = get(this.props, 'booking.cart.info_fields.soothe_pass.available', false) && !hasUuid();
    const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false);
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);
    const alreadyShownPopup = get(this.props, 'bookingFlow.soothePassPopupAlreadyShown', false);
    const subscription_membership_ends_date = get(this.props, 'client.soothe_pass.subscription_membership_ends_date', '');
    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (!loggedIn) {
      return this.setState({ showLoginPopup: true });
    }

    this.setState({ showLoginPopup: false });

    if (isSoothePassAvailable && !alreadyAppliedToCart
      && !isSoothePassSubscribed && !alreadyShownPopup && !subscription_membership_ends_date) {
      this.setState({ showPassPlanModal: true, showPassPlanModalWithoutToaster: false });
    } else {
      this.goToNextStep();
    }
  }

  updateBlindAuctionOption(optn) {
    this.props.setLoaderFlag(true);
    updateCart(get(this.props, 'booking.cart.id', null), { blind_auction_switch: optn }, '', (resp) => {
      this.props.assignToCart({ cart: decode(resp.data.cart) });
      this.props.setLoaderFlag(false);
    }, () => {
      this.props.setLoaderFlag(false);
    });
  }

  blindAuctionOption() {
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const marketplaceEnabled = get(cart, 'info_fields.marketplace_enabled', false);
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const forced_blind_auction_switch = get(cart, 'info_fields.forced_blind_auction_switch', false);
    const gaAllowed = get(this.props, "booking.cart.info_fields.ga_pap_enabled", false);
    if (!gaAllowed) {
      return null;
    }
    if (forced_blind_auction_switch) {
      return (
        <div className="contentSecondary size-14-20 mb-24">
          We'll automatically request new Providers if your request is not confirmed within {get(cart, 'info_fields.blind_auction_time', '')}.
        </div>
      );
    }
    if (!forced_blind_auction_switch && !rebook && marketplaceEnabled && !get(this.props, 'booking.instant_confirmation', false) && !get(this.props, 'booking.find_for_me', false)) {
      return (
        <div className="row align-items-center p-16">
          <div className="col-xs-1 col-sm-2 centered p-12">
            <Checkbox
              checked={get(this.props, 'booking.cart.blind_auction_switch', false)}
              onChange={(e) => {
                this.updateBlindAuctionOption(e.target.checked);
              }}
              name="blind_auction_switch"
              style={{ transform: 'scale(1.6)' }}
            />
          </div>
          <div className="col-xs-11 col-sm-10 size-14-20 contentSecondary txt-left">
            {dynamicCopyForDefaultBlindAuction(bkngDate.diff(nowDate, 'hours'))}
          </div>
        </div>
      );
    }
    return null;
  }

  getRelevantCTAButton() {
    const isCompleteAddress = get(this.props, 'booking.cart.address.complete', false);
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const forced_blind_auction_switch = get(this.props, 'booking.cart.info_fields.forced_blind_auction_switch', false);
    const cart = get(this.props, 'booking.cart', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const gaAllowed = get(this.props, "booking.cart.info_fields.ga_pap_enabled", false);
    if (isCompleteAddress) {
      return (
        <CTAButton
          text="Go to checkout"
          action={this.goToCheckoutAction}
          additionalClass={rebook ? 'full-width-btn' : ''}
          relevantId="goToCheckoutButton"
          topSection={this.state.width >= 800 ? null : this.blindAuctionOption()}
          bottomSection={forced_blind_auction_switch && this.state.width >= 800 && gaAllowed
            ? <div className="contentSecondary size-14-20 pl-16 mb-16 txt-right">We'll automatically request new Providers if your request is not confirmed within {get(cart, 'info_fields.blind_auction_time', '')}.</div>
            : ''}
          showBottomSectionOnly={this.state.width >= 800}
        />
      );
    }

    return (
      <CTAButton
        text="Add address details"
        action={() => {
          this.setState({ openFinishAddressDrawer: true });
          if (this.props.updateExceptionUi) {
            this.props.updateExceptionUi(true);
          }
        }}
      />
    );
  }

  enhancementClick(cartProductId, enhArr) {
    const curr = get(this.state, 'enhancementsByProductId', {});
    curr[cartProductId] = enhArr;
    this.setState({ enhancementsByProductId: curr }, () => {
      if (!isEmpty(curr)) {
        this.props.setLoaderFlag(true);
        forEach(curr, (enhs, pId) => {
          this.setEnhancementToProduct(pId, enhs);
        });
      }
    });
  }

  setEnhancementToProduct(cartProductId, optionIds) {
    if (cartProductId) {
      updateCartProduct(this.props.booking.cart.id, cartProductId, {
        option_ids: optionIds,
      }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
        this.props.assignToCart({ cart: decode(response.data.cart) });
        this.props.setLoaderFlag(false);
      }, (err) => {
        this.props.setLoaderFlag(false);
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      });
    }
  }

  deleteRelevantService(cart_product_id) {
    const cartId = get(this.props, 'booking.cart.id', '');
    deleteCartProduct(cartId, cart_product_id, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      this.props.assignToCart({ cart: decode(response.data.cart) });
    }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
  }

  goToNextStep() {
    const nextStep = find(STEPS, (step) => (step.id === 'CHECKOUT'));
    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (loggedIn) {
      getCartWithSalesTax(get(this.props, 'booking.cart.id', ''), (resp) => {
        this.props.assignToCart({ cart: decode(resp.data.cart) });
        this.props.setBookingFlowStepThroughParent(nextStep);
      }, () => {
        this.props.setBookingFlowStepThroughParent(nextStep);
      });
    }
  }

  soothePassPlanModal() {
    if (this.state.showPassPlanModal) {
      return (
        <SubscriptionPlanPopup
          assignToCart={this.props.assignToCart}
          withoutToaster={this.state.showPassPlanModalWithoutToaster}
          goToNextStep={this.goToNextStep}
          showHideModal={(val) => {
            this.setState({ showPassPlanModal: val, showPassPlanModalWithoutToaster: false });
          }}
        />
      );
    }
  }

  displayFinishAddressDrawer() {
    if (this.state.openFinishAddressDrawer) {
      return (
        <FinishAddressStep
          closeDrawer={() => {
            this.setState({ openFinishAddressDrawer: false });
            if (this.props.updateExceptionUi) {
              this.props.updateExceptionUi(false);
            }
          }}
          assignToCart={this.props.assignToCart}
          displayError={this.props.displayError}
        />
      );
    }
    return null;
  }

  displayLoginModal() {
    if (this.state.showLoginPopup) {
      return (
        <UnchangedAuthModal
          cart_id={get(this.props, 'booking.cart.id', '')}
          next={this.goToCheckoutAction}
          closeModal={() => this.setState({ showLoginPopup: false })}
        />
      );
    }
    return null;
  }

  errorModal() {
    return (
      <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: '' }); }}>
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const guest = Boolean(hasUuid() && get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const blndAuction = this.blindAuctionOption();
    const forced_blind_auction_switch = get(this.props, 'booking.cart.info_fields.forced_blind_auction_switch', false);
    return (
      <Slide direction="up" in mountOnEnter unmountOnExit>
        <div>
          <div className="mb-106 mt-80 sm-mt-0-imp">
            <div className={guest || rebook ? 'max-w-50-vw' : 'max-width-55'}>
              <div className={`size-44-52 sm-size-28-36 ${guest ? 'color-white txt-shadow sm-color-black' : 'contentPrimary'} medium-font txt-center sm-txt-left mb-24 sm-mb-12`}>Review</div>
              <div className="background-primary border-radius-16 pt-24 sm-p-0">
                <div className={`p-0-60-24 ${guest ? 'pt-24' : ''} sm-p-0`}>
                  <div className="br-btm-gray">
                    <AppointmentDate />
                    <AppointmentAddress showFinishAddress={() => {
                      this.setState({ openFinishAddressDrawer: true });
                      if (this.props.updateExceptionUi) {
                        this.props.updateExceptionUi(true);
                      }
                    }}
                    />
                    <AppointmentRoom
                      assignToCart={this.props.assignToCart}
                      displayError={this.props.displayError}
                    />
                  </div>
                  <Appointments
                    assignToCart={this.props.assignToCart}
                    enhancementClick={this.enhancementClick}
                    enhancementsByProductId={this.state.enhancementsByProductId}
                    deleteRelevantService={this.deleteRelevantService}
                    openPassPlanPopup={() => {
                      this.setState({
                        showPassPlanModal: true,
                        showPassPlanModalWithoutToaster: true,
                      });
                    }}
                  />
                  {this.state.width >= 800 && blndAuction && !forced_blind_auction_switch ? <div className="br-t-opaque">{blndAuction}</div> : null}
                </div>
                {this.getRelevantCTAButton()}
              </div>
            </div>
          </div>
          {this.soothePassPlanModal()}
          {this.displayFinishAddressDrawer()}
          {this.displayLoginModal()}
          {this.errorModal()}
        </div>
      </Slide>
    );
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
  creditCards: state.creditCards,
  bookingFlow: state.bookingFlow,
  fieldsHolder: state.fieldsHolder,
});

Index.propTypes = {
  booking: PropTypes.object,
  bookingFlow: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  creditCards: PropTypes.array,
  setBookingFlowStep: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
  setAddresses: PropTypes.func.isRequired,
};
export default withLocalize(connect(mapStateToProps, {
  setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep, setBookingFlowComingFrom,
})(Index));
