/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-string-refs */
/* eslint-disable import/extensions */
/** **************************************************
class Example extends React.Component {
  constructor(props){
    super(props);

    this.phoneInputChange = this.phoneInputChange.bind(this);

    this.state = {
      isValidNumber: false,
      number: '',
      countryCode: ''
    };
  }

  phoneInputChange({ isValidNumber, number, countryCode }){
    this.setState({ isValidNumber, number, countryCode });
  }

  render() {
    return (
      <PhoneInput onPhoneNumberChange={ this.phoneInputChange }
      className={`intl-tel-input form-control ${this.state.isValidNumber ? 'valid' : 'invalid'}`}/>
    );
  }
}
**************************************************** */
import React from 'react';
import PropTypes from 'prop-types';
import intlTelInput from 'intl-tel-input';
import { get, omit } from 'lodash';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils.js';

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iti: null,
    };

    this.phoneChange = this.phoneChange.bind(this);
  }

  componentDidMount() {
    const iti = intlTelInput(this.refs.phone, {
      preferredCountries: ['us', 'gb', 'ca', 'au', 'de'],
      ...get(this.props, 'extraconfig', {}),
    });

    if (this.props.phone) {
      iti.setNumber(this.props.phone);
    }
    const phoneInput = document.getElementById('phone-input');
    this.setState({ iti });
    const self = this;
    phoneInput.addEventListener('countrychange', () => {
      self.phoneChange();
    });
  }

  componentWillUnmount() {
    if (this.state.iti) {
      this.state.iti.destroy();
    }
  }

  phoneChange() {
    const error = this.state.iti.getValidationError();
    const number = this.state.iti.getNumber();
    const isValidNumber = this.state.iti.isValidNumber();
    const countryCode = get(this.state.iti.getSelectedCountryData(), 'iso2');

    this.props.onPhoneNumberChange({
      isValidNumber, number, error, countryCode,
    });
  }

  render() {
    const number = this.state.iti && this.state.iti.getNumber();
    const isValidNumber = this.state.iti && this.state.iti.isValidNumber();

    return (
      <div className={this.props.customstyle || ''}>
        <label style={{
          display: `${this.props.showLable ? 'inline-block' : 'none'}`,
          //  position: 'absolute',
          color: '#5F6368',
          width: 'auto',
          fontSize: '13px',
          marginTop: '-25px',
          fontFamily: 'Poppins-Medium',
          transition: 'all 0.5s ease',
          pointerEvents: 'none',
        }}
        >
          Mobile Number
        </label>
        <input
          type="text"
          ref="phone"
          id="phone-input"
          onChange={this.phoneChange}
          {...omit(this.props, ['onPhoneNumberChange', 'showLable', 'className'])}
          className={`${this.props.className || ''} ${isValidNumber || !number ? '' : 'error-state-input'}`}
        />
      </div>
    );
  }
}

PhoneInput.propTypes = {
  onPhoneNumberChange: PropTypes.func.isRequired,
};

export default PhoneInput;
