/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
import React from 'react';
import './MainBackgroundWrapper.css';
import { startsWith } from 'lodash';

export default class MainBackgroundWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainBg: '',
      width: window.innerWidth,
    };
    this.getRelevantStyle = this.getRelevantStyle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bgImagePath !== undefined
      && nextProps.bgImagePath !== null && nextProps.bgImagePath !== this.props.bgImagePath) {
      this.setState({ mainBg: nextProps.bgImagePath });
    }
  }

  getRelevantStyle() {
    const { mainBg, width } = this.state;
    if (startsWith(mainBg, '#')) {
      return (<div className="booking-main-background" style={{ backgroundColor: mainBg }} />);
    } if (mainBg && mainBg !== 'none' && width > 800) {
      return (<img className="booking-main-background w-100 filter-05 gray-overlay" src={mainBg} alt="bg-img" />);
    } if (mainBg === 'none') {
      return (<div className="booking-main-background" style={{ backgroundColor: '#F6F6F6' }} />);
    }
    return (<div className="booking-main-background" style={{ backgroundColor: 'transparent' }} />);
  }

  render() {
    return (
      <>
        {this.getRelevantStyle()}
        <div className={this.props.noMarginTop ? '' : 'mt-big mb-150'}>{this.props.children}</div>
      </>
    );
  }
}
