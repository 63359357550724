/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { assign } from 'lodash';
import {
  BOOKING_FLOW, BOOKING_FLOW_REDESIGN, RECIPIENT_OPTION, AVAILABLE_THERAPISTS, SOOTHE_PASS_ONE_TIME_POPUP, AVAILABLE_THERAPISTS_WITH_TIME_SLOTS,
} from '../Actions';

export default function (state = {}, action) {
  switch (action.type) {
    case SOOTHE_PASS_ONE_TIME_POPUP:
      return assign(state, action.payload);
    case AVAILABLE_THERAPISTS:
      return assign(state, action.payload);
    case RECIPIENT_OPTION:
      return assign(state, action.payload);
    case BOOKING_FLOW_REDESIGN:
      return assign(state, action.payload);
    case BOOKING_FLOW:
      return action.payload;
    case AVAILABLE_THERAPISTS_WITH_TIME_SLOTS:
      return assign(state, action.payload);
    default:
      return state;
  }
}
