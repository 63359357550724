import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { filter, isEmpty, find, map, get } from 'lodash';
import { setCart, setAddresses, setProducts, setBookingFlowPreviousStep } from '../../../Actions';
import { seBookingServiceSelect } from '../../Shared/WebAnalytics';
import { STEPS } from '../Shared/constants';
import '../Assets/Styles/index.css';
import { createAddress, createCart, updateCart } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage } from '../../../constants';
import Slide from "@material-ui/core/Slide";
HotJar();

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.availableServices = this.availableServices.bind(this);
        this.bookableServicesList = this.bookableServicesList.bind(this);
        this.listItem = this.listItem.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.selectService = this.selectService.bind(this);
        this.setCart = this.setCart.bind(this);
        this.createCartForLoggedInUser = this.createCartForLoggedInUser.bind(this);
        this.setProductThenNext = this.setProductThenNext.bind(this);
        this.createAddressForLoggedInUser = this.createAddressForLoggedInUser.bind(this);
    }
    componentDidMount() {
        this.props.changeBackground("");
        window.scrollTo(0, 0);
        let previousStep = find(STEPS, (step) => (step.id === 'ADDRESS'));
        this.props.setBookingFlowPreviousStep(previousStep);
    }
    goToNextStep() {
        this.props.setLoaderFlag(false);
        let loggedIn = get(this.props, 'client.loggedIn', false),
            rebook = get(this.props, "booking.cart.rebook", false);
        let nextStepKey = rebook ? "MODALITY" : loggedIn ? "RECIPIENT" : "AUTHENTICATION";
        let nextStep = find(STEPS, (step) => (step.id === nextStepKey));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    setCart(value) {
        this.props.setCart({
            ...this.props.booking,
            ...value
        });
    }
    setProductThenNext(product) {
        let market = get(this.props, "booking.cart.address.market", ""),
            loggedIn = get(this.props, "client.loggedIn", false)
        this.props.assignToCart({ product });
        // analytics
        seBookingServiceSelect(get(this.props, 'booking.cart', null), product.title.toLowerCase(), get(this.props, 'client.first_time_booker', null), "booking_23_control", loggedIn, market);
        this.goToNextStep();
    }
    createCartForLoggedInUser(product) {
        let self = this;
        let addressId = get(this.props, 'booking.cart.address.id', null),
            cartId = get(this.props, "booking.cart.id", "");
        if (cartId) {
            updateCart(cartId, { "address_id": addressId }, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
                self.props.assignToCart({ cart: decode(response.data.cart) })
                self.setProductThenNext(product);
            }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)))
        } else {
            createCart(addressId, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
                self.props.assignToCart({ cart: decode(response.data.cart) })
                self.setProductThenNext(product);
            }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)))
        }
    }
    createAddressForLoggedInUser(product) {
        let self = this;
        let address = get(this.props, "booking.cart.address", null);
        createAddress(address, (resp) => {
            self.props.assignToCart({ cart: { address: get(resp, "data.address", null) } })
            self.createCartForLoggedInUser(product);
        }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
    }
    selectService(product) {
        this.props.setLoaderFlag(true);
        let loggedIn = get(this.props, 'client.loggedIn', false),
            address = get(this.props, "booking.cart.address", null);
        if (loggedIn) {
            if (address && address.id) { this.createCartForLoggedInUser(product); }
            else {
                this.createAddressForLoggedInUser(product);
            }
        } else {
            this.setProductThenNext(product);
        }
    }
    listItem(prd, it) {
        let relevantId = get(prd, "slug", `prd-${it}`);
        return (<li key={`product-${it}`} className='centered-list-item color-white'
            id={relevantId === "haircut" ? "hair" : relevantId === "spray_tan" ? "beauty" : relevantId}
            onClick={() => this.selectService(prd)}
            onMouseOver={() => {
                let bg = find(prd.learn_more.images, (i) => (i.kind === 'hero'));
                this.props.changeBackground(`${bg.web_url}`);
            }}
        >
            {prd.title || ''}
        </li>);
    }
    bookableServicesList(products) {
        return (<div className="txt-center">
            <ul className='centered-list color-white'>
                {map(products, (prd, it) => (
                    this.listItem(prd, it)
                ))}
            </ul>
        </div>
        );
    }

    availableServices() {
        let products = filter(this.props.products, (el) => (el.bookable));
        if (!isEmpty(products)) {
            return this.bookableServicesList(products);
        }
        return null
    }

    render() {
        return (
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <div className='display-flex mt-1205vh'>
                    <div className='medium-font max-width-30'>
                        <div className='main-text'>What are you looking for?</div>
                        {this.availableServices()}
                    </div>
                </div>
            </Slide>
        );
    }
}

const mapStateToProps = state => ({
    addresses: state.addresses,
    booking: state.booking,
    products: Object.values(get(state, "productsReducer.products", {})),
    client: state.client,
    bookingFlow: state.bookingFlow,
    fieldsHolder: state.fieldsHolder
});

Index.propTypes = {
    booking: PropTypes.object,
    client: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    setAddresses: PropTypes.func.isRequired,
    setCart: PropTypes.func.isRequired,
    setProducts: PropTypes.func.isRequired
};

export default withLocalize(connect(mapStateToProps, { setCart, setAddresses, setProducts, setBookingFlowPreviousStep })(Index));
