import React from 'react';
import { Star } from '@material-ui/icons';
import './Assets/Styles/ProviderPickStep.css';
import Drawer from '@material-ui/core/Drawer';
import { get, isEmpty, size } from 'lodash';
import { connect } from 'react-redux';
import ProProfileDetails from './ProProfileDetails';

class ProCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.bookSelectCTA = this.bookSelectCTA.bind(this);
  }

  bookSelectCTA() {
    const { therapist, selected } = this.props;
    const isIC = get(this.props, 'therapist.isIC', false);

    return (
      <button
        type="button"
        className="btn md-cta-primary p-10-12-imp sm-p-10-12"
        disabled={isIC && !isEmpty(get(this.props, 'booking.cart.cartProducts.0.pap_therapist_preferences', []))}
        onClick={() => {
          if (selected) {
            this.props.removeProFromList(therapist);
          } else {
            this.props.updateParentState(therapist);
          }
          if (!isIC) {
            this.setState({ open: false });
          }
        }}
      >
        {isIC ? selected ? '✓ Booked' : `Book at ${get(this.props, 'booking.cart.time.display', '')}` : selected ? '✓ Selected' : 'Select'}
      </button>
    );
  }

  render() {
    const { open } = this.state;
    const { therapist, selected } = this.props;
    const {
      rating, number_of_bookings, isIC,
    } = therapist || {};
    return (
      <div className="pro-card br-opaque p-16-16-24">
        <Drawer style={{ zIndex: 999999999999 }} anchor="right" open={open} onClose={() => this.setState({ open: false })}>
          {open ? (
            <ProProfileDetails
              fieldsHolder={this.props.fieldsHolder}
              pro={therapist}
              showProDetailsToggle={() => this.setState({ open: false })}
              selectPro={this.props.updateParentState}
              unselectPro={this.props.removeProFromList}
              time={get(this.props, 'booking.cart.time.display', '')}
              errorHandler={this.props.errorHandler}
              click_source="pick_a_pro"
              selected={selected}
              hasMoreThanProSelected={size(get(this.props, 'booking.cart.cartProducts.0.pap_therapist_preferences', [])) > 1}
              market={get(this.props, 'booking.cart.address.market', '')}
            />
          ) : null}
        </Drawer>
        <div className="d-flex-only ">
          <div className="pr-8 mb-8">
            <img className="w-h-48 br-rd-50" src={therapist.avatar_url || ''} alt={`${therapist.first_name || ''}-avatar`} />
          </div>
          <div className="mb-8">
            <div className="size-18-24 color-black mb-4 font-weight-bold">
              {therapist.first_name || ''}
              {' '}
              {get(therapist, 'last_name.0', '')}
              .
            </div>
            <div className="">
              {rating ? (
                <span className="size-14-20 mr-10 mb-4">
                  <span className="contentTertiary">
                    <Star style={{ color: '#F58266' }} />
                    {' '}
                    {rating || ''}
                    {' '}
                    {number_of_bookings ? `(${number_of_bookings})` : ''}
                  </span>
                </span>
              ) : null}
              {isIC ? <span className="size-12-20 blue-badge mr-8 d-inline-block mb-4">Instant Book</span> : null}
              {!number_of_bookings ? <span className="size-12-20 navy-badge d-inline-block mb-4">New Provider</span> : null}
            </div>
          </div>
        </div>
        <div className="size-12-20 contentSecondary min-ht-40 ellipsis-2 mb-8">{therapist.bio || therapist.type || ''}</div>

        <div className="d-flex-only">
          <div className="mr-8">{this.bookSelectCTA()}</div>
          <div className="">
            <button
              type="button"
              className="btn md-cta-tertiary p-10-12-imp sm-p-10-12"
              onClick={() => {
                this.setState({ open: true });
              }}
            >
              View profile
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {})(ProCard);
