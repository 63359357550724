/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/** **************************************************
class ExampleModalRedesign extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showModal: true
    };
  }

  render() {
    return (
      <ModalRedesign title="hmmmmmm"
      isOpen={this.state.showModal}
      close={ () => { this.setState({ showModal: false }); } } apply={(e)=>{...}}>
        <p>I am the body</p>
      </ModalRedesign>
    );
  }
}
**************************************************** */
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize, Translate } from 'react-localize-redux';
import { capitalize } from 'lodash';
import translations from '../../../Translations/global.json';
import './ModalRedesign.css';

class ModalRedesign extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(translations);
  }

  render() {
    const { relevantId, relevantBtnId } = this.props;
    if (this.props.isOpen) {
      return (
        <Translate>
          {({ translate }) => (
            <div className="modal-wrapper " {...(relevantId ? { id: relevantId } : {})}>
              <div className="modal-content">
                <div className="modal-title">{this.props.title}</div>
                <div>{this.props.children}</div>
                <div className="txt-align-right margin-top-40">
                  <button
                    type="button"
                    className="btn modal-cancel-button"
                    onClick={(e) => { e.preventDefault(); this.props.close(); }}
                  >
                    {this.props.cancelBtnCopy ? this.props.cancelBtnCopy : capitalize(translate('global.cancel'))}
                  </button>
                  <button
                    type="button"
                    className="btn modal-apply-button"
                    {...(relevantBtnId ? { id: relevantBtnId } : {})}
                    onClick={(e) => { this.props.apply(e); }}
                  >
                    {this.props.applyBtnCopy ? this.props.applyBtnCopy : capitalize(translate('global.apply'))}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Translate>
      );
    }
    return null;
  }
}

ModalRedesign.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ModalRedesign.defaultProps = {
  isOpen: false,
};

export default withLocalize(ModalRedesign);
