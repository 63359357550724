/* eslint-disable react/prop-types */
import { split } from 'lodash';
import React from 'react';
import { ROUTES, termsAndConditions } from '../../../constants';
import './Message.scss';

function Message({
  isMyMessage, message, ckey, isRejected,
}) {
  const imageThumbnail = isMyMessage ? null : <img src={message.image_url} alt={message.name} />;
  if (isRejected) {
    const splitString = split(message.message_text, termsAndConditions);
    const a = splitString[0];
    const b = splitString[1];
    const termsLink = <a href={ROUTES.terms} target="_blank" rel="noopener noreferrer">{termsAndConditions}</a>;
    return (
      <div className={`message-row ${isMyMessage ? 'you-message' : 'other-message'} rejected-message `} key={`${ckey}-hidden`}>
        <div className="message-content">
          {imageThumbnail}
          <div className="message-text">
            {a}
            {termsLink}
            {b}
          </div>
          <div className="message-time">{message.createdAt}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={`message-row ${isMyMessage ? 'you-message' : 'other-message'}`} key={ckey}>
      <div className="message-content">
        {imageThumbnail}
        <div className="message-text">
          {message.message_text}
        </div>
        <div className="message-time">{message.createdAt}</div>
      </div>
    </div>
  );
}

export default Message;
