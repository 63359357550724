/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  capitalize, first, replace, result,
} from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { defaultErrorMessage, blankHref } from '../../../constants';
import PhoneInput from '../PhoneInput';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { loadClient } from '../../../Actions';
import backArrow from '../../../Assets/Images/back-arrow.png';
import './EditUser.css';

class EditUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.marketingEmailOptin = this.marketingEmailOptin.bind(this);
    this.phoneCommunicationOptin = this.phoneCommunicationOptin.bind(this);

    const { client } = this.props;

    this.state = {
      userId: client.user_id,
      firstName: client.first_name || '',
      lastName: client.last_name || '',
      mobileNumber: client.phone || '',
      validNumber: false,
      canEmail: false,
      marketingCallsTexts: false,
      countryCode: '',
    };
  }

  handleInputChange({ validNumber, number, countryCode }) {
    this.setState({ validNumber, mobileNumber: validNumber ? `+${replace(number, /\D/g, '')}` : number, countryCode });
  }

  update(event) {
    event.preventDefault();

    axios.put(`${API_ROOT}/clients/${this.state.userId}`, {
      client: {
        mobile_number: this.state.mobileNumber,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      },
    }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
      const { success, result, client } = resp.data;

      if (success || result) {
        this.props.loadClient({ ...client, loggedIn: true });
      } else {
        const message = resp.data.message || defaultErrorMessage;
        alert(message);
      }
    }).catch((error) => {
      console.log(error);
      const message = result(first(error.response.data.errors || {}), 'message') || result(error.response.data.errors || {}, 'message') || defaultErrorMessage;
      alert(message);
    });
  }

  marketingEmailOptin() {
    if (this.state.countryCode !== 'us' && this.state.countryCode !== '') {
      return (
        <div className="form-group">
          <div className="col-xs-12">
            <div className="col-xs-1 col-sm-1">
              <input type="checkbox" className="" checked={this.state.canEmail} onChange={(e) => this.setState({ canEmail: !this.state.canEmail })} />
            </div>
            <div className="col-xs-10 col-sm-9 optin-text">
              I would like to receive promotional emails from Soothe
            </div>
          </div>
        </div>
      );
    }
  }

  phoneCommunicationOptin() {
    if (this.state.countryCode === 'us') {
      return (
        <div className="form-group">
          <div className="col-xs-12">
            <div className="col-xs-1 col-sm-1">
              <input type="checkbox" checked={this.state.marketingCallsTexts} className="" onChange={(e) => this.setState({ marketingCallsTexts: !this.state.marketingCallsTexts })} />
            </div>
            <div className="col-xs-10 col-sm-9 optin-text">
              <Translate id="navigation.receivePromotionalCallsAndTexts" />
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <Translate>
        { ({ translate }) => (
          <div className="overlay editUserModal">
            <div className="popup box-shadow-container">
              <div className="header">
                <h3 className="col-xs-12 signup-header">{ translate('formData.editAccount') }</h3>
              </div>
              <div className="content">
                <form onSubmit={this.update}>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label className="signup-form-lable">{ translate('formData.firstName') }</label>
                      <input type="text" value={this.state.firstName} className="form-control" onChange={(event) => this.setState({ firstName: event.target.value })} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label className="signup-form-lable">{ translate('formData.lastName') }</label>
                      <input type="text" value={this.state.lastName} className="form-control" onChange={(event) => this.setState({ lastName: event.target.value })} />
                    </div>
                  </div>
                  <div className="form-group col-xs-12">
                    <label className="label-padding signup-form-lable phone-label-margin">{ translate('formData.mobileNumber') }</label>
                    <PhoneInput onPhoneNumberChange={this.handleInputChange} className={`intl-tel-input form-control ${this.state.validNumber ? 'valid' : 'invalid'}`} />
                  </div>

                  <div className="form-group col-xs-12 marketing-optin">
                    { translate('formData.phoneOptinDisclaimer') }
                  </div>

                  { this.phoneCommunicationOptin() }
                  { this.marketingEmailOptin() }

                  <div className="form-group">
                    <div className="col-xs-12" />
                  </div>

                  <div className="form-group">
                    <div className="btn-container">
                      <input type="submit" className="btn active form-control" value={capitalize(translate('global.update'))} />
                    </div>

                    <div className="col-xs-12 back-btn-container">
                      <a className="back-btn" href={blankHref} onClick={this.props.back}>
                        <img src={backArrow} className="back-arrow-img" alt="back arrow" />
                        {' '}
                        { capitalize(translate('global.back')) }
                      </a>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
});

EditUserModal.propTypes = {
  loadClient: PropTypes.func.isRequired,
};

export default withLocalize(connect(mapStateToProps, { loadClient })(EditUserModal));
