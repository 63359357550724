/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';

export function getChatsOfCurrentUser(page, callBackFunc, errorHandler) {
  let extraParams = '';
  if (page) {
    extraParams = `?page=${page}`;
  }
  axios.get(`${API_ROOT}/chats${extraParams}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
}
export function getChatMessagesByPage(chat_id, page, callBackFunc, errorHandler) {
  if (chat_id) {
    let extraParams = '';
    if (page) {
      // chats.message_id you currently have. Null for first page
      extraParams = `?page=${page}`;
    }
    axios.get(`${API_ROOT}/chats/${chat_id}${extraParams}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => {
        const { result } = response.data;
        if (result && callBackFunc) {
          callBackFunc(response);
        }
      }).catch((err) => {
        if (errorHandler) {
          errorHandler(err);
        } else {
          console.log(err);
        }
      });
  }
}
export function openChat(participant_ids, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/chats/`,
    {
      participant_ids,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function sendMessage(chat_id, message, callBackFunc, errorHandler) {
  axios.post(
    `${API_ROOT}/chats/${chat_id}/chat_messages`,
    {
      message,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getReportReasons(callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/chat_report_reasons`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => {
      const { result } = response.data;
      if (result && callBackFunc) {
        callBackFunc(response);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
}
export function createReport(
  chat_id,
  {
    reported_id,
    chat_report_reason_id,
    chat_report_reason_index,
    reporter_notes,
  },
  callBackFunc,
  errorHandler,
) {
  axios.post(
    `${API_ROOT}/chats/${chat_id}/reports`,
    {
      reported_id,
      chat_report_reason_id,
      chat_report_reason_index,
      reporter_notes,
    },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  ).then((response) => {
    const { success } = response.data;
    if (success && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
