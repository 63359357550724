/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, map } from 'lodash';
import './ColumnsList.css';

class ColumnsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { headerPrice } = this.props;
    return (
      <div className="ptrb-16">
        {this.props.headerText || this.props.headerPrice ? (
          <div className="header-style">
            <div className="row">
              <div className={headerPrice ? 'col-xs-6 col-sm-6' : 'col-xs-12'}>{this.props.headerText || ''}</div>
              {headerPrice ? <div className="col-xs-6 col-sm-6 txt-end">{headerPrice || ''}</div> : null}
            </div>
          </div>
        ) : null}
        {!isEmpty(this.props.itemsList) ? (
          map(this.props.itemsList, (val, key) => (
            <div className="item-style" key={key}>
              <div className="row">
                <div className={val.price ? 'col-xs-6 col-sm-6' : 'col-xs-12'}>{val.text || ''}</div>
                <div className="col-xs-6 col-sm-6 txt-end">{val.price || ''}</div>
              </div>
            </div>
          )))
          : null}
      </div>
    );
  }
}

export default withLocalize((ColumnsList));
