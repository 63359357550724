import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import NavBar from './../Shared/NavBar';
// should be with position fixed
import HelmetTag from './../Shared/HelmetTag';
import { omit } from 'lodash'
import { withLocalize } from 'react-localize-redux';
import translations from '../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../Actions';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../apiConfig';
import './index.css'
import { HotJar } from './../../constants';
import Landing from './Landing'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import SmoothScroll from 'smooth-scroll'
import { GIFT_CARD_DEFAULT, GIFT_CARD_CART_DEFAULT } from './shared/constants'
import { ROUTES } from '../../constants'
import { authTokenPost } from '../Shared/Helpers';
HotJar();

class GiftCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepNumber: 0,
            giftCardType: '',//deliveryMethod
            giftCardDesign: '',
            cityId: '',//
            totalSteps: 4,
            recipientName: '',
            recipientEmail: '',
            senderName: '',
            senderEmail: '',
            giftCardMessage: '',
            minutes: 0,
            amount: 0,
            currency: 'usd',
            currencySymbol: '$',
            deliveryDate: ''

        };
        this.updatePosition = this.updatePosition.bind(this);
        this.scrollToStep = this.scrollToStep.bind(this);
        this.updateFieldAndStep = this.updateFieldAndStep.bind(this);
        this.updateField = this.updateField.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }
    componentDidMount() {
        window.gladlyConfig = {
            appId: `${process.env.REACT_APP_GLADLY_SIDEKICK_KEY}`
        };

        setTimeout(() => {
            // Impact Init Call
            if (window.ire) window.ire('identify', { customerId: '', customerEmail: '' });
        }, 1500);
        this.props.addTranslation(translations);

        this.props.setGiftCard({
            ...GIFT_CARD_DEFAULT,
            ...this.props.giftCard
        })
        if (!!this.props.giftCardCart.checkout.id) {
            this.props.setGiftCardCart({
                ...GIFT_CARD_CART_DEFAULT,
                sales: []
            });
        } else {
            this.props.setGiftCardCart({
                ...GIFT_CARD_CART_DEFAULT,
                ...this.props.giftCardCart,
                sales: []
            });
        }
        authTokenPost()
    }
    updatePosition() {
        var scroll = new SmoothScroll();
        scroll.animateScroll(document.querySelector(`.step${this.state.stepNumber}`).offsetTop);
    }

    scrollToStep(stepNumber) {
        if (stepNumber === 7) {
            this.addToCart()
            return
        }
        else {
            this.setState({ stepNumber }, this.updatePosition)
        }
    }
    updateFieldAndStep(field, value, stepNumber) {
        this.setState({ [field]: value }, () => {
            if (field === 'giftCardType' && value === 'print_at_home' && this.state.totalSteps === 4) {
                this.updateField('totalSteps', 3)
            }
            else if (field === 'giftCardType' && value === 'digital' && this.state.totalSteps === 3) {
                this.updateField('totalSteps', 4)
            }
            this.scrollToStep(stepNumber);
        })
    }
    updateField(field, value) {
        this.setState({ [field]: value })
    }
    addToCart() {
        this.props.setGiftCardCart({
            ...this.props.giftCardCart,
            items: [...this.props.giftCardCart.items, omit(this.props.giftCard, ['page', 'customPrice', 'emailIsValid', 'recipientEmailIsValid'])]
        });
        window.location.pathname = ROUTES.massageCardsCheckout
    }

    render() {
        let step = this.state.stepNumber, totalSteps = this.state.totalSteps;
        let { giftCardType, recipientName, recipientEmail, senderName, senderEmail, giftCardMessage, cityId,
            amount, currency, currencySymbol } = this.state;
        return (
            <div className="max-w-100">
                <HelmetTag />
                <NavBar giftCardNav={true} cardsTotal={this.props.giftCardCart.items ? this.props.giftCardCart.items.length : 0} />
                <Landing scrollToStep={this.scrollToStep} step={step} />

                {step >= 1 && <Step1 updateField={this.updateField} updateFieldAndStep={this.updateFieldAndStep} giftCardType={this.state.giftCardType} totalSteps={totalSteps} />}
                {/* {step >= 2 && giftCardType === 'digital' && <Step2 updateField={this.updateField} updateFieldAndStep={this.updateFieldAndStep} totalSteps={totalSteps} />} */}
                {/* {step >= 3 &&
                    <Step3 updateField={this.updateField} updateFieldAndStep={this.updateFieldAndStep} cityId={this.state.cityId} totalSteps={totalSteps} />
                } */}
                {step >= 4 && <Step4 updateField={this.updateField} updateFieldAndStep={this.updateFieldAndStep}
                    amount={amount} currency={currency} currencySymbol={currencySymbol}
                    totalSteps={totalSteps} />}
                {step >= 5 && giftCardType === 'digital' && <Step5 updateField={this.updateField} updateFieldAndStep={this.updateFieldAndStep} deliveryDate={this.state.deliveryDate} totalSteps={totalSteps} />}
                {step >= 6 && <Step6 addToCart={this.addToCart} scrollToStep={this.scrollToStep}
                    updateField={this.updateField} updateFieldAndStep={this.updateFieldAndStep}
                    details={{ recipientName, recipientEmail, senderName, senderEmail, giftCardMessage }}
                    giftCardType={giftCardType} totalSteps={totalSteps} />}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    giftCard: state.giftCard,
    giftCardCart: state.giftCardCart
});
export default withLocalize(connect(mapStateToProps, { setGiftCard, setGiftCardCart })(GiftCard));