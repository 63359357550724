/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import React from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import {
  difference, get, includes, isEmpty, map, size, union,
} from 'lodash';
import moment from 'moment-timezone';
import { ArrowBackIos, Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import ProCard from './ProCard';
import ErrorModal from '../../Shared/ErrorModal';
import { defaultErrorMessage } from '../../../constants';
import { setAvailableTherapistsWithTimeSlots, setAppointment, setCart } from '../../../Actions';
import { formatProsWithSlotsList } from '../Shared/constants';
import LoadingOverlay from '../../Shared/LoadingOverlay';
import { requestPro } from '../Shared/helpers';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import { seAptAddProsToCartComplete, seAptAddProsToCartView } from '../../Shared/WebAnalytics';
import { decode } from '../Shared/encode';

class ProListDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      selectedProIds: [],
      showErrorModal: false,
      error: '',
      providers: [],
      allIds: [],
      showLoader: false,
    };
    this.recommendedMsg = this.recommendedMsg.bind(this);
    this.headerSection = this.headerSection.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.fetchProList = this.fetchProList.bind(this);
    this.formatProsList = this.formatProsList.bind(this);
    this.proListView = this.proListView.bind(this);
    this.isAllSelected = this.isAllSelected.bind(this);
    this.submissionCTA = this.submissionCTA.bind(this);
    this.requestMorePros = this.requestMorePros.bind(this);
    this.switchPopupAndDrawer = this.switchPopupAndDrawer.bind(this);
    this.isProSelected = this.isProSelected.bind(this);
    this.addPro = this.addPro.bind(this);
    this.removeProFromSelection = this.removeProFromSelection.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    if (!isEmpty(this.props.recommendedProviders)) {
      const cart = get(this.props, 'booking.cart', null) || get(this.props, 'appointment', null);
      const market = get(cart, 'address.market', '');
      const number_of_requested_pros = size(get(cart, 'cartProducts.0.pap_therapist_preferences', []));
      const number_of_pros_displayed = size(this.props.recommendedProviders);
      const click_source = get(this.props, 'click_source', 'Checkout');
      seAptAddProsToCartView({
        market, number_of_requested_pros, number_of_pros_displayed, click_source,
      });
      this.fetchProList();
    }
  }

  fetchProList() {
    this.setState({ showLoader: true });
    const providers = get(this.props, 'recommendedProviders', []);
    const allIds = map(providers, (ell) => (ell.id));
    this.formatProsList(providers);
    this.setState({
      showLoader: false, providers, allIds, selectedProIds: allIds,
    });
  }

  formatProsList(therpList) {
    this.setState({ formattedList: formatProsWithSlotsList(therpList, moment(get(this.props, 'booking.cart.date.utc') || get(this.props, 'appointment.date.utc')).isSame(moment(), 'day')) });
  }

  requestMorePros() {
    const cart = get(this.props, 'booking.cart', null) || get(this.props, 'appointment', {});
    const cartId = get(cart, 'id', '') || get(this.props, 'cartId', '');
    const cartProductId = get(cart, 'cartProducts.0.id', '') || get(this.props, 'cartProductId', '');
    this.setState({ showLoader: true });
    requestPro(cartId, cartProductId, {
      pro_ids: this.state.selectedProIds,
      remove_enhancements: false,
      remove_therapist_preferences: false,
      cart: {
        request_pros_limit_reached: get(this.props, 'click_source', 'Checkout') !== 'Checkout',
      },
      additional_pros: true,
    }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      this.props.assignToProps({ cart: decode(response.data.cart) });
      const cart = get(this.props, 'booking.cart', null) || get(this.props, 'appointment', null);
      const market = get(cart, 'address.market', '');
      const number_of_requested_pros = size(get(cart, 'cartProducts.0.pap_therapist_preferences', []));
      const number_of_pros_displayed = size(this.props.recommendedProviders);
      const click_source = get(this.props, 'click_source', 'Checkout');
      const number_of_pros_added = size(this.state.selectedProIds);
      seAptAddProsToCartComplete({
        market,
        number_of_requested_pros,
        number_of_pros_displayed,
        number_of_pros_added,
        click_source,
      });
      this.setState({ showLoader: false });
      this.props.closeAndNext();
    }, (err) => {
      this.setState({ showLoader: false, showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  isProSelected(proId) {
    // let cart = get(this.props, "booking.cart", {}) || get(this.props, "appointment", {});
    return includes(this.state.selectedProIds, proId);
  }

  // TODO adjust add remove pro selection and submit / close
  addPro(therapist, subIt) {
    const { selectedProIds } = this.state;
    this.setState({ selectedProIds: union(selectedProIds, [therapist.id]) });
  }

  removeProFromSelection(therapist, subIt) {
    const { selectedProIds } = this.state;
    this.setState({ selectedProIds: difference(selectedProIds, [therapist.id]) });
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  recommendedMsg() {
    const mobileSizeScreen = this.state.width <= 800;
    return (
      <div className={`${mobileSizeScreen ? 'blue-subtle p-16 ' : ''} br-rd-16 mb-24`}>
        <span className="size-12-20 navy-badge p-2-4 mb-2 mr-8 sm-mb-8">Recommended</span>
        {mobileSizeScreen ? <br /> : null}
        <span className="size-16-24 contentPrimary">You can increase your chances at getting a response by requesting more Providers.</span>
      </div>
    );
  }

  isAllSelected() {
    const { allIds, selectedProIds } = this.state;
    return isEmpty(difference(allIds, selectedProIds));
  }

  headerSection() {
    const { customTitle } = this.props;
    const allSelected = this.isAllSelected();
    return (
      <div className="display-flex align-items-end mb-24">
        <div className="flex-1">
          {customTitle || <div className="size-24-32 contentPrimary font-weight-bold">Request Additional Providers</div>}
        </div>
        <div className="">
          <button
            type="button"
            className="btn btn-link DarkBluePrimary size-14-16 font-weight-bold deco-none"
            onClick={() => {
              if (allSelected) {
                this.setState({ selectedProIds: [] });
              } else {
                this.setState({ selectedProIds: this.state.allIds });
              }
            }}
          >
            {allSelected ? 'Unselect all' : 'Select all'}
          </button>
        </div>
      </div>
    );
  }

  proListView() {
    const { providers } = this.state;
    if (!isEmpty(providers)) {
      return (
        <>
          <div className="row">
            {map(providers, (pro, subIt) => (
              <div key={`therapist-${subIt}-ga`} className="col-xs-12 mb-24 sm-mb-16 col-sm-4">
                <ProCard
                  therapist={pro}
                  errorHandler={(err) => {
                    this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
                  }}
                  width={this.state.width}
                  updateParentState={(ther) => this.addPro(ther, subIt + size(providers))}
                  selected={this.isProSelected(pro.id)}
                  removeProFromList={(ther) => this.removeProFromSelection(
                    ther,
                    subIt + size(providers),
                  )}
                />
              </div>
            ))}
          </div>
          {this.errorModal()}
        </>
      );
    }
    return null;
  }

  submissionCTA() {
    const { selectedProIds, providers } = this.state;
    const ln = size(selectedProIds); const
      total = size(providers);
    if (isEmpty(selectedProIds)) {
      return null;
    }
    return (
      <div className="p-16">
        {this.state.width <= 800
          ? (
            <>
              <div className="centered mb-8 contentPrimary size-16-20 medium-font">
                Request
                {' '}
                {ln}
                {' '}
                of
                {' '}
                {total}
                {' '}
                available
              </div>
              <div className="centered mb-8 contentSecondary size-12-20">Request more providers for faster acceptance; You'll be confirmed with the first one who accepts.</div>
            </>
          ) : null}
        <button
          type="button"
          className="btn md-cta-primary"
          onClick={() => {
            this.requestMorePros();
          }}
        >
          Request selected providers
        </button>
      </div>
    );
  }

  switchPopupAndDrawer() {
    const cart = get(this.props, 'booking.cart', null) || get(this.props, 'appointment', {});
    const { isOpen, closeAndNext } = this.props;
    const { width, selectedProIds, providers } = this.state;
    const isConfirmed = get(cart, 'info_fields.tracker.state', 'requested') === 'confirmed';
    if (width > 800) {
      return (
        <LatestModalDesign
          isOpen={isOpen && !isConfirmed && !isEmpty(providers)}
          customIcon={<Close />}
          back={this.props.closeAndNext}
          title="Your request has been sent!"
          contentExtraClass="w-75-imp"
          apply={this.requestMorePros}
          disabled={isEmpty(selectedProIds)}
          applyBtnCopy="Request selected providers"
          hideCancel
        >
          {this.recommendedMsg()}
          {this.headerSection()}
          {this.proListView()}
        </LatestModalDesign>
      );
    }

    return (
      <SwipeableDrawer
        style={{ zIndex: 9999999999999 }}
        PaperProps={{ style: { borderRadius: '16px 16px 0px 0px', zIndex: 9999999999999 } }}
        anchor="bottom"
        open={isOpen && !isConfirmed && !isEmpty(providers)}
        onClose={closeAndNext}
        disableSwipeToOpen
      >
        <div className="sm-p-24-16">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-link contentPrimary size-20-28 deco-none"
            >
              <ArrowBackIos />
            </button>
          </div>
          <div className="size-28-36 contentPrimary medium-font mb-8">Your request has been sent!</div>
          {this.recommendedMsg()}
          {this.headerSection()}
          {this.proListView()}
        </div>
        {this.submissionCTA()}
      </SwipeableDrawer>
    );
  }

  render() {
    const { showLoader } = this.state;
    return (
      <>
        {this.switchPopupAndDrawer()}
        <LoadingOverlay showLoader={showLoader} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  fieldsHolder: state.fieldsHolder,
  bookingFlow: state.bookingFlow,
  appointment: state.appointment,
  client: state.client,
});

export default connect(mapStateToProps, {
  setAvailableTherapistsWithTimeSlots,
  setAppointment,
  setCart,
})(ProListDrawer);
