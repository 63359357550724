/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { SET_SUBSCRIPTION } from '../Actions';
import { SOOTHE_PLUS_DEFAULT } from '../Containers/SoothePlus/Constants';

export default function (state = SOOTHE_PLUS_DEFAULT, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return action.payload;
    default:
      return state;
  }
}
