/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { map } from 'lodash';
import {
  Collapse, Divider, List, ListItem, ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { FOOTER_LINKS, SOCIAL_LINKS } from '../../../constants';
import appStoreIcon from '../../../Assets/Images/download_app_store.png';
import googlePlayIcon from '../../../Assets/Images/download_google_play.png';
import { navigationViewLegalPages } from '../WebAnalytics';
import './Assets/Styles/Footer.css';

export default class FooterRedesign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      openedIndex: -1,
    };

    this.relevantLinksView = this.relevantLinksView.bind(this);
    this.appsAndSocialLinks = this.appsAndSocialLinks.bind(this);
    this.socialLinksAndCopy = this.socialLinksAndCopy.bind(this);
    this.getAppsSection = this.getAppsSection.bind(this);
    this.horizontalFooterLinks = this.horizontalFooterLinks.bind(this);
    this.verticalFooterLinks = this.verticalFooterLinks.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  }

  horizontalFooterLinks() {
    return (
      <div className="d-flex-only justify-content-space-between align-items-baseline">
        {map(FOOTER_LINKS, (colObj, ind) => (
          <div key={`footer-col-${ind}`}>
            <div className="size-18-24 bold mb-16">{colObj.title}</div>
            {map(colObj.linksList, (lnk, it) => (
              <div key={`footer-link-${ind}-${it}`}>
                <a className="content-primary-white text-decoration-none" href={lnk.linkAddress}>
                  <div className="size-14-16 mb-16 bold">{lnk.linkText}</div>
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  verticalFooterLinks() {
    const { openedIndex } = this.state;
    return (
      <List component="nav" aria-labelledby="nested-list-subheader">
        {FOOTER_LINKS.map((colObj, ind) => (
          <div key={`footer-collapsible-${ind}`}>
            <ListItem
              key={`list-item-${ind}`}
              button
              onClick={() => {
                if (openedIndex !== ind) {
                  this.setState({ openedIndex: ind });
                } else {
                  this.setState({ openedIndex: -1 });
                }
              }}
            >
              <ListItemText primary={colObj.title} />
              {openedIndex === ind ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              key={`collapse-${ind}`}
              in={openedIndex === ind}
              timeout="auto"
              unmountOnExit
              className="p-16"
            >
              {map(colObj.linksList, (lnk, it) => (
                <div key={`footer-link-${ind}-${it}`}>
                  <a className="content-primary-white text-decoration-none " href={lnk.linkAddress}>
                    <div className="size-14-16 mb-16 bold">{lnk.linkText}</div>
                  </a>
                </div>
              ))}
            </Collapse>
            <Divider />
          </div>
        ))}
      </List>
    );
  }

  relevantLinksView() {
    if (this.state.width >= 992) {
      return (
        <div className="pb-32 border-bottom-transparent">
          {this.horizontalFooterLinks()}
        </div>
      );
    }
    return this.verticalFooterLinks();
  }

  appsAndSocialLinks() {
    return (
      <div className={`row sm-p-16 pt-32 maxW-ml0 ${this.state.width >= 992 ? '' : 'text-align-center'}`}>
        <div className="col-xs-12 col-md-2">
          {this.getAppsSection()}
        </div>
        <div className="col-xs-12 col-md-10">
          {this.socialLinksAndCopy()}
        </div>
      </div>
    );
  }

  socialLinksAndCopy() {
    return (
      <>
        <div className="mtb-20">
          {map(SOCIAL_LINKS, (lnk, itr) => (
            <span key={`social-link-${itr}`}>
              <a href={lnk.linkAddress} className="mr-16">
                <img src={lnk.imgSrc} alt={`Social-link-${itr}`} />
              </a>
            </span>
          ))}
        </div>
        <div className="size-12-20">
          Copyright 2021, Soothe Inc. Please read our
          <a
            className="content-primary-white"
            href="https://www.soothe.com/terms-and-conditions/"
            onClick={() => navigationViewLegalPages()}
          >
            Terms and Conditions
          </a>
          {' '}
          and
          {this.state.width >= 992 ? <br /> : null}
          <a
            className="content-primary-white"
            href="https://www.soothe.com/privacy-policy/"
            onClick={() => navigationViewLegalPages()}
          >
            {' '}
            Privacy Policy
          </a>
          {' '}
          for more information.
        </div>
      </>
    );
  }

  getAppsSection() {
    return (
      <div className="mtb-20">
        <div className="mb-16">
          <a className="mr-16" href="https://apps.apple.com/us/app/soothe-in-home-massage/id811054908?ls=1">
            <img src={appStoreIcon} alt="Apple store" />
          </a>
        </div>
        <div>
          <a className="mr-16" href="https://play.google.com/store/apps/details?id=com.soothe.client&hl=en_US">
            <img src={googlePlayIcon} alt="Google play store" />
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <footer className="background-darkest-blue content-primary-white ptb-40 sm-ptb-0-40">
        <div className="max-width-75 sm-p-0">
          {this.relevantLinksView()}
          {this.appsAndSocialLinks()}
        </div>
      </footer>
    );
  }
}
