/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  find, get, includes, indexOf, isEmpty, isEqual, size, split,
} from 'lodash';
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import {
  setBookingFlowStep, setCart, setAddresses,
  setProducts, setBookingFlowPreviousStep, setAvailableTherapists,
  setAvailableTherapistsWithTimeSlots, setBookingFlowComingFrom,
} from '../../../Actions';
import './Assets/Timing.css';
import '../Assets/Styles/index.css';
import DayPicker from './DayPicker';
import HourPicker from './HourPicker';
import CTAButton from '../../Shared/CTAButton';
import { STEPS, momentCap } from '../Shared/constants';
import { seBookingSchedule, seBookingScheduleView } from '../../Shared/WebAnalytics';
import { getAvailableTherapists, guestCreateCart, updateCart } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import {
  defaultErrorMessage, HotJar, isGuestUser, hasUuid, NOT_BEFORE_H,
} from '../../../constants';
import TimePicker from './TimePicker';
import Options from '../CouplePreferenceStep/Options';
import DateTimeMarketplace from './DateTimeMarketplace';
import { Close } from '@material-ui/icons';

HotJar();

const yesterday = moment(new Date()).subtract(1, 'day');
const roundedUp = Math.ceil(moment(new Date()).minute() / 15) * 15;
const daysOfWeekIndexes = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionDate: '',
      sessionTime: '',
      nextView: false,
      backToBack: false,
      showBackToBackModal: false,
      todayMinTime: moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0),
      todayMaxTime: moment(new Date()).set({
        hour: 22, minute: 0, second: 0, millisecond: 0,
      }),
      slideFlag: true,
      workingHours: [],
      showHeadsUp: false,
      width: window.innerWidth 
    };
    this.goToNextStep = this.goToNextStep.bind(this);
    this.updateCartDateTime = this.updateCartDateTime.bind(this);
    this.dateValue = this.dateValue.bind(this);
    this.timeValue = this.timeValue.bind(this);
    this.dayOf = this.dayOf.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.onDayChange = this.onDayChange.bind(this);
    this.updateCartCallback = this.updateCartCallback.bind(this);
    this.prepareForGuestNextStep = this.prepareForGuestNextStep.bind(this);
    this.papCallback = this.papCallback.bind(this);
    this.showCouplesOption = this.showCouplesOption.bind(this);
    this.hasCouplesOption = this.hasCouplesOption.bind(this);
    this.refreshHrs = this.refreshHrs.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.headsUpCopy = this.headsUpCopy.bind(this);
    this.sameDayCopy = this.sameDayCopy.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 800) {
        this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || '#FFF');
      } else {
        this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
      }
      this.setState({ width: window.innerWidth });
    });
    const avlPros = get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', []) || get(this.props, 'bookingFlow.availableTherapists', []);
    seBookingScheduleView({
      market: get(this.props, 'booking.cart.address.market', ''), service_nb_pro: size(avlPros),
      test_name: "jul_2024_booking_enhancements",
      test_value: get(this.props, "jul_2024_booking_enhancements", "")
    });
    this.setState({
      workingHours: get(this.props, 'fieldsHolder.listingPageStorage.working_hours', []),
    }, () => {
      const sessionDate = this.dateValue();
      this.setState({
        sessionDate: moment(sessionDate).format('MM/DD/YYYY'),
      }, () => {
        this.sameDayCopy();
        this.setState({
          sessionTime: this.timeValue(),
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    });
    if (!hasUuid()) {
      this.props.changeBackground('none');
      const previousStep = find(STEPS, (step) => (step.id === 'MENU'));
      this.props.setBookingFlowPreviousStep(previousStep);
    } else {
      this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
    }

    const loader = this.props.setLoaderFlag;
    if (loader) {
      loader(true);
    }
    setTimeout(this.refreshHrs, 1500);
  }

  sameDayCopy() {
    moment(this.state.sessionDate).isSame(moment(), 'day') ? this.setState({ showHeadsUp: true }) : this.setState({ showHeadsUp: false });
  }

  refreshHrs() {
    const loader = this.props.setLoaderFlag;
    this.props.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
    if (isEmpty(this.state.workingHours) && !isEmpty(get(this.props, 'fieldsHolder.listingPageStorage.working_hours', []))) {
      this.setState({
        workingHours: get(this.props, 'fieldsHolder.listingPageStorage.working_hours', []),
      }, () => {
        const sessionDate = this.dateValue();
        this.setState({
          sessionDate: moment(sessionDate).format('MM/DD/YYYY'),
        }, () => {
          this.sameDayCopy();
          if (loader) {
            loader(false);
          }
          this.setState({
            sessionTime: this.timeValue(),
            todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
              hour: 8, minute: 0, second: 0, millisecond: 0,
            }),
            todayMaxTime: moment(this.state.sessionDate).set({
              hour: 22, minute: 0, second: 0, millisecond: 0,
            }),
          });
        });
      });
    } else if (loader) {
      loader(false);
    }
  }

  componentDidUpdate() {
    if (!isEqual(this.state.workingHours, get(this.props, 'fieldsHolder.listingPageStorage.working_hours', []))) {
      this.refreshHrs();
    }
  }

  dayOf() {
    const dateTime = this.state.sessionDate;
    return moment(new Date()).isSame(dateTime, 'day');
  }

  dateValue() {
    const dateUtc = get(this.props, 'booking.cart.date.utc', '');
    const wrkDays = this.state.workingHours;
    if (dateUtc) {
      const dateTime = moment(dateUtc);
      if (this.isValidDate(dateTime)) {
        return dateTime;
      } if (!isEmpty(wrkDays)) {
        const currDayInd = indexOf(daysOfWeekIndexes, dateTime.clone().format('dddd'));
        let curr = find(wrkDays, (el) => (indexOf(daysOfWeekIndexes, el.weekname) >= currDayInd));
        if (isEmpty(curr)) {
          curr = get(wrkDays, '0', null);
        }
        const splittedFrom = split(get(curr, 'from', '08:00:00'), ':');
        const hour = Number(get(splittedFrom, '0', '0')); const minute = Number(get(splittedFrom, '1', '0')); const
          second = Number(get(splittedFrom, '2', '0'));

        let newVal = dateTime.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, 'weekname', 'Monday')) + 1).set({ hour, minute, second });
        if (moment(newVal).isBefore(dateTime, 'day')) {
          newVal = dateTime.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, 'weekname', 'Monday')) + 8).set({ hour, minute, second });
        }
        return newVal;
      }
    }
    const defaultOpt = momentCap(new Date());
    if (!this.isValidDate(defaultOpt) && !isEmpty(wrkDays)) {
      const currDayInd = indexOf(daysOfWeekIndexes, defaultOpt.clone().format('dddd'));
      let curr = find(wrkDays, (el) => (indexOf(daysOfWeekIndexes, el.weekname) >= currDayInd));
      if (isEmpty(curr)) {
        curr = get(wrkDays, '0', null);
      }
      const splittedFrom = split(get(curr, 'from', '08:00:00'), ':');
      const hour = Number(get(splittedFrom, '0', '0')); const minute = Number(get(splittedFrom, '1', '0')); const
        second = Number(get(splittedFrom, '2', '0'));

      let newVal1 = momentCap(defaultOpt.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, 'weekname', 'Monday')) + 1).set({ hour, minute, second }).format());
      if (newVal1.isBefore(defaultOpt, 'day')) {
        newVal1 = momentCap(defaultOpt.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, 'weekname', 'Monday')) + 8).set({ hour, minute, second }).format());
      }
      return newVal1;
    }
    return defaultOpt;
  }

  isDisabled(time) {
    const date = this.state.sessionDate;
    const enabledHours = this.state.workingHours;
    if (isEmpty(enabledHours)) {
      return false;
    }
    const dateSelected = moment(date).format('YYYY-MM-DD');
    const dayOfWeek = moment(dateSelected).format('dddd');
    const obj = find(enabledHours, (el) => (el.weekname === dayOfWeek));
    if (obj) {
      const fromH = obj && obj.from && moment.duration(`${obj.from}`).asMinutes();
      const tillH = obj && obj.till && moment.duration(`${obj.till}`).asMinutes();
      const formattedTimeH = time && moment(`${dateSelected} ${time}`, 'YYYY-MM-DD hh:mm A').format('HH:mm');
      const timeH = moment.duration(formattedTimeH).asMinutes();
      if (fromH && tillH && timeH >= fromH && timeH <= tillH) {
        return false;
      }
    }
    return true;
  }

  timeValue() {
    let { sessionDate } = this.state;
    sessionDate = moment(sessionDate);
    const timeUtc = get(this.props, 'booking.cart.time.utc', '');
    if (timeUtc) {
      const sessionDatetime = moment(timeUtc).month(sessionDate.month())
        .date(sessionDate.date()).year(sessionDate.year())
        .format('hh:mm A');
      const todaysCap = momentCap(sessionDate.toDate());

      // if the time is not avail for date then set to the min time for date
      if (todaysCap > sessionDatetime) {
        return todaysCap.format('hh:mm A');
      }
      return this.props.booking.cart.time.display;
    }
    const time = momentCap(sessionDate.toDate()).format('hh:mm A');
    if (this.isDisabled(time)) {
      const dateSelected = moment(sessionDate.toDate()).format('YYYY-MM-DD');
      const dayOfWeek = moment(dateSelected).format('dddd');
      const obj = find(this.state.workingHours, (el) => (el.weekname === dayOfWeek));
      if (obj) {
        return moment(`${dateSelected} ${get(obj, 'from', '')}`, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
      }
    }
    return time;
  }

  updateCartCallback(response) {
    const { result } = response.data;
    const loader = this.props.setLoaderFlag;
    if (loader) {
      loader(false);
    }
    if (result) {
      this.props.assignToCart({ cart: decode(response.data.cart) });

      this.goToNextStep();
    } else {
      const message = get(response, 'data.errors.0.message', defaultErrorMessage);
      this.setState({ showErrorModal: true, message });
    }
  }

  updateCartDateTime(sessionDate, sessionTime) {
    const cartId = get(this.props, 'booking.cart.id', '');
    const loader = this.props.setLoaderFlag;
    const rebook = get(this.props, 'booking.cart.rebook', false);
    if (isGuestUser() && !cartId) {
      if (loader) {
        loader(true);
      }
      const cart = get(this.props, 'fieldsHolder.listingPageStorage.cart', '');
      const address = get(this.props, 'fieldsHolder.listingPageStorage.address', '');
      const cartPayload = {
        ...cart,
        session_date: moment(sessionDate).format('YYYY-MM-DD'),
        session_time: moment(this.state.sessionTime, 'hh:mm A').format('HH:mm'),
      };
      if (this.hasCouplesOption()) {
        cartPayload.back_to_back = Boolean(this.state.backToBack);
      }
      guestCreateCart(
        cartPayload,
        address,
        get(this.props, 'fieldsHolder.csrfToken', ''),
        this.updateCartCallback,
        (err) => {
          if (loader) {
            loader(false);
          }
          console.log({ err });
          this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
        },
      );
    } else if (cartId) {
      if (loader) {
        loader(true);
      }
      const cartPayload = {
        session_date: moment(sessionDate).format('YYYY/MM/DD'),
        session_time: sessionTime,
        remove_pick_a_pro: !rebook,
      };
      if (this.hasCouplesOption()) {
        cartPayload.back_to_back = Boolean(this.state.backToBack);
      }
      updateCart(cartId, cartPayload, get(this.props, 'fieldsHolder.csrfToken', ''), this.updateCartCallback, (err) => {
        if (loader) {
          loader(false);
        }
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      });
    }
  }

  prepareForGuestNextStep(cb) {
    const cart = get(this.props, 'booking.cart', null);
    const cartProducts = get(cart, 'cartProducts', []);
    const pickAProEnabled = get(cart, 'pickAProEnabled', false);
    const loader = this.props.setLoaderFlag;
    if (pickAProEnabled) {
      if (loader) {
        loader(true);
      }
      getAvailableTherapists(cart.id, get(cartProducts, '0.id', ''), get(cart, 'date.utc', ''), get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
        if (loader) {
          loader(false);
        }
        const providers = get(response, 'data.providers', []);
        this.props.setAvailableTherapists(providers);
        cb();
      }, () => {
        if (loader) {
          loader(false);
        }
        this.props.setAvailableTherapists([]);
        cb();
      });
    } else {
      this.props.setAvailableTherapists([]);
      cb();
    }
  }

  hasCouplesOption() {
    const cart = get(this.props, 'booking.cart', {});
    const productsCount = size(get(cart, 'cartProducts', []));
    const backToBackAvailable = get(cart, 'backToBack.available', false);
    const rebook = get(cart, 'rebook', false);
    return !rebook && ((productsCount > 1 && backToBackAvailable) || (hasUuid() && get(this.props, 'fieldsHolder.listingPageStorage.cart.double', '')));
  }

  showCouplesOption() {
    if (this.hasCouplesOption()) {
      return (
        <>
          <div className="font-weight-bold contentPrimary mb-6 size-16-20">How would you like it?</div>
          <Options
            submitOption={(val) => { this.setState({ backToBack: val }); }}
            selection={this.state.backToBack}
            hideIcons
            additionalClassName="force-size"
          />
        </>
      );
    }
    return null;
  }

  papCallback() {
    const pickAProEnabled = get(this.props, 'booking.cart.pickAProEnabled', false);
    let nextStepKey = 'REVIEW';
    const rebook = get(this.props, 'booking.cart.rebook', false);
    if (!rebook && pickAProEnabled && size(get(this.props, 'booking.cart.cartProducts', [])) === 1 && ((!isEmpty(get(this.props, 'bookingFlow.availableTherapistsWithTimeSlots', [])) && get(this.props, 'booking.cart.info_fields.marketplace_enabled', false)) || !isEmpty(get(this.props, 'bookingFlow.availableTherapists', [])))) {
      nextStepKey = 'PICKAPRO';
    }
    const nextStepCustom = find(STEPS, (step) => (step.id === nextStepKey));
    const cart = get(this.props, 'booking.cart', null);
    const productTitle = get(this.props, 'booking.product.title', '').toLowerCase();
    const isFirstTimeBooker = get(this.props, 'client.first_time_booker', false);

    seBookingSchedule(cart, productTitle, isFirstTimeBooker, 'booking_23_variant', size(get(this.props, 'bookingFlow.availableTherapists', [])), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
    this.props.setBookingFlowComingFrom('TIMING');
    this.props.setBookingFlowStepThroughParent(nextStepCustom);
  }

  goToNextStep() {
    this.prepareForGuestNextStep(this.papCallback);
  }

  onDayChange(day, dayString) {
    const sessionTime = moment(this.state.sessionTime, 'hh:mm A');
    const sessionDatetime = sessionTime.month(day.month()).date(day.date()).year(day.year());
    const todaysCap = momentCap(day.toDate());

    // if the time is not avail for date then set to the min time for date
    if (todaysCap > sessionDatetime) {
      this.setState({ sessionDate: dayString, sessionTime: todaysCap.format('hh:mm A') }, () => {
        this.sameDayCopy();
        this.setState({
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    } else {
      this.setState({
        sessionDate: dayString,
      }, () => {
        this.sameDayCopy();
        this.setState({
          sessionTime: this.timeValue(),
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    }
  }

  isValidDate(currentDate) {
    const { todayMinTime } = this.state;
    const { todayMaxTime } = this.state;
    const now = moment(new Date());

    const disabledHours = this.state.workingHours;
    if (!isEmpty(disabledHours)) {
      const dayName = currentDate.clone().format('dddd');
      const obj = find(disabledHours, (el) => (el.weekname === dayName));
      if (isEmpty(obj)) {
        return false;
      }
    }

    if (currentDate.isSame(now, 'day')) {
      if (todayMaxTime.isBefore(todayMinTime)) {
        // There is not available slots today
        return false;
      }

      return now.isSameOrBefore(todayMaxTime) && currentDate.isSameOrBefore(momentCap(new Date()));
    }

    return currentDate.isAfter(yesterday);
  }
  headsUpCopy() {
    return this.state.showHeadsUp ? <div className='p-16 bg-peach br-rd-8 relative mb-16'>
      <div className='mb-8'><span className='size-12-20 p-2-4 bg-deep-orange contentInversePrimary br-rd-4'>Heads up!</span></div>
      <div className='size-14-20 contentPrimary'>Appointments booked a day in advance are more likely to be accepted by a Provider.</div>
      <span className="cursor-pointer contentPrimary abs-top r-16 t-16"
        onClick={() => this.setState({ showHeadsUp: false })}>
        <Close style={{ fontSize: '20px' }} />
      </span>
    </div> : null;
  }

  render() {
    const { todayMaxTime, todayMinTime, slideFlag } = this.state;
    const guest = hasUuid();
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const proFirstName = get(this.props, 'booking.cart.therapist_preferences.0.first_name', '');
    const marketplaceEnabled = get(this.props, 'booking.cart.info_fields.marketplace_enabled', false);
    if (marketplaceEnabled && !guest && !rebook && size(get(this.props, 'booking.cart.cartProducts', [])) === 1) {
      return (
        <div className="display-flex">
          <div className="w-450">
            <div className="color-black medium-font main-text size-44-52 mb-24 sm-hidden mt-24">Date and Time</div>
            <div className="background-primary sm-pb-100 border-radius-16">
              <DateTimeMarketplace
                assignToCart={this.props.assignToCart}
                displayError={this.props.displayError}
                setLoaderFlag={this.props.setLoaderFlag}
                setBookingFlowStepThroughParent={this.props.setBookingFlowStepThroughParent}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <Slide direction="left" in={slideFlag} mountOnEnter unmountOnExit>
        <div className="display-flex">
          <div className={rebook ? 'max-width-30' : 'max-width-47'}>
            <div className={`${guest ? 'color-white txt-shadow sm-color-black' : 'color-black'} medium-font mb-48 size-44-52 sm-size-28-36 sm-mb-12 mt-32 sm-mt-0-imp`}>{proFirstName ? `${proFirstName}'s Availability` : 'Date and Time'}</div>
            <div className={`${guest || rebook ? 'background-primary' : ''} ${rebook ? '' : 'max-width-375'} sm-pb-100 border-radius-16`}>
              {rebook
                ? (
                  <TimePicker
                    action={this.updateCartDateTime}
                    setLoaderFlag={this.props.setLoaderFlag}
                    assignToCart={this.props.assignToCart}
                  />
                )
                : (<>
                  {this.state.width <= 800 ? this.headsUpCopy() : null}
                  <div className="bg-primary contentPrimary br-rd-16 p-24 sm-bg-secondary">
                    {this.state.width > 800 ? this.headsUpCopy() : null}
                    <DayPicker
                      value={this.state.sessionDate}
                      timeFormat={false}
                      onChange={this.onDayChange}
                      isValidDate={this.isValidDate}
                    />
                    <HourPicker
                      date={this.state.sessionDate}
                      time={this.state.sessionTime}
                      minTime={todayMinTime.format('hh:mm A')}
                      maxTime={todayMaxTime.format('hh:mm A')}
                      onChange={(sessionTime) => { this.setState({ sessionTime }); }}
                    />
                    {this.showCouplesOption()}
                    <CTAButton
                      text="Continue"
                      action={() => {
                        this.updateCartDateTime(this.state.sessionDate, this.state.sessionTime);
                      }}
                      additionalClass="w-100-perc"
                      additionalWrapperClass="justify-content-center br-top-none-imp"
                      disabled={todayMinTime.isAfter(todayMaxTime)}
                      relevantId="continueButton"
                    />
                  </div>
                </>
                )}
            </div>
          </div>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  fieldsHolder: state.fieldsHolder,
});

export default withLocalize(connect(mapStateToProps, {
  setBookingFlowStep,
  setCart,
  setAddresses,
  setProducts,
  setBookingFlowPreviousStep,
  setAvailableTherapists,
  setAvailableTherapistsWithTimeSlots,
  setBookingFlowComingFrom,
})(Index));
