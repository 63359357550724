/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import Help from '@material-ui/icons/Help';
import TextWithIcon from '../../Shared/TextWithIcon';
import { ROUTES } from '../../../constants';
import { seViewHelpCenter } from '../../Shared/WebAnalytics';

export default class Support extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="p-24-16">
          <div className="size-24-32 color-black medium-font">Support</div>
        </div>
        <a
          className="clickable"
          href={ROUTES.helpCenter}
          onClick={() => {
            seViewHelpCenter('appointment_details');
          }}
        >
          <TextWithIcon
            muiIcon={<Help />}
            title="Help center"
            displayArrow
            displayBorder
          />
        </a>
      </>
    );
  }
}
