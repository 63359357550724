/* eslint-disable no-console */
import axios from 'axios';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';

export function getPastAppointments(page, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/clients/appointments/past?page=${page}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function getUpcomingAppointments(page, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/clients/appointments/upcoming?page=${page}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
    const { result } = response.data;
    if (result && callBackFunc) {
      callBackFunc(response);
    }
  }).catch((err) => {
    if (errorHandler) {
      errorHandler(err);
    } else {
      console.log(err);
    }
  });
}
export function asyncGetPastAppointments(page) {
  return axios.get(`${API_ROOT}/clients/appointments/past?page=${page}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER);
}
export function asyncGetUpcomingAppointments(page) {
  return axios.get(`${API_ROOT}/clients/appointments/upcoming?page=${page}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER);
}
