/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get } from 'lodash';
import TipPerPerson from './TipPerPerson';

class TipProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.tippingMessage = this.tippingMessage.bind(this);
  }

  tippingMessage() {
    return '20% is the recommended tip in most locations. 100% of the tip goes to your Provider.';
  }

  render() {
    const { cart } = this.props.booking;
    const { cartProducts } = cart;
    if (!isEmpty(cartProducts)) {
      return (
        <div>
          <div className="second-title-style">Add a tip</div>
          {cartProducts.map((cartProduct, it) => {
            const { parent_id } = cartProduct;
            const tip_changes_allowed = get(cartProduct, 'tip.tip_changes_allowed', false);
            if (tip_changes_allowed && !parent_id) {
              return (
                <div key={`tip-${it}`}>
                  <TipPerPerson
                    cartProduct={cartProduct}
                    updateSummaryBillingData={this.props.updateSummaryBillingData}
                    assignToCart={this.props.assignToCart}
                    setLoaderFlag={this.props.setLoaderFlag}
                    elIndex={it + 1}
                  />
                </div>
              );
            }

            return null;
          })}
          <div className="gray-note">{this.tippingMessage()}</div>
        </div>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

TipProvider.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(TipProvider));
