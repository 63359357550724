
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import TextWithIcon from '../../Shared/TextWithIcon';
import CalendarIcon from './Assets/Images/Calendar_icon.png';
import { isEmpty, get } from 'lodash';
import { isGuestUser } from '../../../constants';

class AppointmentDate extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const cart = get(this.props, "booking.cart", null);
        let cartProducts = get(cart, "cartProducts", []);
        if (!isEmpty(cartProducts)) {
            let formattedDisplay = `${get(this.props, "booking.cart.date.display", "")} at ${get(this.props, "booking.cart.time.display", "")}`;
            return (
                <TextWithIcon
                    icon={CalendarIcon}
                    title={"Date"}
                    details={formattedDisplay}
                    displayBorder={isGuestUser() ? true : false}
                />
            )
        }
        return null;
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

AppointmentDate.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(AppointmentDate));