/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { GIFT_CARD_CART } from '../Actions';
import { GIFT_CARD_CART_DEFAULT } from '../Containers/GiftCards/Shared/constants';

export default function (state = GIFT_CARD_CART_DEFAULT, action) {
  switch (action.type) {
    case GIFT_CARD_CART:
      return action.payload;
    default:
      return state;
  }
}
