/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get } from 'lodash';
import TextWithIcon from '../../Shared/TextWithIcon';
import CalendarIcon from './Assets/Images/Calendar_icon.png';
import { isGuestUser } from '../../../constants';

class AppointmentDate extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const cart = get(this.props, 'booking.cart', null);
    const cartProducts = get(cart, 'cartProducts', []);
    if (!isEmpty(cartProducts)) {
      const formattedDisplay = `${get(this.props, 'booking.cart.date.display', '')}, ${get(this.props, 'booking.cart.time.display', '')}`;
      return (
        <TextWithIcon
          icon={CalendarIcon}
          title="Date"
          details={formattedDisplay}
          displayBorder={!!isGuestUser()}
        />
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

AppointmentDate.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(AppointmentDate));
