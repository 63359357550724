import React from 'react';
import DatetimeWrapper from '../../Shared/DatetimeWrapper';

class DayPicker extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className='p-16-24'>
            <DatetimeWrapper open={true} input={false}
                {...this.props}
            /></div>);
    }
}
export default DayPicker;