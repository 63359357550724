/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import {
  capitalize, concat, filter, find, get, isEmpty, join, last, map, size,
} from 'lodash';
import { ArrowBackIos, Close } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ChatList from '../Shared/ChatList';
import HelmetTag from '../Shared/HelmetTag';
import { getChatMessagesByPage, getChatsOfCurrentUser } from './Shared/helpers';
import { setAppointment, setChats, fetchChat } from '../../Actions';
import ErrorModal from '../Shared/ErrorModal';
import { defaultErrorMessage } from '../../constants';
import MessageList from './MessageList';
import './Inbox.css';
import { decode } from '../AppointmentManagement/Shared/encode';
import AppointmentDetails from '../AppointmentManagement/AppointmentDetails';
import MessageInput from './MessageInput';
import ReportMenu from './ReportMenu';
import SignupModal from '../Shared/SignupModal';
import TextWithIcon from '../Shared/TextWithIcon';
import { seInboxToggleAptDetails, seInboxView, seInboxViewMessage } from '../Shared/WebAnalytics';
import NavbarRedesignForMarketplace from '../Shared/NavbarRedesignForMarketplace';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      showModal: false,
      error: '',
      chatList: [],
      isOpen: false,
      hasNewMessages: false,
      page: null,
      firstTimeSingle: true,
      listPage: 1,
      hasMoreChats: true,
    };
    this.fetchChatList = this.fetchChatList.bind(this);
    this.fetchSingleChat = this.fetchSingleChat.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.widthRelevantView = this.widthRelevantView.bind(this);
    this.mobileView = this.mobileView.bind(this);
    this.desktopView = this.desktopView.bind(this);
    this.chatListGrid = this.chatListGrid.bind(this);
    this.messagesGrid = this.messagesGrid.bind(this);
    this.cartDetailsGrid = this.cartDetailsGrid.bind(this);
    this.chatClickAction = this.chatClickAction.bind(this);
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
    this.chatPageMobile = this.chatPageMobile.bind(this);
    this.chatListMobile = this.chatListMobile.bind(this);
    this.getChatState = this.getChatState.bind(this);
    this.getSingleChatState = this.getSingleChatState.bind(this);
    this.loadMoreChats = this.loadMoreChats.bind(this);
  }

  componentDidMount() {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const key = parameters.get('chat_id');
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    this.fetchChatList(true, Boolean(key));
    if (key) {
      this.fetchSingleChat(key, true);
    }
  }

  getChatState(chatList) {
    let stt = 'empty';
    const hasNewMessages = find(chatList, (el) => !get(el, 'chats.0.seen', false));
    if (!isEmpty(get(chatList, 'chats', []))) {
      if (!isEmpty(hasNewMessages)) {
        stt = 'unread';
      } else {
        stt = 'all_read';
      }
    }
    return stt;
  }

  getSingleChatState(chats) {
    let stt = 'empty';
    const hasNewMessages = find(chats, (el) => !get(el, 'seen', false));
    if (!isEmpty(chats)) {
      if (!isEmpty(hasNewMessages)) {
        stt = 'unread';
      } else {
        stt = 'all_read';
      }
    }
    return stt;
  }

  fetchChatList(firstTime = false, key = false) {
    getChatsOfCurrentUser(this.state.listPage, (resp) => {
      const chatList = get(resp, 'data.chat_list', []);
      const hasNewMessages = find(chatList, (el) => !get(el, 'chats.0.seen', false));
      this.setState({
        hasNewMessages: !isEmpty(hasNewMessages),
        hasMoreChats: size(chatList) >= 10,
      });
      if (firstTime) {
        seInboxView(this.getChatState(chatList), key ? 'deep_link' : 'nav_bar');
      }
      this.props.setChats(chatList);
      if (!get(this.props, 'currentChat.chat_id', '')) {
        this.props.setAppointment(null);
        this.setState({ isOpen: false });
      }
    }, (err) => {
      this.setState({ showModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  fetchSingleChat(chat_id, key = false) {
    const oldOpenStatus = this.state.isOpen;
    getChatMessagesByPage(chat_id, this.state.page, (resp) => {
      this.setState({ isOpen: Boolean(get(resp, 'data.cart', null)) });
      this.props.setAppointment(decode(get(resp, 'data.cart', null)));
      this.props.fetchChat(get(resp, 'data', null));
      this.setState({ hasMoreChats: true, listPage: 1, isOpen: oldOpenStatus }, this.fetchChatList);
      if (this.state.firstTimeSingle) {
        seInboxViewMessage({
          state: this.getSingleChatState(get(resp, 'data.chats', [])),
          click_source: key ? 'deep_link' : 'nav_bar',
          cart_product_count: size(get(resp, 'data.cart.display_helpers.v0.cart_products', [])),
          booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
          is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
          service_category: get(resp, 'data.cart.display_helpers.v0.cart_products.0.product.title', ''),
          service_modality: get(resp, 'data.cart.display_helpers.v0.cart_products.0.cart_product_main_option.title', ''),
        });
        this.setState({ firstTimeSingle: false });
      }
    }, (err) => {
      this.setState({ showModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  chatClickAction(chat_id) {
    this.fetchSingleChat(chat_id);
  }

  loadMoreMessages() {
    const currentMsgs = get(this.props, 'currentChat.chats', []);
    const oldestMessageId = get(last(currentMsgs), 'message_id', '');
    this.setState({ page: oldestMessageId }, () => {
      getChatMessagesByPage(get(this.props, 'currentChat.chat_id', ''), this.state.page, (resp) => {
        const extended = concat(currentMsgs, get(resp, 'data.chats', []));
        this.props.fetchChat({ ...this.props.currentChat, chats: extended });
      }, (err) => {
        this.setState({ showModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    });
  }

  loadMoreChats() {
    if (this.state.hasMoreChats) {
      this.setState({ listPage: this.state.listPage + 1 }, () => {
        getChatsOfCurrentUser(this.state.listPage, (resp) => {
          const extended = concat(get(this.props, 'chats', []), get(resp, 'data.chat_list', []));
          this.props.setChats(extended);
          if (isEmpty(get(resp, 'data.chat_list', []))) {
            this.setState({ hasMoreChats: false, listPage: this.state.listPage - 1 });
          }
        }, (_err) => {
          this.setState({ hasMoreChats: false });
        });
      });
    }
  }

  chatListGrid() {
    const noChatsYet = isEmpty(get(this.props, 'chats', []));
    if (noChatsYet && this.state.width >= 1000) {
      return null;
    }
    return (
      <div className=" br-r-opaque">
        <div className={` br-b-opaque  bg-primary z-index-1 ${this.state.width < 1000 ? 'top-sticky' : 'top-sticky-80'} `}>
          <div className="size-20-28 font-weight-bold contentPrimary p-16">Messages</div>
        </div>
        <div className="top-sticky-141 set-overflow max-h-full mt-80 sm-mt-0">
          <ChatList
            chatList={this.props.chats}
            current_chat_id={get(this.props, 'currentChat.chat_id', '')}
            clickAction={this.chatClickAction}
            loadNextChats={this.loadMoreChats}
            hasMoreChats={this.state.hasMoreChats}
          />
        </div>
      </div>
    );
  }

  messagesGrid() {
    const participants = get(this.props, 'currentChat.participants', []);
    const filteredParticipants = filter(participants, (el) => (!el.current_user));
    return (
      <div className="">
        {isEmpty(get(this.props, 'currentChat', {})) ? null : (
          <div className="br-b-opaque top-sticky-80 bg-primary z-index-2 ">
            <div className="d-flex-only align-items-center">
              <div className="flex-1 p-6-16">
                <div className="size-20-28 font-weight-bold contentPrimary">{join(map(filteredParticipants, (pp) => (pp.name || '')), ', ') || 'Chat'}</div>
                <div className="size-12-20 contentSecondary">{join(map(filteredParticipants, (pp) => (pp.last_seen || '')), ', ') || 'No last seen to show'}</div>
              </div>
              {isEmpty(this.props.appointment) ? null : (
                <button
                  type="button"
                  className="btn btn-accent-light mr-16"
                  onClick={() => {
                    seInboxToggleAptDetails();
                    this.setState({ isOpen: !this.state.isOpen });
                  }}
                >
                  Hide details
                </button>
              )}
              <div className=" mr-16">
                <ReportMenu
                  reported_ids={filteredParticipants}
                  hideReportCTA={isEmpty(get(this.props, 'currentChat', {}))}
                />
              </div>
            </div>
          </div>
        )}
        <div className={this.state.isOpen ? 'm-26-24-20' : 'chat-screen-dimensions'}>
          <div className="set-overflow max-h-full-44 mt-80 ">
            <MessageList loadMore={this.loadMoreMessages} />
          </div>
          {isEmpty(get(this.props, 'currentChat', {})) ? null : (
            <MessageInput
              showError={(msg) => {
                this.setState({ showModal: true, error: msg });
              }}
              reloadMessages={() => {
                this.setState({ page: null }, () => {
                  this.fetchSingleChat(get(this.props, 'currentChat.chat_id', ''));
                });
              }}
            />
          )}
        </div>
      </div>
    );
  }

  cartDetailsGrid() {
    return (
      <div className=" br-l-opaque ">
        {this.state.isOpen ? (
          <>
            <div className="br-b-opaque top-sticky-80 bg-primary z-index-1">
              <div className="d-flex-only align-items-center">
                <div className="flex-1 size-20-28 font-weight-bold contentPrimary p-16">Details</div>
                <button
                  type="button"
                  className="btn p-10 bg-secondary contentPrimary br-rd-50 size-10-15 mr-16 w-h-36 flex-centered"
                  onClick={() => {
                    seInboxToggleAptDetails();
                    this.setState({ isOpen: !this.state.isOpen });
                  }}
                >
                  <Close />
                </button>
              </div>
            </div>
            <div className="mt-80 ">
              <AppointmentDetails width={this.state.width} xsDisplay />
            </div>
          </>
        ) : null}
      </div>
    );
  }

  chatPageMobile() {
    const participants = get(this.props, 'currentChat.participants', []);
    const filteredParticipants = filter(participants, (el) => (!el.current_user));
    return (
      <div className="height-100-perc-mobile">
        <div className="bg-primary contentPrimary">
          <div className="br-b-opaque d-flex-only align-items-center justify-content-spaced p-6-16">
            <div>
              <button
                type="button"
                className="btn btn-link LightBlack"
                onClick={() => {
                  this.props.fetchChat({});
                }}
              >
                <ArrowBackIos />
              </button>
            </div>
            <div className="p-6-16">
              <div className="size-16-20 font-weight-bold contentPrimary mb-4">{join(map(filteredParticipants, (pp) => (pp.name || '')), ', ') || ' '}</div>
              <div className="size-10-20 contentSecondary">{join(map(filteredParticipants, (pp) => (pp.last_seen || '')), ', ') || ' '}</div>
            </div>
            <div>
              <ReportMenu
                reported_ids={filteredParticipants}
                hideReportCTA={isEmpty(get(this.props, 'currentChat', {}))}
              />
            </div>
          </div>
          <div className="br-b-opaque cstm-details-style">
            <Link
              className="contentPrimary"
              to={{
                pathname: '/appointment_management/',
                state: {
                  click_source: 'inbox',
                  upcomingAppointmentId: get(this.props, 'currentChat.cart.id', ''),
                },
              }}
            >
              <TextWithIcon
                icon={get(this.props, 'currentChat.cart.info_fields.img_url', '')}
                title={get(this.props, 'currentChat.cart.display_helpers.v0.cart_products.0.cart_product_main_option.title', '')}
                details={(
                  <div className="size-12-20">
                    {capitalize(get(this.props, 'currentChat.cart.info_fields.tracker.state', 'Pending'))}
                    {' '}
                    •
                    {' '}
                    {moment(get(this.props, 'currentChat.chats.0.timestamp', '')).format('ddd, MMM D, h:mm a')}
                  </div>
                )}
                displayArrow
              />
            </Link>
          </div>
        </div>
        <div className="p-16">
          <MessageList loadMore={this.loadMoreMessages} />
          <MessageInput
            showError={(msg) => {
              this.setState({ showModal: true, error: msg });
            }}
            reloadMessages={() => {
              this.setState({ page: null }, () => {
                this.fetchSingleChat(get(this.props, 'currentChat.chat_id', ''));
              });
            }}
          />
        </div>
      </div>
    );
  }

  chatListMobile() {
    return (
      <>
        {this.chatListGrid()}
        <NavbarRedesignForMarketplace
          hideGoBack
          darkenHeader
          additionalActionLoggedIn
          notBooking
          showMenu
          jul_2024_booking_enhancements={get(this.props, "client.ab_tests.jul_2024_booking_enhancements", "")}
        />
      </>
    );
  }

  mobileView() {
    if (!isEmpty(get(this.props, 'currentChat', {}))) {
      return this.chatPageMobile();
    }
    return this.chatListMobile();
  }

  desktopView() {
    const { isOpen } = this.state;
    const noChatsYet = isEmpty(get(this.props, 'chats', [])) && this.state.width >= 1000;
    return (
      <>
        <NavbarRedesignForMarketplace
          hideGoBack
          darkenHeader
          additionalActionLoggedIn
          notBooking
          showMenu
          jul_2024_booking_enhancements={get(this.props, "client.ab_tests.jul_2024_booking_enhancements", "")}
        />
        <div className="">
          <div className="row">
            <div className={`col-xs-0 ${noChatsYet ? 'col-sm-0' : 'col-sm-3'} pr-0`}>
              {this.chatListGrid()}
            </div>
            <div className={`col-xs-12 ${noChatsYet ? 'col-sm-12' : isOpen ? 'col-sm-6' : 'col-sm-9'} prl-0`}>
              {this.messagesGrid()}
            </div>
            {isOpen ? (
              <div className="col-sm-3 pl-0">
                {this.cartDetailsGrid()}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showModal}
        close={() => { this.setState({ showModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  widthRelevantView() {
    if (this.state.width <= 1000) {
      return this.mobileView();
    }
    return this.desktopView();
  }

  render() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (!loggedIn) {
      return (
        <SignupModal
          isSignin
          next={() => {
            window.location.reload();
          }}
        />
      );
    }
    return (
      <>
        <HelmetTag />
        {this.widthRelevantView()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  chats: state.chats,
  currentChat: state.currentChat,
  abTest: state.abTest,
});
export default connect(mapStateToProps, { setAppointment, setChats, fetchChat })(Index);
