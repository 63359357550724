/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import '../../Assets/Styles/index.css';
import ResponseConfirmChanges from './ResponseConfirmChanges';
import ResponseTimesModal from './ResponseTimesModal';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageModal: get(props, 'show', false),
      timesModal: false,
    };
  }

  render() {
    const { messageModal, timesModal } = this.state;
    if (this.props.show) {
      if (this.state.messageModal) {
        return (
          <ResponseConfirmChanges
            show={messageModal}
            switchModal={() => {
              this.setState({ messageModal: false, timesModal: true });
            }}
            hideModal={this.props.hideModal}
          />
        );
      } if (timesModal) {
        return (
          <ResponseTimesModal
            show={timesModal}
            hideModal={() => this.setState({ timesModal: false })}
          />
        );
      }
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
});
export default connect(mapStateToProps, {})(Index);
