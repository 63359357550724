/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import BlueCheckLottie from '../../Shared/BlueCheckLottie';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import CTAButton from '../../Shared/CTAButton';

export default class SuccesModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <LatestModalDesign isOpen={this.props.isOpen} hideBackArrow hideFooter>
        <div className="mb-16"><BlueCheckLottie /></div>
        <div className="size-20-28 medium-font txt-center mb-12">{this.props.title}</div>
        {this.props.subtitle ? <div className="size-12-20 txt-center clr-gray mb-36">{this.props.subtitle}</div> : null}
        {this.props.customCTA ? this.props.customCTA : (
          <CTAButton
            text={this.props.ctaText ? this.props.ctaText : 'View details'}
            additionalClass="full-width-btn"
            additionalWrapperClass="borderless-btn"
            action={this.props.ctaAction}
          />
        )}
      </LatestModalDesign>
    );
  }
}
