/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  find, get, filter, isEmpty, merge, pick, snakeCase, identity,
  pickBy, mapKeys, camelCase, map, orderBy, compact, join,
} from 'lodash';
import { Close, ExpandMore, LocationOn } from '@material-ui/icons';
import { setCart, setAddresses, setProducts } from '../../../Actions';
import { defaultErrorMessage, HotJar } from '../../../constants';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import PlaceAutoCompleteInput from '../../Shared/PlaceAutoCompleteInput';
import { addressAddNew, addressSelection, seBookingAddressInput } from '../../Shared/WebAnalytics';
import grayMarker from '../../../Assets/Images/places_input_marker.png';
import TextWithIcon from '../../Shared/TextWithIcon';
import '../Assets/Styles/index.css';
import './Assets/Styles/AddressStep.css';
import LatestModalDesign from '../../Shared/LatestModalDesign';
// import FinishAddressStep from '../FinishAddressStep';
HotJar();
class AddressStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      address: null,
      isAddNew: false,
      editMode: false,
      addMode: isEmpty(get(props, 'booking.cart.address', '')),
      finishAddress: false,
      width: window.innerWidth,
    };
    this.addressInput = this.addressInput.bind(this);
    this.getProductsByZipcode = this.getProductsByZipcode.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.renderBasedOnUserStatus = this.renderBasedOnUserStatus.bind(this);
    this.addressList = this.addressList.bind(this);
    this.selectDefaultOrFirstAddress = this.selectDefaultOrFirstAddress.bind(this);
    this.addressListItem = this.addressListItem.bind(this);
    this.setDataAndNext = this.setDataAndNext.bind(this);
    this.fetchAddressesByUserId = this.fetchAddressesByUserId.bind(this);
    this.relevantCopy = this.relevantCopy.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
    this.props.changeBackground('');
    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (loggedIn) {
      this.fetchAddressesByUserId(get(this.props, 'client.user_id', ''), loggedIn);
    }
  }

  componentWillReceiveProps(nextProps) {
    const oldUserId = get(this.props, 'client.user_id', '');
    const newUserId = get(nextProps, 'client.user_id', '');
    const loggedIn = get(nextProps, 'client.loggedIn', false);
    if (loggedIn && newUserId && newUserId !== oldUserId) {
      this.fetchAddressesByUserId(newUserId, loggedIn);
    }
  }

  selectDefaultOrFirstAddress(addresses) {
    if (!isEmpty(addresses)) {
      const addressId = get(this.props, 'booking.cart.address.id', '');
      const rebook = get(this.props, 'booking.cart.rebook', false);
      let defaultAddress;
      if (addressId) {
        defaultAddress = find(addresses, (a) => (a.id === addressId || (rebook && a.addressLine1 === get(this.props, 'booking.cart.address.address_line_1', ''))));
      } else {
        defaultAddress = find(addresses, (a) => (a.default && (isEmpty(a.selectability) || get(a.selectability, 'selectable', false))));
      }
      if (!defaultAddress) {
        defaultAddress = find(addresses, (a) => (isEmpty(a.selectability) || get(a.selectability, 'selectable', false)));
      }
      this.setState({ address: defaultAddress }, this.getProductsByZipcode);
    }
  }

  fetchAddressesByUserId(userId, loggedIn) {
    const cartId = get(this.props, 'booking.cart.id', '');
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const address1 = get(this.props, 'booking.cart.address', '');
    if (userId && loggedIn) {
      if (cartId) {
        axios.get(`${API_ROOT}/v7/addresses?cart_id=${cartId}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
          let { addresses } = response.data;
          addresses = orderBy(addresses.map((address) => mapKeys(address, (value, key) => camelCase(key))), ['default'], ['desc']);
          this.props.setAddresses(addresses);
          if (!rebook && isEmpty(address1)) {
            this.selectDefaultOrFirstAddress(addresses);
          }
        }).catch((e) => {
          const message = get(e, 'response.data.errors.0.message', defaultErrorMessage);
          this.setState({ showErrorModal: true, error: message });
        });
      } else {
        axios.get(`${API_ROOT}/users/${userId}/addresses`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
          let { addresses } = response.data;
          addresses = orderBy(addresses.map((address) => mapKeys(address, (value, key) => camelCase(key))), ['default'], ['desc']);
          this.props.setAddresses(addresses);
          this.selectDefaultOrFirstAddress(addresses);
        }).catch((e) => {
          const message = get(e, 'response.data.errors.0.message', defaultErrorMessage);
          this.setState({ showErrorModal: true, error: message });
        });
      }
    }
  }

  setDataAndNext(products, andNext) {
    if (!isEmpty(products)) {
      this.props.setProducts({ products });
      const address = merge({
        has_dogs: false, has_cats: false, has_table: false, has_sheets: false,
      }, pick(pickBy(mapKeys(this.state.address, (value, key) => snakeCase(key)), identity), ['address_line_1', 'address_line_2', 'city', 'state', 'zip_code', 'parking_info', 'parking_type', 'flights_of_stairs', 'hotel_name', 'hotel_room_number', 'gate_door_code', 'has_dogs', 'has_cats', 'has_table', 'has_sheets', 'mobile_number', 'id', 'complete', 'default', 'market']), { client_id: this.props.client.user_id });
      if (!address.address_line_1) {
        address.address_line_1 = this.state.addressLine1;
        address.zip_code = this.state.zipCode;
      }
      const cart = get(this.props, 'booking.cart', {});
      const addresses = get(this.props, 'addresses', []);
      this.props.assignToCart({ cart: { ...cart, address } });
      const addressId = get(address, 'id', '');
      if (addressId) {
        addressSelection(get(cart, 'id', ''), addressId, 'no', 'booking_23_variant', get(cart, 'address.market', get(address, 'market', '')), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
      } else {
        addressAddNew('', 'booking_flow', 'booking_23_variant', 'No', 'No', get(cart, 'address.market', get(address, 'market', '')), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
      }
      this.setState({ addMode: false, editMode: false });
    } else {
      this.setState({ error: 'We currently do not service this address. Please try another one.' });
    }
  }

  getProductsByZipcode(andNext = false) {
    const self = this;
    const { address } = this.state;
    let queryParam = '';
    const {
      country, city, zipCode, cityId, addressLine1, state,
    } = address;
    if (zipCode) {
      queryParam = `?zip_code=${zipCode}`;
    } else if (cityId) {
      queryParam = `?city_id=${cityId}`;
    }
    if (!(zipCode && addressLine1 && (city || cityId) && state)) {
      this.setState({ error: 'Please select a location with a street address' });
      return;
    }
    this.setState({ error: '' });
    this.props.setLoaderFlag(true);
    axios.get(`${API_ROOT}/v7/products${queryParam}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      let { products } = response.data;
      products = filter(products, (el) => (el.bookable));
      seBookingAddressInput(country, city, zipCode, products && products.length > 0);
      self.setDataAndNext(products, andNext);
      this.props.setLoaderFlag(false);
    }).catch((e) => {
      const message = get(e, 'response.data.errors.0.message', '') || get(e, 'response.data.errors.message', defaultErrorMessage);
      this.setState({ error: message });
      this.props.setLoaderFlag(false);
    });
  }

  addressInput() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    return (
      <LatestModalDesign
        title="Add address to see full menu and pricing in your area"
        contentExtraClass="reverse-modal-content pt-32-imp"
        isOpen={!this.props.showLogin && this.state.addMode}
        hideFooter
        hideBackArrow
      >
        <div className="size-16-20 contentPrimary font-weight-bold mt-16 mb-8">Address</div>
        <PlaceAutoCompleteInput
          uniqueId="new-booking-flow-first-screen"
          inputStylingClass="form-control plain-gray-input br-none"
          hideIcon
          handleAddress={(newAddress) => {
            this.setState({ address: newAddress, error: '' }, () => this.getProductsByZipcode(true));
          }}
          placeholder="Search here"
          inCaseOfError={() => {
            this.setState({ error: 'Please select a location with a street address' });
          }}
        />
        {this.errorMessage()}
        {loggedIn ? null : (
          <>
            <div className="or-separator mb-24 mt-24">
              <div className="gray-hr-line flex-1-1-45" />
              <div className="contentTertiary size-14-20 flex-1-1-10 font-weight-bold">OR</div>
              <div className="gray-hr-line flex-1-1-45" />
            </div>
            <div className="centered">
              <div className="size-18-28 contentPrimary font-weight-bold mt-8 mb-6 ">Login to use a saved address</div>
              <button
                type="button"
                className="btn btn-accent-light width-200 mb-20"
                onClick={this.props.toggleAuth}
              >
                Login
              </button>
            </div>
          </>
        )}
      </LatestModalDesign>
    );
  }

  addressListItem(addr, currentId, isFirst = false) {
    const {
      id, addressLine1, city, state, zipCode, country, selectability,
    } = addr;
    const isSelected = Boolean(currentId === id);
    const valid = isEmpty(selectability) || get(selectability, 'selectable', false);
    return (
      <div
        key={`addr-${id}`}
        className={`address-item mb-16 br-rd-8 
        ${isSelected ? 'address-item-selected br-blue-2' : 'br-opaque-2'} ${!valid ? 'opacity-05' : ''}`}
        id={isFirst ? 'firstSavedAddress' : `savedAddress${id}`}
        onClick={() => {
          if (valid) {
            this.setState({ address: addr }, () => this.getProductsByZipcode(true));
          }
        }}
      >
        <TextWithIcon
          icon={grayMarker}
          title={addressLine1 || ''}
          details={join(compact([city, `${state || ''} ${zipCode || ''}`, country]), ', ')}
        />
      </div>
    );
  }

  addressList(addresses) {
    const currentId = get(this.state, 'address.id', '');
    addresses = orderBy(addresses, (o) => (o.id !== currentId));
    return (
      <LatestModalDesign
        title="Address Book"
        customIcon={<Close />}
        back={() => this.setState({ editMode: false })}
        isOpen
        hideFooter
      >
        <PlaceAutoCompleteInput
          uniqueId="existent-booking-flow-first-screen"
          inputStylingClass="form-control plain-gray-input p-14-64-imp br-none"
          handleAddress={(newAddress) => {
            this.setState({ address: newAddress, error: '' }, () => this.getProductsByZipcode(true));
          }}
          placeholder="Search new address"
          inCaseOfError={() => {
            this.setState({ error: 'Please select a location with a street address' });
          }}
        />
        {this.errorMessage()}
        <div className="size-18-24 mt-24 mb-8 font-weight-bold contentPrimary">Saved addresses</div>
        {map(addresses, (addr, ind) => (this.addressListItem(addr, currentId, ind === 0)))}
      </LatestModalDesign>
    );
  }

  renderBasedOnUserStatus() {
    const addressId = get(this.props, 'booking.cart.address.id', '');
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const addresses = get(this.props, 'addresses', []);
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const { addMode, editMode, finishAddress } = this.state;
    if (rebook && !addressId) {
      return this.addressList(addresses);
    }
    if (loggedIn && !isEmpty(addresses)) {
      if (editMode) {
        return this.addressList(addresses);
      }
      return null;
    }
    if (addMode) {
      return this.addressInput();
    }
    return null;
  }

  relevantCopy() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const address = get(this.props, 'booking.cart.address', '');
    const rebook = get(this.props, 'booking.cart.rebook', false);
    if (this.state.width <= 800) {
      return (
        <div className="size-14-16 font-weight-bold contentPrimary d-flex-only justify-content-around">
          <LocationOn />
          <div
            className="mr-8 ml-8"
            onClick={() => {
              if (loggedIn) {
                this.setState({ editMode: true });
              } else {
                this.setState({ addMode: true });
              }
            }}
          >
            {get(address, 'address_line_1', 'Edit address')}
          </div>
          <ExpandMore />
        </div>
      );
    }
    if (isEmpty(address)) {
      return <div className="size-52-64 md-size-32-40 font-weight-bold contentPrimary">Treatments Menu</div>;
    }
    return (
      <div className="size-52-64 md-size-32-40 font-weight-bold contentPrimary">
        Treatments available at
        <br />
        <div
          className="cursor-pointer btn-link BluePrimary underline size-52-64 md-size-32-40 ellipsis-2"
          onClick={() => {
            if (loggedIn) {
              this.setState({ editMode: true });
            } else {
              this.setState({ addMode: true });
            }
          }}
        >
          {get(address, 'address_line_1', '')}
        </div>
      </div>
    );
  }

  errorMessage() {
    return (this.state.error
      ? <div className="size-14-20 color-red mt-8 medium-font">{this.state.error}</div>
      : null);
  }

  render() {
    return (
      <>
        {this.relevantCopy()}
        {this.renderBasedOnUserStatus()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.addresses,
  booking: state.booking,
  products: Object.values(get(state, 'productsReducer.products', {})),
  client: state.client,
  bookingFlow: state.bookingFlow,
});

AddressStep.propTypes = {
  booking: PropTypes.object,
  client: PropTypes.object.isRequired,
  bookingFlow: PropTypes.object.isRequired,
  setAddresses: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
  setProducts: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setCart, setAddresses, setProducts })(AddressStep);
