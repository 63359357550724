import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep, setBookingFlowComingFrom } from '../../../Actions';
import { STEPS } from '../Shared/constants';
import { decode } from '../Shared/encode';
import AppointmentDate from './AppointmentDate';
import AppointmentAddress from './AppointmentAddress';
import Appointments from './Appointments';
import ErrorModal from '../../Shared/ErrorModal';
import { isEmpty, get, forEach, find, size } from 'lodash';
import './Assets/Styles/index.css';
import FinishAddressStep from '../FinishAddressStep';
import Drawer from '@material-ui/core/Drawer';
import { deleteCartProduct, updateCartProduct } from "../Shared/helpers";
import CTAButton from '../../Shared/CTAButton';
import { HotJar, defaultErrorMessage, isGuestUser, hasUuid } from '../../../constants';
import { seBookingReview } from '../../Shared/WebAnalytics';
import Slide from "@material-ui/core/Slide";
import SubscriptionPlanPopup from './SubscriptionPlanPopup';
import AppointmentRoom from './AppointmentRoom';
import UnchangedAuthModal from '../../Shared/UnchangedAuthModal';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import { getCartWithSalesTax } from '../../Shared/Helpers';
import moment from 'moment-timezone';
HotJar();

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            enhancementsByProductId: {},
            error: '',
            showErrorModal: false,
            openFinishAddressDrawer: false,
            showPassPlanModal: false,
            showPassPlanModalWithoutToaster: false,
            showLoginPopup: false,
            width: window.innerWidth || 0
        }
        this.setCart = this.setCart.bind(this);
        this.enhancementClick = this.enhancementClick.bind(this);
        this.setEnhancementToProduct = this.setEnhancementToProduct.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.getCartCallbackFunc = this.getCartCallbackFunc.bind(this);
        this.displayFinishAddressDrawer = this.displayFinishAddressDrawer.bind(this);
        this.getRelevantCTAButton = this.getRelevantCTAButton.bind(this);
        this.deleteRelevantService = this.deleteRelevantService.bind(this);
        this.triggerReviewEvent = this.triggerReviewEvent.bind(this);
        this.soothePassPlanModal = this.soothePassPlanModal.bind(this);
        this.displayFinishAddressDrawer = this.displayFinishAddressDrawer.bind(this);
        this.goToCheckoutAction = this.goToCheckoutAction.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
        this.triggerReviewEvent();
        let rebook = get(this.props, "booking.cart.rebook", false);
        if (rebook) {
            this.props.toggleNavBarStyle(false);
            this.props.changeBackground("");
        } else if (isGuestUser()) {
            this.props.toggleNavBarStyle(!get(this.props, "fieldsHolder.listingPageStorage.bannerimage", ""));
            this.props.changeBackground(get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "") || "none");
        } else {
            this.props.toggleNavBarStyle(true);
            this.props.changeBackground('none');
        }
        // Setting previous step of booking flow
        let pickAProEnabled = get(this.props, "booking.cart.pickAProEnabled", false),
            backToBackAvailable = get(this.props, "booking.cart.backToBack.available", false),
            productsCount = size(get(this.props, "booking.cart.cartProducts", [])),
            previousStepKey = "TIMING";
        if (pickAProEnabled && !isEmpty(get(this.props, "bookingFlow.availableTherapists", []))) {
            previousStepKey = "PICKAPRO";
        } else if (productsCount > 1 && backToBackAvailable) {
            previousStepKey = "COUPLE_PREFERENCE";
        }
        let previousStep = find(STEPS, (step) => (step.id === previousStepKey));
        this.props.setBookingFlowPreviousStep(previousStep);
    }
    triggerReviewEvent() {
        let cart = get(this.props, "booking.cart", null),
            bkngDate = moment(get(cart, "time.utc", "")),
            nowDate = moment().tz(get(cart,"time.timezone", ""));
        let service_nb_people = size(get(cart, "cartProducts", []));
        seBookingReview(get(cart, "id", ""), get(cart, "rebook", false), get(this.props, "booking.product.title", "").toLowerCase(), service_nb_people, this.props.client.first_time_booker, "booking_23_control", {
            market: get(cart, "address.market", ""),
            is_ic: get(this.props, "booking.instant_confirmation", false),
            lead_time_selected: bkngDate.diff(nowDate, 'hours'),
            is_today: bkngDate.isSame(nowDate, 'day'),
        });
    }
    getCartCallbackFunc(response) {
        this.props.assignToCart({ cart: decode(response.data.cart) })
    }
    setCart(value) {
        this.props.setCart({
            ...this.props.booking,
            ...value
        });
    }
    goToCheckoutAction() {
        let isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false) && !hasUuid(),
            isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
            alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
            alreadyShownPopup = get(this.props, "bookingFlow.soothePassPopupAlreadyShown", false),
            subscription_membership_ends_date = get(this.props, "client.soothe_pass.subscription_membership_ends_date", ""),
            loggedIn = get(this.props, 'client.loggedIn', false);
        if (!loggedIn) {
            return this.setState({ showLoginPopup: true })
        }

        this.setState({ showLoginPopup: false })

        if (isSoothePassAvailable && !alreadyAppliedToCart && !isSoothePassSubscribed && !alreadyShownPopup && !subscription_membership_ends_date) {
            this.setState({ showPassPlanModal: true, showPassPlanModalWithoutToaster: false })
        } else {
            this.goToNextStep();
        }
    }
    getRelevantCTAButton() {
        let isCompleteAddress = get(this.props, "booking.cart.address.complete", false),
            rebook = get(this.props, "booking.cart.rebook", false);
        if (isCompleteAddress) {
            return (<CTAButton text="Go to checkout" action={this.goToCheckoutAction}
                additionalClass={rebook ? "full-width-btn" : ""}
                relevantId="goToCheckoutButton"
            />);
        }
        else {
            return (<CTAButton text="Add address details" action={() => {
                this.setState({ openFinishAddressDrawer: true })
                if (this.props.updateExceptionUi) {
                    this.props.updateExceptionUi(true)
                }
            }}
            />);
        }
    }
    enhancementClick(cartProductId, enhArr) {
        let curr = get(this.state, `enhancementsByProductId`, {});
        curr[cartProductId] = enhArr;
        this.setState({ enhancementsByProductId: curr }, () => {
            if (!isEmpty(curr)) {
                this.props.setLoaderFlag(true)
                forEach(curr, (enhs, pId) => {
                    this.setEnhancementToProduct(pId, enhs)
                })
            }
        });
    }
    setEnhancementToProduct(cartProductId, optionIds) {
        if (cartProductId) {
            updateCartProduct(this.props.booking.cart.id, cartProductId, {
                "option_ids": optionIds
            }, get(this.props, "fieldsHolder.csrfToken", ""), (response) => {
                this.props.assignToCart({ cart: decode(response.data.cart) });
                this.props.setLoaderFlag(false)
            }, (err) => {
                this.props.setLoaderFlag(false)
                this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage))
            });
        }
    }
    deleteRelevantService(cart_product_id) {
        let cartId = get(this.props, "booking.cart.id", "");
        deleteCartProduct(cartId, cart_product_id, get(this.props, "fieldsHolder.csrfToken", ""), (response) => {
            this.props.assignToCart({ cart: decode(response.data.cart) });
        }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)))
    }
    goToNextStep() {
        let nextStep = find(STEPS, (step) => (step.id === "CHECKOUT")),
            loggedIn = get(this.props, 'client.loggedIn', false);
            this.props.setBookingFlowComingFrom("REVIEW")
        if (loggedIn) {
            getCartWithSalesTax(get(this.props, "booking.cart.id", ""), (resp) => {
                this.props.assignToCart({ cart: decode(resp.data.cart) });
                this.props.setBookingFlowStepThroughParent(nextStep);
            }, (_err) => {
                this.props.setBookingFlowStepThroughParent(nextStep);
            })
        }
    }
    soothePassPlanModal() {
        if (this.state.showPassPlanModal) {
            return (<SubscriptionPlanPopup assignToCart={this.props.assignToCart} withoutToaster={this.state.showPassPlanModalWithoutToaster} goToNextStep={this.goToNextStep} showHideModal={(val) => {
                this.setState({ showPassPlanModal: val, showPassPlanModalWithoutToaster: false })
            }} />)
        }
    }
    displayFinishAddressDrawer() {
        if (this.state.openFinishAddressDrawer) {
            return (<LatestModalDesign isOpen={true} wrapperExtraClass={this.state.width <= 800 ? "max-h-full-55" : ""} hideFooter hideBackArrow>
                <FinishAddressStep closeDrawer={() => {
                    this.setState({ openFinishAddressDrawer: false })
                    if (this.props.updateExceptionUi) {
                        this.props.updateExceptionUi(false)
                    }
                }} assignToCart={this.props.assignToCart}
                    displayError={this.props.displayError} />
            </LatestModalDesign>)
        }
        return null;
    }

    displayLoginModal() {
        if (this.state.showLoginPopup) {
            return (<UnchangedAuthModal cart_id={get(this.props, "booking.cart.id", "")}
                next={this.goToCheckoutAction}
                closeModal={() => this.setState({ showLoginPopup: false })}
            />)
        }
        return null;
    }

    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: '' }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }

    render() {
        let guest = Boolean(hasUuid() && get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "")),
            rebook = get(this.props, "booking.cart.rebook", false);
        return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div>
                <div className='mb-106'>
                    <div className={guest || rebook ? "max-w-50-vw" : 'max-width-55'}>
                        <div className={`size-44-52 sm-size-32-40 ${guest || rebook ? 'color-white txt-shadow sm-color-black' : 'color-black'} medium-font txt-center sm-text-left mb-24`}>Review</div>
                        <div className={guest || rebook ? 'background-primary border-radius-16 pt-24' : ""}>
                            <div className={`p-0-60-24 ${guest ? "pt-24" : ""}`}>
                                <div className='br-btm-gray'>
                                    <AppointmentDate />
                                    <AppointmentAddress showFinishAddress={() => {
                                        this.setState({ openFinishAddressDrawer: true })
                                        if (this.props.updateExceptionUi) {
                                            this.props.updateExceptionUi(true)
                                        }
                                    }} />
                                    <AppointmentRoom assignToCart={this.props.assignToCart} displayError={this.props.displayError} />
                                </div>
                                <Appointments assignToCart={this.props.assignToCart} enhancementClick={this.enhancementClick} enhancementsByProductId={this.state.enhancementsByProductId}
                                    deleteRelevantService={this.deleteRelevantService} openPassPlanPopup={() => {
                                        this.setState({ showPassPlanModal: true, showPassPlanModalWithoutToaster: true })
                                    }} />
                            </div>
                            {this.getRelevantCTAButton()}
                        </div>
                    </div>
                </div>
                {this.soothePassPlanModal()}
                {this.displayFinishAddressDrawer()}
                {this.displayLoginModal()}
                {this.errorModal()}
            </div>
        </Slide>
        );
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client,
    creditCards: state.creditCards,
    bookingFlow: state.bookingFlow,
    fieldsHolder: state.fieldsHolder
});

Index.propTypes = {
    booking: PropTypes.object,
    bookingFlow: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    creditCards: PropTypes.array,
    setBookingFlowStep: PropTypes.func.isRequired,
    setCart: PropTypes.func.isRequired,
    setAddresses: PropTypes.func.isRequired
};
export default withLocalize(connect(mapStateToProps, {
    setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep, setBookingFlowComingFrom
})(Index));
