/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import produce from 'immer';
import { SET_PRODUCTS } from '../Actions';

export default function (state = { products: {}, productOptions: {}, options: {} }, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_PRODUCTS:
        const { products, productOptions, options } = action.payload;
        draft.products = products;
        draft.productOptions = productOptions;
        draft.options = options;
        break;
      default:
        return draft;
    }
  });
}
