/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import {
  get, isEmpty, join, map, size, sum,
} from 'lodash';
import { CalendarToday } from '@material-ui/icons';
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import moment from 'moment';
import $ from 'jquery';
import ErrorModal from '../../Shared/ErrorModal';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import TextWithIcon from '../../Shared/TextWithIcon';
import { seAddToCalendar } from '../../Shared/WebAnalytics';

class AddToCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionOpen: false,
      showErrorModal: false,
      error: null,
    };
    this.actionModal = this.actionModal.bind(this);
    this.errorModal = this.errorModal.bind(this);
  }

  componentDidMount() {
    atcb_init();
  }

  componentDidUpdate(_nextProps, nextState) {
    const { isActionOpen } = this.state;
    const newOpen = nextState.isActionOpen;
    if (isActionOpen && !newOpen && isEmpty($('#atcb-bgoverlay'))) {
      const cartProducts = get(this.props, 'appointment.cartProducts', []);
      let sumSessionLn = get(cartProducts, '0.base_session_length', 0);
      const back2Back = get(this.props, 'appointment.backToBack.enabled', false);
      const dayDate = get(this.props, 'appointment.date.utc', '');
      const aptStart = moment(get(this.props, 'appointment.time.display', ''), 'hh:mm A');
      if (size(cartProducts) > 1 && back2Back) {
        sumSessionLn = sum(map(cartProducts, (cp) => (cp.base_session_length || 0)));
      } else if (size(cartProducts) > 1) {
        sumSessionLn = get(cartProducts, '1.base_session_length', 0);
      }
      if (!sumSessionLn) {
        sumSessionLn = 60;
      }
      atcb_action({
        name: join(map(cartProducts, (cp) => (cp.title || '')), ' & '),
        startDate: dayDate,
        startTime: aptStart.format('HH:mm'),
        endDate: dayDate,
        endTime: aptStart.clone().add(Number(sumSessionLn) / 60, 'hours').format('HH:mm'),
        options: ['Apple', 'Google', 'Microsoft365', 'Outlook.com', 'Yahoo', 'iCal'],
        timeZone: get(this.props, 'appointment.time.timezone', ''),
        iCalFileName: 'Soothe-Booking',
      });
      $('#atcb-bgoverlay').appendTo('#destination');
      document.getElementById('atcb-bgoverlay').addEventListener('click', () => {
        this.setState({ isActionOpen: false });
      });
      // TODO we can call the create function only once if
      //  we kept the modal displayed and controlled it's visibility
    }
  }

  actionModal() {
    return (
      <LatestModalDesign
        isOpen={this.state.isActionOpen}
        title="Add to calendar"
        close={() => this.setState({ isActionOpen: false })}
        hideFooter
      >
        <div className="p-16-24" id="destination" />
      </LatestModalDesign>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <>
        <div
          className="clickable"
          id="addToCalendarButton"
          onClick={() => {
            seAddToCalendar(this.props.isComingFromInbox ? 'inbox_message' : 'appointment_details');
            this.setState({ isActionOpen: true });
          }}
        >
          <TextWithIcon
            muiIcon={<CalendarToday />}
            title="Add to calendar"
            displayBorder
            displayArrow
          />
        </div>
        {this.actionModal()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, {})(AddToCalendar);
