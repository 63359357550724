/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { PURCHASE_GIFT_CARD_ASSORTMENT } from '../Actions';

export default function (state = null, action) {
  switch (action.type) {
    case PURCHASE_GIFT_CARD_ASSORTMENT:
      return action.payload;
    default:
      return state;
  }
}
