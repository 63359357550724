/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  get, sortBy, map, find,
} from 'lodash';
import './DashedPizzaTracker.css';
import { connect } from 'react-redux';
import { hasUuid } from '../../../constants';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.progressTracker = this.progressTracker.bind(this);
    this.coloredDash = this.coloredDash.bind(this);
  }

  coloredDash(progressStep, completed, selected) {
    const guest = Boolean(hasUuid() && get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
    let dashColor = 'bg-gray'; let textColor = 'color-light-gray';
    const icon = get(this.props, 'currentStep.icon', '');
    const cClass = get(this.props, 'currentStep.cClass', '');
    const textClass = get(this.props, 'currentStep.textClass', '');
    if (completed) {
      dashColor = 'bg-orange';
      textColor = guest ? 'color-white' : 'color-gray';
    } else if (selected) {
      dashColor = 'bg-light-orange';
      textColor = guest ? 'color-white' : 'color-gray';
    }
    return (
      <>
        {icon ? <div style={{ height: '28px' }}>{selected ? icon : ''}</div> : null}
        <div className={`dash ${cClass && selected ? cClass : dashColor}`} />
        <div className={`dashLabel ${textClass && selected ? textClass : textColor}`}>{get(progressStep, 'title', '')}</div>
      </>
    );
  }

  progressTracker() {
    const { progressSteps, currentStep } = this.props;
    sortBy(progressSteps, ['order']);
    const currentProgress = find(progressSteps, (el) => (el.id === get(currentStep, 'progressStep', '')));
    if (currentProgress) {
      return (map(progressSteps, (prg, ind) => {
        const selected = Boolean(prg.id === currentStep.progressStep); const
          completed = Boolean(prg.order < currentProgress.order);
        return <div className="progress-step" key={`dash-${ind}`}>{this.coloredDash(prg, completed, selected)}</div>;
      }));
    }
  }

  render() {
    return (
      <div className="dashed-tracker-wrapper pt-32-imp">
        {this.progressTracker()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {})(Index);
