/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unused-state */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  ChevronRight, Close, FlagOutlined, HelpOutline,
} from '@material-ui/icons';
import { ListItemIcon } from '@material-ui/core';
import './Inbox.scss';
import {
  find, findIndex, get, isEmpty, map, size,
} from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';
import RadioOptions from '../Shared/RadioOptions';
import LatestModalDesign from '../Shared/LatestModalDesign';
import LocalPoliceOutlined from '../../Assets/Images/local_police.svg';
import { createReport, getReportReasons } from './Shared/helpers';
import SuccesModal from './SuccessModal';
import ErrorModal from '../Shared/ErrorModal';
import { defaultErrorMessage, ROUTES } from '../../constants';
import { HOST_ROOT } from '../../apiConfig';
import { seInboxReportPro, seViewHelpCenter } from '../Shared/WebAnalytics';

class ReportMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      whatsHappeningOpen: false,
      reportMessageOpen: false,
      contactAuthorityOpen: false,
      successModalOpen: false,
      report_reasons: [],
      message: '',
      selectedPro: null,
      originalReason: null,
      reasonIndex: null,
      showSuccess: false,
      showError: false,
      error: '',
    };
    this.sendMessageAndRefresh = this.sendMessageAndRefresh.bind(this);
    this.whatsHappening = this.whatsHappening.bind(this);
    this.contactAuthority = this.contactAuthority.bind(this);
    this.reportMessage = this.reportMessage.bind(this);
    this.openRelevantModal = this.openRelevantModal.bind(this);
    this.prosSelector = this.prosSelector.bind(this);
  }

  componentDidMount() {
    getReportReasons((resp) => {
      const chat_report_reasons = get(resp, 'data.chat_report_reasons', []);
      this.setState({
        report_reasons: map(chat_report_reasons, (el) => ({
          label: el.reason,
          value: el.id,
          original: el,
        })),
      });
    }, (err) => console.log({ err }));
    if (size(get(this.props, 'reported_ids', [])) === 1) {
      this.setState({ selectedPro: get(this.props, 'reported_ids.0.user_id', null) });
    }
  }

  prosSelector() {
    if (size(get(this.props, 'reported_ids', [])) === 1) {
      return null;
    }
    const reformattedList = map(get(this.props, 'reported_ids', []), (el) => ({ value: el.user_id, label: el.name }));
    return (
      <>
        <div className="font-weight-bold contentPrimary mb-8 size-16-20">Who are you reporting?*</div>
        <Select
          className="mb-16 contentPrimary"
          classNamePrefix="select"
          options={reformattedList}
          isSearchable={false}
          onChange={(val) => this.setState({ selectedPro: val.value })}
          placeholder="Select"
        />
      </>
    );
  }

  sendMessageAndRefresh() {
    const {
      reasonIndex, originalReason, selectedPro, message,
    } = this.state;
    if (selectedPro && message && originalReason) {
      createReport(get(this.props, 'currentChat.chat_id', ''), {
        reported_id: selectedPro,
        chat_report_reason_id: get(originalReason, 'id', ''),
        chat_report_reason_index: reasonIndex,
        reporter_notes: message,
      }, (_resp) => {
        seInboxReportPro(get(originalReason, 'reason', ''));
        this.setState({ showSuccess: true });
      }, (err) => {
        this.setState({ showError: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    }
  }

  openRelevantModal(val) {
    const { report_reasons } = this.state;
    const res = find(report_reasons, (el) => (el.value === Number(val)));
    const reasonIndex = findIndex(report_reasons, (el) => (el.value === Number(val)));
    const { id, reason_slug } = get(res, 'original', {});
    if (reason_slug === 'safety_risk') {
      this.setState({
        whatsHappeningOpen: false, contactAuthorityOpen: true, originalReason: get(res, 'original', {}), reasonIndex,
      });
    } else if (reason_slug === 'something_else') {
      this.setState({ whatsHappeningOpen: false, originalReason: get(res, 'original', {}), reasonIndex }, () => {
        const gladlyPopup = window ? window.Gladly : null;
        if (gladlyPopup) {
          gladlyPopup.show();
        }
      });
    } else {
      this.setState({
        whatsHappeningOpen: false, reportMessageOpen: true, originalReason: get(res, 'original', {}), reasonIndex,
      });
    }
  }

  reportMessage() {
    const { message, selectedPro } = this.state;
    return (
      <LatestModalDesign
        isOpen={this.state.reportMessageOpen}
        title={<>What&#8217;s happening?</>}
        subtitle="This will only be shared with Soothe."
        back={() => this.setState({ reportMessageOpen: false, whatsHappeningOpen: true })}
        hideFooter
      >
        <div>
          {this.prosSelector()}
          <div className="size-16-20 contentPrimary font-weight-bold mb-8">Details*</div>
          <textarea
            className="form-control generic-input-style-blue mb-40"
            rows={5}
            onChange={(event) => this.setState({ message: event.target.value || '' })}
          />
          {isEmpty(message) || !selectedPro ? null : (
            <button
              type="button"
              className="btn cta-primary"
              onClick={this.sendMessageAndRefresh}
            >
              Report
            </button>
          )}
        </div>
      </LatestModalDesign>
    );
  }

  contactAuthority() {
    return (
      <LatestModalDesign
        isOpen={this.state.contactAuthorityOpen}
        back={() => this.setState({ contactAuthorityOpen: false, whatsHappeningOpen: true })}
        hideFooter
      >
        {' '}
        <div className="centered ">
          <img src={LocalPoliceOutlined} alt="authority" className="mb-24" />
          <div className="size-20-28 contentPrimary font-weight-bold mb-12">Contact authorities</div>
          <div className="size-12-20 contentTertiary mb-12">
            If you believe your immediate safety is at risk, please contact your local authorities.
            <br />
            <br />
            In the United States, dial 9-1-1
            <br />
            In Australia, dial 0-0-0
            <br />
            In the U.K., dial 9-9-9
          </div>
          <button type="button" className="btn btn-accent-light" onClick={() => this.setState({ contactAuthorityOpen: false, reportMessageOpen: true })}>Next</button>
        </div>
      </LatestModalDesign>
    );
  }

  whatsHappening() {
    return (
      <LatestModalDesign
        isOpen={this.state.whatsHappeningOpen}
        customIcon={<Close />}
        title={<>What&#8217;s happening?</>}
        subtitle="This will only be shared with Soothe."
        close={() => this.setState({ whatsHappeningOpen: false })}
        hideFooter
      >
        {' '}
        <div className="contentPrimary size-16-24">
          <RadioOptions
            name="chat-report"
            optionsArray={this.state.report_reasons}
            onChange={this.openRelevantModal}
          />
        </div>
      </LatestModalDesign>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showError}
        close={() => { this.setState({ showError: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const { anchorEl } = this.state;
    const { hideReportCTA } = this.props;
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => this.setState({ anchorEl: event.currentTarget })}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className="report-dropdown"
          id="long-menu"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
          PaperProps={{
            style: {
              width: '375px',
            },
          }}
        >
          <MenuItem onClick={() => {
            seViewHelpCenter();
            window.location.href = `${HOST_ROOT}${ROUTES.helpCenter}`;
          }}
          >
            <ListItemIcon className="pb-12">
              <HelpOutline className="width-20" />
            </ListItemIcon>
            <div className={`d-flex-only align-items-center pb-12 justify-content-spaced w-100-perc ${hideReportCTA ? '' : ' br-b-opaque'}`}>
              <div className="size-16-20">Visit the Help Center</div>
              <div className="">
                <ChevronRight className="dim-10-12 contentTertiary" />
              </div>
            </div>
          </MenuItem>
          {hideReportCTA ? null : (
            <MenuItem onClick={() => this.setState({ whatsHappeningOpen: true, anchorEl: null })}>
              <ListItemIcon>
                <FlagOutlined className="width-20" />
              </ListItemIcon>
              <div className="d-flex-only align-items-center justify-content-spaced w-100-perc">
                <div className="size-16-20">Report an issue</div>
                <div className="">
                  <ChevronRight className="dim-10-12 contentTertiary" />
                </div>
              </div>
            </MenuItem>
          )}
        </Menu>
        {this.whatsHappening()}
        {this.contactAuthority()}
        {this.reportMessage()}
        <SuccesModal
          close={() => this.setState({ showSuccess: false })}
          isOpen={this.state.showSuccess}
        />
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  chats: state.chats,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, {})(ReportMenu);
