/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import { find, get, isEmpty } from 'lodash';
import moment from 'moment';
import { NOT_BEFORE_H } from '../../../constants';

export const capHour = 22;

export const APPOINTMENT_STATUS = [
  { id: 'searching', order: 1, title: 'Requested' },
  { id: 'confirmed', order: 2, title: 'Confirmed' },
  { id: 'otw', order: 3, title: 'On the way' },
  { id: 'arrived', order: 4, title: 'Complete' },
];
export const NEW_STATUSES = [
  { id: 'requested', order: 1, title: 'Requested' },
  // { id: 'partially_confirmed', order: 2, title: 'Requested' },
  { id: 'confirmed', order: 3, title: 'Confirmed' },
  { id: 'on_the_way', order: 4, title: 'On the way' },
  { id: 'complete', order: 5, title: 'Complete' },
];
export const HOW_TO_PREPARE_IMAGES = {
  shower: require('../Assets/Images/Shower.png'),
  mask: require('../Assets/Images/Mask.png'),
  wash: require('../Assets/Images/HandWash.png'),
  sheets: require('../Assets/Images/Sheets.png'),
  shake: require('../Assets/Images/HandShake.png'),
  baby: require('../Assets/Images/Baby.png'),
  pet: require('../Assets/Images/Pet.png'),
};
export const momentCap = (dateTime) => {
  dateTime = moment(dateTime);

  let cap = dateTime;
  if (moment(new Date()).isSame(dateTime, 'day')) {
    cap = moment(new Date()).add(NOT_BEFORE_H, 'hours');
  }

  if (cap.hours() < 8) {
    cap = cap.hours(8);
  } else if (cap.hours() > capHour || (cap.hours() === capHour && cap.minutes() !== 0)) {
    cap = cap.add(1, 'day').hours(8).minutes(0).seconds(0);
  }

  if (cap.minutes() % 15 !== 0) {
    cap.minutes(Math.ceil(cap.minutes() / 15) * 15);
  }

  return cap;
};
export const getSingleChatState = (chats) => {
  let stt = 'empty';
  const hasNewMessages = find(chats, (el) => !get(el, 'seen', false));
  if (!isEmpty(chats)) {
    if (!isEmpty(hasNewMessages)) {
      stt = 'unread';
    } else {
      stt = 'all_read';
    }
  }
  return stt;
};
export const hoursCountTillShiftStart = (dateTime) => {
  const diff = moment(dateTime).diff(moment(new Date()), 'hours');
  let relevantCopy;
  switch (true) {
    case diff <= 2:
      relevantCopy = 'less than 2 hours from shift start.';
      break;
    case diff <=4:
      relevantCopy = 'less than 4 hours from shift start.';
      break;
    default:
      relevantCopy = '.';
  }
  return relevantCopy;
}