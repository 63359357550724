/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import {
  filter, get, join, map,
} from 'lodash';
import { Receipt } from '@material-ui/icons';
import ColumnsList from '../../Shared/ColumnsList';
import TextWithIcon from '../../Shared/TextWithIcon';
import { getCartReceipt } from '../../Shared/Helpers';
import ErrorModal from '../../Shared/ErrorModal';
import { defaultErrorMessage } from '../../../constants';
import LatestModalDesign from '../../Shared/LatestModalDesign';

class PaymentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorModal: false,
      error: null,
      success: null,
      showSuccessModal: false,
    };
    this.getTipAmount = this.getTipAmount.bind(this);
    this.getItemsList = this.getItemsList.bind(this);
    this.getReceipt = this.getReceipt.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.successModal = this.successModal.bind(this);
  }

  getTipAmount() {
    const tipObj = filter(get(this.props, 'appointment.billing.bottom_billing_line_items', []), (el) => (el.title === 'Tip' && el.amount !== 'Not Included'));
    const tipsList = map(tipObj, (tip) => (tip.amount));
    return join(tipsList, ' & ');
  }

  getItemsList() {
    const tipString = this.getTipAmount();
    const arr = []; const
      points = get(this.props, 'appointment.billing.points_used', 0);
    if (points) {
      arr.push({
        text: `${points} points (SoothePass™)`,
      });
    }
    arr.push({
      text: `${get(this.props, 'appointment.billing.price', '')} (${get(this.props, 'appointment.billing.credit_card.display_string', '')})`,
    });
    arr.push({
      text: tipString ? `${tipString} Tip (${get(this.props, 'appointment.billing.credit_card.display_string', '')})` : '',
    });
    return arr;
  }

  successModal() {
    return (
      <LatestModalDesign
        isOpen={this.state.showSuccessModal}
        title={this.state.success}
        hideCancel
        hideBackArrow
        applyBtnCopy="Close"
        apply={() => {
          this.setState({ showSuccessModal: false, success: null });
        }}
      />
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  getReceipt() {
    getCartReceipt(get(this.props, 'appointment.id', ''), (resp) => {
      const successMessage = get(resp, 'data.message', 'Email sent');
      this.setState({ success: successMessage, showSuccessModal: true });
    }, (err) => {
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  render() {
    return (
      <>
        <div className="p-24-16-0">
          <div className="size-24-32 color-black medium-font mb-8">Payment info</div>
          <div className="br-b-opaque">
            <ColumnsList
              headerText="Payment methods"
              itemsList={this.getItemsList()}
            />
            <ColumnsList
              headerText="Total cost"
              itemsList={[{
                text: get(this.props, 'appointment.billing.price_with_tip', ''),
              }]}
            />
          </div>
          {/* TODO maybe better to use this
            <TextWithIcon
                    title={"Payment methods"}
                    details={"dkpej3 39837t"}
                    displayArrow={true}
                    displayBorder={true}
                /> */}
        </div>
        <div
          className="clickable br-btm-gray-4"
          id="getReceipt"
          onClick={this.getReceipt}
        >
          <TextWithIcon
            muiIcon={<Receipt />}
            title="Get receipt"
            displayArrow
          />
        </div>
        {this.successModal()}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, {})(PaymentInfo);
