/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Translate } from 'react-localize-redux';
import { ROUTES } from '../../../constants';

export default function NotReadySection() {
  return (
    <Translate>
      { ({ translate }) => (
        <div className="section-container section-gray">
          <div className="regular-booking">
            <div><span>{ translate('soothePlusIndex.notReady') }</span></div>
            <div className="one-time-container">
              Although credits on Soothe Plus never expire and you can cancel anytime,
              our one-time billing options are always available for you to give Soothe a try.
            </div>
            <div>
              <a className="button" href={ROUTES.booking} target="_blank" rel="noopener noreferrer">{ translate('navigation.bookNow') }</a>
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
}
