/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import BookingFlowRedesign from '../BookingFlowRedesign';
import BookingFlowForMarketplace from '../BookingFlowForMarketplace';
import LoadingOverlay from './LoadingOverlay';
import { hasUuid } from '../../constants';
import { getAnonymousUser, getClientById } from './Helpers';

class BookingRedirection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jul_2024_booking_enhancements: "show_old_flow"
    };
  }

  componentDidMount() {
    // NOTE : calling anonymous user endpoint (needed for attributions utm)
    // STH-3268
    if (!hasUuid()) {
      if (get(window, 'performance.navigation.type', 0) === 0) {
        getClientById(get(this.props, 'client.user_id', ''), (resp_1) => {
          this.setState({ loading: false, jul_2024_booking_enhancements: get(resp_1, "data.client.ab_tests.jul_2024_booking_enhancements", "show_old_flow") });
        }, () => {
          getAnonymousUser((resp_2) => {
            this.setState({ loading: false, jul_2024_booking_enhancements: get(resp_2, "data.anonymous_user.ab_tests.jul_2024_booking_enhancements", "show_old_flow") });
          }, () => {
            this.setState({ loading: false });
          });
        });
      } else {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    // if b2b consumer or not assigned to a/b test, show the old booking flow
    // if assigned to a/b test, show the revamped booking flow
    if (hasUuid()) {
      return <BookingFlowRedesign {...this.props} />;
    }
    if (this.state.loading) {
      return <LoadingOverlay showLoader />;
    }
    return <BookingFlowForMarketplace jul_2024_booking_enhancements={this.state.jul_2024_booking_enhancements} {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  abTest: state.abTest,
});

export default connect(mapStateToProps, {})(BookingRedirection);
