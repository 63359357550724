/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import HelmetTag from '../Shared/HelmetTag';
import { setAppointment } from '../../Actions/AppointmentManagementAction';
import LoadingOverlay from '../Shared/LoadingOverlay';
import ErrorModal from '../Shared/ErrorModal';
import SignupModal from '../Shared/SignupModal';
import { getCartAndUserId, getCartDetails } from './Shared/helpers';
import { defaultErrorMessage, ROUTES } from '../../constants';
import AppointmentDetails from './AppointmentDetails';
import './Assets/Styles/index.css';
import { decode } from './Shared/encode';
import NavbarRedesignForMarketplace from '../Shared/NavbarRedesignForMarketplace';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      showErrorModal: false,
      showLoader: false,
      client_email: '',
      client_id: '',
      cart_id: '',
      width: window.innerWidth,
    };
    this.renderView = this.renderView.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.fetchAppointmentDetails = this.fetchAppointmentDetails.bind(this);
    this.nextPhaseCheck = this.nextPhaseCheck.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const key = parameters.get('lookup_code');
    const upcomingAppointmentId = get(this.props, 'upcomingAppointmentId', '') || get(this.props, 'location.state.upcomingAppointmentId', '');
    if (key) {
      this.setState({ showLoader: true });
      getCartAndUserId(key, (response) => {
        const { client_id, cart_id, client_email } = get(response, 'data.cart', {});
        this.setState({ client_email, client_id, cart_id });

        this.setState({ showLoader: false });
        if (!(client_id && cart_id)) {
          this.setState({ showErrorModal: true, error: "We're sorry. The url you used is broken" });
        } else {
          this.nextPhaseCheck(client_id, cart_id);
        }
      }, (err) => {
        this.setState({ showLoader: false, showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    } else if (!key && upcomingAppointmentId) {
      const client_id = get(this.props, 'client.user_id', '');
      this.setState({ client_email: get(this.props, 'client.email', ''), client_id, cart_id: upcomingAppointmentId });
      this.nextPhaseCheck(client_id, upcomingAppointmentId);
    } else {
      // TODO cover if nor appointment id nor key are present
      this.setState({ showErrorModal: true, error: 'No appointments to show. Please try again later' });
    }
  }

  nextPhaseCheck(clientId, cartId) {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const currentUserId = get(this.props, 'client.user_id', '');
    const isOwnerOfCart = clientId === currentUserId;
    // check if same user owning the appointment
    // TODO add test if user id is present first

    if (loggedIn) {
      if (!isOwnerOfCart) {
        this.setState({ showErrorModal: true, error: 'You are not allowed to access this appointment' });
      } else {
        this.fetchAppointmentDetails(cartId);
      }
    }
  }

  fetchAppointmentDetails(cartId) {
    this.setState({ showLoader: true });
    getCartDetails(cartId, (response) => {
      this.setState({ showLoader: false });
      this.props.setAppointment(decode(response.data.cart));
    }, (err) => { this.setState({ showLoader: false, showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) }); });
  }

  renderView() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const currentUserId = get(this.props, 'client.user_id', '');
    const isOwnerOfCart = this.state.client_id === currentUserId;
    if (loggedIn) {
      if (isOwnerOfCart) {
        return (
          <>
            <NavbarRedesignForMarketplace
              hideGoBack
              darkenHeader
              additionalActionLoggedIn
              notBooking
              showMenu
              jul_2024_booking_enhancements={get(this.props, "client.ab_tests.jul_2024_booking_enhancements", "")}
            />
            <div className={this.state.width < 800 ? '' : 'mt-nav'}>
              <AppointmentDetails width={this.state.width} location={get(this.props, 'location', {})} openRelevantModal={get(this.props, 'location.state.openRelevantModal', false)} />
            </div>
          </>
        );
      }
      return (
        <>
          <NavbarRedesignForMarketplace
            hideGoBack
            darkenHeader
            additionalActionLoggedIn
            notBooking
            showMenu
            jul_2024_booking_enhancements={get(this.props, "client.ab_tests.jul_2024_booking_enhancements", "")}
          />
          <ErrorModal
            isOpen
            close={() => {
              window.location = ROUTES.userSignin;
            }}
          >
            <p>You are not allowed to access this appointment</p>
          </ErrorModal>
        </>
      );
    }
    return (
      <SignupModal
        isSignin
        client_email={this.state.client_email}
        next={() => {
          window.location.reload();
        }}
      />
    );
  }

  // TODO recheck error message
  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => {
          this.setState({ showErrorModal: false, error: null }, () => {
            window.location = ROUTES.userSignin;
          });
        }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const { showLoader } = this.state;
    return (
      <>
        <HelmetTag />
        {this.renderView()}
        {this.errorModal()}
        <LoadingOverlay showLoader={showLoader} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  abTest: state.abTest,
});
export default connect(mapStateToProps, { setAppointment })(Index);
