/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { CardElement, injectStripe } from 'react-stripe-elements';
import axios from 'axios';
import { defaultErrorMessage } from '../../../constants';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import ErrorModal from '../../Shared/ErrorModal';
import backArrow from '../../../Assets/Images/back-arrow.png';
import '../Assets/Styles/CreditCardModal.css';

class CreditCard extends React.Component {
  constructor(props) {
    super(props);

    this.prev = this.prev.bind(this);
    this.save = this.save.bind(this);
    this.errorModal = this.errorModal.bind(this);

    this.state = {
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      name: '',
      showModal: false,
      error: null,
    };
  }

  prev(event) {
    event.preventDefault();
    this.props.prev();
  }

  save(event) {
    event.preventDefault();

    this.props.stripe.createToken({
      name: this.state.name,
    }).then((result) => {
      if (result && result.token) {
        const { card } = result.token;

        axios.post(`${API_ROOT}/credit_cards/create_with_user_id/`, {
          credit_card: {
            last_4: card.last4,
            brand: card.brand,
            id: card.id,
            stripe_id: result.token.id,
            name: card.name,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            user_id: this.props.client.user_id,
          },
        }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
          const { id, success, result } = resp.data;

          if (success || result) {
            this.props.reload(id);
            this.props.close();
          } else {
            const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
            this.setState({ showModal: true, error: message });
          }
        }).catch((error) => {
          const message = _.result(_.first(error.response.data.errors || {}), 'message') || _.result(error.response.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showModal: true, error: message });
        });
      } else {
        const { error } = result;
        const message = error.message || defaultErrorMessage;
        this.setState({ showModal: true, error: message });
      }
    });
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showModal}
        close={() => { this.setState({ showModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <Translate>
        { ({ translate }) => (
          <div className="creditCardModal overlay">
            { this.errorModal() }
            <div className="popup">
              <h2>{ translate('bookingFlow.addPaymentMethod') }</h2>
              <div className="content">
                <form onSubmit={this.save}>
                  <div className="form-pod-large input-name-margin-bottom">
                    <label>{ translate('formData.nameOnCard') }</label>
                    <input
                      type="text"
                      value={this.state.name}
                      data-stripe="name"
                      size="20"
                      className="form-control"
                      onChange={(event) => { this.setState({ name: event.target.value }); }}
                      required
                    />
                  </div>
                  <div className="form-pod-large">
                    <label className="stripe-elements-label">{ translate('formData.cardNumber') }</label>
                    <CardElement
                      classes={{
                        base: 'stripe-cardnumber-element',
                      }}
                      style={{
                        base: {
                          fontSize: '16px',
                          fontFamily: 'Poppins',
                          fontWeight: '300',
                          lineHeight: '30px',
                          color: '#2C3239',
                        },
                      }}
                      hidePostalCode
                    />
                  </div>
                  <div className="add-card-container">
                    <input type="submit" className="btn active form-control add-card" value={_.capitalize(translate('bookingFlow.addCard'))} />
                  </div>
                  <button type="button" onClick={(e) => { this.prev(e); }} className="back-btn form-control">
                    <img src={backArrow} className="back-arrow-img" alt="back arrow" />
                    {' '}
                    { _.capitalize(translate('global.back')) }
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
});

CreditCard.propTypes = {
  client: PropTypes.object.isRequired,
  prev: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default withLocalize(injectStripe(connect(mapStateToProps)(CreditCard)));
