/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
import React from 'react';
import { Close, DateRange, Message } from '@material-ui/icons';
import { connect } from 'react-redux';
import { get, size } from 'lodash';
import moment from 'moment-timezone';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import TextWithIcon from '../../Shared/TextWithIcon';
import Pending from '../../Shared/LottiePending';
import { seSelfServeViewed, seTryAnotherTime } from '../../Shared/WebAnalytics';

class BlindAuctionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllProsDeclined: get(this.props, 'allProsDeclined', false),
      showUnfilled24hrs: get(this.props, 'unfilled24Hrs', false),
    };
    this.allProsDeclined = this.allProsDeclined.bind(this);
    this.unfilled24hrs = this.unfilled24hrs.bind(this);
    this.getSelfServeType = this.getSelfServeType.bind(this);
  }

  componentDidMount() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
    const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));
    const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
    const market = get(this.props, 'appointment.address.market', '');
    const self_serve_type = this.getSelfServeType();
    // multi_pro_none_available, multi_pro_no_response, general_offer, single_pro_special_request
    const click_source = get(this.props, 'upcomingAppointmentId', '') ? 'appointment_details' : 'email_link';
    const is_special_request = get(this.props, 'appointment.rebook', false);
    const is_today = bkngDate.isSame(nowDate, 'day');
    const lead_time_selected = bkngDate.diff(checkoutDate, 'hours');
    const number_of_requested_pros = size(requestedProviders);
    const time_til_appointment = bkngDate.diff(nowDate, 'hours');
    const is_blind_auction = get(this.props, 'appointment.blind_auction_switch', false);
    seSelfServeViewed({
      market,
      self_serve_type,
      click_source,
      is_special_request,
      is_today,
      lead_time_selected,
      number_of_requested_pros,
      time_til_appointment,
      is_blind_auction,
    });
  }

  getSelfServeType() {
    const { showAllProsDeclined, showUnfilled24hrs } = this.state;
    if (showAllProsDeclined) {
      return 'multi_pro_none_available';
    } if (showUnfilled24hrs) {
      return 'multi_pro_no_response';
    } if (get(this.props, 'appointment.rebook', false)) {
      return 'single_pro_special_request';
    }
    return 'general_offer';
  }

  allProsDeclined() {
    return (
      <LatestModalDesign
        title="We have sent your request to more Providers in our network"
        customIcon={<Close />}
        back={() => this.setState({ showAllProsDeclined: false })}
        subtitle={`${get(this.props, 'appointment.info_fields.title', '')} on ${get(this.props, 'appointment.date.display', '')}`}
        isOpen={this.state.showAllProsDeclined}
        applyBtnCopy="View request"
        fullWidthFooter
        apply={() => { this.setState({ showAllProsDeclined: false }); }}
        hideCancel
      >
        <div><Pending /></div>
        <div className="size-16-24">It looks like Providers you requested are busy at the moment. We are sending your appointment request to more Providers in our network.</div>
        <br />
        <div className="size-16-24 medium-font">No action is required.</div>
      </LatestModalDesign>
    );
  }

  unfilled24hrs() {
    return (
      <LatestModalDesign
        title="Requested Providers Pending"
        customIcon={<Close />}
        back={() => this.setState({ showUnfilled24hrs: false })}
        subtitle={`${get(this.props, 'appointment.info_fields.title', '')} on ${get(this.props, 'appointment.date.display', '')}`}
        isOpen={this.state.showUnfilled24hrs}
        hideFooter
      >
        <div><Pending /></div>
        <div className="size-16-24">
          We are expanding our search to send to more providers in our network.
          <br />
          <span className="medium-font">No action is required.</span>
          {' '}
          You can still make changes below.
        </div>
        <div>
          <div
            className="clickable"
            onClick={() => {
              const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
              const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
              const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));
              const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
              const market = get(this.props, 'appointment.address.market', '');
              const self_serve_type = this.getSelfServeType();
              // multi_pro_none_available, multi_pro_no_response,
              //   general_offer, single_pro_special_request
              const click_source = get(this.props, 'upcomingAppointmentId', '') ? 'appointment_details' : 'email_link';
              const is_today = bkngDate.isSame(nowDate, 'day');
              const lead_time_selected = bkngDate.diff(checkoutDate, 'hours');
              const number_of_requested_pros = size(requestedProviders);
              const time_til_appointment = bkngDate.diff(nowDate, 'hours');
              seTryAnotherTime({
                market,
                click_source,
                self_serve_type,
                is_today,
                lead_time_selected,
                number_of_requested_pros,
                time_til_appointment,
              });
              this.props.openReschedule();
            }}
          >
            <TextWithIcon
              cstmImg={<DateRange className="w-h-24" />}
              title={<div className="size-16-20 medium-font">Try another time</div>}
              displayArrow
              displayBorder
            />
          </div>
          <div className="clickable" onClick={this.props.openProsPopup}>
            <TextWithIcon
              cstmImg={<Message className="w-h-24" />}
              title={<div className="size-16-20 medium-font">Message requested providers</div>}
              displayArrow
              displayBorder
            />
          </div>
        </div>
      </LatestModalDesign>
    );
  }

  render() {
    return (
      <>
        {this.unfilled24hrs()}
        {this.allProsDeclined()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  rebookOptions: state.rebookOptions,
  chats: state.chats,
  currentChat: state.currentChat,
  upcomingAppointmentId: state.upcomingAppointmentId,
  abTest: state.abTest,
  fieldsHolder: state.fieldsHolder,
});
export default connect(mapStateToProps, {})(BlindAuctionModal);
