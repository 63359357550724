/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Translate } from 'react-localize-redux';
import arrowDown from '../Assets/Images/faq-arrow-down.png';
import arrowUp from '../Assets/Images/faq-arrow-up.png';

export default function FaqSection() {
  return (
    <Translate>
      { ({ translate }) => (
        <div className="section-container faq-section-container">
          <div className="section-faq-container section-faq-container-v4 container" id="faq">
            <h2>{ translate('soothePlusIndex.sootheFaqs.title') }</h2>
            <div className="panel-group" id="accordion">

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq1.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq1.fact1') }
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default panel-divider">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq2.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwo" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq2.fact1') }
                      <br />
                      <br />
                      { translate('soothePlusIndex.sootheFaqs.faq2.fact2') }
                      <br />
                      <br />
                      { translate('soothePlusIndex.sootheFaqs.faq2.fact3') }
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default panel-divider">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq3.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseThree" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq3.fact1') }
                      <br />
                      <br />
                      { translate('soothePlusIndex.sootheFaqs.faq3.fact2') }
                      <br />
                      <br />
                      { translate('soothePlusIndex.sootheFaqs.faq3.fact3') }
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default panel-divider">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq4.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseFour" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq4.fact1', '1-833-2-SOOTHE') }
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default panel-divider">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseFive" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq5.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseFive" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq5.fact1') }
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default panel-divider">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseSix" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq6.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseSix" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq6.fact1') }
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default panel-divider">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" className="faq-a accordion-toggle">
                      <i className="faq-triangle-down"><img className="faq-arrow-down" alt="arrow" src={arrowUp} /></i>
                      <i className="faq-triangle-right"><img className="faq-arrow-down" alt="arrow" src={arrowDown} /></i>
                      <div className="faq-title-copy-container">{ translate('soothePlusIndex.sootheFaqs.faq7.title') }</div>
                    </a>
                  </h4>
                </div>
                <div id="collapseSeven" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      { translate('soothePlusIndex.sootheFaqs.faq7.fact1') }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
}
