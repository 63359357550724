import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../../../Translations/bookingFlow.json';
import { defaultErrorMessage, blankHref } from './../../../../constants';
import ErrorModal from './../../../Shared/ErrorModal';
import creditGenericDark from '../Assets/Images/cc-generic-dark.png';
import creditGenericLight from '../Assets/Images/cc-generic-light.png';
import deleteCardIcon from '../Assets/Images/delete-payment-icon.png';
import '../Assets/Styles/CreditCardsModal.css';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../../apiConfig';
import Modal from './../../../Shared/Modal';

class CreditCardsModal extends React.Component {
  constructor(props) {
    super(props);

    this.selectedCreditCard = this.selectedCreditCard.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.renderMainModal = this.renderMainModal.bind(this);

    this.state = {
      showErrorModal: false,
      error: null
    }

    this.props.addTranslation(translations);
  }

  selectedCreditCard(event) {
    const creditCardId = parseInt(event.currentTarget.getAttribute('cc-id'), 10);
    const creditCard = _.find(this.props.creditCards, (cc) => { return cc.id === creditCardId; });

    this.props.onChange(creditCardId, creditCard);
  }

  renderMainModal(content) {
    return (
      <Translate>
        {({ translate }) => (
        <Modal title={translate('bookingFlow.paymentMethods')} isOpen={true} close={() => { this.props.close() }}>
          <div style={{"textAlign":"left"}}>
          {content}
          </div>
        </Modal>
        )}
      </Translate>
    )
  }

  deleteCreditCard(index) {
    let message;
    const creditCard = this.props.creditCards[index];

    if (creditCard) {
      axios.delete(`${API_ROOT}/credit_cards/${creditCard.id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result, success } = resp.data;

        if (result || success) {
          this.props.reload();
        } else {
          message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showErrorModal: true, error: message });
        }
      }).catch((error) => {
        message = defaultErrorMessage;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
          message = _.result(_.first(error.response.data.errors || {}), 'message');
        }

        this.setState({ showErrorModal: true, error: message });
      });
    }
  }

  errorModal() {
    return (
      <ErrorModal isOpen={this.state.showErrorModal} close={ () => { this.setState({ showErrorModal: false, error: null }); } }>
        <p>{this.state.error}</p>
      </ErrorModal>
    )
  }

  render() {
    let content = (
      <Translate>
        {({ translate }) => (
          <>
            <div className=''>
              <div className='creditCards'>
                {this.props.creditCards.map((creditCard, index) => (
                  <div className={`credit-card-wrapper box-shadow-container ${this.props.creditCardId === creditCard.id ? 'selected' : ''}`} key={`credit-card-${creditCard.id}`} cc-id={creditCard.id} onClick={this.selectedCreditCard}>
                    <div className='radio-container'>
                      <input className='radio-item' id={creditCard.id} type='radio' value={creditCard.id} checked={this.props.creditCardId === creditCard.id} />
                      <label className='label-item' htmlFor={creditCard.id}><img src={this.props.creditCardId === creditCard.id ? creditGenericDark : creditGenericLight} alt='cc-icon' /></label>
                      <div className='check' style={{ display: 'none' }} />
                    </div>

                    <div className='credit-card-content'>
                      <div className='details-container'>
                        <div className='cc-details'>{creditCard.type}</div>
                        <div className='cc-details-2'>***** ***** {creditCard.last_4}</div>
                      </div>
                    </div>

                    <div className='delete-btn-container'>
                      <a className='btn-delete' href={blankHref} onClick={(e) => { e.preventDefault(); this.deleteCreditCard(index); }}>
                        <img className='delete-icon' src={deleteCardIcon} alt='delete-icon' />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="call-to-action-container">
                <a className="btn has-spinner continue-btn " href={blankHref} onClick={(event) => { event.preventDefault(); this.props.addNewCreditCard(); }}>
                  <span className="spinner"><i className="glyphicon glyphicon-refresh"></i></span>
                  {translate('bookingFlow.addPaymentMethod')}
                </a>
              </div>
              <div className="call-to-action-container">
                <a className="btn modal-cancel-button-custom " href={blankHref} onClick={() => {
                  if (this.props.close)
                    this.props.close();
                }}>
                  Cancel
                </a>
              </div>
            </div>
          </>
        )}
      </Translate>
    );
    return (
      <Translate>
        { ({translate}) =>
          <div className='overlay creditCardsModal' id="paymentMethodPopup">
            <div className='popup'>
              {this.renderMainModal(content)}
            </div>
            { this.errorModal() }
          </div>
        }
      </Translate>
    );
  }
}

const mapStateToProps = state => ({
  creditCards: state.creditCards,
  booking: state.booking
});


CreditCardsModal.propTypes = {
  addNewCreditCard: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  creditCards: PropTypes.array.isRequired,
  booking: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired
};

export default withLocalize(connect(mapStateToProps)(CreditCardsModal));
