/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { includes, map } from 'lodash';
import './BlueButtonsList.css';
import { SELECTABLE_HOURS } from '../../../constants';

class BlueButtonsList extends React.Component {
  constructor(props) {
    super(props);
    this.buttonsListUI = this.buttonsListUI.bind(this);
  }

  buttonsListUI() {
    let firstSubElt = true;
    const {
      buttonsList, globalAction, small, selection, firstTimeSlot,
    } = this.props;
    return map(buttonsList, (obj, it) => {
      const tempVal2 = firstSubElt;
      if (firstSubElt) {
        firstSubElt = false;
      }
      if (!includes(SELECTABLE_HOURS, obj.time)) {
        return null;
      }
      return (
        <div key={`btn-opt-${obj.time}-${it}`} {...(firstTimeSlot && tempVal2 ? { id: 'first-time-slot' } : {})} className={`col-xs-6 ${small ? '' : 'col-sm-3'}`}>
          <button
            type="button"
            className={`btn ${selection && selection.datetime === obj.datetime ? 'contentInversePrimary bg-accent' : 'bg-light-blue font-color-blue-gray'} size-14-20 border-radius-1000 padding-8-0 medium-font full-w-100 mb-8`}
            onClick={() => {
              globalAction(obj);
            }}
          >
            {obj.time}
            <br />
            {obj.category ? <span className="regular-font">{obj.category}</span> : null}
          </button>
        </div>
      );
    });
  }

  render() {
    const { title, customId, customAnchor } = this.props;
    return (
      <div>
        <div id={`${customId}`} className={customAnchor || 'anchor'} />
        <div className="size-20-28 mb-8 mt-24 medium-font">{title}</div>
        <div className="br-gray p-24-16 row border-radius-8 mrl-0">
          {this.buttonsListUI()}
        </div>
      </div>
    );
  }
}

export default BlueButtonsList;
