/* eslint-disable func-names */
/* eslint-disable default-param-last */
import { SET_SUBSCRIPTION_VIEW } from '../Actions';
import { VIEWS } from '../Containers/SoothePlus/Constants';

export default function (state = VIEWS.INDEX, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_VIEW:
      return action.payload;
    default:
      return state;
  }
}
