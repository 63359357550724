/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import ProProfileDetails from '../BookingFlowForMarketplace/ProviderPickStep/ProProfileDetails';
import '../BookingFlowForMarketplace/ProviderPickStep/Assets/Styles/ProviderPickStep.css';

class ProProfilePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      therapist, children, clickSource, isPrivate,
    } = this.props;
    const { open } = this.state;
    if (!therapist) {
      return null;
    }
    return (
      <>
        <button type="button" className="btn btn-link" onClick={() => this.setState({ open: true })}>
          {children}
        </button>
        <div className="pro-card">
          <Drawer style={{ zIndex: 999999999999 }} anchor="right" open={open} onClose={() => this.setState({ open: false })}>
            {open ? (
              <ProProfileDetails
                fieldsHolder={this.props.fieldsHolder}
                isPrivate={isPrivate}
                showSendMessage
                pro={therapist}
                showProDetailsToggle={() => this.setState({ open: false })}
                click_source={clickSource}
              />
            ) : null}
          </Drawer>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  currentChat: state.currentChat,
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {})(ProProfilePopup);
