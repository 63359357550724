import React from 'react';
import { connect } from 'react-redux';
import { get, find } from 'lodash';
import { setBookingFlowStep, setCart, setBookingFlowPreviousStep } from '../../../Actions';
import CenteredGrids from '../../Shared/CenteredGrids';
import { Person, People } from '@material-ui/icons';
import '../Assets/Styles/index.css';
import './Assets/Styles/CouplePreferenceStep.css';
import { STEPS } from '../Shared/constants';
import { updateCart } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage, hasUuid, isGuestUser } from '../../../constants';
import Slide from "@material-ui/core/Slide";
HotJar();

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: null
        }
        this.goToNextStep = this.goToNextStep.bind(this);
        this.couplesOptions = this.couplesOptions.bind(this);
        this.submitOption = this.submitOption.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        let rebook = get(this.props, "booking.cart.rebook", false);
        if (rebook) {
            this.props.changeBackground("");
            this.props.toggleNavBarStyle(false);
        } else if (isGuestUser()) {
            this.props.toggleNavBarStyle(!get(this.props,"fieldsHolder.listingPageStorage.bannerimage",""));
            this.props.changeBackground(get(this.props,"fieldsHolder.listingPageStorage.bannerimage","") || "none");
        } else {
            this.props.toggleNavBarStyle(true);
            this.props.changeBackground('none');
        }
        let previousStep = find(STEPS, (step) => {
            return step.id === 'TIMING';
        });
        this.props.setBookingFlowPreviousStep(previousStep);
    }
    goToNextStep() {
        let nextStep = find(STEPS, (step) => (step.id === "REVIEW"));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    submitOption(val) {
        this.setState({ selection: val })
        let cartId = get(this.props, "booking.cart.id", ""),
            loader = this.props.setLoaderFlag;
        if (loader) {
            loader(true)
        }
        updateCart(cartId, { back_to_back: Boolean(val) }, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
            this.props.assignToCart({ cart: decode(response.data.cart) });
            if (loader) {
                loader(false)
            }
            this.goToNextStep();
        }, (err) => {
            if (loader) {
                loader(false)
            }
            this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage))
        })
    }
    couplesOptions() {
        let { selection } = this.state;
        return [
            {
                icon: <Person style={{ color: selection === true ? "#EEE" : "#586B94", fontSize: '50px' }} />,
                title: 'Back to Back',
                subtitle: '1 Provider for Both',
                value: true,
                action: (val) => {
                    this.submitOption(val)
                },
                selected: selection === true,
                relevantId: "backToBackSelection"
            },
            {

                icon: <People style={{ color: selection === false ? "#EEE" : "#586B94", fontSize: '50px' }} />,
                title: 'At the same time',
                subtitle: '2 Separate Providers',
                value: false,
                action: (val) => {
                    this.submitOption(val)
                },
                selected: selection === false,
                relevantId: "atTheSameTimeSelection"
            }
        ];
    }
    render() {
        let guest = Boolean(hasUuid() && get(this.props,"fieldsHolder.listingPageStorage.bannerimage",""));
        return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div className='display-flex'>
                <div className={guest ? "max-w-50-vw mt-1205vh" : ' max-width-30 mt-1205vh'}>
                    <div className={`medium-font size-44-52 txt-center ${guest ? 'color-white sm-color-black' : 'color-black'} mb-32`}>How would you prefer to enjoy your massages?</div>
                    <div className={`${guest ? 'background-primary p-24' : ""} max-width-25 sm-pb-100 border-radius-16`}>
                        <CenteredGrids
                            grids={this.couplesOptions()}
                        />
                    </div>
                </div>
            </div>
        </Slide>)
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    fieldsHolder: state.fieldsHolder
});

export default connect(mapStateToProps, { setBookingFlowStep, setCart, setBookingFlowPreviousStep })(Index);