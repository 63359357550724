/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import '../../Assets/Styles/index.css';
import moment from 'moment';
import {
  forEach, get, groupBy, isEmpty, map, sortBy,
} from 'lodash';
import { Close } from '@material-ui/icons';
import LatestModalDesign from '../../../Shared/LatestModalDesign';
import { acceptReschedule } from '../../Shared/helpers';
import BlueButtonsList from '../../../Shared/BlueButtonsList';
import { decode } from '../../Shared/encode';
import { defaultErrorMessage } from '../../../../constants';
import { setAppointment } from '../../../../Actions';
import ErrorModalRedesign from '../../../Shared/ErrorModalRedesign';
import SuccesModal from '../SuccessModal';
import { seRescheduleRequestAccept } from '../../../Shared/WebAnalytics';

class ResponseTimesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timesList: [],
      selection: null,
      mainModalOpen: get(this.props, 'show', false),
      showSuccessModal: false,
      showErrorModal: false,
      error: '',
    };
    this.listOfButtons = this.listOfButtons.bind(this);
    this.acceptSelection = this.acceptSelection.bind(this);
  }

  componentDidMount() {
    const booking_options = get(this.props, 'appointment.info_fields.tracker.pending_response_details.reschedule.booking_options', []);
    forEach(booking_options, (bk, itr) => {
      const mmnt = moment(bk.start_time);
      booking_options[itr].datetime = bk.start_time;
      booking_options[itr].month = mmnt.month() + 1;
      booking_options[itr].time = mmnt.format('h:mm A');
      booking_options[itr].day = mmnt.format('D');
      booking_options[itr].day_of_week = mmnt.format('dddd');
    });
    this.setState({ timesList: sortBy(booking_options, 'datetime') });
  }

  acceptSelection() {
    const datetime = get(this.state, 'selection.datetime', '');
    const oldDate = get(this.props, 'appointment.date.utc', '');
    if (datetime) {
      acceptReschedule(get(this.props, 'appointment.cartProducts.0.id', ''), {
        datetime,
      }, (resp) => {
        this.props.setAppointment(decode(resp.data.cart));
        seRescheduleRequestAccept({ multiple_times: true, same_day: moment(datetime).isSame(oldDate, 'day') });
        this.setState({ showSuccessModal: true, mainModalOpen: false });
      }, (err) => {
        this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage), mainModalOpen: false });
      });
    }
  }

  listOfButtons(grpList) {
    return map(grpList, (list, ind) => {
      const grpgrpList = groupBy(list, 'day');
      return (
        <div key={`resp-time-list-${ind}`}>
          {map(grpgrpList, (subList, itr) => (
            <div key={`resp-time-sublist-${itr}`} className="mb-50">
              <BlueButtonsList
                small
                selection={this.state.selection}
                title={moment(subList[0].datetime).format('ddd, MMM D')}
                buttonsList={subList}
                customId={itr}
                globalAction={(selection) => {
                  this.setState({ selection });
                }}
              />
            </div>
          ))}
        </div>
      );
    });
  }

  render() {
    const { timesList, selection, mainModalOpen } = this.state;
    const grpList = groupBy(timesList, 'month');
    const sessionLength = get(this.props, 'appointment.cartProducts.0.session_length');
    if (!isEmpty(timesList)) {
      return (
        <>
          <LatestModalDesign
            isOpen={mainModalOpen}
            title="Select a time"
            customIcon={<Close />}
            hideFooter
            back={() => {
              this.props.hideModal();
            }}
          >
            <div className="mb-24">
              <div data-spy="scroll" data-target="#time-scroller" data-offset="0">
                {this.listOfButtons(grpList)}
              </div>
            </div>
            {selection ? (
              <div className="bottom-sticky bottom-minus-25 bg-primary p-16 br-t-opaque row mx-minus-24">
                <div className="col-sm-4">
                  <div className="size-16-20 font-weight-bold contentPrimary mb-4">
                    {moment().day(selection.day_of_week).format('ddd')}
                    ,
                    {' '}
                    {moment().month(selection.month - 1).format('MMM')}
                    {' '}
                    {selection.day}
                  </div>
                  {sessionLength ? (
                    <div className="size-10-15 contentSecondary">
                      {selection.time}
                      {' '}
                      -
                      {' '}
                      {moment(selection.time, 'hh:mm A').clone().add(sessionLength, 'minutes').format('hh:mm A')}
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-8">
                  <button
                    type="button"
                    className="btn btn-action border-radius-30 full-w-100 medium-font"
                    onClick={this.acceptSelection}
                  >
                    Reschedule
                  </button>
                </div>
              </div>
            ) : null}
          </LatestModalDesign>
          <SuccesModal
            isOpen={this.state.showSuccessModal}
            title="Appointment successfully rescheduled"
            ctaAction={() => {
              this.setState({ mainModalOpen: false });
              this.props.hideModal();
            }}
          />
          <ErrorModalRedesign
            isOpen={this.state.showErrorModal}
            title={this.state.error}
            subtitle={this.state.error ? ' ' : ''}
            clickAction={() => {
              this.props.hideModal();
            }}
          />
        </>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
});
export default connect(mapStateToProps, { setAppointment })(ResponseTimesModal);
