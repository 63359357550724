/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  capitalize, get, merge, pick, replace, startCase, values,
} from 'lodash';
import { withLocalize } from 'react-localize-redux';
import NavBar from '../NavBar';
import AppleLogin from '../AppleLogin';
import ErrorModal from '../ErrorModal';
import {
  defaultErrorMessage, ROUTES, blankHref, hasUuid,
} from '../../../constants';
import {
  setAllUserIds, signUpComplete, logInStart, logInComplete,
  signUpAccountDetails, signUpStart, hashAffiliateUtms,
} from '../WebAnalytics';
import PhoneInput from '../PhoneInput';
import { HOST_ROOT, API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import backArrow from '../../../Assets/Images/back-arrow.png';
import './SignupModal.css';
import { loadClient } from '../../../Actions';
import GenericAuthModal from '../GenericAuthModal';

class SignupModal extends React.Component {
  constructor(props) {
    super(props);

    this.signup = this.signup.bind(this);
    this.signin = this.signin.bind(this);
    this.renderSignupOrSignin = this.renderSignupOrSignin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.facebookSignup = this.facebookSignup.bind(this);
    this.facebookSignin = this.facebookSignin.bind(this);
    this.marketingEmailOptin = this.marketingEmailOptin.bind(this);
    this.phoneCommunicationOptin = this.phoneCommunicationOptin.bind(this);
    this.renderErrorModal = this.renderErrorModal.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.renderNavbar = this.renderNavbar.bind(this);
    this.back = this.back.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.sendPixelData = this.sendPixelData.bind(this);
    this.redirectBasedOnUserStatus = this.redirectBasedOnUserStatus.bind(this);

    this.state = {
      firstName: '',
      lastName: '',
      email: get(props, 'client_email', ''),
      password: '',
      mobileNumber: '',
      signin: props.isSignin,
      isValidNumber: false,
      countryCode: '',
      canEmail: false,
      marketingCallsTexts: false,
      showErrorModal: false,
      error: null,
      facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '679911028787197',
      width: 0,
    };
  }

  componentDidMount() {
    if (!this.props.forceOldDesign) {
      return;
    }
    window.scrollTo(0, 0);
    if (this.props.error) this.setState({ showErrorModal: true, error: this.props.error });

    this.fetchClientOnLoad();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  fetchClientOnLoad() {
    axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response) => {
      const { result, client } = response.data;
      if (result) {
        if (!sessionStorage.getItem('userSet')) {
          setAllUserIds(client.user_id, client.email);
        }

        this.props.loadClient({ ...client, loggedIn: true });
        this.redirectBasedOnUserStatus();

        axios.get(`${API_ROOT}/clients/${client.user_id}/sift_science_params`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((response2) => {
          const { session_id } = response2.data;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'user_session_info',
            user_id: client.user_id,
            session_id,
          });
        });
      } else {
        this.props.loadClient({ loggedIn: false });
        this.redirectBasedOnUserStatus();
      }
    }).catch((e) => {
      const { response } = e;
      if (response.status === 401) {
        this.props.loadClient({ loggedIn: false });
        this.redirectBasedOnUserStatus();
      }

      if (response.status === 404 && response.data.errors && response.data.errors[0].code === 'client_not_found' && response.data.result === false) {
        window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
      }
    });
  }

  redirectBasedOnUserStatus() {
    const inSignOnScreen = window.location.pathname === ROUTES.userSignin;
    if (this.props.client && this.props.client.loggedIn && inSignOnScreen) {
      if (this.props.client.b2b_company_id) {
        window.location.replace(`${HOST_ROOT}${ROUTES.oldUserSignin}`);
      } else {
        this.nextStep();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleInputChange({ isValidNumber, number, countryCode }) {
    this.setState({ isValidNumber, mobileNumber: isValidNumber ? `+${replace(number, /\D/g, '')}` : number, countryCode });
  }

  back() {
    if (this.props.back) {
      this.props.back();
    } else {
      window.location.href = `${HOST_ROOT}${ROUTES.index}`;
    }
  }

  nextStep() {
    if (this.props.next) {
      this.props.next();
    } else {
      return window.location.replace(`${HOST_ROOT}${ROUTES.booking}`);
    }
  }

  sendPixelData(userId, type, userEmail) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') {
      return;
    }
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client_signup',
        user_id: userId,
        user_account: userEmail,
        type,
      });
    } catch (e) {
      console.log({ e });
    }
  }

  signup(event) {
    event.preventDefault();

    const { firstName } = this.state;
    const { lastName } = this.state;

    const params = merge(
      { mobile_number: this.state.mobileNumber },
      { first_name: firstName, last_name: lastName },
      pick(this.state, ['name', 'email', 'password']),
    );

    const attributionParams = hashAffiliateUtms();

    axios.post(`${HOST_ROOT}/users`, {
      user: merge(params, {
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      }),
      react_flow: true,
      attribution: attributionParams,
    }, { withCredentials: true }).then((resp) => {
      const { success, result, user } = resp.data;
      if (success || result) {
        setAllUserIds(user.id, user.email.toString());
        signUpStart();
        signUpComplete(user.id, 'email', user.email.toString());
        signUpAccountDetails(user.email, user.first_name, user.last_name, user.mobile_number);
        this.sendPixelData(user.id, 'email', user.email.toString());
        setTimeout(this.nextStep, 2000);
      } else if (get(resp, 'data.errors.user_already_exists', false)) {
        window.scrollTo(0, 0);
        this.setState({ signin: true });
      } else {
        const message = get(values(get(resp, 'data.errors', {})) || [], '0.0', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      }
    }).catch((error) => {
      const message = get(error, 'response.data.errors.0.message', '') || get(error, 'response.data.errors.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  signin(event) {
    event.preventDefault();

    const params = pick(this.state, [
      'email', 'password',
    ]);
    axios.post(`${HOST_ROOT}${ROUTES.oldUserSignin}`, { user: params, react_flow: true }).then((resp) => {
      const { success, result, user } = resp.data;

      const { type } = resp.data;
      if (result || type === 'client') {
        setAllUserIds(user.id, user.email.toString());
        logInStart('React App');
        logInComplete('email', user.id);
        setTimeout(this.nextStep, 2000);
      } else if (success && result === undefined) {
        return window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
      } else {
        const message = get(resp, 'data.message', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      }
    }).catch((error) => {
      const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  facebookSignup(response) {
    const { name } = response;
    const firstName = name.substr(0, name.indexOf(' '));
    const lastName = name.substr(name.indexOf(' ') + 1);
    const params = {
      first_name: firstName, last_name: lastName, email: response.email, oauth_token: response.accessToken, provider: 'facebook',
    };

    axios.post(`${API_ROOT}/users/facebook`, { user: merge(params, { gdpr_marketing_permissions: this.state.canEmail, marketing_calls_texts: this.state.marketingCallsTexts }), react_flow: true }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
      const { success, result } = resp.data;

      if (success || result) {
        signUpStart();
        signUpComplete(resp.data.user_id, 'Facebook', resp.data.email);
        signUpAccountDetails(
          resp.data.email,
          resp.data.first_name,
          resp.data.last_name,
          resp.data.mobile_number,
        );
        this.sendPixelData(resp.data.user_id, 'Facebook', resp.data.email);
        setTimeout(this.nextStep, 2000);
      } else {
        const message = get(resp, 'data.errors.0.message', '') || get(resp, 'data.errors.message', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      }
    }).catch((error) => {
      const message = get(error, 'response.data.errors.0.message', '') || get(error, 'response.data.errors.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  facebookSignin(response) {
    const { name } = response;
    const firstName = name.substr(0, name.indexOf(' '));
    const lastName = name.substr(name.indexOf(' ') + 1);
    const params = {
      first_name: firstName, last_name: lastName, email: response.email, oauth_token: response.accessToken, provider: 'facebook',
    };

    axios.post(`${API_ROOT}/sessions/facebook`, { user: params, react_flow: true }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
      const { success, result } = resp.data;

      if (success || result) {
        logInStart('React App');
        logInComplete('Facebook', resp.data.user_id);
        setTimeout(this.nextStep, 2000);
      } else {
        const message = get(resp, 'data.errors.0.message', '') || get(resp, 'data.errors.message', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      }
    }).catch((error) => {
      const message = get(error, 'response.data.errors.0.message', '') || get(error, 'response.data.errors.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  marketingEmailOptin() {
    if (this.state.countryCode !== 'us' && this.state.countryCode !== '') {
      return (
        <div className="form-group">
          <div className="col-xs-12">
            <div className="col-xs-1 col-sm-1">
              <input
                type="checkbox"
                checked={this.state.canEmail}
                className=""
                onChange={() => this.setState({ canEmail: !this.state.canEmail })}
              />
            </div>
            <div className="col-xs-10 col-sm-9 optin-text">
              I would like to receive promotional emails from Soothe
            </div>
          </div>
        </div>
      );
    }
  }

  phoneCommunicationOptin() {
    if (this.state.countryCode === 'us') {
      return (
        <div className="form-group">
          <div className="col-xs-12">
            <div className="col-xs-1 col-sm-1">
              <input type="checkbox" checked={this.state.marketingCallsTexts} className="" onChange={() => this.setState({ marketingCallsTexts: !this.state.marketingCallsTexts })} />
            </div>
            <div className="col-xs-10 col-sm-9 optin-text">
              Yes, please text me information about how
              I can receive calls and texts about special offers and promotions
            </div>
          </div>
        </div>
      );
    }
  }

  renderErrorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  renderSignup() {
    return (
      <>
        <div className="col-xs-12 signup-header">
          Sign Up
        </div>

        <div className="col-xs-12 signup-subheader">
          Please sign up to continue booking your treatment with Soothe. Already have an account?
          {' '}
          <a
            href={blankHref}
            onClick={(event) => {
              event.preventDefault();
              this.setState({ signin: true });
            }}
          >
            Sign In
          </a>
        </div>

        <AppleLogin
          signIn={false}
          redirectURI={this.props.redirectURI}
          backToSelectServiceCallback={this.nextStep}
          sendPixelData={this.sendPixelData}
        />

        <div className="form-group">
          <div className="col-xs-12">
            <FacebookLogin
              appId={this.state.facebookAppId}
              autoLoad={false}
              fields="name,email"
              scope="public_profile,email"
              callback={this.facebookSignup}
              textButton="Sign up with Facebook"
              cssClass="form-control my-facebook-button-class"
              isMobile={false}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-xs-12">
            <span className="line-wrapper" style={{ fontSize: '16px', fontFamily: 'Poppins-Regular', color: '#5F6368' }}>{capitalize('or')}</span>
          </div>
        </div>

        <form onSubmit={this.signup}>
          <div className="form-group">
            <div className="col-xs-12 col-sm-6">
              <label className="signup-form-lable">First Name</label>
              <input
                type="text"
                value={this.state.firstName}
                className="form-control"
                style={{ marginBottom: '20px' }}
                onChange={(event) => this.setState({ firstName: event.target.value })}
                required
              />
            </div>

            <div className="form-group">
              <div className="col-xs-12 col-sm-6">
                <label className="signup-form-lable">Last Name</label>
                <input
                  type="text"
                  value={this.state.lastName}
                  className="form-control"
                  style={{ marginBottom: '20px' }}
                  onChange={(event) => this.setState({ lastName: event.target.value })}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="col-xs-12">
              <label className="signup-form-lable">Email Address</label>
              <input
                type="text"
                value={this.state.email}
                className="form-control"
                style={{ marginBottom: '20px' }}
                onChange={(event) => this.setState({ email: event.target.value })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-xs-12">
              <label className="signup-form-lable">{capitalize('password')}</label>
              <input
                type="password"
                value={this.state.password}
                className="form-control"
                onChange={(event) => this.setState({ password: event.target.value })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-xs-12 form-group tel-input mb-16">
              <PhoneInput
                showLable
                onPhoneNumberChange={this.handleInputChange}
                className={`intl-tel-input form-control ${this.state.isValidNumber ? 'valid' : 'invalid'}`}
                required
              />
              {this.state.isValidNumber || !this.state.mobileNumber ? null : <div className="small-red-message">This number is invalid.</div>}
            </div>
          </div>

          <div className="form-group">
            <div className="col-xs-12 marketing-optin">
              By providing your mobile number to Soothe, you agree
              that Soothe and therapists may use that number to
              communicate with you via calls and text messages
              (including automatically dialed calls and texts)
              regarding your appointments and account.
            </div>
          </div>

          {this.phoneCommunicationOptin()}
          {this.marketingEmailOptin()}

          <div className="form-group">
            <div className="col-xs-12" />
          </div>

          <div className="form-group">
            <div className="col-xs-12 signup-container">
              <input type="submit" className="btn active form-control" value={capitalize('continue')} />
            </div>
          </div>

          <div className="form-group">
            <div className="col-xs-12">
              <div className="terms">
                By tapping “Continue” above, you agree to our
                {' '}
                <a href="/legal/terms/b2c/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                {' '}
                and
                {' '}
                <a href="/legal/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                .
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }

  renderSignin() {
    return (
      <>
        <div className="col-xs-12 signup-header">
          Sign In
        </div>

        <div className="col-xs-12 signup-subheader">
          Please sign in to continue booking your treatment with Soothe. Don't have an account?
          {' '}
          <a
            href={blankHref}
            onClick={(event) => {
              event.preventDefault();
              this.setState({ signin: false });
            }}
          >
            Sign Up
          </a>
        </div>

        <AppleLogin
          signIn
          redirectURI={this.props.redirectURI}
          backToSelectServiceCallback={this.nextStep}
        />

        <div className="form-group">
          <div className="col-xs-12">
            <FacebookLogin
              appId={this.state.facebookAppId}
              autoLoad={false}
              fields="name,email"
              scope="public_profile,email"
              callback={this.facebookSignin}
              textButton="Sign in with Facebook"
              cssClass="form-control my-facebook-button-class"
              isMobile={false}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-xs-12">
            <span className="line-wrapper" style={{ fontSize: '16px', fontFamily: 'Poppins-Regular', color: '#5F6368' }}>{capitalize('or')}</span>
          </div>
        </div>

        <form onSubmit={this.signin}>
          <div className="form-group">
            <div className="col-xs-12">
              <label className="signup-form-lable">Email Address</label>
              <input
                type="text"
                value={this.state.email}
                className="form-control"
                onChange={(event) => this.setState({ email: event.target.value })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-xs-12">
              <label className="signup-form-lable">{capitalize('password')}</label>
              <input
                type="password"
                value={this.state.password}
                className="form-control"
                onChange={(event) => this.setState({ password: event.target.value })}
              />
            </div>
          </div>

          <div className="col-xs-12">
            <a href="/users/password/new" target="_blank" rel="noopener noreferrer" className="forgotPassword">{startCase('forgot password?')}</a>
          </div>

          <div className="form-group">
            <div className="col-xs-12" />
          </div>

          <div className="form-group">
            <div className="col-xs-12 signup-container">
              <input type="submit" className="btn active form-control" value={capitalize('continue')} />
            </div>
          </div>
        </form>
      </>
    );
  }

  renderSignupOrSignin() {
    if (this.state.signin) {
      return this.renderSignin();
    }
    return this.renderSignup();
  }

  renderNavbar() {
    if (this.props.isBooking) {
      return null;
    }
    return (
      <NavBar />
    );
  }

  render() {
    if (hasUuid()) {
      return (
        <div className={`${this.props.isBooking ? '' : 'overlay-bg'} overlay signupModal`}>
          {this.renderNavbar()}
          <div className="popup box-shadow-container">
            <div className="header" />
            <div className="content">
              {this.renderErrorModal()}
              {this.renderSignupOrSignin()}

              {!this.props.isBooking
                && (
                <div className="col-xs-12 back-btn-container">
                  <a className="back-btn" href={blankHref} onClick={this.back}>
                    <img src={backArrow} className="back-arrow-img" alt="back arrow" />
                    {' '}
                    {capitalize('back')}
                  </a>
                </div>
                )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <GenericAuthModal
        cart_id={get(this.props, 'booking.cart.id', '')}
        next={this.props.next ? this.props.next : this.nextStep}
        closeModal={this.props.back}
        isSignin={this.props.isSignin}
        showPersonaInParent={this.props.showPersona}
        removePersona={this.props.removePersona}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  booking: state.booking,
});

SignupModal.propTypes = {
  loadClient: PropTypes.func.isRequired,
};

export default withLocalize(connect(mapStateToProps, { loadClient })(SignupModal));
