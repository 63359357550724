/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import Timelapse from '@material-ui/icons/Timelapse';
import { get, size } from 'lodash';
import { defaultErrorMessage } from '../../../constants';
import TextWithIcon from '../../Shared/TextWithIcon';
import ErrorModal from '../../Shared/ErrorModal';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import { extendAppointment } from '../Shared/helpers';
import { setAppointment } from '../../../Actions/AppointmentManagementAction';
import { decode } from '../Shared/encode';
import { seAppointmentExtend } from '../../Shared/WebAnalytics';

class ExtendAppt extends React.Component {
  // Allowed only for single appointments && exntensions.available === true
  constructor(props) {
    super(props);
    this.state = {
      showErrorModal: false,
      error: '',
      isActionOpen: false,
      selectedOptionIndex: 0,
      showSuccessModal: false,
    };
    this.actionModal = this.actionModal.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.submitExtension = this.submitExtension.bind(this);
    this.successModal = this.successModal.bind(this);
    this.minutesSelector = this.minutesSelector.bind(this);
    this.triggerExtendEvent = this.triggerExtendEvent.bind(this);
  }

  triggerExtendEvent(cpId, extension_option_id) {
    const minutes = get(this.props, `appointment.extensions.options.${extension_option_id}.session_length`, 0);
    const pro_id = get(this.props, 'appointment.cartProducts.0.pro.id', 'n/a');
    seAppointmentExtend(cpId, pro_id, minutes, this.props.isComingFromInbox ? 'inbox_message' : 'appointment_details');
  }

  submitExtension() {
    const { selectedOptionIndex } = this.state;
    const cartId = get(this.props, 'appointment.id', '');
    const cartProductId = get(this.props, 'appointment.cartProducts.0.id', '');
    const extension_option_id = get(this.props, `appointment.extensions.options.${selectedOptionIndex}.id`, '');
    this.setState({ isActionOpen: false });
    extendAppointment(cartId, cartProductId, { extension_option_id }, (response) => {
      this.setState({ showSuccessModal: true });
      this.triggerExtendEvent(cartProductId, extension_option_id);
      this.props.setAppointment(decode(response.data.cart));
    }, (err) => {
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  successModal() {
    const { selectedOptionIndex, showSuccessModal } = this.state;
    const cost = get(this.props, `appointment.extensions.options.${selectedOptionIndex}.price`, '');
    const creditCardExtension = get(this.props, 'appointment.billing.credit_card.display_string', '');
    return (
      <LatestModalDesign
        isOpen={showSuccessModal}
        title="Appointment Extended"
        subtitle="Your appointment was successfully extended."
        hideCancel
        back={() => { this.setState({ showSuccessModal: false }); }}
        applyBtnCopy="Done"
        apply={() => { this.setState({ showSuccessModal: false }); }}
      >
        <div className="size-16-24 color-black">
          {cost}
          {' '}
          was added to your total, to be charged to
          {' '}
          {creditCardExtension}
          {' '}
          after your appointment.
        </div>
      </LatestModalDesign>
    );
  }

  minutesSelector(selectedOptionIndex, optionsSz, options) {
    return (
      <div className="display-flex justify-content-spaced max-w-250 size-18-24 color-black medium-font">
        <button
          type="button"
          className="btn rounded-gray-btn"
          disabled={selectedOptionIndex === 0}
          onClick={() => {
            if (selectedOptionIndex > 0) {
              this.setState({ selectedOptionIndex: selectedOptionIndex - 1 });
            }
          }}
        >
          -
        </button>
        <div>
          {get(options, `${selectedOptionIndex}.session_length`, '0')}
          {' '}
          minutes
        </div>
        <button
          type="button"
          className="btn rounded-gray-btn"
          disabled={selectedOptionIndex === optionsSz - 1}
          onClick={() => {
            if (selectedOptionIndex < optionsSz - 1) {
              this.setState({ selectedOptionIndex: selectedOptionIndex + 1 });
            }
          }}
        >
          +
        </button>
      </div>
    );
  }

  actionModal() {
    const { isActionOpen, selectedOptionIndex } = this.state;
    const options = get(this.props, 'appointment.extensions.options', []);
    const optionsSz = size(options);
    return (
      <LatestModalDesign
        isOpen={isActionOpen && optionsSz}
        title="Extend appointment"
        subtitle="Select extension length"
        applyBtnCopy={`Extend for ${get(options, `${selectedOptionIndex}.price`, '')}`}
        apply={this.submitExtension}
        hideCancel
        close={() => this.setState({ isActionOpen: false })}
      >
        {this.minutesSelector(selectedOptionIndex, optionsSz, options)}
      </LatestModalDesign>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    const extensionAvailable = get(this.props, 'appointment.extensions.available', false);
    if (size(cartProducts) === 1 && extensionAvailable) {
      return (
        <>
          <div
            className="clickable"
            onClick={() => { this.setState({ isActionOpen: true }); }}
          >
            <TextWithIcon
              muiIcon={<Timelapse />}
              title="Extend your appointment"
              displayBorder
              displayArrow
            />
          </div>
          {this.actionModal()}
          {this.successModal()}
          {this.errorModal()}
        </>
      );
    }
    return this.successModal();
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, { setAppointment })(ExtendAppt);
