/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */
import React from 'react';
import bgVideo from '../../../Assets/Videos/Soothe-Wave-Background.mp4';
import mobileBgVideo from '../../../Assets/Videos/Soothe-Wave-Background-Mobile.mp4';
import './MainBackgroundWrapper.css';

export default class MainBackgroundWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainBg: '',
      width: window.innerWidth,
    };
    this.backgroundVideo = this.backgroundVideo.bind(this);
    this.getRelevantStyle = this.getRelevantStyle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bgImagePath !== undefined
      && nextProps.bgImagePath !== null && nextProps.bgImagePath !== this.props.bgImagePath) {
      this.setState({ mainBg: nextProps.bgImagePath });
    }
  }

  backgroundVideo() {
    if (this.props.showVideoBg) {
      if (Number(this.state.width) <= 800) {
        return (
          <video className="booking-main-background" autoPlay loop muted>
            <source src={mobileBgVideo} type="video/mp4" />
          </video>
        );
      }
      return (
        <video className="booking-main-background" autoPlay loop muted>
          <source src={bgVideo} type="video/mp4" />
        </video>
      );
    }
    return null;
  }

  getRelevantStyle() {
    const { mainBg, width } = this.state;
    if (mainBg && mainBg !== 'none' && width > 800) {
      return (<img className="booking-main-background w-100 filter-05 gray-overlay" src={mainBg} alt="bg-img" />);
    } if (mainBg === 'none') {
      return (<div className="booking-main-background" style={{ backgroundColor: '#E5E5E5' }} />);
    }
    return (<div className="booking-main-background" style={{ backgroundColor: 'transparent' }} />);
  }

  render() {
    return (
      <>
        {this.backgroundVideo()}
        {this.getRelevantStyle()}

        <div className={this.props.noMarginTop ? '' : 'mt-big mb-150 sm-mt-0'}>{this.props.children}</div>
      </>
    );
  }
}
