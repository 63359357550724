import React, { useState, useEffect } from 'react';
import { capitalize, isEmpty, get } from 'lodash';
import avatar from './Assets/Images/profile-avatar.svg';
import star from './Assets/Images/profile-star.svg';
import checkmark from './Assets/Images/profile-checkmark.png';
import './Assets/Styles/ProviderPickStep.css';
import Badge from '@material-ui/core/Badge';
import ProTabs from './ProTabs'
import ProSpecialtiesList from './ProSpecialtiesList';
import placeholder from './Assets/Images/placeholder.png';
import { getTherapistPrublicProfile } from '../Shared/helpers';
import { seProfileViewed } from '../../Shared/WebAnalytics';

function ComplimentsSection(compliments) {
    if (!isEmpty(compliments)) {
        return (
            <div className='pro-detail'>
                <div className='pro-compliments'>
                    <div className='pro-detail-title'>Client Compliments</div>
                    <div>
                        {compliments.map((compliment, complimentKey) => (
                            <div className='compliment-container' key={`compliment-${complimentKey}`}>
                                <div className='compliment-inner-container'>
                                    <div className='compliment-icon-container'>
                                        <Badge overlap="rectangular" badgeContent={compliment.total} className="badge-style" id={`badge-${complimentKey}`}>
                                            <img className='icon' src={compliment.icon_url || placeholder} alt={`${compliment.title || ""}`} />
                                        </Badge>
                                    </div>
                                    <div className='compliment-copy'>
                                        {compliment.title || ""}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default function ProProfileDetails(props) {
    const [proProfile, setProProfile] = useState({});

    let { pro, selectPro, showProDetailsToggle, time, market, click_source } = props;

    useEffect(() => {
        const getProProfile = async (proId) => {
            getTherapistPrublicProfile(proId, (response) => {
                seProfileViewed(proId, get(response, "data.therapist.rating", 0), get(response, "data.therapist.number_of_bookings", 0), click_source, "existing_flow", market, true)
                setProProfile(response.data.therapist);
            }, props.errorHandler)
        }

        pro.id && getProProfile(pro.id);
    }, [pro.id]);

    let { avatar_url, first_name, last_name, private_avatar_url } = proProfile;

    if (first_name)
        return (
            <div id='pro-profile-details'>
                <div className='pro-profile-container'>
                    <div className='pro-profile-upper'>
                        <a className='back-to-search' href='&times;' onClick={(e) => { e.preventDefault(); showProDetailsToggle(); }}>&#60; Back to Provider Search</a>

                        <div className='top-section'>
                            <div className='pro-img-container'>
                                <img className='pro-img' src={private_avatar_url || avatar_url || avatar} alt='avatar' />
                            </div>
                            <div className='pro-name'>{`${first_name} ${last_name[0]}.`}</div>

                        </div>
                    </div>
                    <ProTabs getTabContent={getTabContent} proProfile={proProfile} pro={pro} />
                    <div className='pro-profile-lower'>
                        <div className='pro-profile-btn-container'>
                            <div className='btn pro-profile-btn' onClick={() => selectPro(pro.id, time, get(pro, "match_type", ""))}>Book with {first_name}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    return null;
}
function getTabContent(proProfile, index, pro) {
    let { about_description, about_title, first_name, last_name,
        number_of_bookings, rating, since, service_categories, compliments, bio } = proProfile;
    if (index === 0) {
        return (
            <div className='pro-profile-lower' key="tab-content-1">
                <div className='pro-profile-upper'>
                    <div className='top-section'>
                        <div className='pro-details-container'>
                            <div className='pro-bookings'>{number_of_bookings || 0} <br /> Bookings</div>
                            <div className='pro-rating'>{rating || pro.rating} <img className='star-img' src={star} alt='star' /><br /> Rating</div>
                            <div className='pro-year'>{since && since.years || 1}<br /> Years</div>
                        </div>
                    </div>
                </div>
                <div className='middle-section'>

                    {/* COMPLIMENTS */}
                    {ComplimentsSection(compliments)}

                    <div className='pro-detail'>
                        <div className='pro-bio'>
                            <div className='pro-detail-title'>{about_title}</div>
                            {about_description}
                        </div>
                    </div>


                    <div className='pro-detail'>
                        <div className='pro-services'>
                            <div className='pro-detail-title'>Service Abilities</div>

                            <ul>
                                {(service_categories).map(service => <li key={service.id}>{capitalize(service.title)}</li>)}
                            </ul>
                        </div>
                    </div>

                    <div className='pro-detail'>
                        <div className='pro-licensed'>
                            <div className='pro-detail-title'>
                                <img className='checkmark' src={checkmark} alt='checkmark' style={{ width: '12px', marginRight: '8px' }} />
                                Fully licensed and certified
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
    if (index === 1) {
        return (
            <div className='pro-profile-lower' key="tab-content-2">
                <div className='middle-section'>
                    {ServiceCategories(service_categories)}
                </div>
            </div>
        )
    }
    return (
        <div className='pro-profile-lower' key="tab-content-3">
            <div className='middle-section'>

                <div className='pro-detail'>
                    <div className='pro-bio'>
                        <div className='pro-detail-title'>A little more about {first_name} {last_name}</div>
                        {bio}
                    </div>
                </div>

            </div>

        </div>
    )
}
function ServiceCategories(services) {
    if (services.length > 0) {
        return (
            <div>
                {services.map((service, serviceKey) => {
                    let title = service.title || "";
                    return (<div key={`service-${serviceKey}`}>
                        <ProSpecialtiesList listItems={service.modalities || []} sectionTitle={`${capitalize(title)} Modalities`} keyNaming={`${title}-modalities`} parentKeyNaming={`service-${serviceKey}`} />
                        {service.addons && service.addons.length ? <ProSpecialtiesList listItems={service.addons || []} sectionTitle="Enhancements" keyNaming={`${title}-addons`} parentKeyNaming={`service-${serviceKey}`} /> : null}
                    </div>
                    );

                })
                }

            </div>
        )
    }
}