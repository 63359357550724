/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import {
  filter,
  find, findLastKey, get, includes, isEmpty, map, sampleSize, size,
} from 'lodash';
import { AvatarGroup } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import moment from 'moment';
import WeeklyHorizontalCalendar from '../../Shared/WeeklyHorizontalCalendar';
import { defaultErrorMessage } from '../../../constants';
import HorizontalCalendar from '../../Shared/HorizontalCalendar';
import { getAvailableTherapistsWithTimeSlots } from '../../Shared/Helpers';
import { setAvailableTherapistsWithTimeSlots } from '../../../Actions';
import LoadingOverlay from '../../Shared/LoadingOverlay';
import placeholderImg from '../../../Assets/Images/Blue-small-logo-svg.svg';
import { updateCart } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { seBookingSchedule } from '../../Shared/WebAnalytics';
import { STEPS, formatProsWithSlotsList } from '../Shared/constants';
import { Close } from '@material-ui/icons';

class DateTimeMarketpLace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionDate: moment().add(1, 'days'), // .format('MM/DD/YYYY'),
      width: window.innerWidth,
      loading: false,
      formattedList: [],
      showHeadsUp: false
    };
    this.listOptions = this.listOptions.bind(this);
    this.getProsOfSelectedDay = this.getProsOfSelectedDay.bind(this);
    this.formatProsList = this.formatProsList.bind(this);
    this.submitSelection = this.submitSelection.bind(this);
    this.sameDayCopy = this.sameDayCopy.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    this.getProsOfSelectedDay();
    this.sameDayCopy();
  }

  sameDayCopy() {
    this.state.sessionDate && this.state.sessionDate.isSame(moment(), 'day') ? this.setState({ showHeadsUp: true }) : this.setState({ showHeadsUp: false });
  }

  getProsOfSelectedDay() {
    this.sameDayCopy();
    // pap marketplace not allowed for couples
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    this.setState({ loading: true });
    getAvailableTherapistsWithTimeSlots(cartId, get(cartProducts, '0.id', ''), this.state.sessionDate.format('YYYY-MM-DD'), (resp) => {
      const providers = get(resp, 'data.therapists', []);
      this.formatProsList(providers);
      this.setState({ loading: false });
    }, (err) => {
      this.setState({ loading: false });
      this.formatProsList([]);
      this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
    });
  }

  formatProsList(therpList) {
    this.setState({ formattedList: formatProsWithSlotsList(therpList, this.state.sessionDate.isSame(moment(), 'day')) });
  }

  submitSelection(hour, proList = []) {
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartPayload = {
      session_date: moment(this.state.sessionDate).format('YYYY/MM/DD'),
      session_time: hour,
      remove_pick_a_pro: true,
    };
    this.setState({ loading: true });
    updateCart(cartId, cartPayload, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
      this.setState({ loading: false });
      this.props.setAvailableTherapistsWithTimeSlots(proList);
      const { result } = response.data;
      if (result) {
        this.props.assignToCart({ cart: decode(response.data.cart) });

        const cart = get(this.props, 'booking.cart', null);
        const productTitle = get(this.props, 'booking.product.title', '').toLowerCase();
        const isFirstTimeBooker = get(this.props, 'client.first_time_booker', false);

        seBookingSchedule(cart, productTitle, isFirstTimeBooker, 'booking_23_variant', size(proList), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));

        let nextStepKey = 'REVIEW';
        if (get(this.props, 'booking.cart.info_fields.marketplace_enabled', false) && !isEmpty(proList)) {
          nextStepKey = 'PICKAPRO';
        }
        const nextStepCustom = find(STEPS, (step) => (step.id === nextStepKey));
        this.props.setBookingFlowStepThroughParent(nextStepCustom);
      } else {
        const message = get(response, 'data.errors.0.message', defaultErrorMessage);
        this.setState({ showErrorModal: true, message });
      }
    }, (err) => {
      this.setState({ loading: false });
      this.props.setAvailableTherapistsWithTimeSlots([]);
      this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
    });
  }

  responiveDaySelector() {
    if (this.state.width < 800) {
      return (
        <div className="top-sticky-80 z-index-1">
          <div className="bg-primary top-br-rd-16">
            <div className="centered size-18-24 contentPrimary font-weight-bold mb-10">Select a time</div>
            <HorizontalCalendar
              daysCount={60}
              startDate={moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')}
              defaultSelectedDate={moment().add(1, 'days')}
              additionalAction={(dateEl) => this.setState(
                { sessionDate: dateEl },
                this.getProsOfSelectedDay,
              )}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="top-sticky-80 z-index-1">
        <div className="bg-primary top-br-rd-16">
          <WeeklyHorizontalCalendar
            defaultSelectedDate={moment().add(1, 'days')}
            sendToParent={(dateEl) => this.setState(
              { sessionDate: dateEl },
              this.getProsOfSelectedDay,
            )}
          />
        </div>
      </div>
    );
  }

  listOptions() {
    const frmtdList = this.state.formattedList;
    if (this.state.loading) {
      return <LoadingOverlay showLoader={this.state.loading} />;
    }
    if (isEmpty(frmtdList)) {
      return <div className="contentPrimary size-14-20 ptb-16 centered">Oh no! There are no Providers available in your area for this selected service. Please try a different date or service.</div>;
    }
    return (
      <>
        {map(frmtdList, (proListt, hr) => {
          const gaAllowed = get(this.props, "booking.cart.info_fields.ga_pap_enabled", false);
          const nonGAPros = filter(proListt, (pro) => !pro.isGA);
          const sz = size(gaAllowed ? proListt : nonGAPros);
          const prosImgsList = gaAllowed ? sampleSize(proListt, proListt?.length) : sampleSize(nonGAPros, nonGAPros?.length);
          return (
            <div
              key={`slct-hr-${hr}`}
              className={`d-flex-only justify-content-spaced ptb-16 ${findLastKey(proListt) === hr ? '' : 'br-b-opaque'} clicked cursor-pointer`}
              onClick={() => this.submitSelection(hr, proListt)}
            >
              <div>
                <div className="size-16-20 contentPrimary font-weight-bold">{moment(hr, 'HH:mm').format('h:mm a')}</div>
                <div className="size-14-20 contentSecondary">
                  {!gaAllowed ? sz ? `${sz} Provider${sz > 1 ? 's' : ''} instantly available` : "Providers available for requests"
                    : `${sz} provider${sz > 1 ? 's' : ''} available`}
                </div>
              </div>
              <div>
                <AvatarGroup max={3}>
                  {map(prosImgsList, (pro, it) => (
                    <Avatar
                      key={`pro-${it}`}
                      src={get(pro, 'avatar_url', placeholderImg)}
                      alt={it}
                    />
                  ))}
                </AvatarGroup>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <div className="p-12 mb-12">
        {this.responiveDaySelector()}
        {this.state.showHeadsUp ? <div className='p-16 bg-peach br-rd-8 relative'>
          <div className='mb-8'><span className='size-12-20 p-2-4 bg-deep-orange contentInversePrimary br-rd-4'>Heads up!</span></div>
          <div className='size-14-20 contentPrimary'>Appointments booked a day in advance are more likely to be accepted by a Provider.</div>
          <span className="cursor-pointer contentPrimary abs-top r-16 t-16"
            onClick={() => this.setState({ showHeadsUp: false })}>
            <Close style={{ fontSize: '20px' }} />
          </span>
        </div> : null}
        <div className="p-24-16 mb-24">
          <div className="size-20-28 contentPrimary font-weight-bold mb-8">{this.state.sessionDate.format('ddd, MMM DD')}</div>
          {this.listOptions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  fieldsHolder: state.fieldsHolder,
});
export default
  connect(mapStateToProps, { setAvailableTherapistsWithTimeSlots })(DateTimeMarketpLace);
