/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-dupe-class-members */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import _ from 'lodash';
import { setSubscription, setSubscriptionView } from '../../Actions';
import { VIEWS } from './Constants';
import FetchClientContainer from '../Client/FetchClient';
import SubscribeToSoothe from './SubscribeToSoothe';
import SubscriptionDetails from './SubscriptionDetails';
import SignupModal from '../Shared/SignupModal';
import Footer from '../Shared/Footer';
import HelmetTag from '../Shared/HelmetTag';
import { componentMountedInitCalls } from '../Shared/WebAnalytics';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../apiConfig';
import { ROUTES } from '../../constants';

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.findAndSetSubscription = this.findAndSetSubscription.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
  }

  componentDidMount() {
    componentMountedInitCalls(this.props.client);
    window.scrollTo(0, 0);
    const searchObj = queryString.parse(this.props.location.search);
    if (searchObj) {
      const { zipCode, subscription_tier_id, minutes } = searchObj;
      this.findAndSetSubscription(zipCode, subscription_tier_id, minutes);
    }
  }

  next() {
    this.props.setSubscriptionView(VIEWS.SUBSCRIPTION_SUMMARY);
    window.location.reload();
  }

  findAndSetSubscription(zipCode, subscription_tier_id, mins) {
    if (zipCode) {
      axios.get(`${API_ROOT}/no_auth_subscriptions/?zip_code=${zipCode}`, ACCEPT_LANGUAGE_HEADER).then((response) => {
        const { subscription_tiers, errors } = response.data;

        if (subscription_tiers) {
          const tier = _.find(subscription_tiers, (tier) => (tier.subscription_tier.id === Number(subscription_tier_id)));
          if (!_.isEmpty(tier)) {
            const sub = tier.subscription_tier;
            if (!_.isEmpty(sub)) {
              const pricing = _.find(sub.pricing, (pri) => (pri.minutes === Number(mins)));
              if (!_.isEmpty(pricing)) {
                const {
                  id, city_id, percentage, title, initial_payments, billing_frequency,
                } = sub;
                const {
                  amount, price, currency, minutes, disclosure, confirmation_message,
                } = pricing;
                const subObj = {
                  subscription_tier_id: id,
                  city_id,
                  amount,
                  minutes,
                  percentage,
                  price,
                  title,
                  billing_frequency,
                  totalMinutes: (minutes * initial_payments || 0),
                  currency,
                  confirmation_message,
                  disclosure,
                  initial_payments,
                };
                if (!_.isEmpty(subObj)) {
                  this.setSubscription(subObj);
                }
              }
            }
          }
        } else {
          console.log('error', errors.message);
        }
      });
    }
  }

  setSubscription(value) {
    this.props.setSubscription({
      ...this.props.subscription,
      ...value,
    });
  }

  next() {
    this.props.setSubscriptionView(VIEWS.SUBSCRIPTION_SUMMARY);
    window.location.reload();
  }

  renderView() {
    const parsedParams = queryString.parse(window.location.search);
    const isLoggedIn = this.props.client.loggedIn;
    const { subscribed } = this.props.client;
    const redirectURI = `${process.env.REACT_APP_API_URL}/apple/auth_redirect_soothe_plus`;

    if (Object.keys(parsedParams).includes('auth_error')) {
      return (<SignupModal error={parsedParams.auth_error} redirectURI={redirectURI} back={() => { window.location.href = ROUTES.soothePlus; }} next={this.next} />);
    }
    if ((isLoggedIn && subscribed) || (isLoggedIn && this.props.client.subscription_minutes > 0)) {
      this.props.setSubscriptionView(VIEWS.SUBSCRIPTION_DETAILS);
      return (<SubscriptionDetails next={this.next} />);
    }
    if (this.props.subscription.city_id === null || this.props.subscription.disclosure === null || this.props.subscription.disclosure === undefined) {
      this.props.setSubscriptionView(VIEWS.SUBSCRIPTION_SUMMARY);
      return;
    }
    if (this.props.subscriptionView === VIEWS.SUBSCRIPTION_DETAILS) {
      return (<SubscriptionDetails next={this.next} />);
    } if ((this.props.subscriptionView === VIEWS.SUBSCRIPTION_SUMMARY) || !subscribed) {
      if (this.props.client.loggedIn) {
        return (<SubscribeToSoothe />);
      }
      return <SignupModal redirectURI={redirectURI} back={() => { window.location.href = ROUTES.soothePlus; }} next={this.next} />;
    }
  }

  render() {
    return (
      <div>
        <HelmetTag
          title="Soothe Plus"
          description="Soothe Plus | Wellness Begins Here"
        />

        <FetchClientContainer />
        { this.renderView() }
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  subscription: state.subscription,
  subscriptionView: state.subscriptionView,
});

Index.propTypes = {
  client: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  setSubscription: PropTypes.func.isRequired,
  setSubscriptionView: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setSubscription, setSubscriptionView })(Index);
