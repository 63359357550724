/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {
  filter, get, isEmpty, join, map, size,
} from 'lodash';
import moment from 'moment';
import { AvatarGroup } from '@material-ui/lab';
import './ChatList.css';
import { Badge } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import GrayAvatar from '../../../Assets/Images/carbon_user-avatar-filled.svg';

export default function ChatList(props) {
  const { chatList, current_chat_id } = props;
  if (isEmpty(chatList)) {
    return null;
  }
  return (
    <List className="overflow-y-scroll" style={{ width: '100%', zIndex: '0' }}>
      <InfiniteScroll
        style={{
          height: 'calc(100vh - 180px)',
        }}
        dataLength={size(chatList)}
        next={props.loadNextChats}
        hasMore={props.hasMoreChats}
        loader={<p className="centered contentTertiary size-10-20 mb-16">Loading...</p>}
        endMessage={<p className="centered contentTertiary size-10-20 mb-16">No more chats</p>}
      >
        {map(chatList, (convo, ckey) => {
          const {
            participants, chats, chat_id, simplified_cart,
          } = convo;
          const filteredParticipants = filter(participants, (el) => (!el.current_user));
          const isSeen = get(chats, '0.seen', false);
          const modalityTitle = get(simplified_cart, 'cart_product_main_option_title', '');
          return (
            <div key={`cht-${ckey}`} className="chats-list">
              <ListItem onClick={() => props.clickAction(chat_id)} className={`cursor-pointer ${isSeen && current_chat_id !== chat_id ? '' : 'bg-accent-light'}`}>
                <ListItemAvatar className="p-18-16-0">
                  {isEmpty(filteredParticipants) ? null
                    : (
                      <AvatarGroup max={2}>
                        {map(filteredParticipants, (pr, it) => (
                          <Avatar
                            key={`pr-${it}-${ckey}`}
                            className={`avatar-img ${size(filteredParticipants) > 1 ? 'shrink-size' : ''}`}
                            alt={get(pr, 'name', '')}
                            src={get(pr, 'image_url', '') || GrayAvatar}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                </ListItemAvatar>
                <ListItemText>
                  <div className="size-16-20 contentPrimary font-weight-bold mb-4">{join(map(filteredParticipants, (pp) => (pp.name || '')), ', ')}</div>
                  <div className="size-14-20 contentSecondary mb-4 ellipsis-2">
                    {get(chats, '0.message_text', '')}
                  </div>
                  <div className="size-12-20 contentTertiary">
                    {modalityTitle ? `${modalityTitle}, ` : null}
                    {moment(get(chats, '0.timestamp', '')).format('MMM D, h:mm a')}
                  </div>
                </ListItemText>
                <span className="ml-16 p-10-12"><Badge overlap="rectangular" color="secondary" variant="dot" invisible={isSeen} /></span>
              </ListItem>
              <Divider variant="inset" component="li" />

            </div>
          );
        })}
      </InfiniteScroll>
    </List>
  );
}
