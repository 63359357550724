import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import AppointmentDetails from './AppointmentDetails';
import appStoreIcon from './../../Shared/TextAppLink/Assets/Images/download_app_apple.png';
import googlePlayIcon from './../../Shared/TextAppLink/Assets/Images/download_app_google.png';
import hero from './Assets/Images/summary-hero.png';
import './Assets/Styles/index.css';
import { HotJar, isGuestUser, ROUTES } from '../../../constants';
import { bookingConversionImpactRadius } from './../../Shared/WebAnalytics.js';
import Slide from "@material-ui/core/Slide";
import { get } from 'lodash';
import { HOST_ROOT } from '../../../apiConfig';
import { setProducts, setCart, setField } from '../../../Actions';
HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_name: "",
      location_name: ""
    }
    this.whatToExpect = this.whatToExpect.bind(this);
    this.dynamicStatement = this.dynamicStatement.bind(this);
    this.redirectToStart = this.redirectToStart.bind(this);
    this.resetBooking = this.resetBooking.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    if (isGuestUser()) {
      this.setState({
        business_name: get(this.props,"fieldsHolder.listingPageStorage.sub_domain",""),
        location_name: get(this.props,"fieldsHolder.listingPageStorage.uuid","")
      })
    }
    this.props.toggleNavBarStyle(true);
    this.props.changeBackground('none');

    bookingConversionImpactRadius(this.props.booking.cart, this.props.client);
  }
  redirectToStart() {
    this.resetBooking();
    if (isGuestUser()) {
      let { business_name, location_name } = this.state;
      this.props.setField("csrfToken", "")
      this.props.setField("listingPageStorage", null);
      return `https://${business_name}${process.env.REACT_APP_PERMITTED_DOMAIN}/${location_name}`;
    }
    return `${HOST_ROOT}${ROUTES.index}`;
  }
  resetBooking() {
    this.props.setProducts({ products: [] });
    this.props.setCart({ cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {} });
  }

  whatToExpect() {
    let whatToExpect;

    if (this.props.booking.product && this.props.booking.product.what_to_expects && this.props.booking.product.what_to_expects.length > 0) {
      whatToExpect = this.props.booking.product.what_to_expects.map(option => (
        <div key={option.id}>
          <div className='expect-icon' style={{ backgroundImage: `url(${option.images[0].url})` }} />

          <div className='detail-wrapper-grey'>
            {option.body}
          </div>
        </div>
      ))

      return (
        <div>
          <br /><br />

          <h3 className='confirmation-header'>
            What to Expect
          </h3>

          {whatToExpect}
        </div>
      )
    }
  }
  dynamicStatement() {
    let papEnabled = get(this.props, "booking.cart.pickAProEnabled", false),
      proId = get(this.props, "booking.cart.therapist_preferences.0.id", "");
    if (papEnabled && proId) {
      return "Your appointment has been confirmed.";
    }
    return "Your appointment has been requested.";
  }

  render() {
    return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <div id='confirmation-legacy'>
        <div className='confirmation-container box-shadow-container'>
          <div className='hero-container' style={{ backgroundImage: `url(${hero})` }}>
            <div className='hero-content'>
              <div className='summary-title'>
                Thank You!
              </div>

              <div className='detail-wrapper'>
                Get ready to relax with Soothe. <br />
                {this.dynamicStatement()}
              </div>
              <AppointmentDetails showAddress={true} booking={this.props.booking} />
            </div>
          </div>

          {this.whatToExpect()}

          <div className='gray-line' />

          <div className='download'>
            <div className='download-header'>View your appointment in our apps!</div>
            <a href='https://itunes.apple.com/de/app/soothe-in-home-massage-delivered/id811054908?mt=8' target='_blank' rel='noopener noreferrer'>
              <div className='download-app-store' style={{ backgroundImage: `url(${appStoreIcon})` }}></div>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.soothe.client&hl=de' target='_blank' rel='noopener noreferrer'>
              <div className='download-google-store' style={{ backgroundImage: `url(${googlePlayIcon})` }}></div>
            </a>
          </div>

          <div className='continue-btn-container'>
            <button className='btn continue-btn' rel='noopener noreferrer' onClick={() => {
              window.location.href = this.redirectToStart()
            }}>
              Done
            </button>
          </div>
        </div>
      </div>
    </Slide>
    );
  }
}

const mapStateToProps = state => ({
  booking: state.booking,
  client: state.client,
  fieldsHolder: state.fieldsHolder
});

Index.propTypes = {
  booking: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps, { setProducts, setCart, setField })(Index));
