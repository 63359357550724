import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get, size } from 'lodash';
import ColumnsList from '../../Shared/ColumnsList';
import Delete from '@material-ui/icons/Delete';
import { hasUuid } from '../../../constants';
import { seDeleteFromCart } from '../../Shared/WebAnalytics';

class ServicePerPerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.relevantPriceDisplay = this.relevantPriceDisplay.bind(this);
        this.relevantMemberPriceDisplay = this.relevantMemberPriceDisplay.bind(this);
        this.displayColumns = this.displayColumns.bind(this);
        this.displayActions = this.displayActions.bind(this);
    }
    relevantPriceDisplay(cartProductDetails) {
        let isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
            isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false) && !hasUuid(),
            alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
            subscription_membership_ends_date = get(this.props, "client.soothe_pass.subscription_membership_ends_date", "");
        let nonMemberPrice;
        if (isSoothePassAvailable && (alreadyAppliedToCart || isSoothePassSubscribed || subscription_membership_ends_date)) {
            nonMemberPrice = <span className='gray-strike-through'>{get(cartProductDetails, "cart_product_main_option.price", "")}</span>
        } else {
            nonMemberPrice = <span>{get(cartProductDetails, "cart_product_main_option.price", "")}</span>;
        }
        return (<div>{nonMemberPrice} {this.relevantMemberPriceDisplay(cartProductDetails)}</div>)
    }
    relevantMemberPriceDisplay(cartProductDetails) {
        let isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
            isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false) && !hasUuid(),
            alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
            price_with_soothe_pass = get(cartProductDetails, "cart_product_main_option.member_price", ""),
            subscription_membership_ends_date = get(this.props, "client.soothe_pass.subscription_membership_ends_date", "");
        if (price_with_soothe_pass && isSoothePassAvailable) {
            if (alreadyAppliedToCart) {
                return <span className='color-green'>Free with SoothePoints at checkout</span>;
            } else if (isSoothePassSubscribed || subscription_membership_ends_date) {
                return <span>{price_with_soothe_pass}</span>;
            } else {
                return (<>| <a className='cursor-pointer' onClick={() => {
                    this.props.openPlanPopup()
                }}>{price_with_soothe_pass} with SoothePass™</a></>);
            }
        }
    }
    displayColumns(cartProductDetails) {
        return (<ColumnsList headerText={<div className='medium-font overflow-wrap-break-word'>{`${cartProductDetails.client_name}'s ${cartProductDetails.product.title}`}</div>}
            itemsList={[{
                text: (<div>{cartProductDetails.subtitle || ""}</div>)
            }, {
                text: (this.relevantPriceDisplay(cartProductDetails))
            }]} />)
    }
    displayActions(moreThanOnePerson, cartProductDetails) {
        if (moreThanOnePerson) {
            return (<div className='col-xs-2 col-sm-1'>
                <button className='btn edit-icon' onClick={() =>{
                     seDeleteFromCart(size(get(this.props, "booking.cart.cartProducts", [])), "booking_23_control", get(this.props, "booking.cart.address.market", ""))
                     this.props.deleteRelevantService(cartProductDetails.id)
                     }}><Delete />
                </button></div>);
        }
        return null;
    }
    render() {
        const cartProductDetails = this.props.cartProduct;
        if (!isEmpty(cartProductDetails)) {
            let moreThanOnePerson = get(this.props, "booking.cart.cartProducts", []).length > 1;
            return (<div className='row'>
                <div className={moreThanOnePerson ? 'col-xs-10 col-sm-11' : 'col-xs-12'}>
                    {this.displayColumns(cartProductDetails)}
                </div>
                {this.displayActions(moreThanOnePerson, cartProductDetails)}
            </div>)
        }
        return null;
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client
});

ServicePerPerson.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired
};

export default withLocalize(connect(mapStateToProps)(ServicePerPerson));