/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
import {
  get, groupBy, isEmpty, map, reverse,
} from 'lodash';
import moment from 'moment';
import React from 'react';
import Message from '../Shared/Message';
import './Inbox.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.messageItems = this.messageItems.bind(this);
  }

  messageItems() {
    const chat = this.props.currentChat;
    const messages = get(chat, 'chats', []);
    let messageItems = null;

    if (messages && messages.length > 0) {
      const groupedByDay = groupBy(messages, (msg) => {
        const mmt = moment(msg.timestamp);
        if (mmt.isSame(new Date(), 'day')) {
          return 'Today';
        } if (moment(new Date()).diff(mmt, 'days') === 1) {
          return 'Yesterday';
        } if (!mmt.isSame(new Date(), 'year')) {
          return mmt.format('ddd, MMM D YYYY');
        }
        return mmt.format('ddd, MMM D');
      });
      messageItems = map(groupedByDay, (msgGrp, it) => {
        const rvrsdGrp = reverse(msgGrp);
        return (
          <div key={`grp-msg-${it}`}>
            <div className="size-12-16 font-weight-bold contentPrimary centered">{it}</div>
            {rvrsdGrp.map((message, index) => {
              const createdAt = moment(message.timestamp).format('h:mm A');
              return (
                <span key={`msg-${index}-it`}>
                  <Message
                    ckey={`${index}-${it}`}
                    isMyMessage={message.current_user}
                    message={{ ...message, createdAt }}
                    isRejected={message.rejected}
                  />
                </span>
              );
            })}
          </div>
        );
      });
    }
    return messageItems;
  }

  render() {
    const chat = this.props.currentChat;
    const messages = get(chat, 'chats', []);
    if (isEmpty(messages)) {
      return (
        <div className="centered size-16-20 font-weight-bold contentPrimary height-custom-100 align-items-center justify-content-centered d-flex-only">No messages</div>
      );
    }
    return (
      <div
        id="scrollableDiv"
        style={{
          height: 'calc(100vh - 180px)',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          // marginTop: '60px',
          // if small size
          marginBottom: '24px',
        }}
      >
        <InfiniteScroll
          dataLength={messages && messages.length}
          // This is important field to render the next data
          next={this.props.loadMore}
          hasMore={Boolean(get(chat, 'chat_messages_remaining', ''))}
          loader={<p className="centered contentTertiary size-10-20 mb-16">Loading...</p>}
          endMessage={<p className="centered contentTertiary size-10-20 mb-16">No more messages</p>}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse
          scrollableTarget="scrollableDiv"
        >
          {this.messageItems()}
        </InfiniteScroll>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  chats: state.chats,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, {})(MessageList);
