import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { capitalize, isEmpty, map, reduce, camelCase, get, mapKeys, replace, forEach } from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart, setPurchasedGiftCardAssortment } from '../../../Actions';
import CardNumber from './cardNumber'
import CardExpirationDate from './cardExpirationDate'
import CardCVC from './cardCVC'
import { Elements } from 'react-stripe-elements';
import { ACTIVE_LANGUAGE_CODE, US, DE, ROUTES, defaultErrorMessage } from '../../../constants'
import checkMark from '../images/Check_mark.png'
import xMark from '../images/X_mark.png'
import grayLock from '../images/Lock_icon_gray.png'
import whiteLock from '../images/Lock_icon_white.png'
import NavBar from './../../Shared/NavBar';
import HelmetTag from './../../Shared/HelmetTag';
import plusIcon from '../images/Plus_icon.png'
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { EMAIL, EMAILREGEX, GIFT_CARD_CART_DEFAULT, encode } from '../shared/constants';
import moment from 'moment';
import xIcon from '../images/X_icon.png'
import ErrorModal from './../../Shared/ErrorModal';
import GiftCardsBase from '../GiftCardsBase';
import { bestSaleMeet } from '../shared/sales'
import Loading from '../LottieLoading'
import Success from '../LottieSuccess'
import cardIcon from '../images/Credit_card_icon.png'
import PhoneInput from './../../Shared/PhoneInput';
import { giftCardBuyAnotherEvent, giftCardCheckoutEvent } from '../../Shared/WebAnalytics';
import { authTokenPost } from '../../Shared/Helpers';

class Checkout extends GiftCardsBase {
  constructor(props) {
    super(props);
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_PUBLIC_TOKEN);


    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.sendPixelData = this.sendPixelData.bind(this);
    this.thankYou = this.thankYou.bind(this);
    this.checkout = this.checkout.bind(this)
    this.billingInfo = this.billingInfo.bind(this)
    this.deleteGiftCardCartItem = this.deleteGiftCardCartItem.bind(this)
    this.setGiftCardCart = this.setGiftCardCart.bind(this);
    this.submitBillingForm = this.submitBillingForm.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.testRedirection = this.testRedirection.bind(this);
    this.renderGiftCertificates = this.renderGiftCertificates.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.getCardImageUrl = this.getCardImageUrl.bind(this);
    this.missingInfoMessage = this.missingInfoMessage.bind(this);
    this.checkValidity = this.checkValidity.bind(this);
    this.getAdditionalClass = this.getAdditionalClass.bind(this);

    this.state = {
      width: window.innerWidth,
      cardSet: !!this.props.giftCardCart.checkout.payment.stripeCardId,
      newCard: false,
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      name: '',
      zipcode: '',
      email: '',
      showModal: false,
      error: null,
      loading: false,
      success: false,
      emailIsValid: false,
      phone: '',
      validPhone: false,
      cardNumberValid: false,
      cardNumberInvalid: false,
      cardExpiryValid: false,
      cardExpiryInvalid: false,
      cardCvcValid: false,
      cardCvcInvalid: false,
    };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    authTokenPost()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  testRedirection() {
    if (isEmpty(this.props.giftCardCart.items) && !(this.props.purchasedGiftCardAssortment && this.props.purchasedGiftCardAssortment.id))
      window.location.href = ROUTES.massageCards;
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
  sendPixelData() {
    var deliveryDate = this.formatDate(new Date()) //default date is today

    if (process.env.REACT_APP_PRODUCTION !== 'true') {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    if (this.props.purchasedGiftCardAssortment)
      window.dataLayer.push({
        'event': 'gift_card_purchase',
        'gift_card_id': this.props.purchasedGiftCardAssortment.id,
        'gift_card_total': Number(this.props.purchasedGiftCardAssortment.total.replace(/[^0-9.-]+/g, "")),
        'gift_card_delivery_date': deliveryDate,
        'gift_card_country_code': this.props.giftCardCart.country,
        'gift_card_currency': this.props.giftCardCart.currency || "usd",
        'gift_card_sender_email': this.props.giftCardCart.checkout.senderInfo.email
      });

  }
  renderGiftCertificates() {
    if (this.props.purchasedGiftCardAssortment && !isEmpty(this.props.purchasedGiftCardAssortment.giftCertificates))
      return (<div className="">
        {this.props.purchasedGiftCardAssortment.giftCertificates.map((giftCertificate, index) => {
          let txt = "";
          txt = `${giftCertificate.price} gift card for ${giftCertificate.recipientName}`;
          return (
            <div key={`download-gc-${index}`}>
              <span className="font-cactus-flower-primary">{txt}</span>
            </div>
          )
        })}
      </div>)
    else return null
  }
  thankYou() {
    return (
      <div>
        <div className="p-xlarge bg-primary full-height-section font-style-regular txt-align-center ">
          <div><Success /></div>
          <div className="animation-title font-style-medium mtb-105">Thank you!</div>

          <div className="small-text mb-7vh">The gift card you purchased will arrive in your recipient's inbox to download and print, along with your receipt.</div>

          {this.renderGiftCertificates()}

          <button className="btn cta-btn cactus-flower-primary cta-btn-size mt-7vh" onClick={() => {
            window.location.href = ROUTES.massageCards;
          }}>
            Done</button>
        </div>
      </div>
    )
  }

  setGiftCardCart(value) {
    this.props.setGiftCardCart({
      ...this.props.giftCardCart,
      ...value
    });
  }

  deleteGiftCardCartItem(index) {
    let items = this.props.giftCardCart.items;
    items.splice(index, 1);
    this.setGiftCardCart({ items });
  }
  saleMeet() {
    return !!bestSaleMeet(this.props.giftCardCart.sales, reduce(this.props.giftCardCart.items, function (sum, n) {
      return sum + n.amount;
    }, 0))
  }

  submitOrder() {
    let tempCart = this.props.giftCardCart;
    forEach(tempCart.items, (item, indd) => {
      tempCart.items[indd]["name"] = this.state.name;
      tempCart.items[indd]["email"] = this.state.email;
    })
    axios.post(`${API_ROOT}/gift_cards`,
      {
        gift_card: encode(tempCart, this.saleMeet(), this.props.giftCardCart.currency || "usd")
      }
      , { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result } = resp.data;
        if (result) {
          let giftCertificateAssortment = mapKeys(resp.data.gift_certificate_assortment, (value, key) => camelCase(key));
          giftCertificateAssortment.giftCertificates = giftCertificateAssortment.giftCertificates.map((giftCertificate) => {
            giftCertificate = mapKeys(giftCertificate, (value, key) => camelCase(key));
            giftCertificate.delivery = mapKeys(giftCertificate.delivery, (value, key) => camelCase(key));
            return giftCertificate
          });
          this.props.setPurchasedGiftCardAssortment(giftCertificateAssortment);
          this.sendPixelData();
          giftCardCheckoutEvent(this.props.giftCardCart.country, this.props.giftCardCart.cityName, this.props.purchasedGiftCardAssortment.giftCertificates.length, Number(this.props.purchasedGiftCardAssortment.total.replace(/[^0-9.-]+/g, "")), this.props.purchasedGiftCardAssortment.id)
          this.props.setGiftCardCart(GIFT_CARD_CART_DEFAULT);
          this.setState({ loading: false, success: true }, () => window.scrollTo(0, 0));
        } else {
          this.setState({ loading: false, success: false }, () => window.scrollTo(0, 0));
          let errorMessage = get(resp, 'data.errors[0].message') || defaultErrorMessage;
          this.setState({ showModal: true, error: errorMessage });
        }
      }).catch((error) => {
        this.setState({ loading: false, success: false }, () => window.scrollTo(0, 0));
        let errorMessage = get(error, 'response.data.errors[0].message') || defaultErrorMessage;
        this.setState({ showModal: true, error: errorMessage });
      })
  }

  submitBillingForm(e) {
    e.preventDefault();
    let { name, zipcode, email, emailIsValid, phone, validPhone } = this.state;
    let disabled = !(name && zipcode && email && EMAILREGEX.test(email) && phone && validPhone);

    const that = this;

    let valid = this.validateParams({
      requiredParams: ['name', 'zipcode', 'email', 'phone'],
      params: this.state
    });

    if (valid && !disabled) {
      this.setState({ loading: true, success: false }, () => window.scrollTo(0, 0));
      this.props.giftCardCart.stripe.createToken({
        name: this.state.name,
        address_zip: this.state.zipcode
      }).then(function (result) {
        if (result && result.token) {
          const { card } = result.token;

          that.props.setGiftCardCart({
            ...that.props.giftCardCart,
            checkout: {
              ...that.props.giftCardCart.checkout,
              payment: {
                ...that.props.giftCardCart.checkout.payment,
                stripeCardId: result.token.id,
                last4: card.last4,
                brand: card.brand,
                expMonth: card.exp_month,
                expYear: card.exp_year,
                name: card.name
              },
              senderInfo: {
                ...that.props.giftCardCart.checkout.senderInfo,
                name, zipcode, email, emailIsValid, phone, validPhone
              }
            }
          });
          that.submitOrder()

        } else {
          const { error } = result;
          const message = error.message || defaultErrorMessage;
          that.setState({ loading: false, success: false }, () => window.scrollTo(0, 0))
          that.setState({ showModal: true, error: message });
        }
      })
    }

  }

  getCardImageUrl() {
    if (process.env.REACT_APP_PRODUCTION === 'true') {
      return "https://s3.amazonaws.com/soothe-public-assets/web/gift-cards/live/gift-cards-web.png";
    }
    return "https://s3.amazonaws.com/soothe-staging-public-assets/gift_cards/live/gift-cards-web.png";
  }

  checkout() {
    let items = this.props.giftCardCart.items;
    if (!isEmpty(items))
      return (<div>
        <p className="heading-1  font-style-medium  mb-16">Checkout</p>
        <p className="xs-text-size mb-32 mb-sm-28">Confirm your order details and enter your billing information</p>
        {map(items, (crd, ind) => (<div className="checkout-modal" key={`card-modal-${ind}`}>
          <div>
            <button className="btn dlt-crd-btn" onClick={() => this.deleteGiftCardCartItem(ind)}><img className="img-icon" src={xIcon} /></button>
          </div>
          <div className="txt-align-center p-12-16">
            <img src={this.getCardImageUrl()} className="card-design m-auto" />
          </div>

          <p className="txt-align-center p-24-16 sm-txt-align-left font-style-medium sm-18-24">{crd.currencySymbol || "$"}{crd.amount} Gift card ({crd.deliveryMethod === EMAIL
            ? "Digital" : "Print out"})</p>
          <hr />
          <div className="p-16">
            <label className="sm-16-20">To</label>
            <div className="color-dark-gray xs-text-size">
              {crd.recipientName}
              {crd.recipientEmail && <br />}
              {crd.recipientEmail || null}</div>
          </div>
          {crd.deliveryMethod === EMAIL && crd.deliveryDate ? <div className="p-16">
            <label className="sm-16-20">Deliver</label>
            <div className="color-dark-gray xs-text-size">{moment(crd.deliveryDate).format("dddd, MMMM D, YYYY")}</div>
          </div> : null}
          {crd.message ? <div className="p-16">
            <label className="sm-16-20">Note</label>
            <div className="color-dark-gray xs-text-size">{crd.message}</div>
          </div> : null}
        </div>))}

        <button className="add-gift-card m-10-0 cta-btn-size sm-18-24" onClick={() => {
          giftCardBuyAnotherEvent();
          window.location.href = ROUTES.massageCards;
        }}><img src={plusIcon} className="plus-icon" />
          Add another gift card</button>
      </div>)
    else return <div>
      <p className="heading-1  font-style-medium  mb-16">Your cart is empty</p>
      <p className="xs-text-size mb-32 mb-sm-28">Share the gift of wellness with someone you appreciate.</p>
      <button className="add-gift-card m-10-0 cta-btn-size sm-18-24" onClick={() => {
        giftCardBuyAnotherEvent();
        window.location.href = ROUTES.massageCards;
      }}><img src={plusIcon} className="plus-icon" />
        Add gift card</button>
      {/* todo revisit amp event */}
      {/* todo don't we have an event for remove too? */}
    </div>
  }

  getAdditionalClass(isValid, exists = false) {
    if (isValid) {
      return "success";
    } else if (exists) {
      return "erroneous";
    }
    return "";
  }

  checkValidity(typeName, objVal) {
    this.setState({
      [`${typeName}Valid`]: Boolean(objVal.complete),
      [`${typeName}Invalid`]: Boolean(!(objVal.complete || objVal.empty))
    })
  }

  missingInfoMessage() {
    let msg = null;
    let { email, phone, validPhone, emailIsValid, cardNumberInvalid, cardExpiryInvalid, cardCvcInvalid } = this.state
    let display = (email && !emailIsValid) || cardNumberInvalid || cardExpiryInvalid || cardCvcInvalid || (phone && !validPhone);
    if (display) {
      msg = (<div className='missing-info-message'>Missing information. Please fix the errors to continue.</div>);
    }
    return msg;
  }

  billingInfo() {
    let { name, zipcode, email, phone, validPhone, emailIsValid } = this.state;
    let disabled = !(name && zipcode && emailIsValid && validPhone && !isEmpty(this.props.giftCardCart.items));

    let items = this.props.giftCardCart.items;
    let curr = this.props.giftCardCart.currencySymbol || "$";
    const fonts = [{ src: 'url("https://www.soothe.com/static/media/GothamHTF-Book.48680770.otf")', family: 'gotham' }];
    let countryCode = ACTIVE_LANGUAGE_CODE === DE ? DE : US;

    let total = reduce(items, function (sum, n) {
      return sum + n.amount;
    }, 0)
    return (
      <Translate>
        {({ translate }) => (<>
          <p className="heading-1 font-style-medium mb-16">Billing Information</p>
          <form onSubmit={this.submitBillingForm}>

            <Elements locale={countryCode} fonts={fonts}>
              <div className="row ">

                <div className="col-xs-12 col-sm-12 mb-24 sm-16-20">
                  <div className={`form-group txt-align-left ${this.getAdditionalClass(emailIsValid, Boolean(email))}`}>
                    <label htmlFor="email" className="">Your email*</label>
                    <input type="email" id="email" className="form-control input-style height-48 m-0" value={email} onChange={(event) => {
                      let emailVal = event.target.value;
                      this.setState({ email: emailVal, emailIsValid: emailVal && EMAILREGEX.test(emailVal) })
                    }} />
                    <img className="input-checkmark" src={checkMark} />
                    <img className="input-xmark" src={xMark} />
                  </div>
                  <span className="small-gray-text">For your receipt and for print out gift cards.</span>

                </div>
                <div className="col-xs-12 col-sm-12 sm-16-20">
                  <div className={`form-group txt-align-left ${this.getAdditionalClass(name)}`}>
                    <label htmlFor="name" className="">Your name*</label>
                    <input type="text" id="name" className="form-control input-style height-48" value={name} onChange={(event) => { this.setState({ name: event.target.value }) }} />
                    <img className="input-checkmark" src={checkMark} />
                    <img className="input-xmark" src={xMark} />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 with-icon sm-16-20">

                  <label>
                    Card number*
                  </label>
                  <CardNumber onChange={(obj) => {
                    this.checkValidity("cardNumber", obj);
                  }} />
                  <img className="input-checkmark" src={checkMark} />
                  <img className="input-xmark" src={xMark} />
                  <img className="img-icon sm-7-left" src={cardIcon} />
                </div>
                <div className="col-xs-12 col-sm-6 sm-16-20">
                  <label>
                    Expiration date*
                  </label>
                  <CardExpirationDate onChange={(obj) => {
                    this.checkValidity("cardExpiry", obj);
                  }} />
                  <img className="input-checkmark" src={checkMark} />
                  <img className="input-xmark" src={xMark} />
                </div>
                <div className="col-xs-12 col-sm-6 sm-16-20">
                  <label>
                    CVC*</label>
                  <CardCVC onChange={(obj) => {
                    this.checkValidity("cardCvc", obj);
                  }} />
                  <img className="input-checkmark" src={checkMark} />
                  <img className="input-xmark" src={xMark} />
                </div>


                <div className="col-xs-12 col-sm-12 sm-16-20">
                  <div className={`form-group txt-align-left ${this.getAdditionalClass(zipcode)}`}>
                    <label htmlFor="zipcode" className="">Zip code*</label>
                    <input type="text" id="zipcode" className="form-control input-style height-48" value={zipcode} onChange={(event) => { this.setState({ zipcode: event.target.value }) }} />
                    <img className="input-checkmark" src={checkMark} />
                    <img className="input-xmark" src={xMark} />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 mb-30 sm-16-20">
                  <div className={`form-group txt-align-left ${this.getAdditionalClass(validPhone, Boolean(phone))}`}>
                    <label htmlFor="phone">Phone number*</label>

                    <div>
                      <PhoneInput phone={phone} onPhoneNumberChange={({ isValidNumber, number }) => {
                        this.setState({ phone: isValidNumber ? `+${replace(number, /\D/g, "")}` : number, validPhone: isValidNumber })
                      }} className={`form-control input-style height-48 ${validPhone ? 'valid' : 'invalid'}`} />
                      <img className="input-checkmark" src={checkMark} />
                      <img className="input-xmark" src={xMark} />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12">
                  <p className="heading-1 font-style-medium">Order Summary</p>
                  <div className='display-flex-2 sm-16-20 mtb-sm-6 mtb-12' >
                    <div className={`item flex-1`}>{capitalize(translate('global.subtotal'))}</div>
                    <div className={`amount `}>{curr}{total}</div>
                  </div>
                  <div className='display-flex-2 sm-16-20 mtb-sm-6 mtb-12' >
                    <div className={`item flex-1`}>Delivery</div>
                    <div className={`amount `}>Free</div>
                  </div>
                  <div className='hr-container'><hr /></div>

                  <div className='display-flex-2 font-style-medium sm-16-20'>
                    <div className='item flex-1'>{capitalize(translate('global.total'))}</div>
                    <div className='amount total-amount'>{curr}{total}</div>
                  </div>
                  <span className="small-gray-text">By placing this order, you agree to the <a href="https://www.soothe.com/terms-and-conditions/">Terms of Service</a>, and <a href="https://www.soothe.com/privacy-policy/">Privacy Policy</a>.</span>

                  <div className="small-gray-text txt-align-left mb-0 mr-0 ml-0">*Note: Soothe Gift Cards are not redeemable for cash and can not be used to purchase physical products in the Soothe Shop.</div>
                  {this.missingInfoMessage()}
                  <button className={`btn cta-btn cactus-flower-primary m-10-0 cta-btn-size ${disabled ? "disabled" : ""}`} type="submit"
                  ><img src={disabled ? grayLock : whiteLock} className="plus-icon" /> Place order: {curr}{total}
                  </button>
                </div>
              </div>
            </Elements>
          </form>
        </>)}</Translate>)
  }

  errorModal() {
    return (
      <ErrorModal isOpen={this.state.showModal} close={() => { this.setState({ showModal: false, error: null }); }}>
        <p>{this.state.error}</p>
      </ErrorModal>
    )
  }
  render() {
    let { loading, success } = this.state;
    return (
      <div className="max-w-100" id="gc-checkout">
        <HelmetTag />
        <NavBar giftCardNav={true} cardsTotal={this.props.giftCardCart.items ? this.props.giftCardCart.items.length : 0} />

        {success && !loading && this.props.purchasedGiftCardAssortment && this.props.purchasedGiftCardAssortment.id ?
          this.thankYou()
          :
          <div className={`row m-auto equal ${!success && loading ? "d-none" : ""}`}>
            <div className="col-xs-12 col-sm-6 p-small bg-secondary full-height-section font-style-regular ">{this.checkout()}</div>
            <div className="col-xs-12 col-sm-6 p-small bg-primary full-height-section font-style-regular pt-sm-24">{this.billingInfo()}</div>
          </div>
        }

        {!success && loading &&
          <div className=" bg-primary full-height-section"><Loading />
          </div>
        }

        {this.errorModal()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart,
  purchasedGiftCardAssortment: state.purchasedGiftCardAssortment
});

export default withLocalize(connect(mapStateToProps, { setGiftCard, setGiftCardCart, setPurchasedGiftCardAssortment })(Checkout));