/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import './LatestModalDesign.css';
import { hasUuid } from '../../../constants';

class LatestModalDesign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  }

  render() {
    const {
      title, subtitle, disabled, hideBackArrow, hideCancel, hideApply,
      hideFooter, customIcon, customFooter, fullWidthFooter,
      wrapperExtraClass, contentExtraClass,
      footerExtraClass, relevantApplyId, popupId, noSmMarginB,
    } = this.props;
    if (this.props.isOpen) {
      return (
        <div
          className={`latest-design modal-wrapper ${wrapperExtraClass || ''} 
        ${!hasUuid() && this.state.width <= 800 ? 'pt-0' : ''}`}
          {...(popupId ? { id: popupId } : {})}
        >
          <div className={`modal-content ${contentExtraClass || ''}`}>
            {hideBackArrow ? null : (
              <div>
                <a
                  className="back-arrow-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.props.back) {
                      this.props.back();
                    } else {
                      this.props.close();
                    }
                  }}
                >
                  {customIcon || <ArrowBack />}
                </a>
              </div>
            )}
            {title ? <div className="modal-title">{title}</div> : null}
            {subtitle ? <div className="modal-subtitle">{subtitle}</div> : null}
            <div className={noSmMarginB ? '' : 'sm-margin-50'}>{this.props.children}</div>
            {hideFooter ? null : customFooter || (fullWidthFooter
              ? (
                <div className={`bottom-sticky bottom-minus-25 bg-primary p-16 br-t-opaque mx-minus-24 ${footerExtraClass || ''}`}>
                  {hideApply ? null : (
                    <a
                      {...(relevantApplyId ? { id: relevantApplyId } : {})}
                      className={`btn modal-apply-link contentInversePrimary full-w-100 medium-font ${disabled ? 'disabled' : ''}`}
                      onClick={(e) => { if (!disabled) { this.props.apply(e); } }}
                    >
                      {this.props.applyBtnCopy
                        ? this.props.applyBtnCopy : 'Apply'}
                    </a>
                  )}
                  {hideCancel ? null : (
                    <button
                      className="btn btn-link cta-secondary sm-btn-display full-w-100 mt-8"
                      onClick={(e) => { e.preventDefault(); this.props.close(); }}
                    >
                      {this.props.cancelBtnCopy ? this.props.cancelBtnCopy : 'Back'}
                    </button>
                  )}
                </div>
              )
              : (
                <div className={`txt-align-right margin-top-40 sm-fix ${footerExtraClass || ''}`}>
                  {hideCancel ? null : (
                    <a
                      className="modal-cancel-link sm-btn-display"
                      onClick={(e) => { e.preventDefault(); this.props.close(); }}
                    >
                      {this.props.cancelBtnCopy ? this.props.cancelBtnCopy : 'Back'}
                    </a>
                  )}
                  {hideApply ? null : (
                    <a
                      {...(relevantApplyId ? { id: relevantApplyId } : {})}
                      className={`btn modal-apply-link contentInversePrimary sm-btn-display ${disabled ? 'disabled' : ''}`}
                      onClick={(e) => { if (!disabled) { this.props.apply(e); } }}
                    >
                      {this.props.applyBtnCopy
                        ? this.props.applyBtnCopy : 'Apply'}
                    </a>
                  )}
                </div>
              ))}
          </div>
        </div>
      );
    }
    return null;
  }
}
export default withLocalize(LatestModalDesign);
