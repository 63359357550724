/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get, replace } from 'lodash';
import Delete from '@material-ui/icons/Delete';
import ColumnsList from '../../Shared/ColumnsList';
import { hasUuid } from '../../../constants';

class ServicePerPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.relevantPriceDisplay = this.relevantPriceDisplay.bind(this);
    this.relevantMemberPriceDisplay = this.relevantMemberPriceDisplay.bind(this);
    this.displayColumns = this.displayColumns.bind(this);
    this.displayActions = this.displayActions.bind(this);
  }

  relevantPriceDisplay(cartProductDetails) {
    const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false);
    const isSoothePassAvailable = get(this.props, 'booking.cart.info_fields.soothe_pass.available', false) && !hasUuid();
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);
    const subscription_membership_ends_date = get(this.props, 'client.soothe_pass.subscription_membership_ends_date', '');
    let nonMemberPrice;
    if (isSoothePassAvailable && (alreadyAppliedToCart
        || isSoothePassSubscribed || subscription_membership_ends_date)) {
      nonMemberPrice = <span className="gray-strike-through">{get(cartProductDetails, 'cart_product_main_option.price', '')}</span>;
    } else {
      nonMemberPrice = <span>{get(cartProductDetails, 'cart_product_main_option.price', '')}</span>;
    }
    return (
      <div>
        {nonMemberPrice}
        {' '}
        {this.relevantMemberPriceDisplay(cartProductDetails)}
      </div>
    );
  }

  relevantMemberPriceDisplay(cartProductDetails) {
    const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false);
    const isSoothePassAvailable = get(this.props, 'booking.cart.info_fields.soothe_pass.available', false) && !hasUuid();
    const alreadyAppliedToCart = get(this.props, 'booking.cart.info_fields.soothe_pass.subscription_applied_to_cart', false);
    const price_with_soothe_pass = get(cartProductDetails, 'cart_product_main_option.member_price', '');
    const subscription_membership_ends_date = get(this.props, 'client.soothe_pass.subscription_membership_ends_date', '');
    if (price_with_soothe_pass && isSoothePassAvailable) {
      if (alreadyAppliedToCart) {
        return <span className="color-green">Free with SoothePoints at checkout</span>;
      } if (isSoothePassSubscribed || subscription_membership_ends_date) {
        return <span>{price_with_soothe_pass}</span>;
      }
      return (
        <>
          |
          <a
            className="cursor-pointer"
            onClick={() => {
              this.props.openPlanPopup();
            }}
          >
            {price_with_soothe_pass}
            {' '}
            with SoothePass™
          </a>
        </>
      );
    }
  }

  displayColumns(cartProductDetails) {
    const papAndIc = get(this.props, 'booking.cart.info_fields.marketplace_enabled', false) && get(this.props, 'booking.instant_confirmation', false);
    return (
      <ColumnsList
        headerText={<div className="medium-font overflow-wrap-break-word">{`${cartProductDetails.client_name}'s ${cartProductDetails.product.title}`}</div>}
        itemsList={[{
          text: papAndIc ? replace(get(cartProductDetails, 'subtitle', ''), 'Requested', 'Instant Book') : get(cartProductDetails, 'subtitle', ''),
        }, {
          text: (this.relevantPriceDisplay(cartProductDetails)),
        }]}
      />
    );
  }

  displayActions(moreThanOnePerson, cartProductDetails) {
    if (moreThanOnePerson) {
      return (
        <div className="col-xs-2 col-sm-1">
          <button
            type="button"
            className="btn edit-icon"
            onClick={() => this.props.deleteRelevantService(cartProductDetails.id)}
          >
            <Delete />
          </button>
        </div>
      );
    }
    return null;
  }

  render() {
    const cartProductDetails = this.props.cartProduct;
    if (!isEmpty(cartProductDetails)) {
      const moreThanOnePerson = get(this.props, 'booking.cart.cartProducts', []).length > 1;
      return (
        <div className="row">
          <div className={moreThanOnePerson ? 'col-xs-10 col-sm-11' : 'col-xs-12'}>
            {this.displayColumns(cartProductDetails)}
          </div>
          {this.displayActions(moreThanOnePerson, cartProductDetails)}
        </div>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

ServicePerPerson.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(ServicePerPerson));
