import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import { HotJar } from '../../../constants.js';
import { assign, map } from 'lodash'
import whiteArrow from "./../images/Arrow_down_white.png"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { HOLIDAY, GIFT_CARD_OCCASION_IMAGES } from '../shared/constants';

HotJar();
class Step2 extends React.Component {
  constructor(props) {
    super(props);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.selectDesign = this.selectDesign.bind(this);

    this.state = {
      width: 0,
      selectedImage: HOLIDAY,
      nav1: null,
      nav2: null,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  selectDesign(selection) {
    this.props.setGiftCard(assign(
      this.props.giftCard,
      { occasion: selection }
    ));
  }
  getSlides(allowSelection) {
    if (allowSelection)
      return (map(GIFT_CARD_OCCASION_IMAGES, (el, it) => (<div className="p-10" key={`img-${it}`} onClick={() => { this.setState({ selectedImage: it }) }}>
        <img className={`m-auto ${this.state.selectedImage === it && allowSelection ? 'selected-type' : ''} ${allowSelection ? 'border-radius-10  max-height-70 ' : 'height-280 br-16'}`} src={el} alt={`slide-${(it)}`} />
      </div>)))
    else return (<div className="p-10">
      <img className={`m-auto height-280 br-16`} src={GIFT_CARD_OCCASION_IMAGES[this.state.selectedImage]} alt={`selected-occasion`} />
    </div>)
  }
  render() {
    let { settings } = this.state;
    return (
      <div className="full-height-section p-large bg-primary font-style-medium txt-align-center step2" id="2">
        <p className="steps-indicator light-mode-secondary">Step 2 of {this.props.totalSteps}</p>
        <p className="title-1">Choose your occasion</p>
        <div>
          <Slider {...settings}
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            arrows={false}
          >
            {this.getSlides()}
          </Slider>
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={4}
            swipeToSlide={true}
            focusOnSelect={true}
          >{this.getSlides(true)}
          </Slider>
        </div>
        <p className=""><button className="btn cta-btn cactus-flower-primary" onClick={() => {
          this.selectDesign(this.state.selectedImage)
          this.props.updateFieldAndStep("giftCardDesign", this.state.selectedImage, 3)
        }}>Select <img className="img-icon" src={whiteArrow} /></button></p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart
});

export default withLocalize(connect(mapStateToProps, { setGiftCard, setGiftCardCart })(Step2));
