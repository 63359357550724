/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { times, map } from 'lodash';
import moment from 'moment';
import './weeklyHorizontalCalendar.scss';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

class WeeklyHorizontalCalendar extends React.Component {
  // TODO this view does not support b2b working hours exclusion
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: props.defaultSelectedDate,
      datesList: [],
      startOfWeek: props.defaultSelectedDate.clone().subtract(props.defaultSelectedDate.day(), 'days'),
      endOfWeek: props.defaultSelectedDate.clone().subtract(props.defaultSelectedDate.day(), 'days').add(7, 'days'),

    };
    this.daysTable = this.daysTable.bind(this);
    this.dayLink = this.dayLink.bind(this);
    this.headerCopy = this.headerCopy.bind(this);
    this.refreshDaysList = this.refreshDaysList.bind(this);
  }

  componentDidMount() {
    this.refreshDaysList();
  }

  refreshDaysList() {
    const selection = moment(this.state.startOfWeek);
    const arr = [];

    times(7, (ind) => {
      arr.push(selection.clone().add(ind, 'days'));
    });
    this.setState({ datesList: arr });
  }

  headerCopy() {
    const { startOfWeek, endOfWeek } = this.state;
    const isSameMonth = startOfWeek.isSame(endOfWeek, 'month') && startOfWeek.isSame(endOfWeek, 'year');
    return `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format(isSameMonth ? 'DD' : 'MMMM DD')}`;
  }

  dayLink(dateEl) {
    const { selectedDate } = this.state;
    const currentMmnt = moment().startOf('day');
    return (
      <div className={`${dateEl.isSame(selectedDate) ? 'selected-day-horizontal' : ''} d-inline-block`}>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            if (dateEl.isBefore(currentMmnt)) {
              return;
            }
            this.setState({ selectedDate: dateEl }, () => this.props.sendToParent(dateEl));
          }}
        >
          <div className="black-color size-16-24">{dateEl.format('D')}</div>
        </button>
      </div>
    );
  }

  daysTable() {
    const { datesList } = this.state;
    return map(datesList, (dateEl, ind) => (
      <td scope="col" key={`date-col-${ind}`} className="dark-grayed fix-col-width">
        <div className="medium-font mb-9 size-16-20 dark-grayed">{dateEl.format('dd')}</div>
        {this.dayLink(dateEl)}
      </td>
    ));
  }

  render() {
    const { startOfWeek, endOfWeek } = this.state;
    return (
      <nav id="time-scroller">
        <div className="d-flex-only justify-content-spaced align-items-center mb-12">
          <div>
            <button
              type="button"
              className="btn btn-link contentPrimary p-14"
              onClick={() => {
                const isNowInThisWeek = moment().isBetween(startOfWeek, endOfWeek);
                if (isNowInThisWeek) {
                  return;
                }
                this.setState({
                  startOfWeek: startOfWeek.clone().subtract(1, 'weeks'),
                  endOfWeek: endOfWeek.clone().subtract(1, 'weeks'),
                }, this.refreshDaysList);
              }}
            >
              <ArrowBackIos className="w-h-20-imp" />
            </button>
          </div>
          <div className="size-18-24 contentPrimary font-weight-bold txt-center ptb-13">{this.headerCopy()}</div>
          <div>
            <button
              type="button"
              className="btn btn-link contentPrimary p-14"
              onClick={() => {
                this.setState({
                  startOfWeek: startOfWeek.clone().add(1, 'weeks'),
                  endOfWeek: endOfWeek.clone().add(1, 'weeks'),
                }, this.refreshDaysList);
              }}
            >
              <ArrowForwardIos className="w-h-20-imp" />
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-borderless text-align-center mb-0">
            <tbody>
              <tr>
                {this.daysTable()}
              </tr>
            </tbody>
          </table>
        </div>
      </nav>
    );
  }
}

export default WeeklyHorizontalCalendar;
