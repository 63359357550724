import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { defaultErrorMessage } from './../../../../constants';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../../apiConfig';
import { setCreditCard } from '../../../../Actions';
import ErrorModal from './../../../Shared/ErrorModal';
import '../Assets/Styles/CreditCardModal.css';
import ccIconCcv from '../Assets/Images/cc_icon_ccv.png';
// *DO NOT REMOVE 'CardElement' from import below
import { CardElement, CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import Modal from './../../../Shared/Modal';

class AddCreditCardModal extends React.Component {
  constructor(props) {
    super(props);

    this.setCreditCard = this.setCreditCard.bind(this);
    this.prev = this.prev.bind(this);
    this.save = this.save.bind(this);
    this.setCreditCardNumber = this.setCreditCardNumber.bind(this);
    this.setCreditCardExp = this.setCreditCardExp.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.renderMainModal = this.renderMainModal.bind(this);

    this.props.setCreditCard(_.merge({
      name: '',
      number: '',
      exp: '',
      cvc: '',
      zipCode: '',
      stripe: this.props.stripe
    }, this.props.creditCard || {}));


    this.state = {
      submitted: false,
      showErrorModal: false,
      error: null
    };
  }

  setCreditCard(params) {
    this.props.setCreditCard(_.merge({}, this.props.creditCard, params))
  }

  prev() {
    this.props.prev();
  }

  save(event) {
    event.preventDefault();
    let loader=this.props.setLoaderFlag;
    if (loader) {
      loader(true)
    }

    const that = this;
    this.props.creditCard.stripe.createToken({
      name: this.props.creditCard.name,
      address_zip: this.props.creditCard.zipCode
    }).then(function(result) {

      if (result && result.token) {
        const { card } = result.token;

        axios.post(`${API_ROOT}/credit_cards/create_with_user_id/`, {
          credit_card: {
            last_4: card.last4,
            brand: card.brand,
            id: card.id,
            stripe_id: result.token.id,
            name: card.name,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            user_id: that.props.client.user_id
          }
        }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
          if (loader) {
            loader(false)
          }
          const { id, success, result } = resp.data;

          if (success || result) {
            that.props.reload(id);
            that.props.close();
          } else {
            const message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
            that.setState({ showErrorModal: true, error: message });
          }
        }).catch((error) => {
          if (loader) {
            loader(false)
          }
          //console.log(error)
          if (error && error.response && error.response.data) {
            const message = _.result(_.first(error.response.data.errors || {}), 'message') || _.result(error.response.data.errors || {}, 'message') || defaultErrorMessage;
            that.setState({ showErrorModal: true, error: message });
          } else if (error) {
            const message = _.result(_.first(error || {}), 'message') || defaultErrorMessage;
            that.setState({ showErrorModal: true, error: message });
          } else {
            that.setState({ showErrorModal: true, error: defaultErrorMessage });
          }

        });
      } else {
        if(loader){
        loader(false)}
        const { error } = result;
        const message = error.message || defaultErrorMessage;
        that.setState({ showErrorModal: true, error: message });
      }
    });
  }

  setCreditCardNumber(newCreditCardNumber) {
    newCreditCardNumber = newCreditCardNumber.trim().replace(/\W/gi, '').substring(0, 16).replace(/(.{4})/g, '$1 ').trim();
    this.setCreditCard({ number: newCreditCardNumber });
  }

  setCreditCardExp(exp) {
    exp = exp.trim().replace(/\W/gi, '').substring(0, 4).replace(/(.{2})/, '$1/').trim();
    this.setCreditCard({ exp });
  }

  errorModal() {
    return (
      <ErrorModal isOpen={this.state.showErrorModal} close={ () => { this.setState({ showErrorModal: false, error: null }); } }>
        <p>{this.state.error}</p>
      </ErrorModal>
    )
  }
  renderMainModal(content) {
    return (
      <Translate>
        {({ translate }) => (
        <Modal title={translate('bookingFlow.addPaymentMethod')} isOpen={true} close={() => { this.prev() }}>
          <div style={{"textAlign":"left"}}>
          {content}
          </div>
        </Modal>
        )}
      </Translate>
    )
  }

  render() {
    let content=(<Translate>
      {({ translate }) =>
        <>
          <div className='content-add-card'>
            <form onSubmit={this.save}>
              <div className='col-xs-12'>
                <label>{translate('formData.nameOnCard')}</label>
                <input
                  type='text'
                  value={this.props.creditCard.name || ''}
                  data-stripe='name'
                  size='20'
                  className='form-control'
                  onChange={(event) => { this.setCreditCard({ name: event.target.value }); }}
                  required
                />
              </div>
              <div className='col-xs-12'>
                <label className='stripe-elements-label'>{translate('formData.cardNumber')}</label>
                <CardNumberElement
                  classes={{
                    base: "stripe-cardnumber-element",
                  }}
                  style={{
                    base: {
                      fontSize: '16px',
                      fontFamily: 'Poppins',
                      fontWeight: '300',
                      lineHeight: '30px',
                      color: '#2C3239'
                    }
                  }}
                />
              </div>
              <div className='col-xs-6'>
                <label className='stripe-elements-label'>{translate('formData.expirationDate')}</label>
                <CardExpiryElement
                  classes={{
                    base: "stripe-expiration-element",
                  }}
                  style={{
                    base: {
                      fontSize: '16px',
                      fontFamily: 'Poppins',
                      fontWeight: '300',
                      lineHeight: '30px',
                      color: '#2C3239'
                    }
                  }}
                />
              </div>
              <div className='col-xs-6'>
                <label className='stripe-elements-label'>{translate('formData.cvc')}</label>
                <CardCVCElement
                  classes={{
                    base: "stripe-cvc-element",
                  }}
                  style={{
                    base: {
                      fontSize: '16px',
                      fontFamily: 'Poppins',
                      fontWeight: '300',
                      lineHeight: '30px',
                      color: '#2C3239'
                    }
                  }}
                />
                <img src={ccIconCcv} className='cc_icon_ccv stripe-elements-cc-icon-layout' alt='cc_icon_ccv' />
              </div>
              <div className='col-xs-6'>
                <label>{translate('formData.zipPostalCode')}</label>
                <input
                  type='text'
                  value={this.props.creditCard.zipCode || ''}
                  placeholder=''
                  data-stripe='address_zip'
                  className='form-control'
                  onChange={(event) => { this.setCreditCard({ zipCode: event.target.value }); }}
                  required
                />
              </div>
              <div className='add-card-container'>
                <input type='submit' className='btn active form-control add-credit-card' value={_.capitalize(translate('bookingFlow.addCard'))} />
              </div>
            <div className='add-card-container'>
              <button className='btn modal-cancel-button-custom' onClick={ (e) => { e.preventDefault(); this.prev(); } }>Cancel</button>
            </div>
            </form>
          </div>
        </>
      }
    </Translate>);
    if (this.props.showAddCreditCard  === true) {
      return (
        <div className='overlay creditCardsModal'>
          <div className='popup'>
            {this.renderMainModal(content)}
          </div>
          {this.errorModal()}
        </div>
      );
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = state => ({
  booking: state.booking,
  client: state.client,
  creditCard: state.creditCard
});

AddCreditCardModal.propTypes = {
  client: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  setCreditCard: PropTypes.func.isRequired
};

export default withLocalize(injectStripe(connect(mapStateToProps, { setCreditCard })(AddCreditCardModal)));
