/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import { Close } from '@material-ui/icons';
import React from 'react';
import LatestModalDesign from '../Shared/LatestModalDesign';
import SimpleCheck from '../Shared/Lotties/SimpleCheck';

export default class SuccesModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <LatestModalDesign
        isOpen={this.props.isOpen}
        hideFooter
        customIcon={<Close />}
        close={this.props.close}
      >
        <div className="centered mtb-32">
          <div className="mb-32 d-flex-only justify-content-centered"><SimpleCheck className="width-82" /></div>
          <div className="size-20-28 font-weight-bold contentPrimary mb-12">Message sent</div>
          <div className="size-12-20 contentTertiary mb-6 c-plr-64">Your issue has been submitted to Soothe&#8217;s Trust & Safety department. An agent will contact you shortly to gather more details and take action accordingly.</div>
          <button type="button" className="btn btn-accent-light" onClick={this.props.close}>Done</button>
        </div>
      </LatestModalDesign>
    );
  }
}
