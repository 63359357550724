import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import Datetime from 'react-datetime';
import DatetimeWrapper from '../../Shared/DatetimeWrapper';
import { HotJar } from '../../../constants.js';
import "../index.css";
import { giftCardDeliveryEvent } from '../../Shared/WebAnalytics';
import whiteArrow from "../images/Arrow_down_white.png";

HotJar();
const cardTypes = [{ type: "digital", title: "Digital", description: "Send directly by email on the date of your choosing" }, { type: "print_at_home", title: "Print at home", description: "Place in an envelope and hand it to someone you appreeciate" }, { type: "buy_in_bulk", title: "Buy in bulk", description: "Share the gift of wellness with employees or clients" }]
class Step5 extends React.Component {
  constructor(props) {
    super(props);


    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.updateGiftCard = this.updateGiftCard.bind(this);

    this.state = { width: 0 };

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  isValidDate(date) {
    const yesterday = Datetime.moment().subtract(1, 'day');
    return date.isAfter(yesterday)
  }
  updateGiftCard(deliveryDate) {
    giftCardDeliveryEvent(this.props.giftCard.deliveryMethod, this.props.giftCardCart.country, this.props.giftCardCart.cityName, this.props.giftCard.minutes, this.props.giftCard.amount, deliveryDate)
    // todo any change on amp events?
    this.props.setGiftCard({
      ...this.props.giftCard,
      deliveryDate
    });
  }
  render() {
    let { deliveryDate } = this.props;
    return (
      <div className={`full-height-section p-large bg-secondary font-style-medium txt-align-center step5`} id="step5">
        <div className="steps-indicator  light-mode-secondary">Step 3 of {this.props.totalSteps}</div>
        <div className="title-1 mb-24 mb-sm-0">When should we deliver it?</div>
        <div className="text font-style-regular mb-52 sm-custom-text mb-sm-23">You pick the date, we’ll email the gift.</div>
        <form onSubmit={(e) => {
          e.preventDefault();
          if (deliveryDate) {
            this.props.updateFieldAndStep('deliveryDate', deliveryDate, 6);
          }
        }}>
          <div className={`form-group`}>
            <DatetimeWrapper dateFormat='MM/DD/YYYY' open={true} input={false}
              value={this.props.deliveryDate}
              timeFormat={false}
              onChange={(date, deliveryDate) => {
                this.updateGiftCard(deliveryDate)
                this.props.updateField('deliveryDate', deliveryDate)
              }}
              isValidDate={this.isValidDate} />
          </div>
          {deliveryDate ? <button type='submit' className={`btn cta-btn cactus-flower-primary mt-30`}
          >Select <img className="img-icon" src={whiteArrow} />
          </button> : null}
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  giftCard: state.giftCard,
  giftCardCart: state.giftCardCart
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setGiftCard, setGiftCardCart }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step5));
