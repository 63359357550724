/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  Cancel, Close, Visibility, VisibilityOff,
} from '@material-ui/icons';
import { get, isEmpty, map } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import LatestModalDesign from './LatestModalDesign';
import EmailSentIcon from '../../Assets/Images/Email_sent.svg';
import AlertIcon from '../../Assets/Images/Danger_yellow_icon.svg';
import { defaultErrorMessage, generalCheckPassRules, hasUuid } from '../../constants';
import ConfirmationLottie from './ConfirmationLottie';
import CTAButton from './CTAButton';
import ErrorModal from './ErrorModal';
import { resetPasswordSubmission, triggerResetPasswordEmail } from './Helpers';
import {
  seResetPasswordEmailSent, seResetPasswordFinalScreen,
  seResetPasswordForcedFlowView, seResetPasswordInitiate, seResetPasswordPasswordComplete,
} from './WebAnalytics';
import checkMark from '../../Assets/Images/Check_mark.png';

class ResetPasswordPopups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: false,
      currentPassword: '',
      password: '',
      confirmPassword: '',
      showCurrentPass: false,
      showPass: false,
      showConfirmPass: false,
      passwordsMatch: true,
      listOfPassMistakes: [],
      alreadyInUse: false,
      authUserReset: false,
      unauthUserReset: false,
      emailSentUI: false,
      deepLinkUI: false,
      showSuccessModal: false,
      showErrorModal: false,
      error: null,
      tokenExpiry: null,
      resetToken: '',
      linkSent: false,
      showPassInputModal: false,
      showEmailSentPopup: false,
      showTokenExpiryPopup: false,
      showKeepSafePopup: false,
    };
    this.resetEmailSentModal = this.resetEmailSentModal.bind(this);
    this.passwordInputModal = this.passwordInputModal.bind(this);
    this.checkPassRules = this.checkPassRules.bind(this);
    this.resetCompleteModal = this.resetCompleteModal.bind(this);
    this.keepYourAccountSafeModal = this.keepYourAccountSafeModal.bind(this);
    this.tokenExpiryPopup = this.tokenExpiryPopup.bind(this);
    this.renderErrorModal = this.renderErrorModal.bind(this);
    this.resendLink = this.resendLink.bind(this);
    this.submitNewPass = this.submitNewPass.bind(this);
    this.initiatePopupFlags = this.initiatePopupFlags.bind(this);
  }

  componentDidMount() {
    const parsedParams = queryString.parse(window.location.search);
    const tokenExpiry = get(parsedParams, 'token_expiry', null);
    const resetToken = get(parsedParams, 'reset_password_token', '');
    this.setState({ tokenExpiry, resetToken }, this.initiatePopupFlags);
  }
  // let testUrl= "https://soothe.onelink.me/9Hnv/kk9qd5s7?reset_password_token=N2x2Jt2xiZroezkcEiHx&token_expiry=2023-10-02T19:23:28-0700"

  initiatePopupFlags() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const { resetToken, tokenExpiry } = this.state;
    if (resetToken && tokenExpiry && moment(tokenExpiry).isSameOrBefore(moment(new Date()))) {
      this.setState({ showTokenExpiryPopup: true });
    } else if (resetToken && tokenExpiry && moment(tokenExpiry).isAfter(moment(new Date()))) {
      this.setState({ showPassInputModal: true }, () => {
        seResetPasswordFinalScreen(loggedIn ? 'Forced Flow' : 'Log In', loggedIn);
      });
    } else if (loggedIn && get(this.props, 'client.force_password_reset', false)) {
      seResetPasswordForcedFlowView();
      this.setState({ showKeepSafePopup: true });
    } else if (!loggedIn && get(this.props, 'showEmailSent', false)) {
      this.setState({ showEmailSentPopup: true });
    }
  }

  checkPassRules() {
    const { password } = this.state;
    this.setState({ listOfPassMistakes: generalCheckPassRules(password) });
  }

  resendLink(user) {
    const loggedIn = get(this.props, 'client.loggedIn', false); const
      self = this;
    triggerResetPasswordEmail(user, () => {
      this.setState({ linkSent: true, showEmailSentPopup: true, showPassInputModal: false }, () => {
        setTimeout(() => {
          self.setState({ linkSent: false });
        }, 10000);
      });
      seResetPasswordEmailSent(loggedIn ? 'Forced Flow' : 'Log In', loggedIn);
    }, (err) => {
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', '') || get(err, 'response.data.errors.message', defaultErrorMessage) });
    });
  }

  submitNewPass() {
    const { currentPassword, password, resetToken } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    resetPasswordSubmission({
      email: get(this.props, 'client.email', ''),
      current_password: currentPassword,
      password,
      reset_password_token: resetToken,
    }, () => {
      this.setState({ showSuccessModal: true }, () => {
        this.setState({ showPassInputModal: false });
      });
      seResetPasswordPasswordComplete(loggedIn ? 'Forced Flow' : 'Log In', loggedIn);
    }, (err) => {
      if (get(err, 'response.data.errors.0.code', '') === 'password_must_be_different') {
        this.setState({ alreadyInUse: true });
      } else {
        this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', '') || get(err, 'response.data.errors.message', defaultErrorMessage) });
      }
    });
  }

  tokenExpiryPopup() {
    const { linkSent, showTokenExpiryPopup } = this.state;
    return (
      <LatestModalDesign wrapperExtraClass="z-index-13x9-imp" isOpen={showTokenExpiryPopup} hideFooter hideBackArrow>
        <div className="centered">
          <div className="mb-30"><img src={AlertIcon} alt="Expired" className="w-h-100" /></div>
          <div className="size-20-28 font-weight-bold contentPrimary mb-12">This link has expired</div>
          <div className="size-14-20 contentTertiary mb-12">Please tap the button below to send a new reset password link, and click on the link in your email.</div>
          {linkSent ? <div className="size-14-16 DarkBluePrimary font-weight-bold">Link sent!</div> : <button type="button" className="btn btn-accent-light" onClick={() => this.resendLink({ reset_password_token: this.state.resetToken })}>Resend Link</button>}
        </div>
      </LatestModalDesign>
    );
  }

  resetEmailSentModal() {
    const { linkSent, showEmailSentPopup } = this.state;
    return (
      <LatestModalDesign wrapperExtraClass="z-index-13x9-imp" isOpen={showEmailSentPopup}
        hideBackArrow={!this.props.setShowEmailSentInParent} customIcon={<Close />} back={() => {
          this.setState({ showEmailSentPopup: false });
          if (this.props.setShowEmailSentInParent) {
            this.props.setShowEmailSentInParent(false);
          }
        }}
        hideFooter noSmMarginB contentExtraClass="sm-h-auto-imp sm-br-rd-16-imp">
        <div className="centered p-8">
          <div><img src={EmailSentIcon} alt="email sent icon" className="w-h-150 mb-40" /></div>
          <div className="size-24-32 font-weight-bold SoftBlack mb-40">Password Reset Link Sent</div>
          <div className="size-14-20 NickleColor mb-40">Please check your email to confirm and create a new password.</div>
          {linkSent ? <div className="size-14-16 DarkBluePrimary font-weight-bold">Link sent!</div> : <button type="button" className="btn btn-accent-light" onClick={() => this.resendLink({ email: get(this.props, 'email', '') || get(this.props, 'client.email', '') })}>Resend Link</button>}
        </div>
      </LatestModalDesign>
    );
  }

  passwordInputModal() {
    const {
      showPassInputModal, currentPassword, password,
      confirmPassword, showCurrentPass, showPass, showConfirmPass, alreadyInUse,
      resetToken, passwordsMatch, listOfPassMistakes,
    } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    return (
      <LatestModalDesign
        wrapperExtraClass="z-index-13x9-imp"
        isOpen={showPassInputModal}
        hideFooter
        hideBackArrow={!get(this.props, 'client.force_password_reset', false)}
        customIcon={<Close />}
        back={() => {
          if (!get(this.props, 'client.force_password_reset', false)) {
            this.setState({ showPassInputModal: false });
          }
        }}
      >
        <form
          className="mt-24"
          onSubmit={(e) => {
            e.preventDefault();
            this.submitNewPass();
          }}
        >
          <div className="size-24-32 contentPrimary font-weight-bold mb-8">Reset Password</div>
          {loggedIn && !resetToken ? (
            <div className="mb-28">
              <div className={`form-group input-with-addon ${currentPassword ? 'success' : ''}`}>
                <label className="contentPrimary size-16-20 mb-8">Current Password</label>
                <input
                  type={showCurrentPass ? 'text' : 'password'}
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  value={currentPassword}
                  onChange={(event) => this.setState({ currentPassword: event.target.value })}
                  placeholder="Current password"
                />
                {currentPassword ? (
                  <div className="input-addon-r d-flex-only align-items-center">
                    <Cancel className="cursor-pointer w-h-20 contentPrimary mr-16" onClick={() => this.setState({ currentPassword: '' })} />
                    {showCurrentPass ? <VisibilityOff className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showCurrentPass: false })} /> : <Visibility className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showCurrentPass: true })} />}
                  </div>
                ) : null}
                {currentPassword ? <img src={checkMark} className="input-checkmark r-82" alt="checkmark-img" /> : null}
              </div>
            </div>
          ) : null}
          <div className="mb-28">
            <div className={`form-group input-with-addon ${password ? 'success' : ''}`}>
              <label className="contentPrimary size-16-20 mb-8">New Password</label>
              <input
                type={showPass ? 'text' : 'password'}
                className={`form-control generic-input-style size-16-24 contentPrimary ${!isEmpty(listOfPassMistakes) || alreadyInUse ? 'error-state' : ''}`}
                value={this.state.password}
                onChange={(event) => this.setState({ password: event.target.value, alreadyInUse: false })}
                onBlur={this.checkPassRules}
                placeholder="New password"
              />
              {password ? (
                <div className="input-addon-r d-flex-only align-items-center">
                  <Cancel className="cursor-pointer w-h-20 contentPrimary mr-16" onClick={() => this.setState({ password: '' }, this.checkPassRules)} />
                  {showPass ? <VisibilityOff className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showPass: false })} /> : <Visibility className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showPass: true })} />}
                </div>
              ) : null}
              {isEmpty(listOfPassMistakes) && password ? <img src={checkMark} className="input-checkmark r-82" /> : null}
            </div>
            {isEmpty(listOfPassMistakes) ? null : (
              <div className="systemRed mt-8 size-14-20">
                Your new password must contain the following:
                <ul>{map(listOfPassMistakes, (mistake, ky) => (<li key={`pass-rule-${ky}`}>{mistake}</li>))}</ul>
              </div>
            )}
            {alreadyInUse ? (
              <div className="systemRed mt-8 size-14-20">
                Your new password must be different from current password.
              </div>
            )
              : null}
          </div>
          <div className={`form-group input-with-addon ${confirmPassword ? 'success' : ''}`}>
            <label className="contentPrimary size-16-20 mb-8">Confirm New Password</label>
            <input
              type={showConfirmPass ? 'text' : 'password'}
              className={`form-control generic-input-style size-16-24 contentPrimary ${!passwordsMatch ? 'error-state' : ''}`}
              value={confirmPassword}
              onChange={(event) => {
                this.setState({ confirmPassword: event.target.value }, () => {
                  this.setState({ passwordsMatch: this.state.confirmPassword === this.state.password });
                });
              }}
              placeholder="Confirm new password"
            />
            {confirmPassword ? (
              <div className="input-addon-r d-flex-only align-items-center">
                <Cancel className="cursor-pointer w-h-20 contentPrimary mr-16" onClick={() => this.setState({ confirmPassword: '' })} />
                {showConfirmPass ? <VisibilityOff className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showConfirmPass: false })} /> : <Visibility className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showConfirmPass: true })} />}
              </div>
            ) : null}
            {confirmPassword && passwordsMatch ? <img src={checkMark} className="input-checkmark r-82" alt="chkmrk-img" /> : null}
          </div>
          {!passwordsMatch ? <div className="systemRed mt-8 size-14-20 mb-28">Your passwords do not match.</div> : null}
          <CTAButton
            text="Continue"
            additionalClass="full-width-btn"
            additionalWrapperClass="br-top-none-imp"
            action={this.submitNewPass}
            disabled={!passwordsMatch || !password || (!currentPassword && !resetToken)}
            bottomSection={loggedIn ? (
              <div className="centered">
                <button
                  type="button"
                  className="btn p-0 btn-link deco-none CactusFlowerPrimary font-weight-bold mt-24 mb-24 size-18-24"
                  onClick={() => this.resendLink({ email: get(this.props, 'client.email', ''), reset_password_token: get(this.state, 'resetToken', '') })}
                >
                  Forgot password?
                </button>
              </div>
            ) : null}
          />
        </form>
        {/* TODO why not use the action cta built-in the modal itself */}
      </LatestModalDesign>
    );
  }

  resetCompleteModal() {
    const { showSuccessModal } = this.state;
    return (
      <LatestModalDesign wrapperExtraClass="z-index-13x9-imp" isOpen={showSuccessModal} hideFooter hideBackArrow>
        <div className="mb-40"><ConfirmationLottie /></div>
        <div className="size-24-32 contentPrimary font-weight-bold centered">Password reset complete</div>
        <div className="centered">
          <button
            type="button"
            className="btn btn-accent-light"
            onClick={() => {
              this.setState({ showSuccessModal: false });
            }}
          >
            Continue
          </button>
        </div>
      </LatestModalDesign>
    );
  }

  keepYourAccountSafeModal() {
    return (
      <LatestModalDesign wrapperExtraClass="z-index-13x9-imp" isOpen={this.state.showKeepSafePopup} hideFooter hideBackArrow noSmMarginB contentExtraClass="sm-h-auto-imp sm-br-rd-16-imp">
        <div className="p-8">
          <div className="mb-24 size-24-32 contentPrimary font-weight-bold">Enhanced Account Security</div>
          <div className="mb-24 size-16-24 contentPrimary">We've added new requirements to make your account even safer. Please go ahead and reset your password.</div>
          <div className="centered">
            <button
              type="button"
              className="btn cta-primary"
              onClick={() => {
                const loggedIn = get(this.props, 'client.loggedIn', false);
                this.setState({ showPassInputModal: true }, () => {
                  this.setState({ showKeepSafePopup: false });
                });
                seResetPasswordInitiate('Forced Flow', get(this.props, 'client.loggedIn', false));
                seResetPasswordFinalScreen(loggedIn ? 'Forced Flow' : 'Log In', loggedIn);
              }}
            >
              Reset password
            </button>
          </div>
        </div>
      </LatestModalDesign>
    );
  }

  renderErrorModal() {
    return (
      <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    if (!hasUuid()) {
      return (
        <>
          {this.keepYourAccountSafeModal()}
          {this.resetEmailSentModal()}
          {this.tokenExpiryPopup()}
          {this.passwordInputModal()}
          {this.renderErrorModal()}
          {this.resetCompleteModal()}
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  fieldsHolder: state.fieldsHolder,
});

export default withLocalize(connect(mapStateToProps, {})(ResetPasswordPopups));
