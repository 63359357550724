import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import translations from '../../../Translations/giftCards.json';
import { setGiftCard, setGiftCardCart } from '../../../Actions';
import { HotJar } from '../../../constants.js';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import { sortBy, isEmpty, map, size } from 'lodash'
import { giftCardAmountEvent } from '../../Shared/WebAnalytics';
import whiteArrow from "../images/Arrow_down_white.png"
import '../index.css'

HotJar();
class Step4 extends React.Component {
    constructor(props) {
        super(props);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getGiftCardCityData = this.getGiftCardCityData.bind(this);
        this.updateGiftCard = this.updateGiftCard.bind(this);

        this.state = {
            width: window.innerWidth,
            gcCityData: [],
            customAmount: null,
            showLabel: false,
            colDivision: "col"
        };

        this.props.addTranslation(translations);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getGiftCardCityData()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }
    getGiftCardCityData() {
        axios.get(`${API_ROOT}/gift_cards`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
            const pricingsArray = resp.data.data;

            if (!isEmpty(pricingsArray)) {
                let temp = sortBy(pricingsArray, (el) => (el.raw_amount)),
                    ln = size(temp);
                this.setState({ gcCityData: temp, colDivision: ln > 5 && ln % 2 === 0 ? "col-sm-2" : "col" });
            } else {
                console.log('No options for this city');
            }
        });
    }

    updateGiftCard(values) {
        giftCardAmountEvent(this.props.giftCard.deliveryMethod, this.props.giftCardCart.country, this.props.giftCardCart.cityName, values.minutes, values.amount);
        this.props.setGiftCard({
            ...this.props.giftCard,
            ...values
        });
    }
    render() {
        let { customAmount, showLabel, gcCityData, colDivision } = this.state;
        return (
            <div className="full-height-section p-small bg-primary font-style-medium txt-align-center step4" id="4">
                <p className="steps-indicator light-mode-secondary mb-sm-32">Step 2 of {this.props.totalSteps}</p>
                <p className="title-1 mb-48 mb-sm-45">Select an amount</p>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (customAmount && customAmount >= 25 && customAmount <= 2000) {
                        this.updateGiftCard({ amount: customAmount, currency: 'usd', currencySymbol: '$' })
                        this.props.updateFieldAndStep("amount", customAmount, this.props.totalSteps === 3 ? 6 : 5)
                    }
                }}>
                    <div className="row equal-flex">
                        {map(gcCityData, (el, it) => {
                            return (<div className={`col-xs-12 ${colDivision} mb-16 prl-5`} key={`card-option-${it}`}>
                                <div className={`light-box mb-16 flex-centered ${this.props.amount === el.raw_amount && this.props.amount !== customAmount ? 'selected-type' : ''}`} onClick={() => {
                                    this.updateGiftCard({ amount: el.raw_amount, currency: 'usd', currencySymbol: '$' })
                                    this.props.updateFieldAndStep("amount", el.raw_amount, this.props.totalSteps === 3 ? 6 : 5)
                                }}>
                                    <div className="amount-heading mb-12 mb-sm-4 font-style-medium">{el.amount}</div>
                                    {/* <p className="small-text font-style-regular">{el.subtitle || ""}</p> */}
                                </div>
                            </div>)
                        })
                        }
                    </div>
                    <div className="row">
                        <div className='col-xs-12 prl-5' key="custom-card-option">
                            <div className={`light-box ${customAmount ? 'selected-type' : ''}`}>
                                <div className={`small-text font-style-regular text-align-left smooth-show-hide ${showLabel || customAmount ? "visible" : ""}`}>Custom</div>
                                <div className="d-flex-only align-items-center">
                                    <div className="amount-heading font-style-medium">$</div>
                                    <div className='font-style-regular flex-1-1-10'>
                                        <input type="number" className={`form-control d-flex-only w-100 custom-color ${customAmount ? "amount-heading" : ""}
                                        ${customAmount && !(customAmount >= 25 && customAmount <= 2000) ? " error-state" : ""}
                                        `} placeholder={showLabel ? "" : "Custom"} style={{ border: "none" }}
                                            value={customAmount} onChange={(e) => {
                                                let newValue = e.target.value;
                                                if (/^\d*$/.test(newValue)) {
                                                    this.setState({ customAmount: Number(newValue) || null })
                                                }
                                            }}
                                            onKeyPress={(e) => {
                                                // Prevent '.' from being entered
                                                if (e.key === '.') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onFocus={() => {
                                                if (!showLabel) this.setState({ showLabel: true })
                                            }}
                                            onBlur={() => {
                                                if (showLabel) this.setState({ showLabel: false })
                                            }} />
                                    </div>
                                </div>
                            </div>
                            {customAmount && !(customAmount >= 25 && customAmount <= 2000) ? <div className="systemRed mt-8 size-16-24 txt-left mlr-5">Custom amount must be between $25 and $2,000.</div> : null}
                        </div>
                    </div>
                    {customAmount && customAmount >= 25 && customAmount <= 2000 ? <button type='submit' className={`btn cta-btn cactus-flower-primary mt-30`}
                    >Select <img className="img-icon" src={whiteArrow} />
                    </button> : null}
                </form>
            </div>
            // todo l font bdu t3dil
        )
    }
}

const mapStateToProps = state => ({
    giftCard: state.giftCard,
    giftCardCart: state.giftCardCart
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setGiftCard, setGiftCardCart }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step4));
