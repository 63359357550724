import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import HelmetTag from './../Shared/HelmetTag';
import { setBookingFlowStep, setCart, setProducts, loadClient, setBookingFlowPreviousStep, setField } from '../../Actions';
import { HOST_ROOT } from '../../apiConfig';
import MainBackgroundWrapper from '../Shared/MainBackgroundWrapper';
import NavbarRedesign from '../Shared/NavbarRedesign';
import { get, find, filter, findIndex, includes, size, isEmpty, assign, orderBy } from 'lodash';
import { seBookingStart, componentMountedInitCalls, hashAffiliateUtms } from '../Shared/WebAnalytics';
import AddressStep from './AddressStep';
import ServiceStep from './ServiceStep';
import RecipientStep from './RecipientStep';
import TimingStep from './TimingStep';
import { STEPS, PROGRESS_STEPS } from './Shared/constants';
import tempImg from '../../Assets/Images/temp-bg.png';
import ModalityStep from './ModalityStep';
import ProviderPickStep from './ProviderPickStep';
import DashedPizzaTracker from '../Shared/DashedPizzaTracker';
import CouplePreferenceStep from './CouplePreferenceStep';
import AuthenticationStep from './AuthenticationStep';
import ReviewStep from './ReviewStep';
import { getCookie, setCookie, ROUTES, hasUuid, defaultErrorMessage } from '../../constants';
import ErrorModal from '../Shared/ErrorModal';
import CheckoutStep from './CheckoutStep';
import ConfirmationStep from './ConfirmationStep';
import LoadingOverlay from '../Shared/LoadingOverlay';
import { getGuestCart, initGuest } from './Shared/helpers';
import { decode } from './Shared/encode';
import { Redirect } from 'react-router-dom';
import './Assets/Styles/index.css';
import { sendAttributions } from '../Shared/Helpers';
import Persona from 'persona';
import { verifyPhoneNumber } from '../Shared/Helpers';
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bgImagePath: tempImg,
            darkenHeader: false,
            error: null,
            showErrorModal: false,
            currentStepId: '',
            currentStepState: null,
            showBgFlag: false,
            showLoader: false,
            redirect: false,
            width: window.innerWidth,
            personaInstance: null,
            exceptionUiShowBtm: false
        }
        this.changeBackground = this.changeBackground.bind(this);
        this.toggleNavBarStyle = this.toggleNavBarStyle.bind(this);
        this.renderStep = this.renderStep.bind(this);
        this.assignToCart = this.assignToCart.bind(this);
        this.resetCart = this.resetCart.bind(this);
        this.triggerBookingStartEvent = this.triggerBookingStartEvent.bind(this);
        this.applyB2bDiscount = this.applyB2bDiscount.bind(this);
        this.errorModal = this.errorModal.bind(this);
        this.relevantGoBack = this.relevantGoBack.bind(this);
        this.isDarkHeader = this.isDarkHeader.bind(this);
        this.showVideoBg = this.showVideoBg.bind(this);
        this.hideHeaderActions = this.hideHeaderActions.bind(this);
        this.updateBookingStep = this.updateBookingStep.bind(this);
        this.goToAuthentication = this.goToAuthentication.bind(this);
        this.displayError = this.displayError.bind(this);
        this.setLoaderFlag = this.setLoaderFlag.bind(this);
        this.getB2bUuid = this.getB2bUuid.bind(this);
        this.createGuest = this.createGuest.bind(this);
        this.initiateOrShowPersona = this.initiateOrShowPersona.bind(this);
        this.removePersonaInstance = this.removePersonaInstance.bind(this);
    }

    componentDidMount() {
        let currentStep = get(this.props, 'bookingFlow.step', STEPS[0])
        let loggedIn = get(this.props, 'client.loggedIn', false);
        let rebook = get(this.props, "location.state.booking.cart.rebook", false);
        let cartId = get(this.props, 'booking.cart.id', null);
        let defaultStepId = get(this.props, "match.params.step", "");
        let defaultStep = find(STEPS, (step) => (step.id === defaultStepId));
        if (defaultStep) {
            this.updateBookingStep(defaultStep, true)
        }
        else {
            this.updateBookingStep(STEPS[0], true)
        }
        // cart reset
        if ((!hasUuid() && (!loggedIn || (!cartId && !rebook) || (currentStep === 'CONFIRMATION')))) {
            this.resetCart();
        }
        if (rebook && cartId) {
            let defaultStepId = get(this.props, "match.params.step", "");
            let defaultStep = find(STEPS, (step) => (step.id === defaultStepId));
            this.assignToCart(get(this.props, "location.state.booking", {}))
            this.assignToCart(get(this.props, "location.state.products.0", null))
            this.props.setProducts({ products: get(this.props, "location.state.products", []) })
            this.updateBookingStep(defaultStep);
        }
        if (hasUuid()) {
            let defaultStep = find(STEPS, (step) => (step.id === "TIMING"));
            this.updateBookingStep(defaultStep);
        }
        if (get(window, "performance.navigation.type", 0) === 0) {
            let utmObj = hashAffiliateUtms();
            if (!isEmpty(utmObj)) {
                sendAttributions(assign(utmObj, { location: "booking_flow_start" }), get(this.props, "client.user_id", null))
            }
        }
        this.triggerBookingStartEvent(cartId);
        componentMountedInitCalls(this.props.client);
        this.getB2bUuid();// web analytics
        //  b2b discount 
        this.applyB2bDiscount()

        window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    }
    componentDidUpdate(prevProps) {
        let oldSlug = get(prevProps, "match.params.step", ""),
            newSlug = get(this.props, "match.params.step", "");
        if (oldSlug !== newSlug) {
            let defaultStepId = get(this.props, "match.params.step", "");
            let defaultStep = find(STEPS, (step) => (step.id === defaultStepId));
            this.updateBookingStep(defaultStep, true)
        }
    }
    removePersonaInstance() {
        if (this.state.personaInstance) {
            this.setState({ personaInstance: null })
        }
    }
    initiateOrShowPersona(startEvent, completeEvent, skipEvent, cb, onlyToInitialize = false) {
        if (!this.state.personaInstance) {
            this.setState({
                personaInstance: new Persona.Client({
                    fields: {
                        phoneNumber: get(this.props, "client.phone", ""),
                        addressCountryCode: get(this.props, "client.country", "")
                    },
                    parent: document.getElementById("persona-custom-parent"),
                    templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
                    referenceId: get(this.props, "client.user_id", ""),
                    environment: process.env.REACT_APP_PERSONA_ENV_ID,
                    // onReady: () => {
                    //     startEvent()
                    //     this.state.personaInstance.open();
                    // },
                    onComplete: ({ inquiryId, status, fields }) => {
                        // Inquiry completed. Optionally tell your server about it.
                        verifyPhoneNumber(get(this.props, "client.user_id", ""), inquiryId, (resp) => {
                            this.props.loadClient({ ...get(resp, "data.client", {}), loggedIn: true });
                            completeEvent()
                            if (cb) {
                                cb();
                            }
                        }, (err) => {
                            this.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
                            // this.setState({ personaInstance: null })
                        })
                    },
                    onCancel: ({ inquiryId, sessionToken }) => {
                        if (skipEvent) {
                            skipEvent()
                        }
                        if (cb && get(this.props, "client.loggedIn", false)) {
                            cb();
                        }
                    },
                    onError: (error) => {
                        console.log(error)
                        this.displayError(get(error, "code", defaultErrorMessage));
                    },
                })
            })
        } else if (!onlyToInitialize) {
            startEvent()
            this.state.personaInstance.open();
        }
    }
    updateBookingStep(step, skipHistoryUpdate = false) {
        let searchValue = window.location.search;
        if (get(step, "id", "") === 'AUTHENTICATION') {
            this.props.setBookingFlowPreviousStep(this.state.currentStepState);
        }
        if (!skipHistoryUpdate && get(step, "id", "") !== 'AUTHENTICATION') {
            // we can user replace instead of push and specify state and search values this.props.history.replace({ pathname: `/booking/${step.id}${searchValue}` })
            this.props.history.push(`/booking/${get(step, "id", "")}${searchValue}`)
        }
        this.setState({ showLoader: false, currentStepId: get(step, "id", ""), currentStepState: step, showBgFlag: this.showVideoBg(step) }, () => {
            this.props.setBookingFlowStep(step)
        })
    }
    triggerBookingStartEvent(cartId) {
        let ind = findIndex(STEPS, (el) => (includes(window.location.href, el.id))),
            tempAddrList = orderBy(get(this.props, "addresses", []), ['default'], ['desc'])
        if (ind !== -1) {
            let serviceCategory = get(this.props, "booking.product.title", ''), firstTimeBooker = get(this.props, "client.first_time_booker", false), user_id = get(this.props, "client.user_id", ''), email = get(this.props, "client.email", '');
            let loggedIn = get(this.props, 'client.loggedIn', false),
                rebook = get(this.props, 'booking.cart.rebook', false),
                market = get(this.props, "booking.cart.address.market", get(tempAddrList, "0.market", ""));
            seBookingStart(firstTimeBooker, cartId, user_id, email, serviceCategory ? serviceCategory.toLowerCase() : '', loggedIn, rebook, "booking_23_control", market);
        }
    }
    createGuest(business, guest) {
        let selff = this;
        initGuest(business, guest, (resp) => {
            selff.setLoaderFlag(false)
            let cart_json = get(resp, "data.cart_json", null);
            if (cart_json) {
                this.props.setField("csrfToken", get(resp, "data.csrfToken", ""));
                this.props.setField("listingPageStorage", cart_json)
                this.toggleNavBarStyle(!get(this.props, "fieldsHolder.listingPageStorage.bannerimage", ""));
                this.changeBackground(get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "") || "none");
            }
        }, (error) => {
            selff.setLoaderFlag(false)
            console.log({ error })
        })
    }
    getB2bUuid() {
        let self = this;
        const parsedParams = queryString.parse(window.location.search);
        if (Object.keys(parsedParams).includes('guest') && Object.keys(parsedParams).includes('business')) {
            self.setLoaderFlag(true)
            getGuestCart(parsedParams['guest'], (response_1) => {
                self.setLoaderFlag(false)
                let cart_1 = get(response_1, "data.carts.cart", null);
                if (cart_1) {
                    this.props.setField("csrfToken", get(response_1, "data.csrfToken", ""));
                    this.props.setField("listingPageStorage", get(response_1, "data.carts", null))
                    this.toggleNavBarStyle(!get(this.props, "fieldsHolder.listingPageStorage.bannerimage", ""));
                    this.changeBackground(get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "") || "none");
                    this.assignToCart({ cart: decode(cart_1) })
                } else {
                    this.createGuest(parsedParams["business"], parsedParams['guest'])
                }
            }, (_err) => {
                this.createGuest(parsedParams["business"], parsedParams['guest'])
            })
        } else {
            this.props.setField("csrfToken", "")
            this.props.setField("listingPageStorage", null);
        }
    }
    applyB2bDiscount() {
        const parsedParams = queryString.parse(window.location.search);
        let b2bDiscountCode = '';

        if (Object.keys(parsedParams).includes('aads')) {
            setCookie(parsedParams['aads']);
            b2bDiscountCode = parsedParams['aads'];
        } else if (getCookie('aads') !== null) {
            b2bDiscountCode = getCookie('aads');
        }

        this.assignToCart({ b2bDiscountCode });
    }
    resetCart() {
        this.props.setProducts({ products: [] });
        this.props.setCart({ cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {} });
        this.updateBookingStep(STEPS[0]);
    }
    assignToCart(value) {
        this.props.setCart({
            ...this.props.booking,
            ...value
        });
    }
    changeBackground(imgPath) {
        this.setState({ bgImagePath: imgPath });
    }
    toggleNavBarStyle(tgl = false) {
        let rebook = get(this.props, "booking.cart.rebook", false);
        if (rebook && Number(this.state.width) <= 800) {
            tgl = true;
        }
        this.setState({ darkenHeader: tgl })
    }
    isDarkHeader() {
        return !Boolean(get(this.props, 'bookingFlow.step.withBackground', STEPS[0].withBackground)) || this.state.bgImagePath === "none" || this.state.darkenHeader;
    }
    showVideoBg(step) {
        let withBg = get(step, 'withBackground', false),
            rebook = get(this.props, "booking.cart.rebook", false);
        if (rebook && Number(this.state.width) > 800) {
            return true;
        }
        return Boolean(withBg);
    }
    hideHeaderActions() {
        return Boolean(get(this.props, 'bookingFlow.step.id', STEPS[0].id) === 'AUTHENTICATION');
    }
    setLoaderFlag(showLoader) {
        this.setState({ showLoader })
    }
    renderStep() {
        const parsedParams = queryString.parse(window.location.search);
        if (get(parsedParams, 'auth_error', null)) {
            return <AuthenticationStep isSignin={this.state.isSignin} changeBackground={this.changeBackground} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag}
                back={this.relevantGoBack} />;
        }
        let currentStep = this.state.currentStepId;
        switch (currentStep) {
            case 'ADDRESS':
                return <AddressStep goToAuthentication={this.goToAuthentication} changeBackground={this.changeBackground} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />;
            case 'SERVICE':
                return <ServiceStep changeBackground={this.changeBackground} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />;
            case 'AUTHENTICATION':
                return <AuthenticationStep initiateOrShowPersona={this.initiateOrShowPersona} removePersona={this.removePersonaInstance} isSignin={this.state.isSignin} changeBackground={this.changeBackground} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag}
                    back={this.relevantGoBack} />
            case 'RECIPIENT':
                return <RecipientStep changeBackground={this.changeBackground} toggleNavBarStyle={this.toggleNavBarStyle} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />;
            case 'MODALITY':
                return <ModalityStep changeBackground={this.changeBackground} toggleNavBarStyle={this.toggleNavBarStyle} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />
            case 'TIMING':
                return <TimingStep changeBackground={this.changeBackground} toggleNavBarStyle={this.toggleNavBarStyle} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />;
            case 'COUPLE_PREFERENCE':
                return <CouplePreferenceStep changeBackground={this.changeBackground} toggleNavBarStyle={this.toggleNavBarStyle} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />
            case 'PICKAPRO':
                return <ProviderPickStep assignToCart={this.assignToCart} toggleNavBarStyle={this.toggleNavBarStyle} changeBackground={this.changeBackground} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />
            case 'REVIEW':
                return <ReviewStep assignToCart={this.assignToCart} toggleNavBarStyle={this.toggleNavBarStyle} changeBackground={this.changeBackground} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} updateExceptionUi={(boolVal) => this.setState({ exceptionUiShowBtm: boolVal })} />
            case 'CHECKOUT':
                return <CheckoutStep initiateOrShowPersona={this.initiateOrShowPersona} removePersona={this.removePersonaInstance} assignToCart={this.assignToCart} toggleNavBarStyle={this.toggleNavBarStyle} changeBackground={this.changeBackground} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />
            case 'CONFIRMATION':
                return <ConfirmationStep assignToCart={this.assignToCart} changeBackground={this.changeBackground} toggleNavBarStyle={this.toggleNavBarStyle} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />

            // TODO
            default:
                return <AddressStep goToAuthentication={this.goToAuthentication} changeBackground={this.changeBackground} assignToCart={this.assignToCart} setBookingFlowStepThroughParent={this.updateBookingStep} displayError={this.displayError} setLoaderFlag={this.setLoaderFlag} />
        }
    }
    displayError(error) {
        this.setState({ showLoader: false, showErrorModal: true, error });
    }
    errorModal() {
        return (
            <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
                <p>{this.state.error}</p>
            </ErrorModal>
        )
    }
    goToAuthentication(isSignin) {
        this.setState({ isSignin }, () => {
            let nextStep = find(STEPS, (step) => (get(step, "id", "") === "AUTHENTICATION"));
            // First let's set the previous step to go back to it
            this.props.setBookingFlowPreviousStep(this.state.currentStepState);
            this.updateBookingStep(nextStep);
        })

    }
    relevantGoBack() {
        let currentStep = get(this.props, 'bookingFlow.step.id', STEPS[0].id),
            products = get(this.props, "products", []);
        if (hasUuid() && (currentStep === 'CONFIRMATION' || currentStep === 'TIMING')) {
            let business = get(this.props, "fieldsHolder.listingPageStorage.sub_domain", ""),
                location = get(this.props, "fieldsHolder.listingPageStorage.uuid", ""),
                nextUrl = `https://${business}${process.env.REACT_APP_PERMITTED_DOMAIN}/${location}`;
            if (currentStep === 'CONFIRMATION') {
                this.props.setField("csrfToken", "")
                this.props.setField("listingPageStorage", null);
                this.props.setProducts({ products: [] });
                this.props.setCart({ cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {} });
            }
            window.location.href = nextUrl;
        } else if (currentStep === 'ADDRESS' || currentStep === 'CONFIRMATION') {
            window.location.href = `${HOST_ROOT}${ROUTES.index}`;
        } else if ((currentStep === 'SERVICE' || (currentStep === 'MODALITY' && size(products) === 1)) && get(this.props, "booking.cart.rebook", false)) {
            this.setState({ redirect: true })
        } else {
            this.updateBookingStep(get(this.props, 'bookingFlow.previousStep', STEPS[0]));
        }
    }

    render() {
        let { bgImagePath, showLoader, currentStepId } = this.state;
        let stp = get(this.state, 'currentStepState', null),
            isB2b = Boolean(hasUuid() && get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "")),
            rebook = get(this.props, "booking.cart.rebook", false);
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/appointment_management/",
                state: {
                    cart: get(this.props, "booking.cart", {})
                }
            }}
            />
        }
        return (<>
            <HelmetTag />
            <NavbarRedesign
                relevantGoBack={this.relevantGoBack}
                goToAuthentication={this.goToAuthentication}
                darkenHeader={this.isDarkHeader()}
                hideHeaderActions={this.hideHeaderActions()}
                currentStepId={currentStepId}
                exceptionUiShowBtm={this.state.exceptionUiShowBtm}
            />
            <MainBackgroundWrapper noMarginTop={!hasUuid() && this.state.width < 800 && currentStepId === "ADDRESS"} bgImagePath={bgImagePath} showVideoBg={this.state.showBgFlag}>

                <div className={get(stp, "hasOpaqueBg", false) && !isB2b && !rebook ? 'opaque-bg-wrapper' : isB2b ? ' dark-bg-wrapper' : ''}>
                    {currentStepId === "CONFIRMATION" ? null : <DashedPizzaTracker progressSteps={hasUuid() ? filter(PROGRESS_STEPS, (el) => (el.id !== "DATE")) : PROGRESS_STEPS} currentStep={stp} />}
                    {this.renderStep()}
                </div>
            </MainBackgroundWrapper>
            {this.errorModal()}
            <LoadingOverlay showLoader={showLoader} />
        </>
        );
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    products: get(state, "productsReducer.products", []),
    bookingFlow: state.bookingFlow,
    client: state.client,
    fieldsHolder: state.fieldsHolder,
    addresses: state.addresses
});

export default connect(mapStateToProps, { setBookingFlowStep, setBookingFlowPreviousStep, setCart, setProducts, loadClient, setField })(Index);