/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-useless-escape */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Translate } from 'react-localize-redux';
import { capitalize } from 'lodash';
import locationPin from '../Assets/Images/zip-pin.png';

export default function HeroSection(props) {
  return (
    <Translate>
      { ({ translate }) => (
        <div className="container-full" id="hero">
          <div className="hero" />

          <div className="details-card" id="pricing" name="pricing">
            <h2>Join Soothe Plus</h2>
            <p>
              Take the stress out of self-care with Soothe Plus.
              You'll save up to 25% on all services with credits that never expire.
              Experience all the perks and joy that come with being a Soothe Plus member.
            </p>
          </div>

          <div className="zip-form-container">
            <form>
              <img src={locationPin} className="location-pin" alt="location pin" />
              <input
                required
                id="zipCode"
                type="text"
                pattern={'\d{5}([ \-]\d{4})?'}
                placeholder={translate('soothePlusIndex.zipPlaceholder')}
                value={props.zipCode}
                onChange={(e) => props.updateZipCode(e)}
              />
              <input
                type="submit"
                value={capitalize(translate('global.submit'))}
                className="submit-zip-code-btn"
                onClick={(e) => { e.preventDefault(); props.getSubscriptionsByZip(); }}
              />
            </form>
          </div>
        </div>
      )}
    </Translate>
  );
}
