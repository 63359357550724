/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable import/no-relative-packages */
/* eslint-disable import/extensions */
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import $ from 'jquery';
import globalTranslations from './Translations/global.json';
import { ACTIVE_LANGUAGE_CODE, EN, DE } from './constants.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './App.css';

window.jQuery = $;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: 'English', code: EN },
        { name: 'German', code: DE },
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: ACTIVE_LANGUAGE_CODE,
      },
    });
  }

  // SAVE COUNTRY CODE IN LOCAL STORAGE
  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;
    const hasLanguageChanged = prevLangCode !== curLangCode;

    if (hasLanguageChanged) {
      window.localStorage.setItem('languageCode', curLangCode);
    }
  }

  render() {
    return null;
  }
}

export default withLocalize(App);
