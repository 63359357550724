/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { StarRate, Warning } from '@material-ui/icons';
import {
  get, size, map, isEmpty,
} from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import GrayAvatar from '../../../Assets/Images/carbon_user-avatar-filled.svg';

const useStyles = makeStyles((theme) => ({
  hz_root: {
    width: '100%',
    height: '250px',
    borderRadius: '16px !important',
    position: 'relative',
    boxShadow: 'none !important',
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'flex',
      height: 'auto',
    },
  },
  reschedule_pending: {
    background: '#FFFAF0',
  },
  reschedule_requested: {
    background: '#FFEFED',
  },
  image: {
    width: '35%',
    height: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '250px',
      width: '100%',
      objectFit: 'cover',
    },
  },
  header_wrapper: {
    width: '53%',
  },
  main_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  main_header: {
    width: '53%',
    fontWeight: 500,
    fontSize: 40,
    marginBottom: 30,
  },
  header_text: {
    fontWeight: 500,
    fontSize: 28,
    marginBottom: 10,
    gridColumn: 2,
    gridRow: 1,
  },
  status: {
    fontFamily: '"Poppins-Medium", sans-serif',
    position: 'absolute',
    top: '16px',
    left: '12px',
    padding: '2px 4px',
    background: '#F6F6F6',
    borderRadius: '16px 16px 20px 20px',
    textAlign: 'center',
    color: '#545454',
    fontSize: '14px',
    lineHeight: '16px',
    background: '#F6F6F6',
    fontWeight: 500,
  },
  reschedule_pending_status: {
    background: '#FFC043',
    color: '#000',
  },

  reschedule_requested_status: {
    background: '#E11900',
    color: '#fff',
  },
  partially_confirmed_status: {
    background: '#FFFAF0',
    color: '#BC8B2C',
  },
  confirmed_status: {
    background: '#E6F2ED',
    color: '#05944F',
  },
  display_row: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse !important',
    },
  },
  display_column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title_text: {
    fontFamily: '"Poppins-Medium", sans-serif',
    fontSize: '24px',
    lineHeight: '32px',
    marginRight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  description_wrapper: {
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px 0px',
    },
  },
  description_text: {
    color: '#545454',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  mr_16: {
    marginRight: '16px',
  },
  small_icon_account: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: 'none !important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon_account: {
    height: '56px !important',
    width: '56px !important',
    justifyContent: 'center',
    borderRadius: '50%',
    border: 'none !important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon_dot: {
    height: '6px !important',
    width: '6px !important',
    justifyContent: 'center',
    position: 'relative',
    top: -2,
  },
  icon_rate: {
    height: 22,
    width: 22,
    position: 'relative',
    top: 6,
  },
  btnn: {
    padding: '10px 12px',
    background: '#F1F6FF',
    borderRadius: '9999px',
    fontFamily: '"Poppins-Medium", sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#586B94',
    height: '36px',
  },
  display_none: {
    marginTop: '-4px',
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
  },
  details_text: {
    fontWeight: 400,
    fontSize: 16,
    color: '#757575',
    borderTop: '2px solid #EEEEEE',
    position: 'absolute !important',
    bottom: 0,
    right: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative !important',
      color: '#000 !important',
      borderTop: '0px !important',
    },
  },
  content_fit: {
    border: '1px solid #EEEEEE',
    position: 'relative',
    width: '65%',
    borderRadius: '0px 16px 16px 0px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: '0px 0px 16px 16px !important',
      border: '2px solid #EEEEEE',
    },
  },
  date_time: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '20px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 16px 22px',
    },
  },
  sm_none: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sm_block: {
    display: 'none',
    color: '#757575',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  br_btm_gray: {
    borderBottom: '1px solid #EEEEEE',
  },
  gray_color: {
    color: '#757575',
  },
  alignCenter: {
    textAlign: 'center',
  },
  flex_centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  padding_16: {
    padding: '16px',
  },
  size_12_20: {
    fontSize: '12px',
    lineHeight: '20px',
  },
  margin_top_btm_4: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  p_btm_16: {
    paddingBottom: '16px',
  },
  tiny: {
    width: '11px',
    marginRight: '4.5px',
    height: '100%',
  },
}));

export default function HorizontalCard(props) {
  const [state, setState] = useState({
    width: window.innerWidth,
  });
  const classes = useStyles();
  const {
    status, img_url, title, date, time, buttonLabel,
    buttonAction, prosList, aptId, hideRating, buttonId,
  } = props.info;

  useEffect(() => {
    window.addEventListener('resize', () => {
      setState({ width: window.innerWidth });
    });
  });
  const mainTitle = () => <div className={classes.title_text}>{title}</div>;
  const extraClassBasedOnStatus = (stt) => {
    switch (stt) {
      case 'Confirmed':
        return 'confirmed';
      case 'Partially Confirmed':
        return 'partially_confirmed';
      case 'Reschedule Pending':
        return 'reschedule_pending';
      case 'Reschedule Requested':
        return 'reschedule_requested';
      case 'Requires Attention':
        return 'reschedule_requested';
      default:
        return '';
    }
  };
  if (status === 'Complete' && state.width < 960) {
    return (
      <div
        className="row"
        onClick={() => {
          if (buttonAction) {
            buttonAction();
          }
        }}
      >
        <div className={`col-xs-3 col-sm-2  ${classes.flex_centered}`}>
          {isEmpty(prosList) ? null
            : (
              <AvatarGroup max={2}>
                {map(prosList, (pro, it) => (
                  <Avatar
                    key={`pro-${it}-${aptId}`}
                    className={classes.small_icon_account}
                    alt={get(pro, 'first_name', '')}
                    src={get(pro, 'avatar_url', '') || GrayAvatar}
                  />
                ))}
              </AvatarGroup>
            )}
        </div>
        <div className={`col-xs-6 col-sm-7 ${classes.br_btm_gray} ${classes.p_btm_16}`}>
          {mainTitle()}
          <div className={`${classes.sm_block} ${classes.margin_top_btm_4}`}>
            {map(prosList, (pro, it) => {
              const proname = get(pro, 'first_name', '');
              return (
                <span key={`pro-rate-${it}-${aptId}`} className={classes.description_text}>
                  {!proname ? null : it === 0 ? `with ${proname}` : ` and ${proname}`}
                </span>
              );
            })}
          </div>

          <div className={`${classes.gray_color} ${classes.size_12_20}`}>
            {date}
            {' '}
            at
            {' '}
            {time}
          </div>
        </div>
        <div className={`col-xs-3 ${classes.br_btm_gray} ${classes.flex_centered}`}>
          {buttonLabel ? (
            <button
              type="button"
              {...(buttonId ? { id: buttonId } : {})}
              className={`btn ${classes.btnn}`}
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                }
                buttonAction();
              }}
            >
              {buttonLabel}
            </button>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <Card
      className={`${classes.hz_root} ${classes[extraClassBasedOnStatus(status)]} shrink-avatar-group`}
      onClick={() => {
        if ((state.width < 960 || status === 'Complete') && buttonAction) {
          buttonAction();
        }
      }}
    >
      <img className={classes.image} src={img_url} />
      <div className={`${classes.status} ${classes[`${extraClassBasedOnStatus(status)}_status`]}`}>
        {status === 'Partially Confirmed' ? <Warning className={classes.tiny} /> : null}
        {status}
      </div>
      <div className={classes.content_fit}>
        <div className={classes.display_column}>
          <div className={classes.description_wrapper}>
            <div className={classes.display_row}>
              {isEmpty(prosList) ? null
                : (
                  <AvatarGroup max={2} className={classes.mr_16}>
                    {map(prosList, (pro, it) => (
                      <Avatar
                        key={`pro-${it}-${aptId}`}
                        className={`avatar-img ${size(prosList) > 1 ? 'w-h-28' : ''}`}
                        alt={get(pro, 'first_name', '')}
                        src={get(pro, 'avatar_url', '') || GrayAvatar}
                      />
                    ))}
                  </AvatarGroup>
                )}

              <div className={classes.display_column}>
                {size(prosList) === 1 && !hideRating && get(prosList, '0.number_of_bookings', '') ? (
                  <div className={classes.sm_block}>
                    <StarRate className={classes.icon_rate} />
                    {get(prosList, '0.rating', '')}
                    {' '}
                    (
                    {get(prosList, '0.number_of_bookings', '')}
                    )
                  </div>
                ) : hideRating ? <div className={classes.sm_block}>Provider Pending</div> : null}
                {mainTitle()}
                <div className={classes.sm_none}>
                  {map(prosList, (pro, it) => {
                    const proname = get(pro, 'first_name', '');
                    return (
                      <span key={`pro-rate-${it}-${aptId}`} className={classes.description_text}>
                        {!proname ? '' : it === 0 ? `with ${proname}` : ` and ${proname}`}
                        {size(prosList) === 1 && !hideRating && get(pro, 'number_of_bookings', '') ? (
                          <>
                            {get(pro, 'rating', 0) ? (
                              <>
                                {` • ${get(pro, 'rating', 0)}`}
                                <StarRate className={classes.icon_rate} />
                                {' '}
                                •
                                {' '}
                              </>
                            ) : null}
                            {get(pro, 'number_of_bookings', 0)}
                            {' '}
                            Bookings
                          </>
                        ) : null}
                      </span>
                    );
                  })}
                </div>
              </div>

              {buttonLabel ? (
                <button
                  type="button"
                  {...(buttonId ? { id: buttonId } : {})}
                  className={`btn ${classes.btnn} ${classes.sm_none}`}
                  onClick={(e) => {
                    if (e) {
                      e.stopPropagation();
                    }
                    buttonAction();
                  }}
                >
                  {buttonLabel}
                </button>
              ) : null}
            </div>
          </div>

          <div className={classes.details_text}>
            <div className={classes.date_time}>
              {date}
              {' '}
              at
              {' '}
              {time}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
