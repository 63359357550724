import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  isEmpty, get, map, indexOf, remove, intersection,
} from 'lodash';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import CheckCircle from '../../../Assets/Images/Check_circle.png';
import plc from '../../../Assets/Images/placeholder.png';

class EnhancementsPerPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productEnhancementsInState: this.props.productEnhancements,
    };
    this.buildEnhancementsObject = this.buildEnhancementsObject.bind(this);
    this.updateState = this.updateState.bind(this);
    this.displayListItem = this.displayListItem.bind(this);
    this.enhancementsSlider = this.enhancementsSlider.bind(this);
  }

  componentDidMount() {
    this.buildEnhancementsObject();
  }

  buildEnhancementsObject() {
    const cp = get(this.props, 'cartProduct', {});
    if (!isEmpty(cp)) {
      const addonIds = map(get(this.props.cartProduct, 'cart_product_add_on_options', []), (prd) => (prd.id));
      this.setState({ productEnhancementsInState: intersection(addonIds, cp.option_ids) });
    }
  }

  updateState(enhId) {
    const arr = get(this.state, 'productEnhancementsInState', []);
    if (indexOf(arr, enhId) === -1) {
      arr.push(enhId);
    } else {
      remove(arr, (val) => (val === enhId));
    }
    this.setState({ productEnhancementsInState: arr });
    this.props.enhancementClick(this.props.cartProduct.id, arr);
  }

  displayListItem(enh, selectionIndex, it) {
    const selected = Boolean(selectionIndex !== -1);
    return (
      <div
        className="addons-card "
        onClick={() => {
          this.updateState(enh.id);
        }}
        id={this.props.productIteration !== 0 ? `second-addon-${it + 1}` : `addon-${it + 1}`}
      >
        <div className="row">
          <div className="col-xs-4">
            <div className="p-relative">
              <img className="w-h-48 border-radius-4" src={enh.web_image_url || plc || ''} alt="" />
              {selected ? (
                <div className="selection-overlay w-h-48">
                  <img src={CheckCircle} alt="Check-circle" />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-xs-8">
            <div className="medium-font mb-4">{enh.title || ''}</div>
            <div className="size-12-20 color-gray">{enh.price ? enh.price.replace(/([.,])00/g, '') : ''}</div>
          </div>
        </div>
      </div>
    );
  }

  enhancementsSlider(newAddons) {
    const { productEnhancementsInState } = this.state;
    return (
      <Slider
        slidesToShow={3}
        swipeToSlide
        focusOnSelect
        infinite={false}
        responsive={[
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
              arrows: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.25,
              arrows: false,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1.25,
              arrows: false,
            },
          },
        ]}
      >
        {(map(newAddons, (enh, index) => {
          const selectionIndex = indexOf(productEnhancementsInState, enh.id);
          return (
            <div key={`enhancement-${this.props.productIteration}-${index}`}>
              {this.displayListItem(enh, selectionIndex, index)}
            </div>
          );
        }))}
      </Slider>
    );
  }

  render() {
    const { cartProduct } = this.props;
    const newAddons = get(cartProduct, 'cart_product_add_on_options', []);

    if (!isEmpty(newAddons)) {
      return (
        <div className="mb-16">
          <div className="third-title-style">Enhance your experience</div>
          {this.enhancementsSlider(newAddons)}
        </div>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

EnhancementsPerPerson.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(EnhancementsPerPerson));
