/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import translations from '../../../Translations/bookingFlow.json';
import { defaultErrorMessage, blankHref } from '../../../constants';
import ccDark from '../Assets/Images/cc-generic-dark.png';
import ccLight from '../Assets/Images/cc-generic-light.png';
import deleteCard from '../Assets/Images/delete-payment-icon.png';
import ErrorModal from '../../Shared/ErrorModal';
import '../Assets/Styles/CreditCardsModal.css';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import Modal from '../../Shared/Modal';

class CreditCardsModal extends React.Component {
  constructor(props) {
    super(props);

    this.selectedCreditCard = this.selectedCreditCard.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.renderMainModal = this.renderMainModal.bind(this);

    this.state = {
      showModal: false,
      error: null,
    };

    this.props.addTranslation(translations);
  }

  selectedCreditCard(event) {
    const creditCardId = parseInt(event.currentTarget.getAttribute('cc-id'), 10);
    const creditCard = _.find(this.props.creditCards, (cc) => cc.id === creditCardId);
    this.props.onChange(creditCardId, creditCard);
  }

  deleteCreditCard(index) {
    let message;
    const creditCard = this.props.creditCards[index];

    if (creditCard) {
      axios.delete(`${API_ROOT}/credit_cards/${creditCard.id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER).then((resp) => {
        const { result, success } = resp.data;

        if (result || success) {
          this.props.reload();
        } else {
          message = _.result(_.first(resp.data.errors || {}), 'message') || _.result(resp.data.errors || {}, 'message') || defaultErrorMessage;
          this.setState({ showModal: true, error: message });
        }
      }).catch((error) => {
        message = defaultErrorMessage;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
          message = _.result(_.first(error.response.data.errors || {}), 'message');
        }

        this.setState({ showModal: true, error: message });
      });
    }
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showModal}
        close={() => { this.setState({ showModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  renderMainModal(content) {
    return (
      <Translate>
        {({ translate }) => (
          <Modal title={translate('bookingFlow.paymentMethods')} isOpen close={() => { this.props.close(); }}>
            <div style={{ textAlign: 'left' }}>
              {content}
            </div>
          </Modal>
        )}
      </Translate>
    );
  }

  render() {
    const content = (
      <Translate>
        {({ translate }) => (
          <div className="">
            <div className="creditCards">
              {this.props.creditCards.map((creditCard, index) => (
                <div
                  className={`credit-card-wrapper ${this.props.creditCardId === creditCard.id ? 'selected' : ''}`}
                  key={`credit-card-${creditCard.id}`}
                  cc-id={creditCard.id}
                  onClick={this.selectedCreditCard}
                >
                  <div className="radio-container">
                    <input className="radio-item" id={creditCard.id} type="radio" value={creditCard.id} defaultChecked={this.props.creditCardId === creditCard.id} />
                    <label className="label-item" htmlFor={creditCard.id}><img src={this.props.creditCardId === creditCard.id ? ccDark : ccLight} alt="cc-icon" /></label>
                    <div className="check" style={{ display: 'none' }} />
                  </div>
                  <div className="credit-card-content">
                    <div className="cc-details-type">{creditCard.type}</div>
                    <div className="cc-details">
                      *****
                      {creditCard.display_string.substr(creditCard.display_string.length - 8)}
                    </div>
                  </div>

                  <div className="delete-btn-container">
                    <a className="btn-delete" href={blankHref} onClick={(e) => { e.preventDefault(); this.deleteCreditCard(index); }}>
                      <img className="delete-icon" src={deleteCard} alt="delete-icon" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="call-to-action-container">
              <a className="btn has-spinner continue-btn " href={blankHref} onClick={(event) => { event.preventDefault(); this.props.addNewCreditCard(); }}>
                <span className="spinner"><i className="glyphicon glyphicon-refresh" /></span>
                {translate('bookingFlow.addPaymentMethod')}
              </a>
            </div>
          </div>
        )}
      </Translate>
    );
    return (
      <div className="overlay creditCardsModal">
        <div className="popup">
          {this.renderMainModal(content)}
        </div>
        {this.errorModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  creditCards: state.creditCards,
});

CreditCardsModal.propTypes = {
  addNewCreditCard: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  creditCards: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  creditCardId: PropTypes.number,
};

export default withLocalize(connect(mapStateToProps)(CreditCardsModal));
