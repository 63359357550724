import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GiftCardsBase from '../GiftCardsBase';
import { setGiftCardCart } from '../../../Actions';
import { injectStripe, CardExpiryElement} from 'react-stripe-elements';

class CardExpirationDate extends GiftCardsBase {
  constructor(props){
    super(props);
    this.props.setGiftCardCart({
      ...this.props.giftCardCart,
      stripe: this.props.stripe
    });
  }


  render () {
    return (
      <CardExpiryElement
      classes={{
        base: "stripe-cardnumber-element input-style",
      }}
      
      onChange={(obj)=>{
        if(this.props.onChange){
          this.props.onChange(obj);
        }
      }}
    />);
  }
}

const mapStateToProps = state => ({    
  giftCardCart: state.giftCardCart
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setGiftCardCart }, dispatch);
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(CardExpirationDate));
