import React from 'react';
import $ from 'jquery';
class RecipientName extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }
    render() {
        const description = "Adding a name creates a more personalized experience for you and your Provider, especially when booking an appointment for someone else.";
        return (<div className=''>
            <div className='row mb-16'>
                <div className='col-xs-9'>
                    <div className='size-24-32 color-black medium-font'>Client name</div>
                    <div className='size-18-28 color-gray'>Enter name • Required</div>
                </div>
                <div className='col-xs-3 txt-right'>
                    <button className='btn infoButton' data-toggle="tooltip" data-placement="bottom" title={description}>Why?</button>
                </div>
            </div>
            <div>
                <input type='text' className='gray-input form-control' defaultValue={this.props.defaultValue || ""}
                    onChange={(event) => this.props.updateClientName(event.target.value || '')} maxLength={255}
                    id={this.props.prdIndex === 0 ? "clientNameTextField" : "secondClientNameTextField"} />
            </div>
        </div>);
    }
}
export default RecipientName;